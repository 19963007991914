import {
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAIL,
  UPDATE_COUPON_REQUEST,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_FAIL,
  GET_COUPON_REQUEST,
  GET_COUPON_SUCCESS,
  GET_COUPON_FAIL,
  LOAD_COUPONS_REQUEST,
  LOAD_COUPONS_SUCCESS,
  LOAD_COUPONS_FAIL,
  LIST_COUPONS_REQUEST,
  LIST_COUPONS_SUCCESS,
  LIST_COUPONS_FAIL,
  DELETE_COUPON_REQUEST,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAIL,
  DELETE_COUPON_RESET,
  CLEAR_COUPON_ERRORS,
} from "../constants/CouponConstants";

const initialState = {
  coupons: [],
  flatCoupons: [],
  discountCoupons: [],
  coupon: {},
  loading: false,
  updated: false,
  added: false,
  deleted: false,
  error: null,
  filteredCouponCount: 0,
};

export const couponsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_COUPONS_REQUEST:
    case GET_COUPON_REQUEST:
    case LIST_COUPONS_REQUEST:
    case CREATE_COUPON_REQUEST:
    case UPDATE_COUPON_REQUEST:
    case DELETE_COUPON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_COUPONS_SUCCESS:
    case LIST_COUPONS_SUCCESS:
      return {
        ...state,

        loading: false,
        coupons:action.payload.coupons,
        // flatCoupons: action.payload.flatCoupons,
        // discountCoupons: action.payload.discountCoupons,
      };
    case GET_COUPON_SUCCESS:
      return {
        ...state,

        loading: false,
        coupon: action.payload,
      };
      case DELETE_COUPON_SUCCESS:
        const deletedCoupon = action.payload;
      
        let updatedCoupons = state.coupons?.filter(
          (coupon) => coupon.id !== deletedCoupon.id
        );
      
        return {
          ...state,
          coupons: updatedCoupons,
          loading: false,
          deleted: true,
        };
      
    case CREATE_COUPON_SUCCESS:
      let coupon = action.payload.data;
      updatedCoupons = [...state.flatCoupons, ...coupon];
      return {
        ...state,
        
        coupons: updatedCoupons,
        loading: false,
        added: true,
      };
      
      // let isDiscount = action.payload.isDiscount;
      // if (isDiscount) {
      //   const updatedCoupons = [...state.discountCoupons, ...coupon];
      //   return {
      //     ...state,

      //     discountCoupons: updatedCoupons,
      //     loading: false,
      //     added: true,
      //   };
      // } else {
      //   const updatedCoupons = [...state.flatCoupons, ...coupon];
      //   return {
      //     ...state,

      //     flatCoupons: updatedCoupons,
      //     loading: false,
      //     added: true,
      //   };


    case UPDATE_COUPON_SUCCESS:
      // coupon = action.payload.data;
      //  isDiscount = action.payload.isDiscount;

      // if (isDiscount) {
      //   const updatedCoupons = [...state.discountCoupons, ...coupon];
      //   return {
      //     ...state,
      //     discountCoupons: updatedCoupons,
      //     loading: false,
      //     added: true,
      //   };
      // } else {
      //   const updatedCoupons = [...state.flatCoupons, ...coupon];
      //   return {
      //     ...state,

      //     flatCoupons: updatedCoupons,
      //     loading: false,
      //     added: true,
      //   };
      // }
      return {
            ...state,
  
            coupon: action.payload,
            loading: false,
            added: true,
          };

    case GET_COUPON_FAIL:
    case LIST_COUPONS_FAIL:
    case LOAD_COUPONS_FAIL:
      return {
        ...state,

        loading: false,
        error: action.payload,
      };

    case CLEAR_COUPON_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// export const newProductReducer = (state = { product: {} }, action) => {
//   switch (action.type) {
//     case CREATE_PRODUCT_REQUEST:
//       return {
//         ...state,
//         loading: true,
//       };
//     case CREATE_PRODUCT_SUCCESS:
//       return {
//         loading: false,
//         success: action.payload.success,
//         product: action.payload.product,
//       };
//     case CREATE_PRODUCT_FAIL:
//       return {
//         ...state,
//         loading: false,
//         error: action.payload,
//       };
//     // case ADD_PRODUCT_RESET:
//     //   return {
//     //     ...state,
//     //     success: false,
//     //   };
//     case CLEAR_PRODUCT_ERRORS:
//       return {
//         ...state,
//         error: null,
//       };
//     default:
//       return state;
//   }
// };

// export const productDetailsReducer = (state = { product: {} }, action) => {
//   switch (action.type) {
//     case GET_PRODUCT_REQUEST:
//       return {
//         loading: true,
//         ...state,
//       };
//     case GET_PRODUCT_SUCCESS:
//       return {
//         loading: false,
//         product: action.payload,
//       };
//     case GET_PRODUCT_FAIL:
//       return {
//         loading: false,
//         error: action.payload,
//       };

//     case CLEAR_PRODUCT_ERRORS:
//       return {
//         ...state,
//         error: null,
//       };
//     default:
//       return state;
//   }
// };

// export const productReducer = (state = {}, action) => {
//   switch (action.type) {
//     case DELETE_PRODUCT_REQUEST:
//     case UPDATE_PRODUCT_REQUEST:
//       return {
//         ...state,
//         loading: true,
//       };
//     case DELETE_PRODUCT_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         isDeleted: action.payload,
//       };

//     case UPDATE_PRODUCT_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         isUpdated: action.payload,
//       };
//     case DELETE_PRODUCT_FAIL:
//     case UPDATE_PRODUCT_FAIL:
//       return {
//         ...state,
//         loading: false,
//         error: action.payload,
//       };
//     case DELETE_PRODUCT_RESET:
//       return {
//         ...state,
//         isDeleted: false,
//       };
//     // case UPDATE_PRODUCT_RESET:
//     //   return {
//     //     ...state,
//     //     isUpdated: false,
//     //   };
//     case CLEAR_PRODUCT_ERRORS:
//       return {
//         ...state,
//         error: null,
//       };
//     default:
//       return state;
//   }
// };
