import React, { useState, useEffect } from "react";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  getDate,
  isEqual,
  isSameMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  isBefore,
  parseISO,
} from "date-fns";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";

import { useSelector } from "react-redux";

import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useLocation, useNavigate } from "react-router-dom";
import getLink from "../../utils/getLinks";
import { toast } from "react-toastify";

export const useHandleMeet = () => {
  const [appointments, setAppointments] = useState();
  const [appointmentsList, setAppointmentsList] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { isAuthenticated, user, userDetails } = useSelector(
    (state) => state.user
  );

  const [appointmentBookedSchedule, setAppointmentBookedSchedule] = useState(
    []
  );

  const [loading, setLoading] = useState(false);

  const getCalendarDetails = async (appointementId) => {
    try {
      await getAppointmentScheduleDetails(appointementId);
    } catch (error) {
      console.log("Error Fet");
    }
  };

  const getAppointmentDetailsById = async (appointmentId) => {
    setLoading(true);
    try {
      const responseOfGetAppointment = await API.graphql({
        query: queries.getAppointment,
        variables: {
          id: appointmentId,
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const appointmentDetails = responseOfGetAppointment?.data.getAppointment;

      setAppointments(appointmentDetails);
      return appointmentDetails;
    } catch (error) {
      console.log("Error Fetching Appointemnt Details", error);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  const getAppointmentScheduleDetails = async (appointmentId) => {
    setLoading(true);
    try {
      const foundSchedules = appointmentsList?.find(
        (appointment) => appointment.id === appointmentId
      );

      const foundSchedulesScheduleItems = foundSchedules?.schedule?.items;
      setAppointmentBookedSchedule(foundSchedulesScheduleItems);

      // await getAvailableDays(foundSchedulesScheduleItems);
    } catch (error) {
      console.log("Error Fetching Appointment Schedules  Details", error);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  const getAppointmentList = async (appointmentId) => {
    let type;

    if (pathname.includes("clinic")) {
      type = "CLINIC";
    } else if (pathname.includes("training")) {
      type = "TRAINING_PROGRAM";
    } else if (pathname.includes("camp")) {
      type = "CAMP";
    } else {
      // Default case if none of the conditions are met
      type = {};
    }
    setLoading(true);
    try {
      const responseOfListUserMeets = await API.graphql({
        query: queries.listAppointments,
        variables: {
          filter: {
            type: {
              eq: type,
            },
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const appointmentsListData =
        responseOfListUserMeets?.data?.listAppointments?.items;

      console.log("AppointmentList", appointmentsListData);
      setAppointmentsList(appointmentsListData);
    } catch (error) {
      console.log("Error Fetching Appointment List", error);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  const verifyPayment = async () => {
    try {
      const checkoutPayload = {
        body: {
          // success_url: "http://localhost:3000/order-success",
          // cancel_url: "http://localhost:3000/cart",
          userId: user,
        },
      };
      console.log("checkoutPayload", checkoutPayload);

      // // Send a request to the Stripe API to create a checkout session
      const response = await API.post(
        "handleClinicAndTrainingProgram",
        "/signup",
        checkoutPayload
      );
      await API.post("signupother", "/signup", checkoutPayload);
    } catch (error) {
      console.log("Error Verifying Payment", error);
      throw new Error("Error Verifying Payment");
    }
  };
  const joinMeet = async (data) => {
    setLoading(true); // Set loading to true while the operation is in progress
    try {
      await verifyPayment();
      let type;

      if (pathname.includes("clinic")) {
        type = "CLINIC";
      } else if (pathname.includes("training")) {
        type = "TRAINING_PROGRAM";
      } else if (pathname.includes("camp")) {
        type = "CAMP";
      } else {
        // Default case if none of the conditions are met
        type = {};
      }

      delete data.slot.__typename;
      delete data.slot.tutorId;

      console.log("data", data);
      let trainingProgramUsr;
      if (pathname.includes("training")) {
        const datas = await API.graphql({
          query: queries.listTrainingPrograms,
          variables: {
            filter: {
              courseID: {
                eq: data.courseId,
              },
              userId: {
                eq: user,
              },
              used: {
                eq: false,
              },
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        trainingProgramUsr = datas.data.listTrainingPrograms.items;
      } else if (pathname.includes("clinic")) {
        // console.log(queries.listClinics);
        const datas = await API.graphql({
          query: queries.listClinics,

          variables: {
            filter: {
              courseID: {
                eq: data.courseId,
              },
              userId: {
                eq: user,
              },
              used: {
                eq: false,
              },
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        trainingProgramUsr = datas.data.listClinics.items;
      } else if (pathname.includes("camp")) {
        const datas = await API.graphql({
          query: queries.listCamps,
          variables: {
            filter: {
              courseID: {
                eq: data.courseId,
              },
              userId: {
                eq: user,
              },
              used: {
                eq: false,
              },
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        trainingProgramUsr = datas.data.listCamps.items;
      }
      const dataResponseTraining = trainingProgramUsr || [];
      if (dataResponseTraining.length < 1) {
        toast.error("Already Meet Schedule");
        navigate("/");
        setLoading(false); // Set loading to false after the operation is completed

        return;
      }
      if (dataResponseTraining[0].transactionStatus !== "SUCCESS") {
        toast.error("Payment Failed");
        if (type === "TRAINING_PROGRAM") {
          // const appointmentDetails = await API.graphql(
          //   graphqlOperation(mutations.deleteTrainingProgram, {
          //     id: dataResponseTraining[0].id,
          //   })
          // );
          const appointmentDetails = await API.graphql({
            query: mutations.deleteTrainingProgram,
            variables: {
              input: {
                id: dataResponseTraining[0].id,
              },
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
        } else if (type === "CLINIC") {
          const appointmentDetails = await API.graphql({
            query: mutations.deleteClinic,
            variables: {
              input: {
                id: dataResponseTraining[0].id,
              },
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
        } else if (type === "CAMP") {
          const appointmentDetails = await API.graphql({
            query: mutations.deleteCamp,
            variables: {
              input: {
                id: dataResponseTraining[0].id,
              },
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
        }

        setLoading(false); // Set loading to false after the operation is completed
        return;
      } else {
        const datas = await API.graphql({
          query: queries.getAppointment,
          variables: {
            id: data.appointmentId,
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        const datasUserMeets = await API.graphql({
          query: queries.listUserMeets,
          variables: {
            filter: {
              courseID: {
                eq: data.courseId,
              },
              appointmentId: {
                eq: data.appointmentId,
              },
              date: {
                eq: data.date,
              },
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        const appointmentdetails = datas.data.getAppointment;
        const userMeetsWithSameDate =
          datasUserMeets?.data?.listUserMeets?.items;

        if (userMeetsWithSameDate.length >= appointmentdetails.maxStudents) {
          toast.error("Schedule Not Available");
          // navigate("/");
          setLoading(false); // Set loading to false after the operation is completed

          return;
        }

        const response = await API.graphql({
          query: mutations.createUserMeet,
          variables: {
            input: {
              userId: user,
              // meetId: meetDetails.id,
              email: userDetails.email,
              courseID: data.courseId,
              slot: data.slot,
              date: data.date,
              appointmentId: data.appointmentId,
              appointmentScheduleId: data.id,
              type,
              typeId: user,
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        if (pathname.includes("training")) {
          const updatedData = {
            ...dataResponseTraining[0],
            appointmentId: data.appointmentId,
            appointmentScheduleId: data.id,
            used: true,
          };
          delete updatedData.appointmentSchedule;
          delete updatedData.createdAt;
          delete updatedData.updatedAt;
          delete updatedData.__typename;
          delete updatedData.isVerified;
          delete updatedData.transactionStatus;
          delete updatedData.message;
          delete updatedData.teamId;
          delete updatedData.paymentStatus;
          delete updatedData.teamName;
          const response = await API.graphql({
            query: mutations.updateTrainingProgram,
            variables: {
              input: {
                ...updatedData,
              },
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
        } else if (pathname.includes("clinic")) {
          const updatedData = {
            ...dataResponseTraining[0],
            appointmentId: data.appointmentId,
            appointmentScheduleId: data.id,
            used: true,
          };
          delete updatedData.appointmentSchedule;
          delete updatedData.createdAt;
          delete updatedData.updatedAt;
          delete updatedData.__typename;
          delete updatedData.isVerified;
          delete updatedData.message;
          delete updatedData.teamId;
          delete updatedData.paymentStatus;
          delete updatedData.teamName;
          delete updatedData.transactionStatus;
          delete updatedData.scoreID;
          delete updatedData.isFirstYearPlaying;
          delete updatedData.NBA;
          delete updatedData.COLLEGE;
          delete updatedData.teamID;
          delete updatedData.WEIGHT;
          delete updatedData.H_S;
          delete updatedData.league;
          delete updatedData.schoolName;
          delete updatedData.HEIGHT;

          const response = await API.graphql({
            query: mutations.updateClinic,
            variables: {
              input: {
                ...updatedData,
              },
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
        } else if (pathname.includes("camp")) {
          const updatedData = {
            // ...dataResponseTraining[0],
            id: dataResponseTraining[0].id,
            appointmentId: data.appointmentId,
            appointmentScheduleId: data.id,
            used: true,
          };

          const response = await API.graphql({
            query: mutations.updateCamp,
            variables: {
              input: {
                ...updatedData,
              },
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
        }
        if (response) {
          toast.success("Schedule Fixed");
          navigate("/");
        }
      }

      setLoading(false);
      // const response = await API.graphql(graphqlOperation(mutations.joinMeet, { input: { /* provide input parameters */ } }));
      // Handle the response accordingly
    } catch (error) {
      toast.error(error.message);
      console.error("ERROR", error);
      throw new Error(error.message);
    } finally {
      setLoading(false); // Set loading to false after the operation is completed
    }
  };

  useEffect(() => {
    getAppointmentList();
  }, []);
  return {
    loading,
    appointmentBookedSchedule,
    appointments,
    appointmentsList,
    getAppointmentDetailsById,
    getCalendarDetails,
    getAppointmentScheduleDetails,
    joinMeet,
  };
};

const convertIsoToDateString = (isoString) => {
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const getTutorDetails = async (tutorId) => {
  try {
    const responseOfgetTutor = await API.graphql({
      query: queries.getTutor,
      variables: {
        id: tutorId,
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    return {
      ...responseOfgetTutor.data.getTutor,
      profile: await getLink(responseOfgetTutor.data.getTutor.profile),
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const getUserFromAppointmentId = async (appointmentId) => {
  try {
    let nextToken = null;
    let items = [];

    do {
      const response = await API.graphql({
        query: queries.listUserMeets,
        variables: {
          filter: {
            appointmentId: {
              eq: appointmentId,
            },
          },
          nextToken: nextToken,
        },
        authMode: "API_KEY",
      });

      items = [...items, ...(response.data.listUserMeets.items || [])];
      nextToken = response.data.listUserMeets.nextToken;
    } while (nextToken);

    return items;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAppointemtnDetailsFromAppointmentId = async (courseId) => {
  try {
    let nextToken = null;
    let items = [];

    do {
      const response = await API.graphql({
        query: queries.listAppointments,
        variables: {
          filter: {
            courseId: {
              eq: courseId,
            },
          },
          nextToken: nextToken,
        },
        authMode: "API_KEY",
      });

      items = [...items, ...(response.data.listAppointments.items || [])];
      nextToken = response.data.listAppointments.nextToken;
    } while (nextToken);

    return items[0];
    // Assuming you want to return all items, but you may adjust as per your logic
  } catch (error) {
    throw new Error(error);
  }
};
