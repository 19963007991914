import React, { useState, useEffect } from "react";
import OrderDetailsCard from "../../Components/Order/OrderDetailsCard";

import ReactPaginate from "react-paginate";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import useGetOrderList from "../../hooks/getOrderList";
import Loader from "../../Components/Loader";
import OrderCardSkeleton from "../../Components/Skeleton/OrderSkeleton";

const OrderPage = () => {
  const { ordersList, loading, evenList } = useGetOrderList();

  console.log("ordersList", ordersList);
  const [page, setPage] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(ordersList?.length / itemsPerPage);

  useEffect(() => {
    const startIndex = page * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const filteredData = ordersList.slice(startIndex, endIndex);
    setFilterData(filteredData);
  }, [page, ordersList]);
  const handlePageChange = (selectedPage) => {
    setPage(selectedPage.selected);
  };

  return (
    <div className="flex flex-col gap-4 my-4 overflow-x-auto px-">
      {" "}
      {/* Add overflow-x-auto and margin */}
      {loading ? (
        <>
          <OrderCardSkeleton />
          <OrderCardSkeleton />
          <OrderCardSkeleton />
          <OrderCardSkeleton />

        </>
      ) : (
        <>{filterData && <OrderDetailsCard ordersList={filterData} />}</>
      )}
      <ReactPaginate
        previousLabel={<BsChevronLeft className="text-orange-500" />} // Style for left arrow
        nextLabel={<BsChevronRight className="  text-orange-500" />} // Style for right arrow
        breakLabel={"..."}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={"pagination flex justify-center items-center mt-4"}
        activeClassName={"active text-orange-500"}
        previousClassName={"border rounded-full p-2"}
        nextClassName={"border rounded-full p-2"}
        pageClassName={"m-2 "} // Style for pagination numbers
        breakClassName={"text-orange-500"} // Style for break label
        pageLinkClassName={"underline"} // Add an underline to the pagination numbers
      />
    </div>
  );
};

export default OrderPage;
