import { FaCampground } from "react-icons/fa";
import { BiBasketball } from "react-icons/bi";
import { HiOutlineHandRaised } from "react-icons/hi2";
import { RiSuitcaseLine } from "react-icons/ri";
import Teams from "../../Components/Teams";
import Shop from "../../Components/Shop";
import SignUpDetail from "../../Components/SignUpDetail";
import { useState, useEffect } from "react";
import useGetAllCity from "../../hooks/getCityList";
import { ToastContainer } from "react-toastify";
import TypoBu from "../../Components/TypoBu";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader";
import { registerAsTrainingProgram } from "../../redux/actions/TrainingProgramAction";
import { clearUserErrors } from "../../redux/actions/UserAction";
import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";
import useGetAllTrainingPrograms from "../../hooks/getAllTrainingPrograms";
import useGetAllTrainingProgramCourses from "../../hooks/getAllTrainingProgramCourses";
import useHandleMeetUser from "../../hooks/handleMeetUser";
import Calendar from "../../Components/Calendar/Calendar";

const ages = ["5-7", "8-9", "10-12", "13-14", "15-17", "18-40", "40+"];

const SignUpTrainingProgram = () => {

  const dispatch = useDispatch();

  const { loading: meetLoadng } = useHandleMeetUser();

  const {
    user,
    userDetails,
    loading: userLoading,
    error: userError,
  } = useSelector((state) => state.user);

  const {
    trainingProgram,
    loading: trainingProgramLoading,
    created,
    error: trainingProgramError,
  } = useSelector((state) => state.trainingProgram);

  const { trainingPrograms: trainingProgramCard, loading } = useGetAllTrainingPrograms();

  const { trainingProgramCourses, loading: courseLoading } = useGetAllTrainingProgramCourses();
  const { citylist } = useGetAllCity();

  const [participant, setParticipant] = useState(userDetails["name"] || "");
  const [typeTrainingProgram, setTrainingProgramsType] = useState("");
  const [course, setCourse] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [agegroup, setAgegroup] = useState(userDetails["age"] || "");
  const [cityOfRegistration, setCityOfRegistration] = useState("");
  const [profile, setProfile] = useState(""); // You can use null or an initial value
  const [phoneNumber, setPhoneNumber] = useState(userDetails["phone_number"] || "");
  const [isParentGuardian, setIsParentGuardian] = useState(false);
  const [email, setEmail] = useState(userDetails["email"] || "");
  const [clubName, setClubName] = useState("");
  const [ext, setExt] = useState("");
  const [signatureType, setSignatureType] = useState(""); // Default value
  const [electronicSignature, setElectronicSignature] = useState("");
 
  const [participantError, setParticipantError] = useState("");
  const [typeTrainingProgramError, setTrainingProgramsTypeError] = useState("");
  const [courseError, setCourseError] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState("");
  const [ageGroupError, setAgeGroupError] = useState("");
  const [cityOfRegistrationError, setCityOfRegistrationError] = useState("");
  const [profileError, setProfileError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [isParentGuardianError, setIsParentGuardianError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [clubNameError, setClubNameError] = useState("");
  const [extError, setExtError] = useState("");
  const [signatureTypeError, setSignatureTypeError] = useState("");
  const [electronicSignatureError, setElectronicSignatureError] = useState("");
 

  const handleParticipantChange = (e) => {
    setParticipant(e.target.value);
    setParticipantError(""); // Reset error
  };
  const handleDateOfBirthChange = (e) => {
    setDateOfBirth(e.target.value);
    setDateOfBirthError("");
  };
  const handleCityOfRegistrationChange = (e) => {
    setCityOfRegistration(e.target.value);
    setCityOfRegistrationError(""); // Reset error
  };
  const handleAgeGroup = (e) => {
    setAgegroup(e.target.value);
    setAgeGroupError("");
  };

  const handleTrainingProgramsChange = (e) => {
    setTrainingProgramsType(e.target.value);
    setTrainingProgramsTypeError("");
  };
  const handleCourseChange = (e) => {
    console.log("EVENT", e.target.value);
    setCourse(e.target.value);
    setCourseError("");
  };

  const handleProfile = async (e) => {
    const file = e.target.files[0];
    // Handle file upload here if needed
    if (file) {
      setProfile(file);
    } else {
      setProfile(null);
    }
    setProfileError("");
  };

  const handleRegister = async () => {
    let hasErrors = false; // Validation functions

    if (!participant) {
      setParticipantError("Participant is required.");
      hasErrors = true;
    }

    if (!typeTrainingProgram) {
      setTrainingProgramsTypeError("Training Program Type is required.");
      hasErrors = true;
    }

    if (!course) {
      setCourseError("Course is required.");
      hasErrors = true;
    }
     
    if (!dateOfBirth) {
      setDateOfBirthError("Date of Birth is required.");
      hasErrors = true;
    }
  
    if (!agegroup) {
      setAgeGroupError("Age Group is required.");
      hasErrors = true;
    }

    if (!cityOfRegistration) {
      setCityOfRegistrationError("City of Registration is required.");
      hasErrors = true;
    }

    if (!profile) {
      setProfileError("Profile is required.");
      hasErrors = true;
    }

    if (!phoneNumber) {
      setPhoneNumberError("Phone Number is required.");
      hasErrors = true;
    }
    
    if (!isParentGuardian) {
      setIsParentGuardianError("Is Parent Guardian is required.");
      hasErrors = true;
    }

    if (!email) {
      setEmailError("Email address is required.");
      hasErrors = true;
    }

 
    if (!clubName) {
      setClubNameError("Club Name is required.");
      hasErrors = true;
    }
   
    if (!ext) {
      setExtError("Ext is required.");
      hasErrors = true;
    }
  

    if (!electronicSignature) {
      setElectronicSignatureError("Electronic Signature is required.");
      hasErrors = true;
    }

    if (hasErrors) {
      // Display validation errors and prevent form submission
      return;
    }
  

    const checkRequiredFields = () => {
      const requiredFields = [
        participant,
        typeTrainingProgram,
        course,
        dateOfBirth,
        agegroup,
        cityOfRegistration,
        profile,
        phoneNumber,
        isParentGuardian,
        email,
        clubName,
        ext,
        electronicSignature
      ];

      // Check if any required field is empty
      const isEmpty = requiredFields.some((field) => field === "");

      return !isEmpty; // Return true if all required fields are filled
    };
    const allRequiredFilled = checkRequiredFields();
    if (allRequiredFilled) {
      const data = {
        participantName: participant,
        type: typeTrainingProgram,
        courseID: [course],
        dateOfBirth,
        agegroup,
        cityOfRegistration,
        profile,
        isAdult: isParentGuardian,
        phoneNumber,
        email,
        clubName,
        ext,
        signature: electronicSignature,
      };

      console.log("Data", data);
      localStorage.setItem("formData", JSON.stringify(data));
      dispatch(registerAsTrainingProgram(data, user));

    }

    setParticipantError("");
    setAgeGroupError("");
    setPhoneNumberError("");
    setCityOfRegistrationError("");
    setExtError("");
    setProfileError("");
    setDateOfBirthError("");
    setIsParentGuardianError("");
    setEmailError("");
    setClubNameError("");
    setSignatureTypeError("");
    setElectronicSignatureError("");
    setTrainingProgramsTypeError("");
  };
  const stateSetters = {
    setExt,
    ext,
    setPhoneNumber,
    phoneNumber,
    setIsParentGuardian,
    isParentGuardian,
    setEmail,
    email,
    setClubName,
    clubName,
    setSignatureType,
    signatureType,
    setElectronicSignature,
    electronicSignature,
    setPhoneNumberError,
    phoneNumberError,
    setExtError,
    extError,
    setIsParentGuardianError,
    isParentGuardianError,
    setEmailError,
    emailError,
    setClubNameError,
    clubNameError,
    setSignatureTypeError,
    signatureTypeError,
    setElectronicSignatureError,
    electronicSignatureError,
  };

  useEffect(() => {
    if (userError) {
      dispatch(clearUserErrors());
    }

    if (userError) {
      dispatch(clearUserErrors());
    }
  }, [created]);

  if (userLoading || meetLoadng || trainingProgramLoading || courseLoading) {
    return <Loader />;
  }
  return (
    <div className="">
      <ToastContainer />
      <div className="md:pr-2 mt-12 sm:pr-2 md:ml-1 md:pl-2 sm:ml-2 sm:pl-2 md:flex ">
        <div className="md:w-2/5 ml-2 mr-2">
          <div className="text-3xl font-bold">SIGNUP FOR TRAINING PROGRAM</div>
          <div className="mt-4">
            <h5>Participant</h5>
            <input
              name="participant"
              type="text"
              required
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              value={participant}
              onChange={handleParticipantChange}
            />
            <span className="text-red-500">{participantError}</span>
          </div>
          <div className="mt-4">
            <h5 className="">Training Programs</h5>
            <select
              name="TrainingPrograms"
              id="program"
              value={typeTrainingProgram}
              onChange={handleTrainingProgramsChange}
              className="  border-[#e7e7e7] uppercase border-2 rounded-md p-2 w-full "
            >
              <option value="" className="uppercase">
                select Training Programs
              </option>
              {trainingProgramCard?.map((item, index) => {
                return (
                  <option
                    value={item?.title}
                    className="uppercase"
                    key={index?.id}
                  >
                    {item?.title}
                  </option>
                );
              })}
            </select>
            <span className="text-red-500">{typeTrainingProgramError}</span>

          </div>
          <div className="mt-4">
            <h5 className="">Course</h5>
            <select
              name="TrainingProgramCourse"
              id="program"
              value={course}
              onChange={handleCourseChange}
              className="  border-[#e7e7e7] uppercase border-2 rounded-md p-2 w-full "
            >
              <option value="" className="uppercase">
                Select Course
              </option>
              {trainingProgramCourses?.map((item, index) => {
                return (
                  <option
                    value={item?.id}
                    className="uppercase"
                    key={index?.id}
                  >
                    {item?.title} - ${item?.price} /hr
                  </option>
                );
              })}
            </select>
            <span className="text-red-500">{courseError}</span>
          </div>

          <div className="scheduleMeet">
            {course && <Calendar appointmentId={course} />}
          </div>
          <div className="mt-4">
            <h5>Date of birth</h5>
            <input
              type="date"
              value={dateOfBirth}
              className="border border-2"
              onChange={handleDateOfBirthChange}
            />
          </div>
          <span className="text-red-500 pb-2">{dateOfBirthError}</span>
          <div className="mt-4">
            <h5 className="">Age</h5>
            <select
              name="age"
              id="age"
              value={agegroup}
              onChange={handleAgeGroup}
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
            >
              <option value="">Age</option>
              {ages?.map((item, index) => {
                return (
                  <option value={item} key={index}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          <span className="text-red-500 pb-2">{ageGroupError}</span>
          <div className="mt-4">
            <h5>City of Registration</h5>
            <select
              name="city_of_registration"
              id="city_of_registration"
              required
              value={cityOfRegistration}
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              onChange={handleCityOfRegistrationChange}
            >
              <option value="" defaultValue="">
                Choose option
              </option>

              {citylist?.map((value, index) => (
                <option value={value.name} key={index}>
                  {value.name}
                </option>
              ))}
            </select>
            <span className="text-red-500">{cityOfRegistrationError}</span>
          </div>
      
          <div className="mt-4 mb-2">
            <h5 className="mb-2">Upload Profile Picture</h5>
            <label
              htmlFor="profileInput"
              className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg"
            >
              Upload Profile Picture
            </label>
            <input
              type="file"
              id="profileInput"
              className="hidden"
              onChange={handleProfile}
            />
            {profile && <p className="mt-2">Selected File: {profile.name}</p>}
          </div>
          <span className="text-red-500 pb-2">{profileError}</span>

          <SignUpDetail stateSetters={stateSetters} />
          <div className="flex pt-20 mb-5 ml-2 mr-2">
            <button
              onClick={handleRegister}
              className="font-bold  bg-[#fec749] lg:px-40 px-20 whitespace-nowrap py-1"
            >
              Sign Up
            </button>
          </div>
        </div>
        <div className="md:w-3/5 p-4">
          <div className="text-center uppercase whitespace-nowrap text-xl font-bold">
            Sign Up For
          </div>
          <div className="text-center bg-white px-10 py-16">
            <div className="flex flex-wrap justify-center md:gap-[9rem]  md:flex-row flex-col ">
              <NavLink to="/signuptournament">
                <BiBasketball className="inline text-4xl mb-4" />
                <div className="font-bold mb-4 text-sm ">Tournament</div>
              </NavLink>
              <NavLink to="/signupcamp" className="mb-4">
                <FaCampground className="inline text-4xl mb-4" />
                <div className="font-bold text-sm mb-4">CAMPS</div>
              </NavLink>
              <NavLink to="/signupevent" className="mb-4">
                <img
                  src="/images/Ticket.svg"
                  alt="Ticket"
                  className="inline h-12 mb-2 w-16"
                />
                <div className="font-bold text-sm mb-4">EVENTS</div>
              </NavLink>
              <NavLink to="/signupvolunteer" className="mb-2">
                <HiOutlineHandRaised className="inline text-4xl mb-4" />
                <div className="font-bold text-sm mb-4">Volunteer</div>
              </NavLink>
              <NavLink to="/signupworkwithus" className="mb-2">
                <RiSuitcaseLine className="inline text-4xl mb-4" />
                <div className="font-bold text-sm mb-4">Work with us</div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#181B22] text-white">
        <div className="md:p-20">
          <AdvertisementOne index={0} />
        </div>
        <Teams />
        <div className="md:p-20 b-[#181B22]">
          <AdvertisementOne index={1} />
        </div>
        <div className="md:p-20 p-4 b-[#181B22] flex flex-col gap-20 py-20">
          <TypoBu
            item={{
              text: "shop",
              text_ssize: "text-3xl",
              text_bsize: "text-4xl",
              border: true,
              button: false,
            }}
          />
          <Shop />
          <div className="text-center">
            <Link
              to="/store"
              className="px-20 py-2 whitespace-nowrap border-white border rounded-sm"
            >
              VIEW ALL
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignUpTrainingProgram;
