export const CREATE_PLAYER_AGE_REQUEST = 'CREATE_PLAYER_AGE_REQUEST';
export const CREATE_PLAYER_AGE_SUCCESS = 'CREATE_PLAYER_AGE_SUCCESS';
export const CREATE_PLAYER_AGE_FAIL = 'CREATE_PLAYER_AGE_FAIL';

export const GET_PLAYER_AGE_REQUEST = 'GET_PLAYER_AGE_REQUEST';
export const GET_PLAYER_AGE_SUCCESS = 'GET_PLAYER_AGE_SUCCESS';
export const GET_PLAYER_AGE_FAIL = 'GET_PLAYER_AGE_FAIL';

export const LIST_PLAYER_AGE_REQUEST = 'LIST_PLAYER_AGE_REQUEST';
export const LIST_PLAYER_AGE_SUCCESS = 'LIST_PLAYER_AGE_SUCCESS';
export const LIST_PLAYER_AGE_FAIL = 'LIST_PLAYER_AGE_FAIL';

export const UPDATE_PLAYER_AGE_REQUEST = 'UPDATE_PLAYER_AGE_REQUEST';
export const UPDATE_PLAYER_AGE_SUCCESS = 'UPDATE_PLAYER_AGE_SUCCESS';
export const UPDATE_PLAYER_AGE_FAIL = 'UPDATE_PLAYER_AGE_FAIL';

export const DELETE_PLAYER_AGE_REQUEST = 'DELETE_PLAYER_AGE_REQUEST';
export const DELETE_PLAYER_AGE_SUCCESS = 'DELETE_PLAYER_AGE_SUCCESS';
export const DELETE_PLAYER_AGE_FAIL = 'DELETE_PLAYER_AGE_FAIL';
export const DELETE_PLAYER_AGE_RESET = 'DELETE_PLAYER_AGE_RESET';

export const CLEAR_PLAYER_AGE_ERRORS = 'CLEAR_PLAYER_AGE_ERRORS';