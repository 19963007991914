import axios from "axios";
import { useEffect, useState } from "react";
import Shop from "../../Components/Shop";
import OrderBox from "../../Components/OrderBox";
import { Link, NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TypoBu from "../../Components/TypoBu";
import usePaymentApi from "../../hooks/paymentApi";
import useGetAddressList from "../../hooks/getAddressList";
import CartCard from "./CartCard";
import useUpdateAddress from "../../hooks/updateAddress";
import useDeleteAddress from "../../hooks/deleteAddress";
import LeagueCart from "./LeagueCart";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCartErrors,
  getCart,
  getUserCart,
  removeItemFromCart,
  removeLeagueFromCart,
} from "../../redux/actions/cartAction";
import AddressCard from "../../Components/AddressCard";
import LoadingSkeleton from "../../Components/Skeleton/SkeletonLoader";
import CartCardSkeleton from "../../Components/Skeleton/CartCardSkeleton";
import RenderShop from "../../Components/Shop/RenderShop";
import useHandleCartUser from "../../hooks/handleCart";

const Cart = () => {
  const dispatch = useDispatch();
  //
  const {
    leagueCart,
    cartList,
    fetchCartUsers,
    loading: cartLoading,
    addToCartById,
    deleteCartItemUserById,
    updateCartItemById,
  } = useHandleCartUser();
  const [isUpdated, setIsUpdated] = useState(false);
  const {
    cart,
    cartItems,
    leagueItems,
    loading,
    deleted,
    updated,
    added,
    error,
  } = useSelector((state) => state.cart);

  const [mainCart,setMainCart]=useState(cartList||[]);
  const [mainLeague,setMainLeague]=useState(cartList||[]);
  
  const { isAuthenticated, user } = useSelector((state) => state.user);

  const [newAddress, setNewAddress] = useState({
    city: "",
    country: "",
    pincode: "",
    address: "",
    phonenumber: "",
    secondaryPhonenumber: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditAddress, setIsEditAddress] = useState(false); // New state variable to track edit mode
  const { deleteAddress, loading: deleteLoading } = useDeleteAddress();
  const { updateAddress } = useUpdateAddress();
  const [editIndex, setEditIndex] = useState();
  const {
    addressesList,
    loading: addressLoading,
    fetchAddressesList,
  } = useGetAddressList();

  const [cartValue, setCartValue] = useState(1); // State to store user ID
  const [formErrors, setFormErrors] = useState({
    city: "",
    country: "",
    pincode: "",
    address: "",
    phonenumber: "",
    secondaryPhonenumber: "",
  });


  const [success, setSuccess] = useState(false);

  // console.log("userCartuserCart", userCart);
  const { fetchFunction } = usePaymentApi();
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState([]);

  // Initialize with userCart
  // console.log('userCart', items)
  const [showConfirmation, setShowConfirmation] = useState(false);
  fetchFunction();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsEditAddress(false);
    // Reset the formErrors state to an empty object
    setFormErrors({
      city: "",
      country: "",
      pincode: "",
      address: "",
      phonenumber: "",
      secondaryPhonenumber: "",
    });

    // Reset the newAddress state to an empty object
    setNewAddress({
      city: "",
      country: "",
      pincode: "",
      address: "",
      phonenumber: "",
      secondaryPhonenumber: "",
    });
  };
  // cartItemscartItems

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the newAddress state
    setNewAddress({
      ...newAddress,
      [name]: value,
    });

    // Remove the error message for the corresponding field
    setFormErrors({
      ...formErrors,
      [name]: "", // Set the error message to an empty string
    });
  };

  const clearForm = () => {
    setNewAddress({
      city: "",
      country: "",
      pincode: "",
      address: "",
      phonenumber: "",
      secondaryPhonenumber: "",
    });
  };

  const toggleLeagueSelection = (leagueId) => {
    console.log("Selectedid", leagueId);
    if (selectedLeague.includes(leagueId)) {
      setSelectedLeague(selectedLeague.filter((id) => id !== leagueId));
    } else {
      setSelectedLeague([...selectedLeague, leagueId]);
      console.log("updatedLeague", leagueId);
    }
  };
  const toggleItemSelection = (itemId) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };
  const handleCreateAddress = async (editMode) => {
    try {
      const errors = {};

      // Checking if fields are empty and updating the errors object
      if (!newAddress.city) errors.city = "City is required";
      if (!newAddress.country) errors.country = "Country is required";
      if (!newAddress.pincode) errors.pincode = "Pincode is required";
      if (!newAddress.address) errors.address = "Address is required";
      if (!newAddress.phonenumber)
        errors.phonenumber = "Phone Number is required";
      if (!newAddress.secondaryPhonenumber)
        errors.secondaryPhonenumber = "Secondary Phone Number is required";

      // Setting the errors object to the state
      setFormErrors(errors);

      // Checking for any errors
      if (Object.keys(errors).length === 0) {
        let isSuccess = false;

        if (editMode) {
          isSuccess = await updateAddress(editIndex, newAddress, true);
        } else {
          isSuccess = await updateAddress(null, newAddress, false);
        }

        if (isSuccess) {
          await fetchAddressesList();
          clearForm();
          closeModal();
          toast.success(
            `Address ${editMode ? "edited" : "added"} successfully!`
          );
        } else {
          throw new Error(
            `Failed to ${editMode ? "edit" : "add"} the address.`
          );
        }
      }
    } catch (error) {
      // Handle errors, display error message to the user
      toast.error(error.message);
      console.error("Address creation/editing error:", error);
    }
  };

  const handleEditAddress = async (index, addressData) => {
    // Set the newAddress state with the address data
    setNewAddress({
      city: addressData.city || "",
      country: addressData.country || "",
      pincode: addressData.pincode || "",
      address: addressData.address || "",
      phonenumber: addressData.phonenumber || "",
      secondaryPhonenumber: addressData.secondaryPhonenumber || "",
    });

    setEditIndex(index);
    // Set isEditAddress to true to indicate edit mode
    setIsEditAddress(true);

    // Open the modal
    openModal();

    // console.log(`Editing address  ${addressData}`);
  };

  const handleDeleteAddress = async (index, address) => {
    try {
      await deleteAddress(index, address);
      await fetchAddressesList();
      toast.success("Address deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete the address.");
    }
  };

  const deleteFinal = async () => {
    setIsUpdated(true)

    const newItemIds = [];
    const deletionPromises = selectedItems?.map(async (id) => {
      dispatch(removeItemFromCart(id, cartItems));
      fetchCartUsers();
  
    });
    const deletionPromisesS = selectedLeague?.map(async (id) => {
      dispatch(removeLeagueFromCart(id, leagueItems));
      fetchCartUsers();
    });
    setSelectedItems([]);
    setSelectedLeague([]);
    setIsUpdated(false)

    // setCartItemss([]);
    setShowConfirmation(false);
  };
  const selectAllItems = () => {
    const allItemIds = cart?.cartItems?.map((item) => item.id);
    const allItemIdsLeague = cart?.leagueItems?.map((item) => item.id);

    setSelectedItems(allItemIds);
    setSelectedLeague(allItemIdsLeague);
  };

  const removeAllSelectedItems = async () => {
    selectAllItems();
    setShowConfirmation(true);
  };

  console.log("selectedLeagues:", selectedLeague);
  const removeFromCart = async () => {
    setIsUpdated(true)
    const deletionPromises = selectedItems?.map(async (id) => {
      // dispatch(removeItemFromCart(id, cartItems));
      await deleteCartItemUserById(id)
      fetchCartUsers();
    
    });
    const deletionPromisesS = selectedLeague?.map(async (id) => {
      dispatch(removeLeagueFromCart(id, leagueItems));
      fetchCartUsers();
    });

    // Check if this is an action creator that fetches news
    // dispatch(getCart(user));
    setIsUpdated(false)

    // Check if this is an action creator that fetches news
  };
const fetchData=async ()=>{
 
  await fetchCartUsers()
}

console.log("leagueItems",leagueItems)
  useEffect(() => {
    if (error) {
      dispatch(clearCartErrors());
    }
    if (user) {
      // Check if this is an action creator that fetches news
      dispatch(getCart(user));
      console.log("cartlist", cartList);
      fetchCartUsers();

      // Check if this is an action creator that fetches news
    }
   
    fetchCartUsers();
    setIsUpdated(false)
    
  }, [success, updated, isUpdated, added, error, user, deleted]);

  // console.log("itemss",cartItems)

  return (
    <div className="">
      <ToastContainer />

      <div className="relative text-xl p-7 ml-2 uppercase font-bold mb-4">
        shopping cart
      </div>
      <></>
      {/* <div className="absolute m-auto z-[100]  max-w-[650px] bg-white flex flex-col gap-[20px]"> */}
      {showConfirmation && (
        <FinalDelete
          setShowConfirmation={setShowConfirmation}
          deleteFinal={deleteFinal}
        />
      )}
      {/* </div> */}

      <div className="md:pr-4 sm:pr-2 md:ml-1 md:pl-8 sm:ml-4 sm:pl-4 md:flex">
        <div className=" md:w-3/5 p-4 ">
          <p className="text-sm  uppercase font-bold pb-0">saved addresses</p>
          <span className="text-sm">
            A default address is already selected. choose an address to change
            the current address.
          </span>

          {addressLoading ? (
            <LoadingSkeleton />
          ) : (
            <div className="mt-2 border overflow-x-auto">
              <div className="flex flex-col md:flex-row">
                <div className="mb-2 p-2 border bg-gray-50 rounded-lg w-full md:w-1/3 md:mr-2 flex justify-center flex-shrink-0 mt-2 items-center">
                  <button
                    type="button"
                    onClick={openModal}
                    className="text-gray-500 text-xl font-bold"
                  >
                    +
                  </button>
                </div>
                {addressesList?.map((address, index) => (
                  <AddressCard
                    address={address}
                    index={index}
                    key={address?.id}
                    handleDeleteAddress={handleDeleteAddress}
                    handleEditAddress={handleEditAddress}
                  />
                ))}
              </div>
            </div>
          )}

          {cartLoading ? (
            <CartCardSkeleton />
          ) : (
            <div className="border-black mt-4 rounded-md overflow-y-auto  max-h-[800px] flex-col pt-4">
              <div className="flex justify-end">
                <button
                  onClick={removeFromCart}
                  className=" uppercase font-bold px-2 py-1 "
                >
                  Remove Selected
                </button>
                <button
                  onClick={removeAllSelectedItems}
                  className="uppercase font-bold px-2 py-1 ml-2"
                >
                  Remove All
                </button>
              </div>
              <div className="mt-4">
                {leagueItems?.length > 0 && (
                  <span className="text-[25px] mt-2 mb-2 font-semibold">
                    Events
                  </span>
                )}
                {leagueItems?.length > 0 &&
                  leagueItems?.map((events, index) => (
                    <LeagueCart
                      item={events}
                      isUpdated={isUpdated}
                      setIsUpdated={setIsUpdated}
                      key={events.id}
                      index={index}
                      toggleItemSelection={toggleLeagueSelection}
                      selectedItems={selectedLeague}
                    />
                  ))}
              </div>
              <div className="mt-4">
                {cartItems?.length > 0 && (
                  <span className="text-[25px] mt-2 mb-2 font-semibold">
                    Products
                  </span>
                )}
                {cartList?.length === 0 ? (
                  <div className="text-center">Cart is empty.</div>
                ) : (
                  cartList?.map((item, index) => (
                    <CartCard
                      item={item}
                      isUpdated={isUpdated}
                      setIsUpdated={setIsUpdated}
                      key={item.id}
                      index={index}
                      updateCartItemById={updateCartItemById}
                      toggleItemSelection={toggleItemSelection}
                      selectedItems={selectedItems}
                    />
                  ))
                )}
              </div>
            </div>
          )}
        </div>

        <div className=" w-full md:w-2/5 md:ml-4">
          <OrderBox
            cartItems={cartList}
            address={addressesList}
            league={leagueItems}
            loading={loading}
          />
          <div className="p-4">
            <RenderShop addToCartById={addToCartById} fetchData={fetchData} />
          </div>
        </div>
      </div>
      <div className="md:p-20 p-4 bg-[#181B22] text-white flex flex-col gap-20 py-20">
        <TypoBu
          item={{
            text: "shop",
            text_ssize: "text-3xl",
            text_bsize: "text-4xl",
            border: true,
            button: false,
          }}
        />
        <Shop fetchData={fetchData} />
        <div className="text-center">
          <Link
            to="/store"
            className="px-20 py-2 whitespace-nowrap border-white border rounded-sm"
          >
            VIEW ALL
          </Link>
        </div>
      </div>
      {/* Address Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-gray-800 opacity-50"></div>
          <div className="bg-white p-6 rounded-lg z-10 w-[400px]">
            <h2 className="text-lg font-bold mb-4">
              {isEditAddress ? "Edit Address" : "Enter Address Details"}
            </h2>
            <div>
              <div className="mb-2">
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  value={newAddress.city}
                  onChange={handleChange}
                  className="border p-2 rounded-lg w-full"
                />
                {formErrors.city && (
                  <p className="text-red-500 text-sm">{formErrors.city}</p>
                )}
              </div>
              <div className="mb-2">
                <select
                  name="country"
                  value={newAddress.country}
                  onChange={handleChange}
                  className="border p-2 rounded-lg w-full"
                >
                  <option value="">Select Country</option>
                  {countries.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
                {formErrors.country && (
                  <p className="text-red-500 text-sm">{formErrors.country}</p>
                )}
              </div>

              <div className="mb-2">
                <input
                  type="text"
                  name="pincode"
                  placeholder="Zip Code"
                  value={newAddress.pincode}
                  onChange={handleChange}
                  className="border p-2 rounded-lg w-full"
                />
                {formErrors.pincode && (
                  <p className="text-red-500 text-sm">{formErrors.pincode}</p>
                )}
              </div>
              <div className="mb-2">
                <input
                  type="text"
                  name="address"
                  placeholder="Address"
                  value={newAddress.address}
                  onChange={handleChange}
                  className="border p-2 rounded-lg w-full"
                />
                {formErrors.address && (
                  <p className="text-red-500 text-sm">{formErrors.address}</p>
                )}
              </div>
              <div className="mb-2">
                <input
                  type="text"
                  name="phonenumber"
                  placeholder="Phone Number"
                  value={newAddress.phonenumber}
                  onChange={handleChange}
                  className="border p-2 rounded-lg w-full"
                />
                {formErrors.phonenumber && (
                  <p className="text-red-500 text-sm">
                    {formErrors.phonenumber}
                  </p>
                )}
              </div>
              <div className="mb-2">
                <input
                  type="text"
                  name="secondaryPhonenumber"
                  placeholder="Secondary Phone Number"
                  value={newAddress.secondaryPhonenumber}
                  onChange={handleChange}
                  className="border p-2 rounded-lg w-full"
                />
                {formErrors.secondaryPhonenumber && (
                  <p className="text-red-500 text-sm">
                    {formErrors.secondaryPhonenumber}
                  </p>
                )}
              </div>
              <div className="flex justify-between mt-4">
                <button
                  type="button"
                  className="bg-red-500 mr-2 text-white px-4 py-2 rounded-lg"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={() => handleCreateAddress(isEditAddress)}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                >
                  {isEditAddress ? "Edit" : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;

const FinalDelete = ({ setShowConfirmation, deleteFinal }) => {
  return (
    <div className="absolute inset-0 flex items-center justify-center bg-opacity-50 bg-gray-700">
      <div className="max-w-[650px] bg-white flex flex-col gap-[20px] p-8 rounded-md shadow-lg">
        <p className="text-xl font-bold mb-4 text-center">
          Are You Sure You Want To Remove All Items From Cart?
        </p>
        <div className="flex justify-around items-center">
          <button
            className="bg-blue-600 text-white px-4 py-2 rounded-md"
            onClick={() => setShowConfirmation(false)}
          >
            Cancel
          </button>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded-md"
            onClick={deleteFinal}
          >
            Delete All!
          </button>
        </div>
      </div>
    </div>
  );
};
const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo (Congo-Brazzaville)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czechia (Czech Republic)",
  "Democratic Republic of the Congo (Congo-Kinshasa)",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini (fmr. Swaziland)",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Holy See",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar (formerly Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia (formerly Macedonia)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine State",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

// You can use this 'countries' array in your React component as needed.
