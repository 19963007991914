export const REGISTER_PLAYER_REQUEST = 'REGISTER_PLAYER_REQUEST';
export const REGISTER_PLAYER_SUCCESS = 'REGISTER_PLAYER_SUCCESS';
export const REGISTER_PLAYER_FAIL = 'REGISTER_PLAYER_FAIL';

export const UPDATE_PLAYER_REQUEST = 'UPDATE_PLAYER_REQUEST';
export const UPDATE_PLAYER_SUCCESS = 'UPDATE_PLAYER_SUCCESS';
export const UPDATE_PLAYER_FAIL = 'UPDATE_PLAYER_FAIL';

export const GET_PLAYER_REQUEST = 'GET_PLAYER_REQUEST';
export const GET_PLAYER_SUCCESS = 'GET_PLAYER_SUCCESS';
export const GET_PLAYER_FAIL = 'GET_PLAYER_FAIL';

export const LOAD_PLAYERS_REQUEST = 'LOAD_PLAYERS_REQUEST';
export const LOAD_PLAYERS_SUCCESS = 'LOAD_PLAYERS_SUCCESS';
export const LOAD_PLAYERS_FAIL = 'LOAD_PLAYERS_FAIL';

export const DELETE_PLAYER_REQUEST = 'DELETE_PLAYER_REQUEST';
export const DELETE_PLAYER_SUCCESS = 'DELETE_PLAYER_SUCCESS';
export const DELETE_PLAYER_FAIL = 'DELETE_PLAYER_FAIL';
export const DELETE_PLAYER_RESET = 'DELETE_PLAYER_RESET';

export const CLEAR_PLAYER_ERRORS = 'CLEAR_PLAYER_ERRORS';
