import {
  REGISTER_CLINIC_REQUEST,
  REGISTER_CLINIC_SUCCESS,
  REGISTER_CLINIC_FAIL,
  UPDATE_CLINIC_REQUEST,
  UPDATE_CLINIC_SUCCESS,
  UPDATE_CLINIC_FAIL,
  GET_CLINIC_REQUEST,
  GET_CLINIC_SUCCESS,
  GET_CLINIC_FAIL,
  LOAD_CLINICS_REQUEST,
  LOAD_CLINICS_SUCCESS,
  LOAD_CLINICS_FAIL,
  DELETE_CLINIC_REQUEST,
  DELETE_CLINIC_SUCCESS,
  DELETE_CLINIC_FAIL,
  DELETE_CLINIC_RESET,
  CLEAR_CLINIC_ERRORS,
} from "../constants/ClinicConstants";

const initialState = {
  clinics: [],
  clinicDetails: {},
  created:false,
  score: {},
  team: {},
  loading: false,
  error: null,
};

export const clinicReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CLINICS_REQUEST:
    case GET_CLINIC_REQUEST:
      case REGISTER_CLINIC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CLINIC_SUCCESS:
      return {
        ...state,
        loading: false,
        clinicDetails: action.payload.clinic,
        team: action.payload.team,
        score: action.payload.score,
      };

      case REGISTER_CLINIC_SUCCESS:
        return {
          ...state,
          loading: false,
          clinicDetails: action.payload,
          created:true
        };
    case LOAD_CLINICS_SUCCESS:
      return {
          ...state,
        loading: false,
       clinics: action.payload,
      };
    case GET_CLINIC_FAIL:
    case LOAD_CLINICS_FAIL:
      case REGISTER_CLINIC_FAIL:

      return {
          ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_CLINIC_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
