import React from "react";

const LtNewsSkeleton = () => {
  return (
    <div className="animate-pulse flex bg-white flex-col md:flex-row">
      <div className="animate-pulse w-full md:w-1/2">
        <div className=" animate-pulse h-55 w-full md:max-w-full md:h-[300px] bg-gray-200"></div>
      </div>
      <div  className="animate-pulse w-full flex flex-col justify-between gap-1 md:w-1/2 p-4">
        <div className="animate-pulse text-[22px] max-h-[60px] overflow-hidden bg-gray-200"></div>
        <div className="animate-pulse h-[20px] overflow-hidden bg-gray-200"></div>
        <div className="animate-pulse h-[10px] overflow-hidden bg-gray-200"></div>
        <div className="animate-pulse h-[10px] overflow-hidden bg-gray-200"></div>
        <div className="animate-pulse h-[10px] overflow-hidden bg-gray-200"></div>
        <div className="animate-pulse h-[10px] overflow-hidden bg-gray-200"></div>
        <div className="animate-pulse h-[10px] overflow-hidden bg-gray-200"></div>
        <div className="animate-pulse h-[10px] overflow-hidden bg-gray-200"></div>
        <div className="animate-pulse h-[10px] overflow-hidden bg-gray-200"></div>
        <div className="animate-pulse h-[10px] overflow-hidden bg-gray-200"></div>
        <div className="animate-pulse h-[10px] overflow-hidden bg-gray-200"></div>
        <div className="animate-pulse h-[10px] overflow-hidden bg-gray-200"></div>
        <div className="animate-pulse h-[10px] overflow-hidden bg-gray-200"></div>
        <div className="animate-pulse h-[10px] overflow-hidden bg-gray-200"></div>
        
        <div className="animate-pulse bg-gray-200"></div>
      </div>
    </div>
  );
};

export default LtNewsSkeleton;
