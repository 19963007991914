import OrNews from "../../Components/OrNews";
import Articles from "../../Components/Articles";
import FlatNews from "../../Components/FlatNews";
import LtNews from "../../Components/LtNews";
import SignUpList from "../../Components/SignUPList";
import Advertisement from "../../Components/Advertisement";
import Sponsors from "../../Components/Sponsors";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RenderShop from "../../Components/Shop/RenderShop";
import useHandleNews from "../../hooks/handleNews";
import ArticleLoading from "../../Components/Skeleton/ArticleLoading";
import useHandleEvents from "../../hooks/handleEvents";
import {useParams} from 'react-router-dom';
import { addLeaguesToCart } from "../../redux/actions/cartAction";
import Loader from "../../Components/Loader";
import AdvertisementEvent from "../../Components/Advertisement/EVENTADVERTISMENT";

const Event = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.user);

  const {id}=useParams()
  const {
    news,
    loading,
   
  } = useHandleNews();

  const {loading:eventLoading,event,fetchEventById}=useHandleEvents();
  const AddToCart = async () => {
    const id = event?.id;
    const data = {
      title: event?.title,
      selling_price: event?.selling_price,
      location: event?.location,
      date: event?.date,
      img_url: event?.img_url,
      rating: event?.rating,
      leagueId: event?.id,
      teamsId: event?.teamsId,
      teamsName: event?.teamsName,
      cartId: user,
      quantity: 1,
      finalPrice: event?.selling_price,
    };
    dispatch(addLeaguesToCart(user, data));
    toast.success("Added League To Cart");
  };
  // Update current news when 'news' state changes or initial load
  useEffect(() => {
    if (id) {
      fetchEventById(id)
    }
  }, [id]);
  console.log("event",event)

  if(loading){
    return <Loader/>
  }

  return (
    <div className="pt-10">
      <ToastContainer />

      <div className="px-5 md:px-20 py-10">
        <AdvertisementEvent one index={0} />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-20 px-4 md:px-20">
        <div className="col-span-2">
          <div className="py-2 uppercase font-bold text-4xl border-b-2 mb-10 flex justify-between border-[#c4c4c4]">
            <span className=" border-l-4 border-[#D70D15] text-3xl px-2">
              Event
            </span>
            <button className="text-sm rounded-sm hover:opacity-80 transition-all duration-300 ease-in-out  font-normal p-3 bg-blue-600 text-white " onClick={AddToCart}>
              Add to Cart
            </button>
          </div>
          <OrNews loading={eventLoading} event news={event} />
        </div>
        <div>{loading ? <ArticleLoading /> : <Articles news={news} />}</div>
      </div>
      <div className="px-4 md:px-20 mt-20 mb-20">
    
        <FlatNews event loading={loading} newsList={news} />
      </div>
      <br />

      <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-cols-3 md:gap-10 bg-gray-300 pt-20 px-4 md:px-18">
        <div className="bg col-span-2 ">
          
          <LtNews />
        </div>

        <div className="flex  min-w-[100%] lg:w-full  flex-col  gap-10 mb-10">
          {/* <div className=" hidden lg:block"> */}
          <RenderShop />
          {/* </div> */}
          {/* <div className="w-full block lg:hidden"> */}
          {/* <Shop /> */}
          {/* </div> */}
          <Sponsors />
          {/* <SignUpWK /> */}
          <SignUpList />
          {/* <Workwith title="work with us" button="volunteer" link="/signupvolunteer" /> */}
        </div>
      </div>
    </div>
  );
};

export default Event;
