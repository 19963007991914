import { Auth } from "aws-amplify";

import {
  LOGIN_REQUEST,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  ALL_USERS_REQUEST,
  ALL_USERS_SUCCESS,
  ALL_USERS_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  CLEAR_ERRORS,
} from "../constants/UserConstants";

import axios from "axios";

// Login
export const login = (email, password, user) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });
    let User = null;
    localStorage.setItem("email", email);
    localStorage.setItem("auth", true);
console.log("data",user);
const userId=user.attributes.sub;
let customAttributes=user.attributes;
let attributes;
    dispatch({ type: LOGIN_SUCCESS, payload: {userId:userId,userDetails:customAttributes} });
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({ type: LOGIN_FAIL, payload: errorMessage });
  }
};

// Register
export const register = (userData) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_USER_REQUEST });

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.post(`/api/v1/register`, userData, config);

    dispatch({ type: REGISTER_USER_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: REGISTER_USER_FAIL,
      payload: errorMessage,
    });
  }
};

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_USER_REQUEST });
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });

    
    const userId=user.attributes.sub;
    let customAttributes=user.attributes;
    let attributes;

    const printCustomAttributes = () => {
      for (const attribute in customAttributes) {
        if (attribute.startsWith('custom:')) {
          if (attribute === 'custom:name') {
            attribute=customAttributes[attribute];
          }
        }
      }
    };

    const isAuthenticated = true;
    // console.log("isAuthenticated: " , isAuthenticated)
  
    if (user || isAuthenticated === true) {
      dispatch({ type: LOAD_USER_SUCCESS, payload: {userId,userDetails:customAttributes} });
    } else {
      dispatch({ type: LOAD_USER_FAIL, payload: "Not Logged In" });
    }
    
    // token: localStorage.getItem("token") || null,
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({ type: LOAD_USER_FAIL, payload: errorMessage });
  }
};

// Logout User
export const logout = () => async (dispatch) => {
  try {
    Auth.signOut()
      .then(() => {
        console.log("Successfully signed out");
      })
      .catch((err) => {
        console.log("Signout Error - ", err);
      });
    localStorage.clear();

    dispatch({ type: LOGOUT_SUCCESS });
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({ type: LOGOUT_FAIL, payload: errorMessage });
  }
};

// updateProfile

export const updateProfile = (userNewData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PROFILE_REQUEST });

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.put(
      `/api/v1/upd/profile`,
      userNewData,
      config
    );

    dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: data.success });
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: UPDATE_PROFILE_FAIL,
      payload: errorMessage,
    });
  }
};

// get All Users
export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_USERS_REQUEST });
    const { data } = await axios.get(`/api/v1/admin/users`);
    console.log("users", data);
    dispatch({ type: ALL_USERS_SUCCESS, payload: data.Users });
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({ type: ALL_USERS_FAIL, payload: errorMessage });
  }
};

// get  User Details
export const getUserDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST });
    const { data } = await axios.get(`/api/v1/admin/user/${id}`);

    dispatch({ type: USER_DETAILS_SUCCESS, payload: data.User });
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({ type: USER_DETAILS_FAIL, payload: errorMessage });
  }
};

// Update User
export const updateUser = (id, userData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_REQUEST });

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.put(
      `/api/v1/admin/user/${id}`,
      userData,
      config
    );

    dispatch({ type: UPDATE_USER_SUCCESS, payload: data.success });
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: UPDATE_USER_FAIL,
      payload: errorMessage,
    });
  }
};

// Delete User
export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_USER_REQUEST });

    const { data } = await axios.delete(`/api/v1/admin/user/${id}`);

    dispatch({ type: DELETE_USER_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: DELETE_USER_FAIL,
      payload: errorMessage,
    });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// updatePassword

export const updatePassword = (passwords) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PASSWORD_REQUEST });

    // console.log("userNewData", );
    const config = { headers: { "Content-Type": "multipart/form-data" } };

    console.log("passwords", passwords);
    const { data } = await axios.put(`/api/v1/upd/pass`, passwords, config);

    dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: data.success });
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload: errorMessage,
    });
  }
};

// forgotPassword

export const forgotPassword = (myForm) => async (dispatch) => {
  try {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });

    console.log("userEmial", myForm);
    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.post(
      `/api/v1/password/forgot`,
      myForm,
      config
    );

    dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data.message });
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: errorMessage,
    });
  }
};

// resetPassword

export const resetPassword = (token, password) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    console.log("Token", token, "password", password);
    const config = { headers: { "Content-Type": "application/json" } };

    // const { data } = await axios.put(`/api/v1/password/reset/${token}`, password, config);
    const { data } = await axios.put(
      `/api/v1/password/reset/${token}`,
      { password },
      config
    );

    console.log("data", data, data?.success);
    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data?.success || true });
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: errorMessage,
    });
  }
};


export const clearUserErrors = () => async (dispatch) => {
  
  dispatch({ type: CLEAR_ERRORS });
};