import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import getLink from "../utils/getLinks";

const useGetAllTournamentTeams = () => {
  const [teamslist, setTeamsList] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchAllTeams = async () => {
    try {
      setLoading(true);

      // Fetch all tournament teams
      const teamsResponse = await API.graphql({
        query: queries.listTournamentTeams,
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const tournamentTeams =
        teamsResponse?.data?.listTournamentTeams?.items || [];

      // Fetch tournaments for each team
      const updatedData = await Promise.all(
        tournamentTeams.map(async (team) => {
          try {
            // Count players associated with the team from the `players.items` array
            const playerCount = team.players.items.length;

            // Filter out teams with rarely 4 players in their tournaments
            if (playerCount >= 4) {
              return null; // Return null to exclude this team from the list
            }

            // Fetch signed URL for team image (assuming getLink function handles this)
            const signedUrl = await getLink(team?.imageURL);

            // Return updated team object with signed URL and player count
            return {
              ...team,
              imageURL: signedUrl,
              playerCount: playerCount,
            };
          } catch (error) {
            console.error("Error fetching player count for team:", error);
            return null; // Return null for error handling
          }
        })
      );

      // Filter out null entries (teams with rarely 4 players)
      const filteredTeams = updatedData.filter((team) => team !== null);

      setTeamsList(filteredTeams);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching tournament teams:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllTeams();
  }, []);

  return { teamslist, loading };
};

export default useGetAllTournamentTeams;
