import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import getLink from "../utils/getLinks";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import { useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { listAppointments } from "../graphql/customQueries";

const useHandleMeetUser = () => {
  const [meetList, setMeetList] = useState([]);
  const { pathname } = useLocation();

  const { id } = useParams();

  const [meetDetails, setMeetDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const getAllMeet = async () => {
    setLoading(true);
    try {
      let query;

      let filter;

      if (pathname.includes("clinic")) {
        filter = { type: { eq: "CLINIC" } };
      } else if (pathname.includes("camp")) {
        filter = { type: { eq: "CAMP" } };
      } else {
        filter = { type: { eq: "TRAINING_PROGRAM" } };
      }
      // Use the filter in the GraphQL operation
      const response = await API.graphql(
        graphqlOperation(queries.listAppointments, { filter })
      );
      let appointmentList = [];

      appointmentList = response.data.listAppointments.items;
      for (const appointment of appointmentList) {
        for (const schedule of appointment.schedule.items) {
          const id = schedule.tutorId;
          const tutorResponse = await API.graphql({
            query: queries.getTutor,
            variables: { id },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
          const tutor = tutorResponse.data.getTutor;
          const profile = await getLink(tutor.profile);
          // Store tutor details in the schedule
          schedule.tutorName = tutor.name;
          schedule.tutorProfile = profile;
        }
      }

      setMeetList(appointmentList);
      setLoading(false);
    } catch (error) {
      console.log("ERROR", error);
      toast.error(error.message);
      throw new Error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getMeetById = async (id) => {
    setLoading(true);
    try {
      const response = await API.graphql({
        query: queries.getMeet,
        variables: { input: { id } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const meeT = response?.data.getMeet;
      setMeetDetails(meeT);

      setLoading(false);
    } catch (error) {
      console.log("ERROR", error);
      toast.error(error.message);

      throw new Error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllMeet();
    //

    //if (id) {
    //    getMeetById(id)
    //    }
  }, []);

  return { meetList, loading };
};

export default useHandleMeetUser;
