import {
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_FAIL,
  UPDATE_TEAM_REQUEST,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAIL,
  GET_TEAM_REQUEST,
  GET_TEAM_SUCCESS,
  GET_TEAM_FAIL,
  LOAD_TEAMS_REQUEST,
  LOAD_TEAMS_SUCCESS,
  LOAD_TEAMS_FAIL,
  LIST_TEAMS_REQUEST,
  LIST_TEAMS_SUCCESS,
  LIST_TEAMS_FAIL,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAIL,
  DELETE_TEAM_RESET,    
  CLEAR_TEAM_ERRORS,
  
} from "../constants/TeamsConstants";

  const initialState = {
    teams: [],
    teamsCount: 0,
    resultPerPage: 0,
    team: {},
    loading: false,
    error: null,
    filteredteamsCount: 0,
  };
  
  export const teamsReducer = (state = initialState, action) => {
    switch (action.type) {
      case LIST_TEAMS_REQUEST:
      case GET_TEAM_REQUEST:
        return {
          loading: true,
          teams: [],
          team:{}

        };
      case LIST_TEAMS_SUCCESS:
        return {
          loading: false,
          teams: action.payload.teams,
          teamCount: action.payload.teamsCount,
          resultPerPage: action.payload.resultPerPage,
          filteredteamsCount: action.payload.filteredteamsCount,
        };
  
        case GET_TEAM_SUCCESS:
          return {
            loading: false,
            team:action.payload

          };
      case GET_TEAM_FAIL:
      case LIST_TEAMS_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_TEAM_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  
  
  
  
  
  
  
  // export const newProductReducer = (state = { product: {} }, action) => {
  //   switch (action.type) {
  //     case CREATE_PRODUCT_REQUEST:
  //       return {
  //         ...state,
  //         loading: true,
  //       };
  //     case CREATE_PRODUCT_SUCCESS:
  //       return {
  //         loading: false,
  //         success: action.payload.success,
  //         product: action.payload.product,
  //       };
  //     case CREATE_PRODUCT_FAIL:
  //       return {
  //         ...state,
  //         loading: false,
  //         error: action.payload,
  //       };
  //     // case ADD_PRODUCT_RESET:
  //     //   return {
  //     //     ...state,
  //     //     success: false,
  //     //   };
  //     case CLEAR_PRODUCT_ERRORS:
  //       return {
  //         ...state,
  //         error: null,
  //       };
  //     default:
  //       return state;
  //   }
  // };
  
  
  
  // export const productDetailsReducer = (state = { product: {} }, action) => {
  //   switch (action.type) {
  //     case GET_PRODUCT_REQUEST:
  //       return {
  //         loading: true,
  //         ...state,
  //       };
  //     case GET_PRODUCT_SUCCESS:
  //       return {
  //         loading: false,
  //         product: action.payload,
  //       };
  //     case GET_PRODUCT_FAIL:
  //       return {
  //         loading: false,
  //         error: action.payload,
  //       };
  
  //     case CLEAR_PRODUCT_ERRORS:
  //       return {
  //         ...state,
  //         error: null,
  //       };
  //     default:
  //       return state;
  //   }
  // };
  
 

  

  
  
  // export const productReducer = (state = {}, action) => {
  //   switch (action.type) {
  //     case DELETE_PRODUCT_REQUEST:
  //     case UPDATE_PRODUCT_REQUEST:
  //       return {
  //         ...state,
  //         loading: true,
  //       };
  //     case DELETE_PRODUCT_SUCCESS:
  //       return {
  //         ...state,
  //         loading: false,
  //         isDeleted: action.payload,
  //       };
  
  //     case UPDATE_PRODUCT_SUCCESS:
  //       return {
  //         ...state,
  //         loading: false,
  //         isUpdated: action.payload,
  //       };
  //     case DELETE_PRODUCT_FAIL:
  //     case UPDATE_PRODUCT_FAIL:
  //       return {
  //         ...state,
  //         loading: false,
  //         error: action.payload,
  //       };
  //     case DELETE_PRODUCT_RESET:
  //       return {
  //         ...state,
  //         isDeleted: false,
  //       };
  //     // case UPDATE_PRODUCT_RESET:
  //     //   return {
  //     //     ...state,
  //     //     isUpdated: false,
  //     //   };
  //     case CLEAR_PRODUCT_ERRORS:
  //       return {
  //         ...state,
  //         error: null,
  //       };
  //     default:
  //       return state;
  //   }
  // };