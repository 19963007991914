import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

const useLeagueList = () => {
  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        setLoading(true);

        const response = await API.graphql({
          query: queries.listLeagues,
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        const leaguesData = response?.data?.listLeagues?.items || [];
   
        setLeagues(leaguesData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching leagues:", error);
      }
    };

    fetchLeagues();
  }, []);

  return { leagues };
};

export default useLeagueList;
