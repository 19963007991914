import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import useAuthInfo from "./getUser";
import useUserCart from "./getUserCart";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useCreateAddress from "./createAddress";


const useCreateCart = () => {

const {createAddress}=useCreateAddress()


  const createUserCart = async (id) => {
    try {

      

      const leaguesCart = {
        userid: id,
        id: id,
        Leagues: []
        // Use the existing cart ID

      };

      console.log("Id",id)

      const res=await createAddress(id);
     

      // const resp = await API.graphql({
      //   query: mutations.createLeaguesCart,
      //   variables: { input: leaguesCart },
      //   authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS // Adjust this as needed
      // });

    } catch (error) {
      console.error("Error Creating cart:", error);
    }
  };

  
  return { createUserCart };
};

export default useCreateCart;
