import React from "react";
import LazyLoad from "react-lazyload";
import placeholderImage from "./imageicon.png";
import SmallLoader from "../Loader/SmallLoader";

const AdvertisementImage = ({ ad }) => {
  console.log("add", ad);
  // Determine image size based on the viewport width
  let imageSize;
  const windowWidth = window.innerWidth;
  
  if (ad) {
    const { lgImageUrl, smImageUrl, mdImageUrl, xsImageUrl } = ad;
    if (windowWidth > 1500) {
      imageSize = lgImageUrl;
    } else if (windowWidth > 1000) {
      imageSize = mdImageUrl;
    } else if (windowWidth > 640) {
      imageSize = smImageUrl;
    } else {
      imageSize = xsImageUrl;
    }
  }

  return (
    <img
      className="w-screen h-[240px] object-cover"
      src={imageSize || placeholderImage}
      alt="AdvertisementImage"
    />
    // </LazyLoad>
  );
};

export default AdvertisementImage;
