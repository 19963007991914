import React from "react";

const OrNewsSkeleton = () => {
  return (
    <>
      <div className="px-10">
        {/* Placeholder for date */}
        <span className="w-[80px] h-6 mb-2 bg-gray-200 block"></span>
        {/* Placeholder for views */}
        <span className="w-[50px] h-6 mb-2 bg-gray-200 block"></span>
        {/* Placeholder for title */}
        <span className="w-full h-5 mb-4 bg-gray-200 block"></span>
        {/* Placeholder for content text */}
        <span className="w-full h-5 mb-2  bg-gray-200 block"></span>
        <span className="w-full h-5 mb-2 bg-gray-200 block"></span>
        <span className="w-full h-5 mb-2 bg-gray-200 block"></span>
        <span className="w-full h-5 mb-2 bg-gray-200 block"></span>
        <span className="w-full h-5 mb-2 bg-gray-200 block"></span>
        <span className="w-full h-5 mb-2 bg-gray-200 block"></span>
        <span className="w-full h-5 mb-2 bg-gray-200 block"></span>
        <span className="w-full h-5 mb-2 bg-gray-200 block"></span>
        <span className="w-full h-5 mb-2 bg-gray-200 block"></span>
        <span className="w-full h-5 mb-2 bg-gray-200 block"></span>
        <span className="w-full h-5 mb-2  bg-gray-200 block"></span>

      </div>
      {/* Placeholder for image */}
  
    </>
  );
};

export default OrNewsSkeleton;
