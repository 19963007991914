import Teams from "../../Components/Teams";
import Shop from "../../Components/Shop";
import TypoBu from "../../Components/TypoBu";
import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import Advertisement from "../../Components/Advertisement";
import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";

const About = () => {
 
  return (
    <div className="bg-white text-white">
      <div className="sm:ml-4 md:flex mb-20">
        <div className="md:w-3/5 md:p-8 md:mt-20 mt-4 relative mb-20">
          <div className="text-4xl text-[#F1F1F1] font-bold relative z-10 whitespace-nowrap md:text-9xl md:flex md:flex-col">
            <span className="uppercase whitespace-nowrap md:whitespace-normal pr-2">about</span>
            <span className="uppercase whitespace-nowrap md:whitespace-normal">us</span>
          </div>
          <img
            className="absolute top-2 md:mt-[5.5rem] mt-4 w-96 left-2 sm:left-[8rem] z-20"
            src="/images/Bigball.png"
            alt="Bigball"
          />
        </div>
        <div className="whitespace-normal text-[1.09rem] break-words w-full md:w-2/5 text-black md:mr-8 mt-[17rem] sm:mt-[10rem] px-2">
          <span>
            The Bay Area Hoop it up 3x3 league is the first and the largest
            youth and adult 3x3 regional league of it's kind within the Hoop It
            Up 3x3 basketball tour family. We are dedicated to delivering a
            world class 3x3 basketball league experience to our community, our
            fans, and business partners. We are dedicated to our community with
            elite basketball leagues, camps, clinics, training programs to the
            San Francisco Bay area and other regions in Northern California. We
            want to bring a product to our community and the rest of the Hoop It
            Up family that is a quality family entertainment experience.
          </span>
        </div>
      </div>
      <div className="md:flex md:items-center mb-20 ">
        <div className="md:w-1/2 md:px-[3.8rem] ml-2 mr-2">
          <span className="md:mb-0 text-black md:mr-4">
            <strong className="block  text-2xl mb-2">Our goal</strong>
            <span className="text-[1.09rem]">
              is to provide players with a basketball platform that is an
              opportunity to not only play in a fun competitive league but also gain
              the knowledge mindset for good sportsmanship, dedication, compassion,
              the importance of health and wellness, the correct nutrition for a
              better lifestyle, preventative medicine to lower injury risk and help
              guide them to better basketball and all sport biomechanics.
            </span>
          </span>
        </div>
        <div className="md:flex md:w-1/2 md:justify-end md:items-center md:mt-4">
          <img src="/images/dunk.png" className="w-full md:w-2/3" />
        </div>
      </div>
      <div className="md:flex md:items-center">
        <div className="md:flex md:w-1/2 md:justify-start md:items-center md:mb-0 mb-2 md:mt-4">
          <img src="/images/dunk1.png" className="w-full md:w-2/3" />
        </div>
        <div className="md:w-1/2 md:px-[3.8rem] ml-2 mr-2">
          <span className="md:mb-0 text-black md:mr-4">
            <strong className="block text-2xl mb-2">Our mission</strong>
            <span className="text-[1.09rem]">
              Our mission as an organization is to engage in the local communities
              by interacting and offering our support and encouragement within all
              communities through all types of activities within schools,
              fundraising and charity event, youth camps, clinics and non-profit
              organizations and many other group events as possible as we grow with
              our families.
            </span>
          </span>
        </div>

      </div>
      <div className="relative">
        <img src="/images/bg-ball.png" className="w-full h-full" alt="Background Image" />
        <div className="absolute inset-0 md:flex md:items-center md:justify-start mt-[9.5rem]">
          <div className="md:w-1/2 md:px-[3.8rem] ml-2 mr-2 text-white text-sm md:text-[1.09rem]">
            <div className="mb-4">
              Bay Area 3x3 Basketball organizations joined forces with Hoop It Up World Tour and is now officially sanctioned by FIBA, The International Basketball Federation, the world governing body for basketball, Which oversees international competitions that include the <span className="underline">FIBA Basketball World Cup</span>, the <span className="underline">Olympic Basketball Tournament</span>, and <span className="underline">3x3 basketball.</span>
            </div>
            <div>
              Our goal is to give all basketball players the opportunity to play the game they love, in America and even around the world. Also give players the biggest opportunity to be nationally recognized and a chance to play for a Gold Medal in the 3x3 Olympic Basketball Tournament.
            </div>
          </div>
        </div>
      </div>
      <div className="pt-[18rem] md:pt-[5rem] pb-[3rem] bg-[#181B22]">
        <Teams />
        <AdvertisementOne />
       
      </div>
    </div>
  );
};

export default About;
