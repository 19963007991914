import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import { useParams } from "react-router-dom";
import { allImage, getNonMatchingValues, replaceUrl, updateImageSrcInHtml, getKey } from '../utils/replaceUrl';
import getLink from "../utils/getLinks";

const useGetAllTrainingPrograms = () => {
  const [trainingPrograms, setTrainingPrograms] = useState([]);
  const [trainingProgramsDetails, setTrainingProgramsDetails] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const getTrainingPrograms = async () => {
    try {
      setLoading(true);
      const response = await API.graphql({
        query: queries.listTrainingProgramCards,
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      if (!response || !response.data || !response.data.listTrainingProgramCards || !response.data.listTrainingProgramCards.items) {
        throw new Error("No data found");
      }

      const sortedPrograms = response.data.listTrainingProgramCards.items.sort((a, b) => a.order - b.order);

      const updatedPrograms = await Promise.all(
        sortedPrograms.map(async (program) => {
          const descriptionWithUpdatedImageURL = await updateImageSrcInHtml(program.description, getLink, getKey);
          program.description = descriptionWithUpdatedImageURL;
          return program;
        })
      );

      setTrainingPrograms(updatedPrograms);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching training programs:', error);
    }
  };

  const getTrainingProgramsDetail = async () => {
    try {
      setLoading(true);

      const options = {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      };

      const response = await API.graphql(
        graphqlOperation(queries.getTrainingProgramCard, { id }),
        options
      );

      if (!response || !response.data || !response.data.getTrainingProgramCard) {
        throw new Error("No data found");
      }

      const programDetails = response.data.getTrainingProgramCard;
      const descriptionWithUpdatedImageURL = await updateImageSrcInHtml(programDetails.description, getLink, getKey);
      programDetails.description = descriptionWithUpdatedImageURL;

      setTrainingProgramsDetails(programDetails);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching training program details:', error);
    }
  };

  useEffect(() => {
    if (id) {
      getTrainingProgramsDetail();
    }
    getTrainingPrograms();
  }, [id]);

  return { trainingPrograms, loading, trainingProgramsDetails };
};

export default useGetAllTrainingPrograms;
