export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAIL = 'CREATE_PRODUCT_FAIL';

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL';

export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL';

export const LIST_PRODUCTS_REQUEST = 'LIST_PRODUCTS_REQUEST';
export const LIST_PRODUCTS_SUCCESS = 'LIST_PRODUCTS_SUCCESS';
export const LIST_PRODUCTS_FAIL = 'LIST_PRODUCTS_FAIL';

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL';
export const DELETE_PRODUCT_RESET = 'DELETE_PRODUCT_RESET';

export const BUY_PRODUCT_REQUEST = 'BUY_PRODUCT_REQUEST';
export const BUY_PRODUCT_SUCCESS = 'BUY_PRODUCT_SUCCESS';
export const BUY_PRODUCT_FAIL = 'BUY_PRODUCT_FAIL';

export const CLEAR_PRODUCT_ERRORS = 'CLEAR_PRODUCT_ERRORS';
