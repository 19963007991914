import { useState } from "react";
import { API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import useAuthInfo from "./getUser";
import useGetAddressList from "./getAddressList";

const useUpdateAddress = () => {
  const [loading, setLoading] = useState(false);
  const { userId, isAuthenticated } = useAuthInfo();
  const { addressesList } = useGetAddressList();

  const updateAddress = async (index, newAddress, edit) => {
    let address = [];

    address = [...addressesList];

    try {
      if (isAuthenticated && userId) {
        setLoading(true);
        if (edit) {
          address = [...addressesList];

          // Replace the address at the specified index with the newAddress
          address[index] = newAddress;
          // Add the new address to the array
        } else {
          address.push(newAddress);
        }

        // update the address using the API
        const response = await API.graphql({
          query: mutations.updateAddress,
          variables: {
            input: {
              id: userId,
              userid: userId,
              addresses: address,
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        // Address creation was successful
        setLoading(false);
        return true;
      }
    } catch (error) {
      console.error("Error updating Address:", error);
      setLoading(false);
      return false;
    }
  };

  return { updateAddress, loading };
};

export default useUpdateAddress;
