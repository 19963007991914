import { Table } from "flowbite-react";
import Loader from "../Loader";
import { useEffect } from "react";
import {
  clearLeagueInfoErrors,
  getleagueAgeInfo,
} from "../../redux/actions/leagueInfoAction";
import {
  clearPlayerErrors,
  getAllPlayer,
} from "../../redux/actions/PlayerAction";
import { useDispatch, useSelector } from "react-redux";

const Age = {
  ages: ["5-7", "8-9", "10-12", "13-14", "15-17", "18-40", "40+"],
};

const LeagueCityTable = ({ teamslist, loading }) => {
  if (loading) {
    return <Loader />;
  }



  // Organize teams by age group
  const teamsByAge = {};
  teamslist?.forEach((team) => {
    if (!teamsByAge[team.agegroup]) {
      teamsByAge[team.agegroup] = [];
    }
    teamsByAge[team.agegroup].push(team);
  });

  console.log("Table Teams list", teamslist)


  return (
    <div className="overflow-x-auto">
      <Table className="text-center">
        <Table.Head>
          <Table.HeadCell className="min-w-[30px] bg-white whitespace-nowrap border px-1 py-1">
            Age Group
          </Table.HeadCell>
          {Array.isArray(teamslist) && teamslist.length > 0
            ? teamslist.length > 0 && teamslist?.map((item, index) => (
              <Table.HeadCell
                key={index}
                className="min-w-[30px] bg-white border px-2 py-2"
              >
                Team Name
              </Table.HeadCell>
            ))
            :
            // Age.ages.map((item, index) => (
            <Table.HeadCell
              //  key={index}
              className="min-w-[30px] bg-white border px-2 py-2"
            >
              Team Name
            </Table.HeadCell>
            //  ))
          }
        </Table.Head>
        <Table.Body className="divide-y">
          {Age.ages.map((age, index) => (
            <Table.Row
              className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
              key={index}
            >
              <Table.Cell className="whitespace-nowrap px-1 py-1 text-center border font-medium text-gray-900 dark:text-white min-w-[30]">
                {age}
              </Table.Cell>
              {teamsByAge[age]
                ? new Array(
                  Math.max(
                    teamslist.length > 0
                      ? teamslist.length
                      : Age.ages.length,
                    teamsByAge[age].length
                  )
                )
                  .fill("")
                  .map((_, teamIndex) => (
                    <Table.Cell
                      key={teamIndex}
                      className="min-w-[30px] text-center border px-2 py-2"
                    >
                      {teamsByAge[age][teamIndex]
                        ? teamsByAge[age][teamIndex].name
                        : ""}
                    </Table.Cell>
                  ))
                : new Array(
                  teamslist.length > 0 ? teamslist.length : 1
                )
                  .fill("")
                  .map((_, emptyIndex) => (
                    <Table.Cell
                      key={emptyIndex}
                      className="min-w-[30px] text-center border px-2 py-2"
                    />
                  ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default LeagueCityTable;
