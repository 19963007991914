import React, { useState } from "react";
import ProfileComponent from "./ProfileComponent";
import PasswordComponent from "./PasswordComponent";
import MemberComponent from "./MemberComponent";
import { FiMenu } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import useHandlePagintationPlayers from "../../hooks/handlePaginationPlayer";
import Loader from "../../Components/Loader";

const Profile = () => {
  const { updatePlayers, loading,alreadyPlayer, playersDetails } =
    useHandlePagintationPlayers();
  const [currentPage, setCurrentPage] = useState("PROFILE");

  console.log("Profile", playersDetails);




  if (loading) {
    return <Loader />;
  }

  return (
    <div className="grid grid-cols-5 sm:grid-cols-6  sm:justify-between w-full h-fit  sm:min-h-screen ">
      <div className="p-[25px] text-sm hidden sm:block border min-w-[150px]">
        <div className="flex flex-col  absolute sm:relative   w-full h-full">
          <p
            onClick={() => setCurrentPage("PROFILE")}
            className={`cursor-pointer hover:text-blue-500 duration-300 transition-all ease-in-out ${
              currentPage === "PROFILE"
                ? "text-blue-500 duration-300 transition-all ease-in-out"
                : ""
            }`}
          >
            Profile
          </p>

          <p
            onClick={() => setCurrentPage("PASSWORD")}
            className={`cursor-pointer hover:text-blue-500 duration-300 transition-all ease-in-out${
              currentPage === "PASSWORD"
                ? "text-blue-500 duration-300 transition-all ease-in-out"
                : ""
            }`}
          >
            Password
          </p>

          <p
            onClick={() => setCurrentPage("MEMBER")}
            className={`cursor-pointer hover:text-blue-500 duration-300 transition-all ease-in-out${
              currentPage === "MEMBER"
                ? "text-blue-500 duration-300 transition-all ease-in-out"
                : ""
            }`}
          >
            Member
          </p>
        </div>
      </div>
        <MobileProfile
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
    
      <div className="col-span-5 p-[25px] w-full overflow-auto md:sticky md:top-0">
        {currentPage === "PROFILE" && playersDetails && (
          <ProfileComponent
            updatePlayers={updatePlayers}
            playerData={playersDetails}
          />
        )}
        {currentPage === "PASSWORD" && <PasswordComponent />}
        {currentPage === "MEMBER" && (
          <MemberComponent expirationDate={playersDetails?.memberExpire} />
        )}
      </div>
    </div>
  );
};

const MobileProfile = ({ currentPage, setCurrentPage }) => {
  return (
    <>
      <div className=" text-sm sm:hidden  w-screen h-fit border overflow-hidden  p-[25px] bg-white">
     
          <p
            onClick={() => setCurrentPage("PROFILE")}
            className={`cursor-pointer hover:text-blue-500 w-full duration-300 transition-all ease-in-out ${
              currentPage === "PROFILE"
                ? "text-blue-500 duration-300 transition-all ease-in-out"
                : ""
            }`}
          >
            Profile
          </p>

          <p
            onClick={() => setCurrentPage("PASSWORD")}
            className={`cursor-pointer hover:text-blue-500 w-full duration-300 transition-all ease-in-out${
              currentPage === "PASSWORD"
                ? "text-blue-500 duration-300 transition-all ease-in-out"
                : ""
            }`}
          >
            Password
          </p>

          <p
            onClick={() => setCurrentPage("MEMBER")}
            className={`cursor-pointer hover:text-blue-500 w-full duration-300 transition-all ease-in-out${
              currentPage === "MEMBER"
                ? "text-blue-500 duration-300 transition-all ease-in-out"
                : ""
            }`}
          >
            Member
          </p>
      </div>
    </>
  );
};

export default Profile;
