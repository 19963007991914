import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../../graphql/queries";
import { useDispatch, useSelector } from "react-redux";

import * as mutations from "../../graphql/mutations"; // Import your GraphQL queries
// Import your GraphQL queries
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import {
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  GET_CART_FAIL,
  UPDATE_CARTITEMS_REQUEST,
  UPDATE_CARTITEMS_SUCCESS,
  UPDATE_CARTITEMS_FAIL,
  UPDATE_LEAGUE_REQUEST,
  UPDATE_LEAGUE_SUCCESS,
  UPDATE_LEAGUE_FAIL,
  DELETE_CART_RESET,
  CLEAR_CART_ERRORS,
  ADD_ITEMS_TO_CART_REQUEST,
  ADD_ITEMS_TO_CART_SUCCESS,
  ADD_ITEMS_TO_CART_FAIL,
  ADD_ITEMS_TO_CART_RESET,
  REMOVE_ITEMS_FROM_CART_REQUEST,
  REMOVE_ITEMS_FROM_CART_SUCCESS,
  REMOVE_ITEMS_FROM_CART_FAIL,
  REMOVE_LEAGUE_FROM_CART_REQUEST,
  REMOVE_LEAGUE_FROM_CART_SUCCESS,
  REMOVE_LEAGUE_FROM_CART_FAIL,
  REMOVE_LEAGUE_FROM_CART_RESET,
  ADD_LEAGUE_TO_CART_REQUEST,
  ADD_LEAGUE_TO_CART_SUCCESS,
  ADD_LEAGUE_TO_CART_FAIL,
  ADD_LEAGUE_TO_CART_RESET,
  GET_MAIN_CART_REQUEST,
GET_MAIN_CART_SUCCESS,
GET__MAIN_CART_FAIL
} from "../constants/CartConstants";
import getLinks from "../../utils/getLinks";



export const getCart = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_CART_REQUEST });
    // const responseCart = await API.graphql({
    //   query: queries.getCart,
    //   variables: {
    //     id
    //   },
    //   authMode: GRAPHQL_AUTH_MODE.API_KEY,
    // });
    // const carts=responseCart?.data.getCart||{};
    // console.log("Carts",carts)
    const response = await API.graphql({
      query: queries.listCartItems,
      variables: {
        filter: {
          userid: {
            eq: id,
          },
          // cartId: {
          //   eq: id,
          // },
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const responseleague = await API.graphql({
      query: queries.listLeagueCarts,
      variables: {
        filter: {
          userid: {
            eq: id,
          },
          // cartId: {
          //   eq: id,
          // },
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const cartItems = response?.data?.listCartItems?.items || [];
    const leagueItems = responseleague?.data?.listLeagueCarts?.items || [];
    const updatedData = await Promise.all(
      leagueItems?.map(async (items) => {
        try {
          const signedUrl = await getLinks(items?.img_url);

          return {
            ...items,
            render_img_url: signedUrl,
          };
        } catch (error) {
          console.error("Error fetching image URL", error);
          return items;
        }
      })
    );
    const updatedItems = await Promise.all(
      cartItems?.map(async (items) => {
        try {
          const signedUrl = await getLinks(items?.img_url);
          return {
            ...items,
            render_img_url: signedUrl,
          };
        } catch (error) {
          console.error("Error fetching image URL", error);
          return items;
        }
      })
    );

    const payloadData = {
      cart: { cartItems: updatedItems, leagueItems: updatedData },
      cartQuantity: cartItems?.length,
      leagueQuantity: leagueItems?.length,
      cartItems:updatedItems,
      leagueItems:updatedData
      // totalPrice:carts?.totalPrice,
    };

    

    dispatch({
      type: GET_CART_SUCCESS,
      payload: payloadData,
    });
  } catch (error) {
    console.log("Error", error);
    dispatch({
      type: GET_CART_FAIL,
      payload: error.message || "Failed to fetch cart data",
    });
  }
};

export const getUserCart = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_MAIN_CART_REQUEST });
    // const responseCart = await API.graphql({
    //   query: queries.getCart,
    //   variables: {
    //     id
    //   },
    //   authMode: GRAPHQL_AUTH_MODE.API_KEY,
    // });
    // const carts=responseCart?.data.getCart||{};
    // console.log("Carts",carts)
    const response = await API.graphql({
      query: queries.listCartItems,
      variables: {
        filter: {
          userid: {
            eq: id,
          },
          // cartId: {
          //   eq: id,
          // },
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const responseleague = await API.graphql({
      query: queries.listLeagueCarts,
      variables: {
        filter: {
          userid: {
            eq: id,
          },
          // cartId: {
          //   eq: id,
          // },
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const cartItems = response?.data?.listCartItems?.items || [];
    const leagueItems = responseleague?.data?.listLeagueCarts?.items || [];
    const updatedData = await Promise.all(
      leagueItems?.map(async (items) => {
        try {
          const signedUrl = await getLinks(items?.img_url);

          return {
            ...items,
            render_img_url: signedUrl,
          };
        } catch (error) {
          console.error("Error fetching image URL", error);
          return items;
        }
      })
    );
    const updatedItems = await Promise.all(
      cartItems?.map(async (items) => {
        try {
          const signedUrl = await getLinks(items?.img_url);
          return {
            ...items,
            render_img_url: signedUrl,
          };
        } catch (error) {
          console.error("Error fetching image URL", error);
          return items;
        }
      })
    );

    const payloadData = {
      cart: { cartItems: updatedItems, leagueItems: updatedData },
      cartQuantity: cartItems?.length,
      leagueQuantity: leagueItems?.length,
      cartItems:updatedItems,
      leagueItems:updatedData
      // totalPrice:carts?.totalPrice,
    };

    dispatch({
      type: GET_MAIN_CART_SUCCESS,
      payload: payloadData,
    });
  } catch (error) {
    console.log("Error", error);
    dispatch({
      type: GET__MAIN_CART_FAIL,
      payload: error.message || "Failed to fetch cart data",
    });
  }
};

export const addToCart = (userid, item,cartItems) => async (dispatch) => {
  const isAlready = await API.graphql({
    query: queries.listCartItems,
    variables: {
      filter: {
        userid: {
          eq: userid, // Assuming userid is a variable containing the user ID
        },
        itemId: {
          eq: item?.itemId, // Replace specificItemId with the actual item ID you're querying for
        },
      },
    },
  });
  if (isAlready?.data?.listCartItems?.items.length > 0) {
    return;
  }
  try {
    dispatch({ type: ADD_ITEMS_TO_CART_REQUEST });
    const response = await API.graphql({
      query: mutations.createCartItem,
      variables: {
        input: {
          userid,
          ...item,
          cartId: userid,
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const data = [...cartItems,response?.data?.createCartItem];
    dispatch({
      type: ADD_ITEMS_TO_CART_SUCCESS,
      payload:data ,
    });
  } catch (error) {
    console.log("Error", error);
    dispatch({
      type: ADD_ITEMS_TO_CART_FAIL,
      payload: error.message || "Failed to fetch cart data",
    });
  }
};

export const addLeaguesToCart = (userid, item,leagueItems) => async (dispatch) => {
  const isAlready = await API.graphql({
    query: queries.listLeagueCarts,
    variables: {
      filter: {
        userid: {
          eq: userid, // Assuming userid is a variable containing the user ID
        },
        leagueId: {
          eq: item?.leagueId, // Replace specificItemId with the actual item ID you're querying for
        },
      },
    },
  });
  const responsedd=isAlready?.data?.listLeagueCarts?.items?.length>0;
  if (isAlready?.data?.listLeagueCarts?.items?.length > 0) {
    return;
  }
  try {
    dispatch({ type: ADD_LEAGUE_TO_CART_REQUEST });
    const response = await API.graphql({
      query: mutations.createLeagueCart,
      variables: {
        input: {
          userid,
          ...item,
          cartId: userid,
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const createLeagueItem = response?.data?.createLeagueCart;
    const data = [...leagueItems,createLeagueItem];

    dispatch({
      type: ADD_LEAGUE_TO_CART_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log("Error", error);
    dispatch({
      type: ADD_LEAGUE_TO_CART_FAIL,
      payload: error.message || "Failed to fetch cart data",
    });
  }
};

export const removeItemFromCart = (id,cartItems) => async (dispatch) => {
  console.log("ID", id);
  try {
    dispatch({ type: REMOVE_ITEMS_FROM_CART_REQUEST });
    const response = await API.graphql({
      query: mutations.deleteCartItem,
      variables: {
        input: {
          id,
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

  
    const itemIndex = cartItems?.findIndex(item => item?.itemId === id);
    const updatedCartItems = [...cartItems];
     // Create a copy of leagueItems array
    if (itemIndex !== -1) {
      // Item found, remove it from the leagueItems array
      updatedCartItems.splice(itemIndex, 1); 
    }


    
    // const createCartItem = response?.data?.createCartItem;
    dispatch({
      type: REMOVE_ITEMS_FROM_CART_SUCCESS,
      payload: updatedCartItems,
    });
  } catch (error) {
    console.log("Error", error);
    dispatch({
      type: REMOVE_ITEMS_FROM_CART_FAIL,
      payload: error.message || "Failed to fetch cart data",
    });
  }
};

export const updateCartQuantity = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CARTITEMS_REQUEST });

    const response = await API.graphql({
      query: mutations.updateCartItem,
      variables: {
        input: {
          id,
          userid: data.userid,
          itemId: data.itemId,
          title: data.title,
          selling_price: data.selling_price,
          original_price: data.original_price,
          rating: data.rating,
          //  img_url:data.img_url,
          tags: data.tags,
          stock_qty: data.stock_qty,
          category: data.category,
          cartId: data.cartId,
          quantity: data.quantity,
          finalPrice: data.finalPrice,
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const {
      cartItems,
      leagueItems,
      loading,
      deleted,
      updated,
      added,
      error,
  
    } = useSelector((state) => state.cart);
    const itemIndex = cartItems?.findIndex(item => item?.itemId === id);
    
    const updatedCartItems = [...cartItems]; // Create a copy of cartItems array
    if (itemIndex !== -1) {
      // Item found, update its quantity
      updatedCartItems[itemIndex].quantity = data?.quantity;
      updatedCartItems[itemIndex].finalPrice = data?.finalPrice;

    }
   
    const updateCartItem = response?.data?.updateCartItem;
    dispatch({
      type: UPDATE_CARTITEMS_SUCCESS,
      payload: { updatedCartItems },
    });
  } catch (error) {
    console.log("Error", error);
    dispatch({
      type: UPDATE_CARTITEMS_FAIL,
      payload: error.message || "Failed to fetch cart data",
    });
  }
};

export const removeLeagueFromCart = (id,leagueItems) => async (dispatch) => {
  try {
    const response = await API.graphql({
      query: mutations.deleteLeagueCart,
      variables: {
        input: {
          id,
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    
    
  
    const itemIndex = leagueItems?.findIndex(item => item?.leagueId === id);
    const updatedLeagueItems = [...leagueItems];
     // Create a copy of leagueItems array
    if (itemIndex !== -1) {
      // Item found, remove it from the leagueItems array
      updatedLeagueItems.splice(itemIndex, 1); 
    }



    // const createCartItem = response?.data?.createCartItem;
    dispatch({
      type: REMOVE_LEAGUE_FROM_CART_SUCCESS,
      payload: {updatedLeagueItems},
    });
  } catch (error) {
    console.log("Error", error);
    dispatch({
      type: REMOVE_LEAGUE_FROM_CART_FAIL,
      payload: error.message || "Failed to fetch cart data",
    });
  }
};

export const updateLeagueCartQuantity = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_LEAGUE_REQUEST });
    
    const {
      leagueItems,
  
    } = useSelector((state) => state.cart);
    const itemIndex = leagueItems?.findIndex(item => item?.leagueId === id);
    
    const updatedCartItems = [...leagueItems]; // Create a copy of cartItems array
    if (itemIndex !== -1) {
      // Item found, update its quantity
      updatedCartItems[itemIndex].quantity = data?.quantity;
      updatedCartItems[itemIndex].finalPrice = data?.finalPrice;

    }

    const response = await API.graphql({
      query: mutations.updateCartItem,
      variables: {
        input: {
          id,
          userid: data.userid,
          itemId: data.itemId,
          title: data.title,
          selling_price: data.selling_price,
          original_price: data.original_price,
          rating: data.rating,
          //  img_url:data.img_url,
          tags: data.tags,
          stock_qty: data.stock_qty,
          category: data.category,
          cartId: data.cartId,
          quantity: data.quantity,
          finalPrice: data.finalPrice,
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const updateCartItem = response?.data?.updateCartItem;
    dispatch({
      type: UPDATE_LEAGUE_SUCCESS,
      payload: { updatedCartItems },
    });
  } catch (error) {
    console.log("Error", error);
    dispatch({
      type: UPDATE_LEAGUE_SUCCESS,
      payload: error.message || "Failed to fetch cart data",
    });
  }
};

// export const getNewsDetails = (newsId) => async (dispatch) => {
//   try {
//     dispatch({ type: G });

//     const newsResponse = await API.graphql({
//       query: queries.getNews,
//       variables: {
//         id: newsId,
//       },
//       authMode: GRAPHQL_AUTH_MODE.API_KEY,
//     });

//     const newsData = newsResponse?.data?.getNews;

//     const signedUrl = await getLinks(newsData?.content_img_url);
//     const signedUrl2 = await getLinks(newsData?.img_url);
//     const videLink = await getLinks(newsData?.video_url);

//     const updatedData = {
//       ...newsData,
//       img_url: signedUrl,
//       content_img_url: signedUrl2,
//       video_url: videLink,
//     };

//     const updateViewCountInput = {
//       id: newsId,
//       view_count: updatedData?.view_count + 1,
//     };

//     await API.graphql({
//       query: mutations.updateNews,
//       variables: {
//         input: updateViewCountInput,
//       },
//       authMode: GRAPHQL_AUTH_MODE.API_KEY,
//     });

//     const data = {
//       newsDetails: updatedData,
//       newsViews: updatedData?.view_count,
//     };

//     dispatch({
//       type: GET_NEWS_SUCCESS,
//       payload: data,
//     });
//   } catch (error) {
//     const errorMessage =
//       error.response?.data?.message ||
//       error.message ||
//       "Failed to fetch news details";

//     dispatch({
//       type: GET_NEWS_FAIL,
//       payload: errorMessage,
//     });
//   }
// };

//add a new product
// export const addProduct = (productData,images) => async (dispatch) => {
//     try {
//       dispatch({ type: ADD_PRODUCT_REQUEST });

//       const config = {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       };
//       // const config = { headers: { "Content-Type": "multipart/form-data" } };

//       const { data } = await axios.post("/api/v1/admin/new/product", productData, config);

//       dispatch({
//         type: ADD_PRODUCT_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       const errorMessage =
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message;

//       dispatch({
//         type: ADD_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

// export const updateProduct = (productId, productData) => async (dispatch) => {
//     try {
//       dispatch({ type: UPDATE_PRODUCT_REQUEST });

//       const config = {
//         headers: { "Content-Type": "application/json" },
//       };

//       const { data } = await axios.put(
//         `/api/v1/admin/product/${productId}`,
//         productData,
//         config
//       );

//       dispatch({
//         type: UPDATE_PRODUCT_SUCCESS,
//         payload: data.success,
//       });
//     } catch (error) {
//           const errorMessage =
//             error.response && error.response.data.message
//               ? error.response.data.message
//               : error.message;

//       dispatch({
//         type: UPDATE_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

// export const deleteProduct = (productId) => async (dispatch) => {
//     try {
//       dispatch({ type: DELETE_PRODUCT_REQUEST });

//       const { data } = await axios.delete(`/api/v1/admin/product/${productId}`);

//       dispatch({
//         type: DELETE_PRODUCT_SUCCESS,
//         payload: data.success,
//       });
//     } catch (error) {
//           const errorMessage =
//             error.response && error.response.data.message
//               ? error.response.data.message
//               : error.message;

//       dispatch({
//         type: DELETE_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

export const clearCartErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_CART_ERRORS });
};


export const addItemsToCart = (userid, item) => async (dispatch, getState) => {

  const response = await API.graphql({
    query: mutations.createCartItem,
    variables: {
      input: {
        userid,
        ...item,
        cartId: userid,
      },
    },
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  });
  
  const createCartItem = response?.data?.createCartItem;
  dispatch({
    type: ADD_LEAGUE_TO_CART_REQUEST,
    payload: {
      createCartItem
    },
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cartItems));
};
