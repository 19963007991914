import TypoBu from "../TypoBu";
import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import getLink from "../../utils/getLinks";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import { NavLink } from "react-router-dom";
import ArticleLoading from "../../Components/Skeleton/ArticleLoading";

const Articles = ({ news }) => {
  return (
    <div>
      <TypoBu
        item={{
          text: "articles",
          text_ssize: "text-3xl",
          text_bsize: "text-4xl",
          border: true,
          button: false,
        }}
      />
      <div className="ml-0 md:ml-  border-2 border-[#E7E7E7] px-4 my-10 ">
        {news?.slice(0, 6)?.map((item, index) => {
          const d = new Date();
          let date = new Date(item.date);
          let days = new Date(d - date).getTime() / 86400000;
          days = Math.floor(days);
          return (
            <div
              className="border-b-2  border-[#E7E7E7] [&:last-child]:border-b-0 py-4"
              key={index}
            >
              <NavLink className="flex flex-col" to={`/news/${item?.id}`}>
                <span className="text-gray-400 mb-4">{days} days ago</span>
                <span className="h-[50px] overflow-hidden">
                  {item?.title.split(" ").slice(0, 20).join(" ")}
                  {item?.title.split(" ").length > 20 ? "..." : ""}
                </span>
              </NavLink>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Articles;
