import React from 'react';
import { Link } from 'react-router-dom';

const getTimeAgo = (dateString) => {
  const currentDate = new Date();
  const newsDate = new Date(dateString);
  const timeDifference = currentDate - newsDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (daysDifference === 0) {
    return 'Today';
  } else if (daysDifference === 1) {
    return 'Yesterday';
  } else {
    return `${daysDifference} days ago`;
  }
};

const LtNewsCard = ({ item }) => {
  return (
    <Link to={`/news/${item?.id}`} className="block w-full">
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="flex md:flex-row flex-col">
          <div className="md:w-1/2">
            <img
              className="h-56 md:h-[300px] w-full object-cover"
              src={item?.content_img_url ? item.content_img_url : item.img_url[0]}
              alt={item?.title}
            />
          </div>
          <div className="md:w-1/2 p-4 flex flex-col justify-between">
            <div className="text-lg font-semibold mb-4 line-clamp-2">
              {item?.title?.split(" ").slice(0, 20).join(" ")}
              {item?.title?.split(" ").length > 20 ? "..." : ""}
            </div>
            <p className="text-sm  mb-4">
              {item?.content_text?.split(" ").slice(0, 100).join(" ")}
              {item?.content_text.split(" ").length > 100 ? "..." : ""}
            </p>
            <div className="text-xs text-gray-500">
              {getTimeAgo(item?.createdAt)}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default LtNewsCard;
