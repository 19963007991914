import { useState } from "react";
import { Tab } from "@headlessui/react";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TournamentTable({ tournament }) {
  let maxRounds = 0; // Initialize maxRounds as BigInt

  if (tournament) {
    for (const tournamen of tournament) {
      const rounds = tournamen.round;
      if (rounds > maxRounds) {
        maxRounds = rounds;
      }
    }
  }
  console.log("Tourname", tournament);
  return (
    <div className="w-full px-6 py-16">
      <h1 className="text-center text-black text-2xl font-bold mb-8">
        {tournament[0]?.event}
      </h1>

      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-gray-200 p-1 items-center text-center">
          {Array.from(
            { length: Number(maxRounds) },
            (_, index) => index + 1
          ).map((round, index) => (
            <Tab
              key={index}
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                  "ring-gray-300 ring-offset-2 ring-offset-gray-400 justify-center grid grid-cols-1 items-center gap-4 focus:outline-none focus:ring-2",
                  selected
                    ? "bg-gray-300 text-gray-700 shadow"
                    : "text-gray-500 hover:bg-gray-300 hover:text-gray-800"
                )
              }
            >
              <div className="w-fit mx-auto flex items-center gap-2">
                <span className="hidden sm:block">Round</span>
                {round}
              </div>
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {Array.from(
            { length: Number(maxRounds) },
            (_, index) => index + 1
          ).map((round, idx) => (
            <Tab.Panel
              key={idx}
              className={classNames(
                "rounded-xl bg-transparent p-3",
                "ring-gray-300 ring-offset-2 w-full ring-offset-gray-400 focus:outline-none focus:ring-2 text-black"
              )}
            >
              <ul className="mt-1 space-x-1 text-sm font-normal leading-5 text-gray-700 grid grid-cols-1 gap-4">
                {tournament
                  ?.filter((match) => match.round === round)
                  ?.map((match) => (
                    <li
                      key={match.id}
                      className="relative w-full flex items-center gap-5 md:gap-8 justify-around cursor-pointer rounded-md p-3 bg-gradient-to-br from-gray-100 to-gray-50 text-black text-start shadow-md"
                    >
                      <div className="p-4 bg-gray-50 grid sm:grid-cols-4 gap-5 items-start items-center justify-center w-full">
                        <div className="flex items-center gap-4">
                          <h3 className="text-lg font-medium leading-6">
                            <div>
                              {new Date(match?.date).toLocaleDateString(
                                "en-GB"
                              )}{" "}
                              {new Date(match?.time).toLocaleTimeString(
                                "en-US",
                                {
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                }
                              )}
                            </div>
                            <div>{match?.courtNo && match.courtNo}</div>
                          </h3>
                          <img
                            src={match?.firstTeam?.teamLogo}
                            className="w-12 h-12 rounded-full"
                            alt={match?.firstTeam?.teamName}
                          />
                          <h3 className="text-lg font-medium leading-6">
                            {match?.firstTeam?.teamName}
                          </h3>
                          {match?.firstTeam?.score != null && (
                          <span className={`px-2 py-1 rounded-md text-white ${match?.firstTeam?.score > match?.secondTeam?.score ? 'bg-green-500' : 'bg-red-500'}`}>
          {match?.firstTeam?.score}
        </span>)}
                        </div>

                        <span className="text-xl font-bold w-fit text-center p-2 rounded-md">
                          Vs
                        </span>

                        <div className="flex items-center gap-4">
                          <img
                            src={match?.secondTeam?.teamLogo}
                            className="w-12 h-12 rounded-full"
                            alt={match?.secondTeam?.teamName}
                          />

                          <h3 className="text-lg font-medium leading-6">
                          {match?.secondTeam?.teamName}
                          </h3>
                          {match?.secondTeam?.score != null && (
                          <span className={`px-2 py-1 rounded-md text-white ${match?.secondTeam?.score > match?.firstTeam?.score ? 'bg-green-500' : 'bg-red-500'}`}>
          {match?.secondTeam?.score}
        </span>)}
                        </div>
                        <Link
                          to={`/score/${match.id}`}
                          className="p-2 py-4 rounded-md text-sm text-center px-6 bg-[#fec749] text-white"
                        >
                          View Stats
                        </Link>
                      </div>
                    </li>
                  ))}
              </ul>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}