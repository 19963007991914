import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import { useParams } from "react-router-dom";

const useGetAllTrainingProgramCourses = () => {
  const [trainingProgramCourses, setTrainingProgramCourses] = useState([]);
  const [trainingProgramCoursesDetails, setTrainingProgramCoursesDetails] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const getTrainingProgramCourses = async () => {
    try {
      setLoading(true);
      const response = await API.graphql({
        query: queries.listTrainingProgramCourses,
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      if (!response) {
        return new Error("Something went wrong");
      }

      setTrainingProgramCourses(response?.data?.listTrainingProgramCourses?.items);

      setLoading(false);
    } catch (error) {
      setLoading(false);

      throw new Error(error);
    }
  };

  const getTrainingProgramCoursesDetail = async () => {
    try {
      setLoading(true);

      const response = await API.graphql(
        graphqlOperation(queries.getTrainingProgramCourse, { id })
      );
      if (!response) {
        return new Error("Something went wrong");
      }
      setTrainingProgramCoursesDetails(response?.data?.getTrainingProgramCourse);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      throw new Error(error);
    }
  };

  useEffect(() => {
    if (id) {
      getTrainingProgramCoursesDetail();
    }
    getTrainingProgramCourses();
  }, [id]);
  return { trainingProgramCourses, loading, trainingProgramCoursesDetails };
};

export default useGetAllTrainingProgramCourses;
