export const REGISTER_TOURNAMENT_REQUEST = 'REGISTER_TOURNAMENT_REQUEST';
export const REGISTER_TOURNAMENT_SUCCESS = 'REGISTER_TOURNAMENT_SUCCESS';
export const REGISTER_TOURNAMENT_FAIL = 'REGISTER_TOURNAMENT_FAIL';

export const UPDATE_TOURNAMENT_REQUEST = 'UPDATE_TOURNAMENT_REQUEST';
export const UPDATE_TOURNAMENT_SUCCESS = 'UPDATE_TOURNAMENT_SUCCESS';
export const UPDATE_TOURNAMENT_FAIL = 'UPDATE_TOURNAMENT_FAIL';

export const GET_TOURNAMENT_REQUEST = 'GET_TOURNAMENT_REQUEST';
export const GET_TOURNAMENT_SUCCESS = 'GET_TOURNAMENT_SUCCESS';
export const GET_TOURNAMENT_FAIL = 'GET_TOURNAMENT_FAIL';

export const LOAD_TOURNAMENTS_REQUEST = 'LOAD_TOURNAMENTS_REQUEST';
export const LOAD_TOURNAMENTS_SUCCESS = 'LOAD_TOURNAMENTS_SUCCESS';
export const LOAD_TOURNAMENTS_FAIL = 'LOAD_TOURNAMENTS_FAIL';

export const DELETE_TOURNAMENT_REQUEST = 'DELETE_TOURNAMENT_REQUEST';
export const DELETE_TOURNAMENT_SUCCESS = 'DELETE_TOURNAMENT_SUCCESS';
export const DELETE_TOURNAMENT_FAIL = 'DELETE_TOURNAMENT_FAIL';
export const DELETE_TOURNAMENT_RESET = 'DELETE_TOURNAMENT_RESET';

export const CLEAR_TOURNAMENT_ERRORS = 'CLEAR_TOURNAMENT_ERRORS';
