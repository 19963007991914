import React, { useState } from 'react';
import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";


const PasswordComponent = () => {
  const [otp, setOtp] = useState('');
  const { isAuthenticated,userDetails } = useSelector(
    (state) => state?.user
    );
    const [email, setEmail] = useState(userDetails?.email);
  const [confirmationSent, setConfirmationSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [updated, setUpdated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleForgotPassword = async () => {
    try {
      await Auth.forgotPassword(email);
      setConfirmationSent(true);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.message);
      setConfirmationSent(false);
    }
  };
  console.log("email",email,userDetails)

  const handlePasswordReset = async () => {
    try {
      const res=await Auth.forgotPasswordSubmit(email, otp, newPassword);
     console.log("updated",res);
      if(res==="SUCCESS") {

       setUpdated(true);
      } 
      setConfirmNewPassword(false);
      setErrorMessage('');
      setEmail('');
      setOtp('');
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  
  const renderConfirmationMessage = () => {
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full bg-white p-8 rounded-md shadow-md text-center transition-opacity duration-300">
      <p className="text-lg font-semibold mb-2">Password Updated Successfully!</p>
      <p className="text-gray-600">
        Your password has been updated. You can now log in with your new password.
      </p>
      {/* <button onClick={closeModal} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none">
        Close
      </button> */}
    </div>
    );
  };

  return (
    <div className=" w-full h-full flex justify-center items-center   transition-opacity duration-300">
    <div className="relative max-w-md w-full bg-white p-8 rounded-md shadow-md transform transition-transform duration-300">
      <h2 className="text-2xl font-bold mb-4 text-center">Forgot Password</h2>
        {!confirmationSent ? (
          <div>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full mb-4 px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
            />
            <button
              onClick={handleForgotPassword}
              className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 focus:outline-none"
            >
              Reset Password
            </button>
            {errorMessage && <p className="text-red-500 text-sm mt-2">{errorMessage}</p>}
          </div>
        ) : updated ? (
          renderConfirmationMessage()
        ) : (
          <div>
            <input
              type="text"
              placeholder="Enter verification code"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className="w-full mb-2 px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
            />
            <input
              type="password"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full mb-2 px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
            />
            <input
              type="password"
              placeholder="Confirm new password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              className="w-full mb-2 px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
            />
            <button
              onClick={handlePasswordReset}
              className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 focus:outline-none"
            >
              Reset Password
            </button>
            {errorMessage && <p className="text-red-500 text-sm mt-2">{errorMessage}</p>}
          </div>
        )}
      </div>
    </div>
  );
};

export default PasswordComponent;
