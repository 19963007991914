export const LOAD_EVENTS_REQUEST = 'LOAD_EVENTS_REQUEST';
export const LOAD_EVENTS_SUCCESS = 'LOAD_EVENTS_SUCCESS';
export const LOAD_EVENTS_FAIL = 'LOAD_EVENTS_FAIL';

export const GET_EVENT_REQUEST = 'GET_EVENT_REQUEST';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_FAIL = 'GET_EVENT_FAIL';


export const ALL_EVENT_REQUEST = 'ALL_EVENT_REQUEST';
export const ALL_EVENT_SUCCESS = 'ALL_EVENT_SUCCESS';
export const ALL_EVENT_FAIL = 'ALL_EVENT_FAIL';

export const BUY_EVENT_TICKET_REQUEST = 'BUY_EVENT_TICKET_REQUEST';
export const BUY_EVENT_TICKET_SUCCESS = 'BUY_EVENT_TICKET_SUCCESS';
export const BUY_EVENT_TICKET_FAIL = 'BUY_EVENT_TICKET_FAIL';

export const CLEAR_EVENT_ERRORS = 'CLEAR_EVENT_ERRORS';
