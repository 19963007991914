export const REGISTER_CAMP_REQUEST = 'REGISTER_CAMP_REQUEST';
export const REGISTER_CAMP_SUCCESS = 'REGISTER_CAMP_SUCCESS';
export const REGISTER_CAMP_FAIL = 'REGISTER_CAMP_FAIL';

export const UPDATE_CAMP_REQUEST = 'UPDATE_CAMP_REQUEST';
export const UPDATE_CAMP_SUCCESS = 'UPDATE_CAMP_SUCCESS';
export const UPDATE_CAMP_FAIL = 'UPDATE_CAMP_FAIL';

export const GET_CAMP_REQUEST = 'GET_CAMP_REQUEST';
export const GET_CAMP_SUCCESS = 'GET_CAMP_SUCCESS';
export const GET_CAMP_FAIL = 'GET_CAMP_FAIL';

export const LOAD_CAMPS_REQUEST = 'LOAD_CAMPS_REQUEST';
export const LOAD_CAMPS_SUCCESS = 'LOAD_CAMPS_SUCCESS';
export const LOAD_CAMPS_FAIL = 'LOAD_CAMPS_FAIL';

export const DELETE_CAMP_REQUEST = 'DELETE_CAMP_REQUEST';
export const DELETE_CAMP_SUCCESS = 'DELETE_CAMP_SUCCESS';
export const DELETE_CAMP_FAIL = 'DELETE_CAMP_FAIL';
export const DELETE_CAMP_RESET = 'DELETE_CAMP_RESET';

export const CLEAR_CAMP_ERRORS = 'CLEAR_CAMP_ERRORS';
