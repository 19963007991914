import React from "react";
import { ImFacebook } from "react-icons/im";
import { IoLogoTwitter } from "react-icons/io";
import { AiOutlineGooglePlus } from "react-icons/ai";
import { SiInstagram } from "react-icons/si";

const SocialMediaShareButtons = ({url,title}) => {
  console.log("url",url);
  const shareOnFacebook = () => {
    // Replace 'YourContentURL' with the URL you want to share
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`);
  };

  const shareOnTwitter = () => {
    // Replace 'YourContentURL' and 'YourContentDescription' with appropriate values
    const description = title;
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(description)}`);
  };

  const shareOnGooglePlus = () => {
    // Replace 'YourContentURL' with the URL you want to share
    window.open(`https://plus.google.com/share?url=${encodeURIComponent(url)}`);
  };

  const shareOnInstagram = () => {
    // Replace 'YourContentURL' with the URL you want to share
    window.open(`https://www.instagram.com/sharer.php?u=${encodeURIComponent(url)}`);
  };

  return (
    <div className="w-full text-gray-400  mt-[10px] flex items-center justify-between flex-wrap text-right mb-2">
      <button className="" onClick={shareOnFacebook}>
        SHARE
      </button>
      <ImFacebook className="inline" onClick={shareOnFacebook} />
      <IoLogoTwitter className=" inline" onClick={shareOnTwitter} />
      <AiOutlineGooglePlus className=" inline" onClick={shareOnGooglePlus} />
      <SiInstagram className="m inline" onClick={shareOnInstagram} />
    </div>
  );
};

export default SocialMediaShareButtons;
