import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import getLink from "../utils/getLinks";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import { useParams } from "react-router-dom";
import { getKey, updateImageSrcInHtml } from "../utils/replaceUrl";

const useHandleNews = () => {
  const [initialNextNextToken, setInitialNextNextToken] = useState(undefined);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState(undefined);
  const [news, setNews] = useState([]);
  const [blog, setBlog] = useState([]);
  const [vlog, setVlog] = useState([]);

  const [loading, setLoading] = useState(false);
  const [previousTokens, setPreviousTokens] = useState([]);
  const { productName } = useParams();


  const fetchImageFromCacheWithExpiration = async (url) => {
    const cachedData = localStorage.getItem(url);
    if (cachedData) {
      const { timestamp, imageUrl } = JSON.parse(cachedData);
      const currentTime = Date.now();

      // Check if the stored image is older than 300 seconds (5 minutes)
      if (currentTime - timestamp < 300000) {
        return imageUrl; // Use the cached image URL
      }
    }
    // If the image is older than 300 seconds or not found, fetch a new one
    const signedUrl = await loadImageAndCache(url);
    if (signedUrl) {
      // Store the new image URL with the current timestamp
      const dataToCache = JSON.stringify({ timestamp: Date.now(), imageUrl: signedUrl });
      localStorage.setItem(url, dataToCache);
      return signedUrl;
    }
    return null;
  };
  const loadImageAndCache = async (url) => {
    try {
      const signedUrl = await getLink(url);
      localStorage.setItem(url, signedUrl); // Store image data in cache
      return signedUrl;
    } catch (error) {
      console.error('Error fetching or caching image:', error);
      return null;
    }
  };



  const fetchNews = async () => {
    setLoading(true);

    let variables;
    try {
      let filterExpression = {};
      console.log("productName: " + productName);
      if (productName && productName.trim() !== "") {
        setInitialNextNextToken(undefined);
        setNextToken(undefined);
        setNextNextToken(undefined);
        setPreviousTokens([]);
        filterExpression = {
          title: {
            contains: productName.trim(),
          },
        };

        variables = {
          limit: 100,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }

      } else {
        variables = {
          limit: 20,
          filter: filterExpression,
        };
      }
      if (nextToken !== null && nextToken !== undefined && nextToken !== "") {
        variables = {
          ...variables,
          nextToken,
        };
      }

      // }

      const result = await API.graphql({
        query: queries.listNews,
        variables,
        authMode: GRAPHQL_AUTH_MODE.API_KEY, // Adjust this as needed
      });

      const fetchedNews = result?.data?.listNews?.items || [];
      const updateNews = await Promise.all(
        fetchedNews?.map(async (news) => {
          const img_url = news?.img_url;

          if (img_url) {
            const key = getKey(img_url)
            const link = await getLink(key);
            news.img_url = link;
            news.content_img_url = link;
          }


          const briefWithUpdatedImageURl = await updateImageSrcInHtml(news?.brief, getLink, getKey);
          news.brief = briefWithUpdatedImageURl;

          return news;
        })
      );
      const news = updateNews?.filter((item) => item.type === "NEWS");
      const blog = updateNews?.filter((item) => item.type === "BLOG");
      const vlog = updateNews?.filter((item) => item.type === "VLOG");
      console.log('Newsss', news)
      console.log('Blog', blog)
      console.log('Vlog', vlog)

      if (!initialNextNextToken) {
        setInitialNextNextToken(result?.data?.listNews?.nextToken);
      }
      setBlog(blog);
      setNews(news);
      setVlog(vlog);



      setNextNextToken(result?.data?.listNews?.nextToken);
      // if (!nextToken) {
      // } else {
      //   setNews((prevNews) => [...prevNews, ...updatedNews]); // Append to existing News
      // }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching News:", error);
    }
  };

  const fetchNextPage = () => {
    if (nextNextToken) {
      setPreviousTokens([...previousTokens, nextToken]);
      setNextToken(nextNextToken);
    }
  };

  const fetchPreviousPage = () => {
    if (previousTokens.length > 0) {
      const prevTokens = [...previousTokens];
      const lastToken = prevTokens.pop();

      setPreviousTokens(prevTokens);
      setNextNextToken(nextToken || initialNextNextToken);
      setNextToken(lastToken);
    }
  };

  useEffect(() => {
    fetchNews();
  }, [nextToken]);

  return {
    news,
    blog,
    vlog,
    nextNextToken,
    loading,
    fetchNextPage,
    fetchPreviousPage,
    previousTokens,
  };
};

export default useHandleNews;
