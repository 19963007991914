import React from 'react';
import './loader.css'

const Loader = ({ className }) => (
  <div className={`loader-container ${className}`}>

    <div className="loader" />
  </div>
);

export default Loader;
