import React from "react";

const OrderCardSkeleton = () => {
  return (
    <div className="border container mx-auto h-[150px] p-[8px] items-center   flex  rounded-lg overflow-hidden shadow-md animate-pulse">
      <div className="bg-gray-300 h-[75px] w-[75px]"></div>
      <div className="p-4 flex  gap-2 justify-between w-full">
        <div className="bg-gray-300 h-6 w-[30%]"></div>
        <div className="bg-gray-300 h-6 w-12"></div>
        <div className="bg-gray-300 h-6 w-12"></div>
        <div className="bg-gray-300 h-6"></div>
        <div className="bg-gray-300 h-6"></div>
      </div>
    </div>
  );
};

export default OrderCardSkeleton;
