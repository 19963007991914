import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries"; // Import your GraphQL queries
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

const useGetAllCity = () => {
  const [citylist, setCityList] = useState([]);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    setLoading(true);
    API.graphql({
      query: queries.listCities,
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    })
      .then((response) => {
        setCityList(response?.data?.listCities?.items);
        setLoading(false); // Move setLoading(false) inside the 'then' block
      })
      .catch((error) => {
        console.error("Error fetching cities:", error);
        setLoading(false); // Ensure setLoading(false) is called on error too
      });
  }, []);

  // console.log('cities', citylist);

  return { citylist, loading };
};

export default useGetAllCity;
