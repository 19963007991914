import OrNews from "../../Components/OrNews";
import Articles from "../../Components/Articles";
import FlatNews from "../../Components/FlatNews";
import LtNews from "../../Components/LtNews";
import SqShop from "../../Components/SqShop";
import SignUpWK from "../../Components/SignUpWK";
import SignUpList from "../../Components/SignUPList";
import Workwith from "../../Components/Workwith";

import Sponsors from "../../Components/Sponsors";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

import { useState, useEffect } from "react";

import RenderShop from "../../Components/Shop/RenderShop";

import useHandleNews from "../../hooks/handleNews";
import AdvertisementEvent from "../../Components/Advertisement/EVENTADVERTISMENT";

const Blog = () => {
  const {
    blog,
    loading,
    nextNextToken,
    previousTokens,
    fetchNextPage,
    fetchPreviousPage,
  } = useHandleNews();
  const [newsIndex, setNewsIndex] = useState(0); // State to manage current news index
  const [currentNews, setCurrentNews] = useState(blog[0]); // State to hold current news data

  // Update current news when 'news' state changes or initial load
  useEffect(() => {
    setCurrentNews(blog[newsIndex]);
  }, [blog, newsIndex]);

  const handleNextPage = () => {
    if (newsIndex < blog.length - 1) {
      setNewsIndex(newsIndex + 1);
    } else {
      // Fetch next page if available
      fetchNextPage();
      setNewsIndex(0);
    }
  };
  const handlePreviousPage = () => {
    if (newsIndex > 0) {
      setNewsIndex(newsIndex - 1);
    } else if (previousTokens.length > 0) {
      // Fetch previous page if available
      fetchPreviousPage();
    }
  };

  return (
    <div className="pt-10">
      <div className="px-5 md:px-20 py-10">
        <AdvertisementEvent index={0} one />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-20 px-4 md:px-20">
        <div className="col-span-2">
          <div className="col-span-2">
            <div className="py-2 uppercase font-bold text-4xl border-b-2 mb-10 border-[#c4c4c4]">
              <span className=" border-l-4 border-[#D70D15] text-3xl px-2">
                Blog
              </span>

              <div className="float-right text-3xl">
                {/* {fetchPreviousPage && previousTokens?.length > 0 && ( */}
                <button
                  onClick={handlePreviousPage}
                  // disabled={!fetchPreviousPage && !previousTokens?.length > 0}
                  className="text-[#D70D15] font-bold mr-8"
                >
                  <AiOutlineLeft className="text-lg font-bold" />
                </button>

                {/* )} */}
                {/* {nextNextToken !== null && ( */}
                <button
                  onClick={handleNextPage}
                  // disabled={nextNextToken === null}
                  className="text-[#D70D15] font-bold"
                >
                  <AiOutlineRight className="text-lg font-bold" />
                </button>
                {/* )} */}
              </div>
            </div>
            {!blog.length > 0 && <p className="text-center text-[30px]">
              Currently   There is No Blog                                                                                                                                                                                                      
              </p>}

            {blog.length > 0 && <OrNews loading={loading} news={currentNews} />}
          </div>
        </div>
        <div>
          <Articles news={blog} />
        </div>
      </div>
      {blog?.length > 0 && (
        <div className="px-4 md:px-20 mt-20 mb-20">
          <FlatNews newsList={blog} />
        </div>
      )}
      <br />
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-cols-3 md:gap-10 bg-gray-300 pt-20 px-4 md:px-18">
        <div className="bg col-span-2 ">
          <LtNews />
        </div>

        <div className="flex  min-w-[100%]  lg:w-full  flex-col  gap-10 mb-10">
          <RenderShop />
          <Sponsors />
          {/* <SignUpWK /> */}
          <SignUpList />
          <Workwith
            title="work with us"
            button="volunteer"
            link="/signupvolunteer"
          />
        </div>
      </div>
    </div>
  );
};

export default Blog;
