import React from "react";

const AddressSkeleton = () => {
  return (
    <div className="mb-2 w-[250px] p-2 border  bg-gray-50 text-center flex-shrink-0 mt-2 rounded-lg md:mr-2 animate-pulse">
      <div className="h-16 bg-gray-200 rounded-lg mb-2"></div>
      <div className="h-8 bg-gray-200 rounded-lg mb-2"></div>
      <div className="h-4 bg-gray-200 rounded-lg mb-2"></div>
      <div className="flex justify-center">
        <div className="bg-gray-200 text-gray-200 cursor-not-allowed p-2 rounded-lg mr-2">
          {/* Placeholder for edit icon */}
        </div>
        <div className="bg-gray-200 text-gray-200 cursor-not-allowed p-2 rounded-lg">
          {/* Placeholder for delete icon */}
        </div>
      </div>
    </div>
  );
};

const AddressCardSkeleton = () => {
  return (
    <div className="flex gap-1">
      <AddressSkeleton />
      <AddressSkeleton />
      <AddressSkeleton />
    </div>
  );
};

export default AddressCardSkeleton;
