import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import getLink from "../utils/getLinks";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import { useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const useHandleClinicMeetUser = () => {
  const [meetList, setMeetList] = useState([]);

  const { id } = useParams();

  const [meetDetails, setMeetDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const getAllMeet = async () => {
    setLoading(true)
    try {
      const response = await API.graphql({
        query: queries.listClinicMeets,
        authMode: GRAPHQL_AUTH_MODE.API_KEY
      })

      setMeetList(response?.data?.listClinicMeets ? response?.data?.listClinicMeets?.items : [])
      setLoading(false)
      console.log("Meet List", response)
    } catch (error) {

      console.log("ERROR", error);

      toast.error(error.message)

      throw new Error(error.message)

    } finally {
      setLoading(false)
    }



  }

  const getMeetById = async (id) => {
    setLoading(true)
    try {



      const response = await API.graphql({
        query: queries.getClinicMeet,
        variables: { input: { id } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY
      })

      const meeT = response?.data.getClinicMeet;
      setMeetDetails(meeT);

      setLoading(false);

    } catch (error) {

      console.log("ERROR", error);
      toast.error(error.message);

      throw new Error(error.message)

    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {

    getAllMeet();
    //


    //if (id) {
    //    getMeetById(id)
    //    }


  }, [])




  return { meetList, loading }

}


export default useHandleClinicMeetUser;

