import { lazy } from "react";
import useFetchAdvertisement from "../../hooks/getAdvertisementByType";
import LazyLoad from "react-lazyload";
import placeholderImage from "./advertisement.png";
import { useLocation } from "react-router-dom"; // Assuming you are using React Router

import Loader from "../Loader";
import AdvertisementImage from "../LazyLoader/Advertisment";

const Advertisement = ({ad, index }) => {
  // const currentPage = location.pathname.slice(1).toUpperCase(); // Assuming the page is represented by the pathname
  // console.log("location: ", currentPage);
  // if (currentPage) {
  // }
  // const adTypesKeywords = {
  //   NEWS: ["NEWS"],
  //   EVENTS: ["EVENTS"],
  //   BLOGS: ["BLOGS"],
  //   VLOGS: ["VLOGS"],
  // };

  // // Identify the ad type based on keywords in the current page
  // const adType = Object.keys(adTypesKeywords).find((type) =>
  //   adTypesKeywords[type].some((keyword) => currentPage.includes(keyword))
  // );

  // // Filter ads based on the identified ad type
  // const filteredAds = advertisement.filter((ad) => ad.page === adType);

  return (
    <div className="w-full">
      <div className="bg-transparent w-full text-center flex flex-col justify-center">
        <div className="flex flex-col gap-2 h-full items-center">
          {ad && <AdvertisementImage ad={ad} />}
        </div>
      </div>
    </div>
  );
};

export default Advertisement;
