import {
  REGISTER_TOURNAMENT_REQUEST,
  REGISTER_TOURNAMENT_SUCCESS,
  REGISTER_TOURNAMENT_FAIL,
  UPDATE_TOURNAMENT_REQUEST,
  UPDATE_TOURNAMENT_SUCCESS,
  UPDATE_TOURNAMENT_FAIL,
  GET_TOURNAMENT_REQUEST,
  GET_TOURNAMENT_SUCCESS,
  GET_TOURNAMENT_FAIL,
  LOAD_TOURNAMENTS_REQUEST,
  LOAD_TOURNAMENTS_SUCCESS,
  LOAD_TOURNAMENTS_FAIL,
  DELETE_TOURNAMENT_REQUEST,
  DELETE_TOURNAMENT_SUCCESS,
  DELETE_TOURNAMENT_FAIL,
  DELETE_TOURNAMENT_RESET,
  CLEAR_TOURNAMENT_ERRORS,
} from "../constants/TournamentConstants";

const initialState = {
  tournaments: [],
  tournamentDetails: {},
  created:false,
  score: {},
  team: {},
  loading: false,
  error: null,
};

export const tournamentReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TOURNAMENTS_REQUEST:
    case GET_TOURNAMENT_REQUEST:
      case REGISTER_TOURNAMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TOURNAMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        tournamentDetails: action.payload.tournament,
        team: action.payload.team,
        score: action.payload.score,
      };

      case REGISTER_TOURNAMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          tournamentDetails: action.payload,
          created:true
        };
    case LOAD_TOURNAMENTS_SUCCESS:
      return {
          ...state,
        loading: false,
       tournaments: action.payload,
      };
    case GET_TOURNAMENT_FAIL:
    case LOAD_TOURNAMENTS_FAIL:
      case REGISTER_TOURNAMENT_FAIL:

      return {
          ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_TOURNAMENT_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
