export const CREATE_NEWS_REQUEST = 'CREATE_NEWS_REQUEST';
export const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS';
export const CREATE_NEWS_FAIL = 'CREATE_NEWS_FAIL';

export const GET_NEWS_REQUEST = 'GET_NEWS_REQUEST';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_FAIL = 'GET_NEWS_FAIL';

export const LIST_NEWS_REQUEST = 'LIST_NEWS_REQUEST';
export const LIST_NEWS_SUCCESS = 'LIST_NEWS_SUCCESS';
export const LIST_NEWS_FAIL = 'LIST_NEWS_FAIL';

export const UPDATE_NEWS_REQUEST = 'UPDATE_NEWS_REQUEST';
export const UPDATE_NEWS_SUCCESS = 'UPDATE_NEWS_SUCCESS';
export const UPDATE_NEWS_FAIL = 'UPDATE_NEWS_FAIL';

export const UPDATE_NEWS_VIEWS_REQUEST = 'UPDATE_NEWS_VIEWS_REQUEST';
export const UPDATE_NEWS_VIEWS_SUCCESS = 'UPDATE_NEWS_VIEWS_SUCCESS';
export const UPDATE_NEWS_VIEWS_FAIL = 'UPDATE_NEWS_VIEWS_FAIL';


export const DELETE_NEWS_REQUEST = 'DELETE_NEWS_REQUEST';
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_FAIL = 'DELETE_NEWS_FAIL';
export const DELETE_NEWS_RESET = 'DELETE_NEWS_RESET';

export const CLEAR_NEWS_ERRORS = 'CLEAR_NEWS_ERRORS';
