import React from "react";
import { FaEdit, FaTrash } from "react-icons/fa";

const AddressCard = ({ address,index, handleEditAddress, handleDeleteAddress }) => {
  return (
    <div>
      <div className="mb-2 w-[250px] p-2 border border-black bg-gray-50 text-center flex-shrink-0 mt-2 rounded-lg   md:mr-2">
        {/* <p>{userName}</p> */}
        <p>
          {address?.address}, {address?.city} - {address?.pincode},{" "}
          {address?.country}
        </p>

        <p>{address?.phonenumber}</p>
        <div className="mt-2 flex justify-center">
          <div className="bg-blue-500 text-white cursor-pointer p-2 rounded-lg mr-2">
            <FaEdit
              className="cursor-pointer"
              onClick={() => handleEditAddress(index, address)} // Implement the edit functionality
            />
          </div>
          <div className="bg-red-500 cursor-pointer text-white p-2 rounded-lg">
            <FaTrash
              className="cursor-pointer"
              onClick={() => handleDeleteAddress(index, address)} // Implement the delete functionality
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressCard;
