import { useState } from "react";
import { API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";

import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import useAuthInfo from "./getUser";
import useGetAddressList from "./getAddressList";
import { Toast } from "flowbite-react";

const useSubscribeNewsLetter = () => {
  const [loading, setLoading] = useState(false);

  const subscribeNewsLetter = async (email) => {
    try {
      setLoading(true);

      const alreadySubsribed = await API.graphql({
        query: queries.listSubscribedUsers,
        variables: {
          filter: {
            email: {
              eq: email,
            },
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      if (alreadySubsribed?.data?.listSubscribedUsers?.items?.length > 0) {
        return false;
      } else {
        // update the address using the API
        const response = await API.graphql({
          query: mutations.createSubscribedUsers,
          variables: {
            input: {
              email,
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        console.log("create subscribed ", response);
        // Address creation was successful
        setLoading(false);
        return true;
      }
    } catch (error) {
      console.error("Error Creating SubscribedUser:", error);
      setLoading(false);
      return false;
    }
  };

  return { subscribeNewsLetter, loading };
};

export default useSubscribeNewsLetter;
