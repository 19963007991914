import React from "react";

const ProductSkeleton = () => {
  return (
    <div className="bg-white h-[350px] w-[250px] rounded-lg overflow-hidden shadow-lg m-4 relative animate-pulse">
      <div className="relative">
        <div className="w-full h-48 bg-gray-200 animate-pulse"></div>
      </div>
      <div className="p-4 flex flex-col gap-2 items">
        <span className="text-gray-300 font-bold text-1xl mb-2 bg-gray-200 h-4 w-4/5 animate-pulse"></span>
      </div>
      <div className="flex absolute px-5 bottom-7 w-full justify-between gap-1 items-center ">
        <div className="flex gap-1 items-center mb-2">
          <span className="text-gray-300 opacity-500 line-through bg-gray-200 h-4 w-1/3 animate-pulse"></span>
          <span className="text-gray-300 bg-gray-200 h-4 w-1/4 animate-pulse"></span>
        </div>
        <div className="flex items-center mb-2">
          <div className="bg-gray-200 h-5 w-20 animate-pulse"></div>
        </div>
      </div>
    </div>
  );
};

const ProductCardSkeleton = ({quantity=10}) => {
  return (
    <div className="flex flex-wrap items-center justify-center max-w-[1400px] mx-auto gap-[20px] py-20 text-center">
      {[...Array(quantity)].map((_, index) => (
        <ProductSkeleton key={index} />
      ))}
    </div>
  );
};

export default ProductCardSkeleton;
