import React, { useState, useEffect, useMemo } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import {
  getAppointemtnDetailsFromAppointmentId,
  useHandleMeet,
} from "./processAvailableSlots";
import Loader from "../Loader";
import AppointmentCalendar from "./AppointmentCalendar";

const Calendar = ({ appointmentId }) => {
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [error, setError] = useState(null);

  const { appointmentsList, loading, getAppointmentScheduleDetails } =
    useHandleMeet();

  const handleAppointmentSelect = async (id) => {
    try {
      const data = await getAppointemtnDetailsFromAppointmentId(id);
      if (data) {
        setError("");
        setSelectedAppointment(data);
        await getAppointmentScheduleDetails(data.id);

      } else {
        setError("No appointment available");
        setSelectedAppointment(null);
      }
    } catch (error) {
      setError("Error fetching appointment details");
    }
  };

  useEffect(() => {
    if (appointmentId) {
      handleAppointmentSelect(appointmentId);
    }
  }, [appointmentId]);

  const memoizedSelectedAppointment = useMemo(
    () => selectedAppointment,
    [selectedAppointment]
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="calendar-containe max-w-[450px]">
      {error && <div className="text-red-500 font-bold mb-2">{error}</div>}
      {memoizedSelectedAppointment ? (
        <AppointmentCalendar appointment={memoizedSelectedAppointment} />
      ) : (
        !error && <div className="text-red-500 font-bold mb-2">No appointment selected</div>
      )}
    </div>
  );
};

export default Calendar;
