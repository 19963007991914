import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"; // Import your icon components
import ProductCard from "../../Components/Product/ProductCard";
import ProductCardSkeleton from "../../Components/Skeleton/ProductCardSkeleton";
import useHandleProducts from "../../hooks/handleProducts";
import { Link } from "react-router-dom";

const Shop = ({setIsUpdated}) => {
  const {
    products,
    nextNextToken,
    loading,
    previousTokens,
    fetchNextPage,
    fetchPreviousPage,
  } = useHandleProducts();
  const dispatch = useDispatch();
  const { loading: cartLoading } = useSelector((state) => state?.cart);

  const handleNextPage = () => {
    fetchNextPage();
  };

  const handlePreviousPage = () => {
    fetchPreviousPage();
  };

  return (
    <div className="pt-10 pb-20 text-center flex-col px-4">
      <ToastContainer />

      {loading || cartLoading ? (
        <ProductCardSkeleton />
      ) : (
        <>
          {products.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-full">
              <p className="text-2xl">Products not found.</p>
              <Link to='/store' className="text-1xl rounded-sm bg-blue-500 p-5 text-white ">All Products</Link>

            </div>
          ) : (
            <>
              <div className="flex flex-wrap items-center justify-center max-w-[1400px] mx-auto gap-[10px] py-20 text-center">
                {products.map((item, index) => {
                  return <ProductCard setIsUpdated={setIsUpdated} key={index} item={item} />;
                })}
              </div>
              <div className="pagination-buttons flex gap-4 justify-center items-center mt-4">
                {fetchPreviousPage && previousTokens?.length > 0 && (
                  <button
                    onClick={handlePreviousPage}
                    className="w-[100px] text-center p-3 border rounded-md bg-blue-500 text-white hover:bg-blue-600 transition duration-300"
                  >
                    Previous
                  </button>
                )}
                {nextNextToken !== null && (
                  <button
                    onClick={handleNextPage}
                    className="w-[100px] text-center p-3 border rounded-md bg-blue-500 text-white hover:bg-blue-600 transition duration-300"
                  >
                    Next
                  </button>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Shop;
