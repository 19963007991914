import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AuthRedirect = ({ children }) => {
  const { isAuthenticated } = useSelector((state) => state.user);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is authenticated after 3 seconds
    const timeout = setTimeout(() => {
      if (!isAuthenticated) {
        // If not authenticated, redirect to the login page after 3 seconds
        navigate("/login");
      }
    }, 500);

    return () => clearTimeout(timeout); // Clear the timeout on component unmount

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this effect runs only once

  // If authenticated or during the 3-second timeout, render the children (component)
  if (isAuthenticated) {
    return <>{children}</>;
  }

  // If not authenticated, show a message while redirecting
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="bg-white shadow-md rounded px-8 py-6 text-center">
        {!isAuthenticated && (
          <p className="text-gray-800 mb-4">
            Not logged in, redirecting to login...
          </p>
        )}
        <div className="flex justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      </div>
    </div>
  );
};

export default AuthRedirect;
