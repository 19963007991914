import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries"; 
import * as mutations from "../../graphql/mutations"; // Import your GraphQL queries
// Import your GraphQL queries

import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import {
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAIL,
  UPDATE_COUPON_REQUEST,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_FAIL,
  GET_COUPON_REQUEST,
  GET_COUPON_SUCCESS,
  GET_COUPON_FAIL,
  LOAD_COUPONS_REQUEST,
  LOAD_COUPONS_SUCCESS,
  LOAD_COUPONS_FAIL,
  LIST_COUPONS_REQUEST,
  LIST_COUPONS_SUCCESS,
  LIST_COUPONS_FAIL,
  DELETE_COUPON_REQUEST,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAIL,
  DELETE_COUPON_RESET,
  CLEAR_COUPON_ERRORS,
} from "../constants/CouponConstants";



import getLinks from "../../utils/getLinks";

export const getAllCoupons = () => async (dispatch) => {

  
  try {
    dispatch({ type: LOAD_COUPONS_REQUEST });

    const response = await API.graphql(graphqlOperation(queries.listDiscountCoupons));

    const data = response.data.listDiscountCoupons.items || [];
  const flatCoupons=data.filter((coupon)=>coupon.type==="FLAT_DISCOUNT");
  const discountCoupons=data.filter((coupon)=>coupon.type==="PERCENTAGE_DISCOUNT");
const coupons=[...flatCoupons,...discountCoupons];
    dispatch({
      type: LOAD_COUPONS_SUCCESS,
      payload: {coupons},
    });
  } catch (error) {
    dispatch({
      type: LOAD_COUPONS_FAIL,
      payload: error.response.data.message,
    });
  }
};


export const deletedCoupons = (id) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_COUPON_REQUEST });
  
      const response = await API.graphql(
        graphqlOperation(mutations.deleteDiscountCoupon, { id: id })
      );
      const data=response?.data.deleteDiscountCoupon;

      dispatch({
        type: DELETE_COUPON_SUCCESS,
        payload: data,
      });
    } catch (error) {
          const errorMessage =
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message;
  
      dispatch({
        type: DELETE_COUPON_FAIL,
        payload: errorMessage,
      });
    }
  };
  

  export const createCoupons = (data) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_COUPON_REQUEST });
  
      const response = await API.graphql(
        graphqlOperation(mutations.createDiscountCoupon, { ...data })
      );
      const datas=response?.data.createDiscountCoupon;

      dispatch({
        type: DELETE_COUPON_SUCCESS,
        payload: {data,isDiscount:true},
      });
    } catch (error) {
          const errorMessage =
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message;
  
      dispatch({
        type: DELETE_COUPON_FAIL,
        payload: errorMessage,
      });
    }
  };
  export const createFlatCoupons = (data) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_COUPON_REQUEST });
  
      const response = await API.graphql(
        graphqlOperation(mutations.createDiscountCoupon, { ...data })
      );
      const datas=response?.data.createDiscountCoupon;

      dispatch({
        type: DELETE_COUPON_SUCCESS,
        payload: {data,isDiscount:false},
      });
    } catch (error) {
          const errorMessage =
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message;
  
      dispatch({
        type: DELETE_COUPON_FAIL,
        payload: errorMessage,
      });
    }
  };


export const clearCouponErrors = () => async (dispatch) => {
  
    dispatch({ type: CLEAR_COUPON_ERRORS });
  };