import { FcGoogle } from "react-icons/fc";
import { BsFacebook } from "react-icons/bs";
import { CiCircleMore } from "react-icons/ci";
import SignTour from "../../Components/SignTour";
import validator from "validator";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../App";
import React from "react";
import { Auth } from "aws-amplify";
import Loader from "../../Components/Loader";
import { useSelector, useDispatch } from "react-redux";
import useAuthInfo from "../../hooks/getUser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoMdClose } from "react-icons/io";
import { clearErrors, login } from "../../redux/actions/UserAction";
import { LOGIN_SUCCESS } from "../../redux/constants/UserConstants";
import { NavLink } from "react-router-dom";
import useCreateAddress from "../../hooks/createAddress";
import SmallLoader from "../../Components/Loader/SmallLoader";

const LogIn = () => {
  const { error: error, isAuthenticated } = useSelector((state) => {
    return state.user;
  });
  const {createAddress}=useCreateAddress()


  const [loading, setLoading] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  const [confirmationSent, setConfirmationSent] = useState(false);

  const navigate = useNavigate();

  // Function to simulate sending OTP

  // const { dispatch } = React.useContext(AuthContext);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // const { isAuthenticated } = useAuthInfo();


  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [passwordDescription, setPasswordDescription] = useState({
    text: "Password",
    style: "",
  });
  const [emailError, setEmailError] = useState("");
  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmail(email);

      setEmailError("");
    } else {
      setEmailError("Enter valid Email!");
    }
  };

  const handleLogin = () => {
    let User = null;
    setLoading(true);
    Auth.signIn(
      document.getElementById("email").value,
      document.getElementById("password").value
    )
      .then((user) => {
        console.log("User", user);
        User = user;

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setPasswordDescription({
            text: "Password Reset Required. Enter New password",
            style: "",
          });
          Auth.completeNewPassword(
            user,
            document.getElementById("password").value
          ).then((user) => {});
        }
        setLoading(false);
        // dispatch({
        //   type: "LOGIN",
        //   email: document.getElementById("email").value,
        // });
        console.log("user",user)
        createAddress(user?.username);
     
        dispatch(login(email, password, user));
        //
        navigate("/");
        // Show toast notification for successful login
        toast.success("Logged in successfully");
      })
      .catch((error) => {
        setLoading(false);
        setShowModal(true);

        setModalMessage(error.message);
        console.log("modalMessage", modalMessage, error.message);
      });
  };
  const handleEnterPress=(e)=>{
    if (e.key === 'Enter') {
      handleLogin()
    }
  }
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }
    if (emailVerified) {
      handleLogin();
    }
    if (isAuthenticated) {
      navigate("/");
    }
    console.log("modalMessage", modalMessage);
  }, [error, isAuthenticated,emailVerified]);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const closeVerifiedModal = () => {
    setTimeout(() => {
      navigate("/");
    }, 1500);
  };

  const handleForgotPassword = () => {
    setForgotPassword(!forgotPassword);
  };

  return (
    <div>
      {emailVerified && (
        <VerificationSuccessModal
          email={email}
          closeModal={closeVerifiedModal}
        />
      )}

      {forgotPassword && (
        <ForgotPassword
          confirmationSent={confirmationSent}
          closeModal={handleForgotPassword}
          setConfirmationSent={setConfirmationSent}
        />
      )}

      {/* Modal Not being used anymore */}
      {showModal ? (
        <>
          {modalMessage === "User is not confirmed." ? (
            <OTPVerificationModel
              closeModal={closeModal}
              emailVerified={emailVerified}
              setEmailVerified={setEmailVerified}
              email={email}
            />
          ) : (
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                      Something went wrong
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative  p-2 flex flex-col items-center ">
                    <p className="m-2 text-center text-slate-500 text-1xl leading-relaxed">
                      {modalMessage}
                    </p>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                    {/* Replace the following navigation logic with your own logic */}
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        let path = "/";
                        // Replace with your own navigation logic
                        // navigate(path);
                      }}
                    >
                      Go to the Home Page
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      <div className="sm:ml-4 md:flex">
        <div className="md:w-3/5 p-8 mt-10 relative mb-20">
          <div className="text-8xl text-[#F1F1F1] font-bold relative z-10">
            <div className="uppercase">log</div>
            <div className="uppercase">in</div>
          </div>
          <img
            className="absolute top-0 md:left-0 sm:left-20 z-20"
            src="/images/Bigball.png"
            alt="Bigball"
          />
        </div>

        <div className="w-full md:w-2/5  md:mr-8 mt-20 px-2">
          <div className="py-2 text-4xl text-red-500 md:tracking-widest">
            <span className="text-[#D70D15] text-5xl font-bold">|</span>
            League Log In
          </div>
          <div>
            <div className="md:ml-10">
              <div className="mt-4">
                <h5 className="lg:block">Email</h5>
                <input
                  className="border-[#e7e7e7] border-2 rounded-md p-2 w-full"
                  type="Email"
                  id="email"
                  onChange={(e) => validateEmail(e)}
                ></input>
                <br />
                <span className="font-bold text-red">{emailError}</span>
              </div>
              <div className="mt-4">
                <h5 className={`lg:block ${passwordDescription.style}`}>
                  {passwordDescription.text}
                </h5>
                <input
                  className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
                  placeholder=""
                  type="password"
                  id="password"
                  value={password}
                  onKeyPress={handleEnterPress}

                  onChange={handlePasswordChange}
                ></input>
              </div>
              <div className="text-right mt-2">
                <button onClick={handleForgotPassword} className="">
                  Forgot Password?
                </button>
              </div>
              <div className="text-center">
                <div className="m-6">
                  {loading ? (
                    <SmallLoader  />
                  ) : (
                    <button
                      className="bg-[#fec749] px-20 py-2 whitespace-nowrap"
                      onClick={handleLogin}
                      onKeyPress={handleEnterPress}
                    >
                      Sign In
                    </button>
                  )}
                </div>
                <div>
                  <span>
                    Don't have an account?
                    <NavLink 
                      to="/register"
                      className="ml-2 font-bold "
                    >
                      Sign Up
                    </NavLink>
                  </span>
                </div>
                <div className="flex-grow">
                  <div className="flex items-center">
                    <div className="flex-grow border-t border-gray-300"></div>
                    <div className="mx-4 text-gray-500">or</div>
                    <div className="flex-grow border-t border-gray-300"></div>
                  </div>
                </div>
                <div className="text-4xl">
                  <BsFacebook className="inline text-[#1877F2] mr-4" />
                  <FcGoogle className="inline mr-4" />
                  <CiCircleMore className="inline text-[#8C8C8C]" />
                </div>
                <div>
                  <p>
                    By logging in or creating an account, you agree to Breaking
                    Boinners
                  </p>
                  <p>
                    {" "}
                    <Link href="#" className="underline font-bold">
                      Terms of Service
                    </Link>{" "}
                    and{" "}
                    <Link href="#" className="underline font-bold">
                      Privacy Policy
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <SignTour />
      </div>
    </div>
  );
};

const OTPVerificationModel = ({
  closeModal,
  emailVerified,
  setEmailVerified,
  email,
}) => {
  const [otp, setOTP] = useState("");
  const [message, setMessage] = useState("");
  const [showInput, setShowInput] = useState(false);
  const handleOTPChange = (e) => {
    setOTP(e.target.value);
  };

  const verifyCode = async (e) => {
    e.preventDefault();
    try {
      if (!email || !otp) {
        throw new Error("Enter Email and OTP");
      }
      const res = await Auth.confirmSignUp(email, otp);
      if (res === "SUCCESS") {
        setEmailVerified(true);
        closeModal();
      }
      console.log("Verification code verified successfully!", res);
      return {
        success: true,
        message: "Verification code verified successfully!",
      };
    } catch (error) {
      console.error("Error verifying verification code:", error);
      toast.error("Error verifying verification code")

      return { success: false, message: "Error verifying verification code" };
    }
  };

  const sendVerificationCode = async () => {
    try {
      if (!email) {
        throw new Error("Enter Email To Continue");
      }
      console.log("email", email);

      const res = await Auth.resendSignUp(email);
      setShowInput(true);
      console.log("Verification code sent successfully!", res);
      return { success: true, message: "Verification code sent successfully!" };
    } catch (error) {
      console.error("Error sending verification code:", error);
      toast.error("Error sending verification code")
      return { success: false, message: "Error sending verification code" };
    }
  };

 


  console.log("showInput: ", showInput);
  console.log("emailVerified: ", emailVerified);

  return (
    <>
      <div className="absolute w-full z-[100] flex items-center justify-center h-screen bg-gray-500 bg-opacity-40 overflow-hidden">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-2xl font-bold mb-4">OTP Verification</h2>

          {!emailVerified && !showInput && (
            <div>
              <p className="text-red-600 font-semibold mb-4">
                Email not verified. Please verify your email first.
              </p>
              <button
                className="bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600"
                onClick={() => sendVerificationCode()}
              >
                Verify Email
              </button>
            </div>
          )}
          {showInput ? (
            <form className="relative">
              <div className="mb-4">
                <label htmlFor="otp" className="block font-medium mb-2">
                  Enter OTP:
                </label>
                <input
                  type="text"
                  id="otp"
                  value={otp}
                  onChange={handleOTPChange}
                  className="border border-gray-300 rounded px-3 py-2 w-full focus:outline-none focus:border-blue-500"
                />
              </div>

              <button
                // type="submit"
                onClick={(e) => verifyCode(e)}
                className="bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600"
              >
                Verify OTP
              </button>
              <p className="mt-2 text-sm text-gray-600">{message}</p>
              <p
                className="mt-2 text-sm text-gray-600 cursor-pointer underline"
                onClick={sendVerificationCode}
              >
                Resend OTP
              </p>

              <button
                className="mt-6 border-1 text-sm absolute top-[-80px] right-0 text-red-500 underline cursor-pointer"
                onClick={closeModal}
              >
                Close
              </button>
            </form>
          ) : null}
        </div>
      </div>
    </>
  );
};

const VerificationSuccessModal = ({ email, closeModal }) => {
  return (
    <div className="fixed z-[1001] transition-all duration-500 delay-500 ease-in-out inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-green-500 px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-white">
              Email Verification Success
            </h3>
          </div>

          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                {/* You can replace the checkmark icon with your preferred icon */}
                <svg
                  className="h-6 w-6 text-green-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>

              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Congratulations!
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    The email address{" "}
                    <span className="font-medium">{email}</span> has been
                    successfully verified.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={closeModal}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ForgotPassword = ({ confirmationSent, closeModal, setConfirmationSent }) => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [updated, setUpdated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleForgotPassword = async () => {
    try {
      await Auth.forgotPassword(email);
      setConfirmationSent(true);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.message);
      setConfirmationSent(false);
    }
  };

  const handlePasswordReset = async () => {
    try {
      const res=await Auth.forgotPasswordSubmit(email, otp, newPassword);
     console.log("updated",res);
      if(res==="SUCCESS") {

       setUpdated(true);
      } 
      setConfirmNewPassword(false);
      setErrorMessage('');
      setEmail('');
      setOtp('');
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const renderConfirmationMessage = () => {
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full bg-white p-8 rounded-md shadow-md text-center transition-opacity duration-300">
      <p className="text-lg font-semibold mb-2">Password Updated Successfully!</p>
      <p className="text-gray-600">
        Your password has been updated. You can now log in with your new password.
      </p>
      <button onClick={closeModal} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none">
        Close
      </button>
    </div>
    );
  };

  return (
    <div className="fixed top-0 left-0 z-50 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 transition-opacity duration-300">
    <div className="relative max-w-md w-full bg-white p-8 rounded-md shadow-md transform transition-transform duration-300">
      <h2 className="text-2xl font-bold mb-4 text-center">Forgot Password</h2>
        {!confirmationSent ? (
          <div>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full mb-4 px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
            />
            <button
              onClick={handleForgotPassword}
              className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 focus:outline-none"
            >
              Reset Password
            </button>
            {errorMessage && <p className="text-red-500 text-sm mt-2">{errorMessage}</p>}
          </div>
        ) : updated ? (
          renderConfirmationMessage()
        ) : (
          <div>
            <input
              type="text"
              placeholder="Enter verification code"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className="w-full mb-2 px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
            />
            <input
              type="password"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full mb-2 px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
            />
            <input
              type="password"
              placeholder="Confirm new password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              className="w-full mb-2 px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
            />
            <button
              onClick={handlePasswordReset}
              className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 focus:outline-none"
            >
              Reset Password
            </button>
            {errorMessage && <p className="text-red-500 text-sm mt-2">{errorMessage}</p>}
          </div>
        )}
      </div>
    </div>
  );
};


export default LogIn;
