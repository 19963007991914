/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { API, Storage } from "aws-amplify";
import * as queries from "../../graphql/queries"; // Make sure to import your GraphQL queries
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useParams } from "react-router-dom";

const useScoreById = () => {
  const [score, setScore] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    const fetchScoreById = async (scoreId) => {
      try {
        setLoading(true);
        // Fetch score by ID
        const response = await API.graphql({
          query: queries.getScore,
          variables: { id: scoreId },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        const scoreData = response?.data?.getScore;

        if (!scoreData) {
          setError("Score not found");
          setLoading(false);
          return;
        }

        console.log("ScoreDetails", scoreData)
        // Fetch images associated with the score
        const firstTeamImage = await getSignedUrl(scoreData.team1?.teamLogo);
        const secondTeamImage = await getSignedUrl(scoreData.team2?.teamLogo);

        const playerDetails = scoreData?.playerDetails ? scoreData?.playerDetails : [...scoreData?.firstTeamDetails, ...scoreData?.secondTeamDetails]

        // Update score data with image URLs
        const scoreWithImages = {
          ...scoreData,
          team1: {
            ...scoreData.team1,
            teamLogo: firstTeamImage,
          },
          team2: {
            ...scoreData.team2,
            teamLogo: secondTeamImage,
          },
          playerDetails
        };

        setScore(scoreWithImages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error);
        console.error("Error fetching score by ID:", error);
      }
    };

    if (id) {


      fetchScoreById(id);
    }
  }, [id]);

  // Function to get signed URL for images
  const getSignedUrl = async (link) => {
    try {
      const signedUrl = await Storage.get(link, { level: "public", download: false });
      return signedUrl;
    } catch (error) {
      console.error("Error getting signed URL:", error);
      throw error;
    }
  };

  return { score, error, loading };
};

export default useScoreById;

