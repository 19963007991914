import Advertisement from ".";
import useFetchAdvertisement from "../../hooks/getAdvertisementByType";
import LoaderSkeleton from "./ADSKELETON";
import placeholderImage from "./advertisement.png";
const AdvertisementOne = ({index = 0}) => {
  const { advertisement, loading } = useFetchAdvertisement();
console.log('AdvertisementOne',advertisement)
  return (
    <>
      {loading ? (
        // Placeholder for image while loading
        <LoaderSkeleton/>

      ) : (
        (index < advertisement?.length && advertisement.length > 0) ? (
          <Advertisement ad={advertisement[index]} index={index} />
        ) : (
          <img
            className="w-screen h-[240px] object-cover"
            src={placeholderImage}
            alt="AdvertisementImage"
          />
        )
      )}
    </>
  );
};

export default AdvertisementOne;
