import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import AppointmentCalendar from "../../Components/Calendar/AppointmentCalendar";
import {
  getAppointemtnDetailsFromAppointmentId,
  useHandleMeet,
} from "../../Components/Calendar/processAvailableSlots";
const ScheduleMeet = () => {
  const { id } = useParams();
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const { loading, getAppointmentScheduleDetails } = useHandleMeet();

  const handleAppointmentSelect = async (id) => {
    const data = await getAppointemtnDetailsFromAppointmentId(id);
    setSelectedAppointment(data);
    await getAppointmentScheduleDetails(data.id);
  };

  useEffect(() => {
    if (id) {
      handleAppointmentSelect(id);
    }
  }, [id]);

  const memoizedSelectedAppointment = useMemo(
    () => selectedAppointment,
    [selectedAppointment]
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className=" max-w-[450px] py-10 mx-auto">
      {memoizedSelectedAppointment && (
        <AppointmentCalendar
          show={true}
          appointment={memoizedSelectedAppointment}
        />
      )}
    </div>
  );
};
export default ScheduleMeet;
