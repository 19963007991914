import React, { useEffect, useState } from 'react';
import { Table } from "flowbite-react";
import Loader from "../Loader";


function formatDate(createdAt) {
  const date = new Date(createdAt);
  const options = { month: 'short', day: 'numeric', year: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}

const LastGames = ({ teamDetails, loading }) => {
  const [totalAverages, setTotalAverages] = useState({});
  useEffect(() => {
    if (teamDetails) {
      const calculateTotalAverages = () => {
        const propertiesToAverage = [
          'AST',
          'BLK',
          'DDTWO',
          'DREB',
          'FGA',
          'FGM',
          'FGPERCENT',
          'FP',
          'FTA',
          'FTM',
          'FTPERCENT',
          'GP',
          'MIN',
          'OREB',
          'PF',
          'PTS',
          'PlusMinus',
          'REB',
          'STL',
          'TDTHREE',
          'THREEPA',
          'THREEPM',
          'THREEPPERCENT',
          'TOV'
        ];

        const averages = {};
        propertiesToAverage.forEach((property) => {
          averages[property] = 0;
        });

        teamDetails.forEach((game) => {
          game.playerDetails.forEach((player) => {
            propertiesToAverage.forEach((property) => {
              averages[property] += Number(player[property] || 0);
            });
          });
        });

        const totalAverages = {};
        propertiesToAverage.forEach((property) => {
          totalAverages[property] = (averages[property] / propertiesToAverage.length).toFixed(2);
        });

        return totalAverages;
      };

      const averages = calculateTotalAverages();
      setTotalAverages(averages);
    }
  }, [teamDetails]);
  if (loading) {
    return <Loader />;
  }

  return (
    <div className=" bg-white overflow-x-auto">
      <Table className="text-center">
        <Table.Head>
          <Table.HeadCell className="whitespace-nowrap text-xs px-1 pr-1 py-1">GAME DATE</Table.HeadCell>
          <Table.HeadCell className="whitespace-nowrap text-xs px-1 pr-1 py-1 border-r">MATCH UP</Table.HeadCell>
          {Teams.stats.map((item, index) => (
            <Table.HeadCell className="whitespace-nowrap px-1 pr-1 py-1" key={index}>
              {item}
            </Table.HeadCell>
          ))}
        </Table.Head>
        <Table.Body className="divide-y">
          {teamDetails?.map((game, gameIndex) => (
            <React.Fragment key={gameIndex}>
              {game?.playerDetails?.map((player, playerIndex) => (
                <Table.Row
                  className="bg-white dark:border-gray-700 dark:bg-gray-800 px-1 py-8"
                  key={playerIndex}
                >
                  <Table.Cell className="whitespace-nowrap text-xs uppercase text-red-400 dark:text-white px-1">
                    {formatDate(game.createdAt)}
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap  border-r text-xs uppercase text-gray-900 dark:text-white px-1">
                    {game.event}
                  </Table.Cell>

                  <Table.Cell className="px-1 pr-1 py-8">
                    {player?.GP}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.MIN}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.PTS}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.FGM}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.FGA}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.FGPERCENT}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.THREEPM}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.THREEPA}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.THREEPPERCENT}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.FTM}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.FTA}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.FTPERCENT}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.OREB}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.DREB}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.REB}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.AST}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.TOV}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.STL}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.BLK}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.PF}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.FP}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.DDTWO}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.TDTHREE}
                  </Table.Cell>
                  <Table.Cell className="px-1 pr-1 py-8" >
                    {player?.PLUSMINUS}
                  </Table.Cell>
                </Table.Row>
              ))}
            </React.Fragment>
          ))}
          <Table.Row>
            <Table.Cell className="text-red-400" colSpan="2">TOTAL</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.GP}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.MIN}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.PTS}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.FGM}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.FGA}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.FGPERCENT}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.THREEPM}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.THREEPA}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.THREEPPERCENT}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.FTM}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.FTA}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.FTPERCENT}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.OREB}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.DREB}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.REB}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.AST}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.TOV}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.STL}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.BLK}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.PF}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.FP}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.DDTWO}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.TDTHREE}</Table.Cell>
            <Table.Cell className="px-1 pr-1 py-8">{totalAverages?.PLUSMINUS}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};
export default LastGames;

const Teams = {
  stats: [
    "GP",
    "MIN",
    "PPG",
    "FGM",
    "FGA",
    "FG%",
    "3PM",
    "3PA",
    "3P%",
    "FTM",
    "FTA",
    "FT%",
    "OREB",
    "DREB",
    "REP",
    "APG",
    "TOV",
    "STL",
    "BLK",
    "PF",
    "FP",
    "DD2",
    "TD3",
    "+/-",
  ]
};
