import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import { useParams } from "react-router-dom";

const useGetAllCampCourses = () => {
  const [campCourses, setCampCourses] = useState([]);
  const [campCoursesDetails, setCampCoursesDetails] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const getCampCourses = async () => {
    try {
      setLoading(true);
      const response = await API.graphql({
        query: queries.listCampCourses,
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      if (!response) {
        return new Error("Something went wrong");
      }

      setCampCourses(response?.data?.listCampCourses?.items);

      setLoading(false);
    } catch (error) {
      setLoading(false);

      throw new Error(error);
    }
  };

  const getCampCoursesDetail = async () => {
    try {
      setLoading(true);

      const response = await API.graphql(
        graphqlOperation(queries.getCampCourse, { id })
      );
      if (!response) {
        return new Error("Something went wrong");
      }
      setCampCoursesDetails(response?.data?.getCampCourse);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      throw new Error(error);
    }
  };

  useEffect(() => {
    if (id) {
      getCampCoursesDetail();
    }
    getCampCourses();
  }, [id]);
  return { campCourses, loading, campCoursesDetails };
};

export default useGetAllCampCourses;
