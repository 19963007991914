import React from "react";
import UpComingEvents from "../../Components/UpComingEvents";
import UpComingSkeleton from "../../Components/Skeleton/UpComingEventsSkeleton";
import TypoBu from "../../Components/TypoBu";
import useHandleEvents from "../../hooks/handleEvents";
import { Link } from "react-router-dom";

const HomeUpComingEvents = () => {
  const {
    events,
    nextNextToken,
    loading,
    fetchNextPage,
    fetchPreviousPage,
    previousTokens,
  } = useHandleEvents();

  console.log("HomeUpComingEvents", events);

  return (
    <div className="">
      <TypoBu
        item={{
          text: "upcoming events",
          text_ssize: "text-3xl",
          text_bsize: "text-4xl",
          border: true,
          button: true,
        }}
        onClickLeft={fetchPreviousPage} // Pass the fetchPreviousPage function to TypoBu
        onClickRight={fetchNextPage} // Pass the fetchNextPage function to TypoBu
        disabledLeft={!fetchPreviousPage && previousTokens?.length >0}
        disabledRight={nextNextToken !== null}
      />
      <br />
      <br />

      {loading ? (
        <UpComingSkeleton />
      ) : events && events.length > 0 ? (
        // events[].map((event, index) => (
          <UpComingEvents item={events[0]}  />
        // ))
      ) : (
        <div className="w-full flex items-center justify-center pt-4">
          <span className="text-center text-[25px]">
            Currently No Events Going on
          </span>
        </div>
      )}

      {events && events.length > 0 && (
        <div className="text-center pt-4">
          <Link
            to="/events"
            className="px-20 py-2 whitespace-nowrap border-white border rounded-sm"
          >
            SEE ALL
          </Link>
        </div>
      )}
    </div>
  );
};

export default HomeUpComingEvents;
