import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import getLink from "../utils/getLinks";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import { useParams } from "react-router-dom";

const useHandleProducts = () => {
  const [initialNextNextToken, setInitialNextNextToken] = useState(undefined);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState(undefined);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [previousTokens, setPreviousTokens] = useState([]);
  const { productName } = useParams();

  const fetchImageFromCacheWithExpiration = async (url) => {
    const cachedData = localStorage.getItem(url);
    if (cachedData) {
      const { timestamp, imageUrl } = JSON.parse(cachedData);
      const currentTime = Date.now();

      // Check if the stored image is older than 300 seconds (5 minutes)
      if (currentTime - timestamp < 300000) {
        return imageUrl; // Use the cached image URL
      }
    }
    // If the image is older than 300 seconds or not found, fetch a new one
    const signedUrl = await loadImageAndCache(url);
    if (signedUrl) {
      // Store the new image URL with the current timestamp
      const dataToCache = JSON.stringify({ timestamp: Date.now(), imageUrl: signedUrl });
      localStorage.setItem(url, dataToCache);
      return signedUrl;
    }
    return null;
  };
  const loadImageAndCache = async (url) => {
    try {
      const signedUrl = await getLink(url);
      localStorage.setItem(url, signedUrl); // Store image data in cache
      return signedUrl;
    } catch (error) {
      console.error('Error fetching or caching image:', error);
      return null;
    }
  };


  const fetchProducts = async () => {
    setLoading(true);

    let variables;
    try {
      let filterExpression = {};
      if (productName && productName.trim() !== "") {
        const formattedProductName = productName.trim().charAt(0).toUpperCase() + productName.trim().slice(1).toLowerCase();

        setInitialNextNextToken(undefined);
        setNextToken(undefined);
        setNextNextToken(undefined);
        setPreviousTokens([]);
        filterExpression = {
          title: {
            contains: formattedProductName,
          },
        };

        variables = {
          limit: 100,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }



      } else {
        variables = {
          limit: 10,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }

      }

      const result = await API.graphql({
        query: queries.listItems,
        variables,
        authMode: GRAPHQL_AUTH_MODE.API_KEY, // Adjust this as needed
      });

      const fetchedProducts = result?.data?.listItems?.items || [];
      const updatedProducts = await Promise.all(
        fetchedProducts.map(async (product) => {
          try {
            const cachedImgUrl = await fetchImageFromCacheWithExpiration(product?.img_url);
            // const cachedContentImgUrl = await fetchImageFromCacheWithExpiration(product?.content_img_url);
            // const cachedVideoUrl = await fetchImageFromCacheWithExpiration(product?.video_url);
            const dataWithImageUrl = {
              render_img_url: cachedImgUrl,
              ...product,
            };
            return { ...dataWithImageUrl };
          } catch (error) {
            console.error("Error fetching image URL", error);
            return product;
          }
        })
      );

      if (!initialNextNextToken) {
        setInitialNextNextToken(result?.data?.listItems?.nextToken);
      }

      setNextNextToken(result?.data?.listItems?.nextToken);
      // if (!nextToken) {
      setProducts(updatedProducts); // If nextToken is undefined, set the products directly
      // } else {
      //   setProducts((prevProducts) => [...prevProducts, ...updatedProducts]); // Append to existing products
      // }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching products:", error);
    }
  };

  const fetchNextPage = () => {
    if (nextNextToken) {
      setPreviousTokens([...previousTokens, nextToken]);
      setNextToken(nextNextToken);
    }
  };

  const fetchPreviousPage = () => {
    if (previousTokens.length > 0) {
      const prevTokens = [...previousTokens];
      const lastToken = prevTokens.pop();

      setPreviousTokens(prevTokens);
      setNextNextToken(nextToken || initialNextNextToken);
      setNextToken(lastToken);
    }
  };


  useEffect(() => {
    fetchProducts();
  }, [nextToken, productName]);

  return {
    products,
    nextNextToken,
    loading,
    fetchNextPage,
    fetchPreviousPage,
    previousTokens,
  };
};

export default useHandleProducts;
