import { useEffect, useState } from "react";
import useGetAllTournamentTeams from "../../hooks/getTournamentTeamsLists";

const useTeamCityValidation = () => {
  const { teamslist, loading: teamLoading } = useGetAllTournamentTeams();
  const [isTeamValid, setIsTeamValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const validateTournamentTeam = async (teamName, agegroup) => {
    const existingTeam = teamslist.find(
      (team) => team.agegroup === agegroup && team.name === teamName
    );

    if (existingTeam) {
      // Team with the same name already exists in the same age group
      setIsTeamValid(false);
      setErrorMessage(
        `Team ${existingTeam.name} is already created in the ${agegroup} age group`
      );
    } else {
      // No team with the same name exists in the same age group
      setIsTeamValid(true);
      setErrorMessage("");
    }
  };

  return { validateTournamentTeam, isTeamValid, errorMessage };
};

export default useTeamCityValidation;
