import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../../graphql/queries"; // Import your GraphQL queries
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import {
  LOAD_EVENTS_REQUEST,
  LOAD_EVENTS_SUCCESS,
  LOAD_EVENTS_FAIL,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAIL,
  BUY_EVENT_TICKET_REQUEST,
  BUY_EVENT_TICKET_SUCCESS,
  BUY_EVENT_TICKET_FAIL,
  CLEAR_EVENT_ERRORS,
  ALL_EVENT_REQUEST,
  ALL_EVENT_SUCCESS,
  ALL_EVENT_FAIL,
} from "../constants/EventsConstants";
import getLinks from "../../utils/getLinks";

export const getAllEvents = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_EVENT_REQUEST });
    const response = await API.graphql({
      query: queries.listLeagues,
      variables: {
        filter: {
          eventStatus: {
            eq:"EVENT_NOT_STARTED"
          },
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const data = response?.data?.listLeagues?.items || [];
    const updatedEvents = await Promise.all(
      data?.map(async (event) => {
        try {
          const signedUrl = await getLinks(event?.img_url);
          return { ...event, img_link: signedUrl };
        } catch (error) {
          console.error("Error fetching image URL", error);
          return event;
        }
      })
    );

    const updatedData = {
      events: updatedEvents,
      eventsCount: updatedEvents?.length,
      resultPerPage: 10,
      filteredEventsCount: null,
    };

    dispatch({
      type: ALL_EVENT_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    const errorMessage =
      error?.response && error?.response?.data?.message
        ? error?.response?.data?.message
        : error?.message;
    dispatch({
      type: ALL_EVENT_FAIL,
      payload: errorMessage,
    });
  }
};

export const getEventDetails = (eventId) => async (dispatch) => {
  try {
    dispatch({ type: GET_EVENT_REQUEST });

    const response = await API.graphql(
      graphqlOperation(queries.getLeague, { id: eventId })
    );

    const data = response?.data?.getLeague;
    const signedUrl = await getLinks(data?.img_url);
    const newData = { ...data, render_img_url: signedUrl };
    dispatch({
      type: GET_EVENT_SUCCESS,
      payload: newData,
    });
  } catch (error) {
    const errorMessage =
      error?.response && error?.response?.data?.message
        ? error?.response?.data?.message
        : error?.message;

    dispatch({
      type: GET_EVENT_FAIL,
      payload: errorMessage,
    });
  }
};

//add a new product
// export const addProduct = (productData,images) => async (dispatch) => {
//     try {
//       dispatch({ type: ADD_PRODUCT_REQUEST });

//       const config = {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       };
//       // const config = { headers: { "Content-Type": "multipart/form-data" } };

//       const { data } = await axios.post("/api/v1/admin/new/product", productData, config);

//       dispatch({
//         type: ADD_PRODUCT_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       const errorMessage =
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message;

//       dispatch({
//         type: ADD_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

// export const updateProduct = (productId, productData) => async (dispatch) => {
//     try {
//       dispatch({ type: UPDATE_PRODUCT_REQUEST });

//       const config = {
//         headers: { "Content-Type": "application/json" },
//       };

//       const { data } = await axios.put(
//         `/api/v1/admin/product/${productId}`,
//         productData,
//         config
//       );

//       dispatch({
//         type: UPDATE_PRODUCT_SUCCESS,
//         payload: data.success,
//       });
//     } catch (error) {
//           const errorMessage =
//             error.response && error.response.data.message
//               ? error.response.data.message
//               : error.message;

//       dispatch({
//         type: UPDATE_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

// export const deleteProduct = (productId) => async (dispatch) => {
//     try {
//       dispatch({ type: DELETE_PRODUCT_REQUEST });

//       const { data } = await axios.delete(`/api/v1/admin/product/${productId}`);

//       dispatch({
//         type: DELETE_PRODUCT_SUCCESS,
//         payload: data.success,
//       });
//     } catch (error) {
//           const errorMessage =
//             error.response && error.response.data.message
//               ? error.response.data.message
//               : error.message;

//       dispatch({
//         type: DELETE_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

export const clearEventsErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_EVENT_ERRORS });
};
