import { useState, useEffect } from "react";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import getLink from "../utils/getLinks";
import { useParams } from "react-router-dom";

const usePlayerDetails = () => {
  const [playerDetails, setPlayerDetails] = useState(null); // Use null for single playerDetails
  const [loading, setLoading] = useState(false);
  const {playerId} = useParams();

  const fetchPlayerByName = async () => {
    try {
      setLoading(true);

      const response = await API.graphql(graphqlOperation(queries.getPlayer, { id: playerId }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const fetchedPlayer = response?.data?.getPlayer || {};

      const signedUrl = await getLink(fetchedPlayer?.profile);

      fetchedPlayer.profile = signedUrl;

      console.log("playerDetails",fetchedPlayer)
      setPlayerDetails(fetchedPlayer);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching player details:", error);
    }
  };

  useEffect(() => {
  
    if (playerId) {
      fetchPlayerByName(playerId);
    }
  }, [playerId]);

  return { playerDetails, loading, fetchPlayerByName };
};

export default usePlayerDetails;
