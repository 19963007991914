import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addToCart, getCart } from "../../redux/actions/cartAction";
import ProductCard from "../Product/ProductCard";
import ProductCardSkeleton from "../Skeleton/ProductCardSkeleton";
import useHandleProducts from "../../hooks/handleProducts";

const RenderShop = ({ fetchData, addToCartById }) => {
  const {
    products,
    nextNextToken,
    loading,
    previousTokens,
    fetchNextPage,
    fetchPreviousPage,
  } = useHandleProducts();
  const dispatch = useDispatch();

  const { isAuthenticated, user } = useSelector((state) => state?.user);
  const {
    cart,
    cartItems,
    loading: cartLoading,
    added,
  } = useSelector((state) => state?.cart);

  const AddToCart = async (item) => {
    try {
      const data = {
        title: item?.title,
        selling_price: item?.selling_price,
        original_price: item?.original_price,
        rating: item?.rating,
        itemId: item?.id,
        img_url: item?.img_url,
        tags: item?.tags,
        stock_qty: item?.stock_qty,
        quantity: 1,
        finalPrice: item?.selling_price,
      };

      if (addToCartById) {
        await addToCartById(user, data);
      } else {
        dispatch(addToCart(user, data, cartItems));
        dispatch(getCart(user));
        toast.success("Added-to-cart");
      }
    } catch (error) {
      toast.error("Failed to add item to cart.");
    }
  };

  return (
    <div className="bg-[#181B22] pt-10 pb-10 rounded-md text-center flex flex-col items-center">
      <span className="text-white">SHOP</span>
      <div className="flex flex-wrap justify-center items-center py-10 text-center">
        {loading ? (
          <ProductCardSkeleton />
        ) : (
          products?.slice(0, 4)?.map((item, index) => (
            <ProductCard key={index} item={item} AddToCart={AddToCart} />
          ))
        )}
      </div>
    </div>
  );
};

export default RenderShop;
