import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import getLink from "../utils/getLinks";

const useTeam = () => {
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [updateLeagues, setProduct] = useState([]);

  const fetchTeams = async (teamIds) => {
    try {
      setLoading(true);
      const teamPromises = teamIds.map(async (teamId) => {
        const response = await API.graphql({
          query: queries.getTeam,
          variables: { id: teamId },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        const teamData = response?.data?.getTeam;

        return teamData;
      });

      const teamsData = await Promise.all(teamPromises);
      const updatedData = await Promise.all(
        teamsData?.map(async (team) => {
          try {
            const signedUrl = await getLink(team?.imageURL);
            return {
              ...team,
              imageURL: signedUrl,
            };
          } catch (error) {
            console.error("Error fetching image URL", error);
            return team;
          }
        })
      );
      setTeam(teamsData);
      setLoading(false);
      return updatedData;
    } catch (error) {
      setLoading(false);
      console.error("Error fetching teams:", error);
      throw error;
    }
  };



  return { team, fetchTeams };
};

export default useTeam;
