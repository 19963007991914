import HeaderLg from "../../Components/HeaderLg";
import Registration from "../../Components/Registration";
import FollowUS from "../../Components/FollowUS";
import SqShop from "../../Components/SqShop";
import Workwith from "../../Components/Workwith";
import LgNews from "../../Components/LgNews";
import FlatVideo from "../../Components/FlatVideo";
import LeagueCityTable from "../../Components/LeagueCityTable";
import Advertisement from "../../Components/Advertisement";
import Sponsors from "../../Components/Sponsors";
import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { NavLink } from "react-router-dom";
import ImageGrid from "../../Components/ImageGrid";
import useLeagueInfoData from "../../hooks/leagueInfoDate";
import RenderShop from "../../Components/Shop/RenderShop";
import useGetAllTeams from "../../hooks/getTeamsLists";
import { useParams } from "react-router-dom";
import TypoBu from "../../Components/TypoBu";
import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";

const LeagueCity = () => {
  const { teamslist, loading } = useGetAllTeams();
  const { cityname } = useParams();

  const updatedTeams = teamslist.filter(team => team.location === cityname || team.city === cityname);


  console.log("updatedTeams", updatedTeams);

  console.log("cityname", cityname)

  console.log("teamslistttttttttttttttttttttttttttttttt", teamslist)

  return (
    <div className="mt-[25px] bg-[#E7E7E7] pb-10 scroll  ">
      <HeaderLg />
      <ImageGrid />

      <div className="px-4 md:px-10 mt-10 lg:mt-20">
        <div className="grid grid-cols-1 lg:grid-cols-7 gap-4">
          <div className="lg:col-span-5 flex flex-col gap-10">
            <TypoBu
              item={{
                text: cityname,
                text_ssize: "text-3xl",
                text_bsize: "text-4xl",
                border: true,
                button: false,
              }}
            />
            <div className="mr-2 sm:mr-0">
              <LeagueCityTable teamslist={updatedTeams} cityname={cityname} loading={loading} />
            </div>
            <AdvertisementOne index={0} />
            <LgNews />
            <AdvertisementOne index={1} />
            <FlatVideo />
          </div>
          <div className="lg:col-span-2">
            <Registration />
            <div className="mt-5 md:mt-10">
              <FollowUS />
            </div>
            <div className="py-5 md:py-10">
              <Workwith
                title="join the team as a volunteer"
                button="volunteer"
                link="/signupvolunteer"
              />
            </div>
            <Sponsors />
            <div className="my-5 md:my-10">
              <RenderShop />
            </div>
            <Sponsors />
          </div>
        </div>
      </div>
    </div>
  );
};
export default LeagueCity;
