import React, { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import useAuthInfo from "./getUser";
import { toast } from "react-toastify";

const useAddOrder = () => {
  // const { userId }=useAuthInfo() // Use your user hook to get the userId
  const [loading, setLoading] = useState(false);

  const addOrder = (items) => {
    setLoading(true);

    API.graphql({
      query: mutations.createOrder,
      variables: {
        input: { ...items },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    })
      .then((response) => {
        console.log("Cart creating successfully:", response);
        toast.success("Order Success.", items);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error creating order:", error);
        setLoading(false);
      });
  };

  const buyEvent = (items) => {
    setLoading(true);
    console.log("items", items);

    API.graphql({
      query: mutations.createBookedEvent,
      variables: {
        input: { ...items },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    })
      .then((response) => {
        console.log("BookedEvent creating successfully:", response);
        toast.success("BookedEvent Success.", items);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error creating BookedEvent:", error);
        setLoading(false);
      });
  };

  return { addOrder, buyEvent };
};

export default useAddOrder;
