export const GET_CART_REQUEST = 'GET_CART_REQUEST';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const GET_CART_FAIL = 'GET_CART_FAIL';


export const GET_MAIN_CART_REQUEST = 'GET_MAIN_CART_REQUEST';
export const GET_MAIN_CART_SUCCESS = 'GET_MAIN_CART_SUCCESS';
export const GET__MAIN_CART_FAIL = 'GET__MAIN_CART_FAIL';


export const UPDATE_CARTITEMS_REQUEST = 'UPDATE_CARTITEMS_REQUEST';
export const UPDATE_CARTITEMS_SUCCESS = 'UPDATE_CARTITEMS_SUCCESS';
export const UPDATE_CARTITEMS_FAIL = 'UPDATE_CARTITEMS_FAIL';


export const UPDATE_LEAGUE_REQUEST = 'UPDATE_LEAGUE_REQUEST';
export const UPDATE_LEAGUE_SUCCESS = 'UPDATE_LEAGUE_SUCCESS';
export const UPDATE_LEAGUE_FAIL = 'UPDATE_LEAGUE_FAIL';


export const LIST_CARTS_REQUEST = 'LIST_CARTS_REQUEST';
export const LIST_CARTS_SUCCESS = 'LIST_CARTS_SUCCESS';
export const LIST_CARTS_FAIL = 'LIST_CARTS_FAIL';

export const CREATE_CART_REQUEST = 'CREATE_CART_REQUEST';
export const CREATE_CART_SUCCESS = 'CREATE_CART_SUCCESS';
export const CREATE_CART_FAIL = 'CREATE_CART_FAIL';

export const DELETE_CART_REQUEST = 'DELETE_CART_REQUEST';
export const DELETE_CART_SUCCESS = 'DELETE_CART_SUCCESS';
export const DELETE_CART_FAIL = 'DELETE_CART_FAIL';
export const DELETE_CART_RESET = 'DELETE_CART_RESET';

export const CLEAR_CART_ERRORS = 'CLEAR_CART_ERRORS';
export const ADD_ITEMS_TO_CART_REQUEST = 'ADD_ITEMS_TO_CART_REQUEST';
export const ADD_ITEMS_TO_CART_SUCCESS = 'ADD_ITEMS_TO_CART_SUCCESS';
export const ADD_ITEMS_TO_CART_FAIL = 'ADD_ITEMS_TO_CART_FAIL';
export const ADD_ITEMS_TO_CART_RESET = 'ADD_ITEMS_TO_CART_RESET';

export const ADD_LEAGUE_TO_CART_REQUEST = 'ADD_LEAGUE_TO_CART_REQUEST';
export const ADD_LEAGUE_TO_CART_SUCCESS = 'ADD_LEAGUE_TO_CART_SUCCESS';
export const ADD_LEAGUE_TO_CART_FAIL = 'ADD_LEAGUE_TO_CART_FAIL';
export const ADD_LEAGUE_TO_CART_RESET = 'ADD_LEAGUE_TO_CART_RESET';

export const REMOVE_ITEMS_FROM_CART_REQUEST = 'REMOVE_ITEMS_FROM_CART_REQUEST';
export const REMOVE_ITEMS_FROM_CART_SUCCESS = 'REMOVE_ITEMS_FROM_CART_SUCCESS';
export const REMOVE_ITEMS_FROM_CART_FAIL = 'REMOVE_ITEMS_FROM_CART_FAIL';
export const REMOVE_ITEMS_FROM_CART_RESET = 'REMOVE_ITEMS_FROM_CART_RESET';


export const REMOVE_LEAGUE_FROM_CART_REQUEST = 'REMOVE_LEAGUE_FROM_CART_REQUEST';
export const REMOVE_LEAGUE_FROM_CART_SUCCESS = 'REMOVE_LEAGUE_FROM_CART_SUCCESS';
export const REMOVE_LEAGUE_FROM_CART_FAIL = 'REMOVE_LEAGUE_FROM_CART_FAIL';
export const REMOVE_LEAGUE_FROM_CART_RESET = 'REMOVE_LEAGUE_FROM_CART_RESET';

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const ADJUST_ITEM_QTY = 'ADJUST_ITEM_QTY';
export const CLEAR_CART = 'CLEAR_CART';
export const GET_CART_ITEMS = 'GET_CART_ITEMS';
export const SAVE_CART_ITEMS = 'SAVE_CART_ITEMS';
export const CART_ERROR = 'CART_ERROR';
export const APPLY_DISCOUNT = 'APPLY_DISCOUNT';
export const REMOVE_DISCOUNT = 'REMOVE_DISCOUNT';
export const SET_SHIPPING_ADDRESS = 'SET_SHIPPING_ADDRESS';
export const SET_BILLING_ADDRESS = 'SET_BILLING_ADDRESS';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const CART_CHECKOUT = 'CART_CHECKOUT';
export const CART_CHECKOUT_SUCCESS = 'CART_CHECKOUT_SUCCESS';
export const CART_CHECKOUT_FAIL = 'CART_CHECKOUT_FAIL';
export const CART_RESET_CHECKOUT = 'CART_RESET_CHECKOUT';
