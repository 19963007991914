import { BiClinic } from "react-icons/bi";
import { BsTrophy } from "react-icons/bs";
import { FaCampground } from "react-icons/fa";
import { BiBasketball } from "react-icons/bi";
import { HiOutlineHandRaised } from "react-icons/hi2";
import { FaHandshake } from "react-icons/fa";
import { RiSuitcaseLine } from "react-icons/ri";
import Teams from "../../Components/Teams";
import Shop from "../../Components/Shop";
import { Link, NavLink } from "react-router-dom";

import TypoBu from "../../Components/TypoBu";
import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";

const SignUpIcon = () => {
  return (
    <div className="pt-20 ">
      <div className="text-center text-4xl font-black uppercase">
        <p>Sign Up</p>
      </div>
      <div className="text-center bg-white px-10 py-16">
        <div className="flex justify-between md:flex-row flex-col ">
          <div className="mb-4">
            <NavLink to="/signupleague">
              <BsTrophy className="inline text-4xl mb-4" />
              <div className="font-bold mb-4">LEAGUE(Player)</div>
            </NavLink>
          </div>
          <div>
            <NavLink to="/signuptournament">
              <BiBasketball className="inline text-4xl mb-4" />
              <div className="font-bold mb-4 whitespace-nowrap">Tournament</div>
            </NavLink>
          </div>
          <div className="mb-4">
            <NavLink to="/signupcamp">
              <FaCampground className="inline text-4xl mb-4" />
              <div className="font-bold mb-4">CAMPS</div>
            </NavLink>
          </div>
          <div className="mb-4">
            <NavLink to="/signupclinic">
              <BiClinic className="inline text-4xl mb-4" />
              <div className="font-bold mb-4">CLINICS</div>
            </NavLink>
          </div>
          <div>
            <NavLink to="/signuptrainingprogram">
              <BiBasketball className="inline text-4xl mb-4" />
              <div className="font-bold mb-4 whitespace-nowrap">
                TRAINING PROGRAMS
              </div>
            </NavLink>
          </div>
          {/* <div className="mb-4">
            <img src="/images/Ticket.svg" alt="Ticket" className="inline h-12 mb-2 w-16" />
            <div className="font-bold mb-4">
              EVENTS
            </div>
          </div> */}
          <div className="mb-2">
            <NavLink to="/signupworkwithus">
              <RiSuitcaseLine className="inline text-4xl mb-4" />
              <div className="font-bold mb-4">Work with us</div>
            </NavLink>
          </div>
          <div className="mb-4">
            <NavLink to="/signupvolunteer">
              <HiOutlineHandRaised className="inline text-4xl mb-4" />
              <div className="font-bold mb-4 whitespace-nowrap">Volunteer</div>
            </NavLink>
          </div>
          <div className="mb-2">
            <NavLink to="/signuppartner">
              <FaHandshake className="inline text-4xl mb-4" />
              <div className="font-bold mb-4">partner</div>
            </NavLink>
          </div>
          {/* <div className="mb-2">
            <RiSuitcaseLine className="inline text-4xl mb-4" />
            <div className="font-bold mb-4">
              Work with us
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className="flex justify-center mb-5">
        <NavLink to="/signup" className="font-bold  bg-[#fec749] px-40 whitespace-nowrap py-1">Sign Up </NavLink>
      </div> */}

      <div className="m-0 md:flex bg-[#181B22] mt-20">
        <div className="md:w-3/5 flex md:flex-row flex-col">
          <img
            src="/images/john-arano-big.png"
            alt="john-arano"
            className="w-full"
          />
          <div className="w-full md:w-2/5">
            <div className="uppercase text-6xl text-white font-bold px-10 pt-20  mb-10">
              Sign Up For A <p className="text-5xl">Weekend Tournament</p>
            </div>
            <div className="pl-12 mb-4">
              <button
                href="#"
                className="bg-[#fec749] px-20 whitespace-nowrap py-1"
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10 bg-work-with ty-10 uppercase text-white text-4xl text-center pt-32">
        {/* <div>
          <div className="">work with us</div>
          <div className=" mt-20">
            <HiOutlineHandRaised className="inline" />
          </div>
          <div className="pt-20 pb-20">
            <button
              to=""
              className="px-20 py-1 bg-[#fec749]  text-black"
            >
              VOLUNTEER
            </button>
          </div>
        </div> */}
        <div className="flex justify-center flex-wrap">
          {/* First Column */}
          <div className="uppercase justify-center items-center text-white text-xl md:text-4xl text-center  md:mx-4 pr-[5rem] md:pr-0 w-full md:w-1/2 lg:w-auto">
            <NavLink
              to="/signupworkwithus"
              className="pb-20 w-full flex flex-col items-center justify-center h-full"
            >
              <RiSuitcaseLine className="inline text-4xl mb-4" />

              <div>work with us</div>

              {/* <div className="pt-20 pb-20">
                <NavLink to="/signuppartner" className="uppercase px-10 py-1 bg-[#fec749] text-black">sign up</NavLink>
                </div> */}
            </NavLink>
          </div>

          {/* Second Column */}
          <div className="uppercase justify-center items-center text-white text-xl md:text-4xl text-center md:mx-4 pr-[5rem] md:pt-0 pt-10 md:pb-0 pb-10 md:pr-0 w-full md:w-1/2 lg:w-auto">
            <NavLink
              to="/signupvolunteer"
              className="pb-20 w-full flex flex-col items-center justify-center h-full"
            >
              <HiOutlineHandRaised className="inline text-4xl mb-4" />

              <div>volunteer</div>
              {/* <div className="pt-20 pb-20">
                <NavLink to="/signupvolunteer" className="uppercase px-10 py-1 bg-[#fec749] text-black">sign up</NavLink>
              </div> */}
            </NavLink>
          </div>
        </div>
      </div>
      <div className="md:p-20">
        <AdvertisementOne index={0} />
      </div>
      <Teams />
      <div className="bg-[#181B22] md:p-20">
        <AdvertisementOne index={1} />
      </div>
      <div className="md:p-20 p-4 bg-[#181B22] text-white flex flex-col gap-20 py-20">
        <TypoBu
          item={{
            text: "shop",
            text_ssize: "text-3xl",
            text_bsize: "text-4xl",
            border: true,
            button: false,
          }}
        />
        <Shop />
        <div className="text-center">
          <Link
            to="/store"
            className="px-20 py-2 whitespace-nowrap border-white border rounded-sm"
          >
            VIEW ALL
          </Link>
        </div>
      </div>
    </div>
  );
};
export default SignUpIcon;
