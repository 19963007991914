import React from "react";
import { InlineWidget } from "react-calendly";
import useFetchMeetLink from "./FetchMeetLink";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";

const CalendlyComponent = () => {

  const { loading, userDetails, calendlyLink, isTrainingProgramUser } =
    useFetchMeetLink();
  console.log("User Details: ", userDetails);

  if (loading) {
    return <Loader />;
  }


  return (
    <div>
      <InlineWidget url={calendlyLink} />
    </div>
  );
};

export default CalendlyComponent;
