import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import getLink from "../utils/getLinks";

const useHandleCartUser = () => {
  const [cartList, setCartList] = useState([]);
  const [leagueCart, setLeagueCartList] = useState([]);

  const { user, isAuthenticated } = useSelector((state) => state.user);
  const [verifiedCarts, setVerifiedCartList] = useState([]);
  const [notVerifiedCarts, setNotVerifiedCartList] = useState([]);

  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();



  const fetchImageFromCacheWithExpiration = async (url) => {
    const cachedData = localStorage.getItem(url);
    if (cachedData) {
      const { timestamp, imageUrl } = JSON.parse(cachedData);
      const currentTime = Date.now();

      // Check if the stored image is older than 300 seconds (5 minutes)
      if (currentTime - timestamp < 300000) {
        return imageUrl; // Use the cached image URL
      }
    }
    // If the image is older than 300 seconds or not found, fetch a new one
    const signedUrl = await loadImageAndCache(url);
    if (signedUrl) {
      // Store the new image URL with the current timestamp
      const dataToCache = JSON.stringify({ timestamp: Date.now(), imageUrl: signedUrl });
      localStorage.setItem(url, dataToCache);
      return signedUrl;
    }
    return null;
  };
  const loadImageAndCache = async (url) => {
    try {
      const signedUrl = await getLink(url);
      localStorage.setItem(url, signedUrl); // Store image data in cache
      return signedUrl;
    } catch (error) {
      console.error('Error fetching or caching image:', error);
      return null;
    }
  };


  const fetchCartUsers = async () => {
    try {
      setLoading(true);
      const response = await API.graphql({
        query: queries.listCartItems,
        variables: {
          filter: {
            userid: {
              eq: user,
            },
            // cartId: {
            //   eq: id,
            // },
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      console.log("UserCart", response);
      const responseleague = await API.graphql({
        query: queries.listLeagueCarts,
        variables: {
          filter: {
            userid: {
              eq: user,
            },
            // cartId: {
            //   eq: id,
            // },
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const cartItems = response?.data?.listCartItems?.items || [];
      const leagueItems = responseleague?.data?.listLeagueCarts?.items || [];
      const updatedData = await Promise.all(
        leagueItems?.map(async (items) => {
          try {
            const cachedImgUrl = await fetchImageFromCacheWithExpiration(items?.img_url);


            return {
              ...items,
              render_img_url: cachedImgUrl,
            };
          } catch (error) {
            console.error("Error fetching image URL", error);
            return items;
          }
        })
      );
      const updatedItems = await Promise.all(
        cartItems?.map(async (items) => {
          try {
            const signedUrl = await getLink(items?.img_url);
            return {
              ...items,
              render_img_url: signedUrl,
            };
          } catch (error) {
            console.error("Error fetching image URL", error);
            return items;
          }
        })
      );

      setCartList(updatedItems);
      setLeagueCartList(updatedData);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching training program users:", error);
      setLoading(false);
    }
  };

  const deleteCartItemUserById = async (id) => {
    try {
      setLoading(true);
      const idDelete = id;

      await API.graphql(
        graphqlOperation(mutations.deleteCartItem, { input: { id } })
      );

      const itemIndex = cartList?.findIndex((item) => item?.itemId === id);
      const updatedCartItems = [...cartList];
      // Create a copy of leagueItems array
      if (itemIndex !== -1) {
        // Item found, remove it from the leagueItems array
        updatedCartItems.splice(itemIndex, 1);
      }

      setCartList(cartList);

      setLoading(false);
      toast.success("removed successfully.");
    } catch (error) {
      console.error("Error deleting training program user:", error);
      setLoading(false);
    }
  };
  const deleteLeagueCartItemById = async (idToDelete) => {
    try {
      setLoading(true);
      const idDelete = id || idToDelete;

      await API.graphql(
        graphqlOperation(mutations.deleteCartItem, {
          input: { id: idDelete },
        })
      );

      setLoading(false);
      toast.success("removed successfully.");
    } catch (error) {
      console.error("Error deleting training program user:", error);
      setLoading(false);
    }
  };
  const updateCartItemById = async (id, data) => {
    try {
      setLoading(true);
      console.log("data", data);

      const response = await API.graphql({
        query: mutations.updateCartItem,
        variables: {
          input: {
            id,
            userid: data.userid,
            itemId: data.itemId,
            title: data.title,
            selling_price: data.selling_price,
            original_price: data.original_price,
            rating: data.rating,
            //  img_url:data.img_url,
            tags: data.tags,
            stock_qty: data.stock_qty,
            category: data.category,
            cartId: data.cartId,
            quantity: data.quantity,
            finalPrice: data.finalPrice,
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const itemIndex = cartList?.findIndex((item) => item?.itemId === id);

      const updatedCartItems = [...cartList]; // Create a copy of cartItems array
      if (itemIndex !== -1) {
        // Item found, update its quantity
        updatedCartItems[itemIndex].quantity = data?.quantity;
        updatedCartItems[itemIndex].finalPrice = data?.finalPrice;
      }

      const updateCartItem = response?.data?.updateCartItem;
      const updatedData = {
        ...updateCartItem,
        render_img_url: data?.render_img_url,
      };
      setCartList((prevList) =>
        prevList.map((user) =>
          user.id === updateCartItem.id ? updatedData : user
        )
      );

      // toast.success("Updated");
      setLoading(false);
    } catch (error) {
      console.error("Error updating training program user:", error);
      setLoading(false);
    }
  };

  const addToCartById = async (userid, item) => {
    try {
      setLoading(true);

      const isAlready = await API.graphql({
        query: queries.listCartItems,
        variables: {
          filter: {
            userid: {
              eq: userid, // Assuming userid is a variable containing the user ID
            },
            itemId: {
              eq: item?.itemId, // Replace specificItemId with the actual item ID you're querying for
            },
          },
        },
      });
      if (isAlready?.data?.listCartItems?.items.length > 0) {
        return;
      }

      const response = await API.graphql({
        query: mutations.createCartItem,
        variables: {
          input: {
            userid,
            ...item,
            cartId: userid,
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const data = [...response?.data?.createCartItem, ...cartList];
      setCartList(data);
      toast.success("Added-to-cart");
      fetchCartUsers()
      setLoading(false);
    } catch (error) {
      console.error("Error updating training program user:", error);
      setLoading(false);
    }
  };

  const addLeaguesToCart = async (userid, item) => {
    try {
      const isAlready = await API.graphql({
        query: queries.listLeagueCarts,
        variables: {
          filter: {
            userid: {
              eq: userid, // Assuming userid is a variable containing the user ID
            },
            leagueId: {
              eq: item?.leagueId, // Replace specificItemId with the actual item ID you're querying for
            },
          },
        },
      });
      const responsedd = isAlready?.data?.listLeagueCarts?.items?.length > 0;
      if (isAlready?.data?.listLeagueCarts?.items?.length > 0) {
        return;
      }

      const response = await API.graphql({
        query: mutations.createLeagueCart,
        variables: {
          input: {
            userid,
            ...item,
            cartId: userid,
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      toast.success("Added to cart");
      const createLeagueItem = response?.data?.createLeagueCart;
      const data = [...leagueCart, createLeagueItem];
      setLeagueCartList(data);
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchCartUsers();
    }
  }, [user]);

  return {
    leagueCart,
    cartList,
    fetchCartUsers,
    loading,
addToCartById,
    deleteCartItemUserById,
    updateCartItemById,
  };
};

export default useHandleCartUser;
