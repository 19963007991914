import React from 'react';

const UpComingSkeleton = () => {
  return (
    <div className="mt-8">
      <div className="h-[150px] bg-gray-400 animate-pulse"></div>
      <div className="bg-white p-10 text-2xl font-bold">
        <div className="uppercase bg-gray-400 h-8 w-1/2 animate-pulse"></div>
        <div className="grid grid-cols-1 lg:grid-cols-5 mt-4">
          <div className="lg:col-span-2 space-y-4">
            <div className="bg-gray-400 h-6 w-1/3 animate-pulse"></div>
            <div className="bg-gray-400 h-6 w-2/3 animate-pulse"></div>
            <div className="bg-gray-400 h-6 w-1/2 animate-pulse"></div>
            <div className="bg-gray-400 h-6 w-3/4 animate-pulse"></div>
            <div className="bg-gray-400 h-6 w-3/5 animate-pulse"></div>
          </div>
          <div className="lg:col-span-3 lg:px-10">
            <div className="flex-1 flex-col bg-white text-black lg:py-10 border-white">
              <div className="flex justify-between UpComings-center py-8">
                <div className="bg-gray-400 h-32 w-2/5 animate-pulse"></div>
                <span className="italic">VS</span>
                <div className="bg-gray-400 h-32 w-2/5 animate-pulse"></div>
              </div>
              <div className="flex float-right">
                <div className="bg-gray-400 h-10 w-16 animate-pulse mr-4"></div>
                <button className="bg-gray-400 h-10 rounded-md text-xl whitespace-nowrap px-10 lg:px-24 animate-pulse"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpComingSkeleton;
