import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

import { useParams } from "react-router-dom";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import getLink from "../utils/getLinks";
import { getKey, updateImageSrcInHtml } from "../utils/replaceUrl";

const useGetNewsById = () => {
  const [newsDetails, setDetails] = useState();
  const [view_count, setViewsCount] = useState();

  const [loading, setLoading] = useState(false);
  const { id } = useParams(); // Get the ID from URL parameters
  const fetchNewsById = async () => {
    try {
      setLoading(true);

      const newsResponse = await API.graphql({
        query: queries.getNews,
        variables: {
          id: id,
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const newsData = newsResponse?.data?.getNews;

      // const signedUrl = await getLink(newsData?.content_img_url);
      // const signedUrl2 = await getLink(newsData?.img_url);
      // const videLink = await getLink(newsData?.video_url);

      const updatedData = {
        ...newsData,
      }

      const updateViewCountInput = {
        id: id,
        view_count: updatedData?.view_count + 1,
      };

      await API.graphql({
        query: mutations.updateNews,
        variables: {
          input: updateViewCountInput,
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      setViewsCount(updatedData?.view_count);
      const img_url = updatedData?.img_url;

      if (img_url) {
        const key = getKey(img_url);
        const link = await getLink(key);

        updatedData.img_url = link;
        updatedData.content_img_url = link;
      }


      const briefWithUpdatedImageURl = await updateImageSrcInHtml(updatedData?.brief, getLink, getKey);
      updatedData.brief = briefWithUpdatedImageURl;
      setDetails(updatedData);
      setLoading(false);
      return updatedData;
    } catch (error) {
      setLoading(false);
      throw new Error(error);
    }
    // setLastId(res.data.listNews.items.length - 1);
  };
  useEffect(() => {
    if (id) {
      fetchNewsById();
    }
  }, [id]);

  // No need to have any dependencies for initial load

  return { newsDetails, view_count, loading };
};

export default useGetNewsById;
