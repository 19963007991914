import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import useAuthInfo from "../../hooks/getUser";
import * as queries from "../../graphql/queries";
import * as mutation from "../../graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useFetchMeetLink = () => {
  const { user: userId, isAuthenticated } = useSelector((state) => state.user);

  const [userDetails, setUserDetails] = useState();
  const [calendlyLink, setCalendlyLink] = useState("");
  const navigate = useNavigate();

  const [isTrainingProgramUser, setIsTrainingProgramUser] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchClinicDetails = async (userId) => {
    try {
      // const response = await API.graphql(
      //   graphqlOperation(queries.getClinic, {
      //     id: userId,
      //   })
      // );

      const response = await API.graphql({
        query: queries.listClinics,
        variables: {
          filter: {
            id: {
              eq: userId,
            },
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const clinicData = response?.data.listClinics.items;
      if (!clinicData) {
        navigate("/signupclinic");
        setUserDetails(null);
        return;
      }
      clinicData?.map(async (res) => {
        if (res.transactionStatus === "FAILED") {
          toast.error("Payment Failed");

          await API.graphql({
            query: mutation.deleteClinic,
            variables: {
              input: {
                id: res.id,
              },
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
          navigate("/signupclinic");
        }
      });

      // Handle clinic data as needed
      console.log("Clinic Data:", clinicData);
      setUserDetails(clinicData[0]);
    } catch (error) {
      console.log("Error fetching clinic details:", error);
      throw error;
    }
  };

  const fetchTrainingProgramDetails = async (userId) => {
    try {
      const checkoutPayload = {
        userId,
      };

      const response = await API.graphql({
        query: queries.listTrainingPrograms,
        variables: {
          filter: {
            userId: {
              eq: userId,
            },
            enable: {
              eq: true,
            },
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const dataResponseTraining = response?.data.listTrainingPrograms.items;

      dataResponseTraining.map(async (res) => {
        if (res.transactionStatus === "FAILED") {
          toast.error("Payment Failed");

          await API.graphql({
            query: mutation.deleteTrainingProgram,
            variables: {
              input: {
                id: res.id,
              },
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
        }
      });

      console.log("check program2");

      console.log("Response", response);

      setIsTrainingProgramUser(true);
      setUserDetails(response.data);
    } catch (error) {
      console.log("Error fetching training program details:", error);
      throw error;
    }
  };
  const fetchCampDetails = async (userId) => {
    try {
      // const response = await API.graphql(
      //   graphqlOperation(queries.getCamp, {
      //     id: userId,
      //   })
      // );

      const response = await API.graphql({
        query: queries.listCamps,
        variables: {
          filter: {
            userId: {
              eq: userId,
            },
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const campData = response?.data.listCamps.items;
      console.log("CampData", campData);
      if (!campData) {
        navigate("/signupcamp");
        setUserDetails(null);
        return;
      }
      campData?.map(async (res) => {
        if (res.transactionStatus === "FAILED") {
          toast.error("Payment Failed");

          await API.graphql({
            query: mutation.deleteCamp,
            variables: {
              input: {
                id: res.id,
              },
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
          navigate("/signupcamp");
        }
      });

      // Handle camp data as needed
      console.log("Clinic Data:", campData);
      setUserDetails(campData[0]);
    } catch (error) {
      console.log("Error fetching camp details:", error);
      throw error;
    }
  };

  const fetchLink = async () => {
    setLoading(true);

    try {
      const currentPath = window.location.pathname;
      console.log("Current path:", currentPath);
      if (currentPath.includes("clinic")) {
        await fetchClinicDetails(userId);
      } else if (currentPath.includes("camp")) {
        await fetchCampDetails(userId);
      } else {
        await fetchTrainingProgramDetails(userId);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      throw new Error(error);
    }
  };

  // const fetchLink = async () => {
  //   setLoading(true);

  //   try {
  //     const checkoutPayload = {
  //       userId,
  //     };

  //     const checkoutPayload2 = {
  //       body: {
  //         userId: userId,

  //         // document: document,
  //         // profile: profileImage,
  //       },
  //     };
  //     console.log("check program2");

  //     // Send a request to the Stripe API to create a checkout session
  //     const responsePlayer = await API.post(
  //       "signup",
  //       "/signup",
  //       checkoutPayload2
  //     );
  //     // const response = await API.graphql(
  //     //   graphqlOperation(queries.getPlayer, { id })
  //     // );

  //     let data;

  //     const response = await API.graphql(
  //       graphqlOperation(queries.getTrainingProgram, {
  //         id: userId,
  //       })
  //     );

  //     const responseClinic = await API.graphql(
  //       graphqlOperation(queries.getClinic, {
  //         id: userId,
  //       })
  //     );

  //     const dataResponseTraining = response?.data.getTrainingProgram;

  //     if (dataResponseTraining.transactionStatus === "PENDING") {
  //       toast.error("Payment Failed");
  //       navigate("/training-program-details");
  //       return false;
  //     }

  //     console.log("Response", response);

  //     if (!dataResponseTraining) {
  //       setIsTrainingProgramUser(false);
  //       navigate("/signuptrainingprogram");
  //       setUserDetails(null);
  //       return;
  //     } else if (
  //       dataResponseTraining &&
  //       !dataResponseTraining.transactionStatus === "SUCCESS"
  //     ) {
  //       const response = await API.graphql({
  //         query: mutation.deleteTrainingProgram,
  //         variables: {
  //           input: {
  //             id: userId,
  //           },
  //         },
  //         authMode: GRAPHQL_AUTH_MODE.API_KEY,
  //       });
  //       navigate("/signuptrainingprogram");
  //     }

  //     const res = await API.graphql(
  //       graphqlOperation(queries.getTrainingProgramCourse, {
  //         id: dataResponseTraining.courseID,
  //       })
  //     );

  //     const link = res?.data.getTrainingProgramCourse;
  //     setCalendlyLink(link.calendlyLink);
  //     setIsTrainingProgramUser(true);
  //     setLoading(false);

  //     setUserDetails(response.data);
  //   } catch (error) {
  //     setLoading(false);

  //     console.log("error", error);
  //     throw new Error(error);
  //   }
  // };

  const verifyPayment = async () => {
    try {
      const checkoutPayload = {
        body: {
          // success_url: "http://localhost:3000/order-success",
          // cancel_url: "http://localhost:3000/cart",
          userId: userId,
        },
      };
      console.log("checkoutPayload", checkoutPayload);

      // // Send a request to the Stripe API to create a checkout session
      const response = await API.post(
        "handleClinicAndTrainingProgram",
        "/signup",
        checkoutPayload
      );
      await API.post("signupother", "/signup", checkoutPayload);
    } catch (error) {
      console.log("Error Verifying Payment", error);
      throw new Error("Error Verifying Payment");
    }
  };

  useEffect(() => {
    if (userId) {
      fetchLink();
      verifyPayment();
    }
  }, [userId]);

  return { loading, userDetails, isTrainingProgramUser, calendlyLink };
};

export default useFetchMeetLink;

/*
import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import useAuthInfo from "../../hooks/getUser";
import * as queries from "../../graphql/queries";
import * as mutation from "../../graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useFetchMeetLink = () => {
  const { user: userId, isAuthenticated } = useSelector((state) => state.user);

  const [userDetails, setUserDetails] = useState();
  const [calendlyLink, setCalendlyLink] = useState("");
  const navigate = useNavigate();

  const [isTrainingProgramUser, setIsTrainingProgramUser] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchLink = async () => {
    setLoading(true);

    try {
      const checkoutPayload = {
        userId,
      };

      const response = await API.graphql({
        query: queries.listTrainingPrograms,
        variables: {
          filter: {
            userId: {
              eq: userId,
            },
            enable:{
              eq: true,
            },
          },
        },

        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const dataResponseTraining = response?.data.listTrainingPrograms.items;

      dataResponseTraining.map(async (res) => {
        const checkoutPayload2 = {
          body: {
            userId: res.id,
          },
        };
        const responsePlayer = await API.post(
          "signup",
          "/signup",
          checkoutPayload2
        );
        if (dataResponseTraining.transactionStatus === "FAILED") {
          toast.error("Payment Failed");

          await API.graphql({
            query: mutation.deleteTrainingProgram,
            variables: {
              input: {
                id: res.id,
              },
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
        }
      });

      console.log("check program2");

      // Send a request to the Stripe API to create a checkout session

      // const response = await API.graphql(
      //   graphqlOperation(queries.getPlayer, { id })
      // );

      console.log("Response", response);

      // setIsTrainingProgramUser(true);
      setLoading(false);

      setUserDetails(response.data);
    } catch (error) {
      setLoading(false);

      console.log("error", error);
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchLink();
    }
  }, [userId]);

  return { loading, userDetails, isTrainingProgramUser, calendlyLink };
};

export default useFetchMeetLink;

/*/
