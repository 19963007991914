import { API } from 'aws-amplify';

const usePaymentApi = () => {
  const fetchFunction = async () => {
    try {
      // const response = await API.get('paymentApi', '/initiatePayment');
      // console.log(response);
    } catch (error) {
      console.error('Error fetching payment:', error);
    }
  };


  return {
    fetchFunction,  
  };
};

export default usePaymentApi;
