export const CREATE_COUPON_REQUEST = 'CREATE_COUPON_REQUEST';
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS';
export const CREATE_COUPON_FAIL = 'CREATE_COUPON_FAIL';

export const UPDATE_COUPON_REQUEST = 'UPDATE_COUPON_REQUEST';
export const UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS';
export const UPDATE_COUPON_FAIL = 'UPDATE_COUPON_FAIL';

export const GET_COUPON_REQUEST = 'GET_COUPON_REQUEST';
export const GET_COUPON_SUCCESS = 'GET_COUPON_SUCCESS';
export const GET_COUPON_FAIL = 'GET_COUPON_FAIL';

export const LOAD_COUPONS_REQUEST = 'LOAD_COUPONS_REQUEST';
export const LOAD_COUPONS_SUCCESS = 'LOAD_COUPONS_SUCCESS';
export const LOAD_COUPONS_FAIL = 'LOAD_COUPONS_FAIL';

export const LIST_COUPONS_REQUEST = 'LIST_COUPONS_REQUEST';
export const LIST_COUPONS_SUCCESS = 'LIST_COUPONS_SUCCESS';
export const LIST_COUPONS_FAIL = 'LIST_COUPONS_FAIL';

export const DELETE_COUPON_REQUEST = 'DELETE_COUPON_REQUEST';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_FAIL = 'DELETE_COUPON_FAIL';
export const DELETE_COUPON_RESET = 'DELETE_COUPON_RESET';

export const CLEAR_COUPON_ERRORS = 'CLEAR_COUPON_ERRORS';
