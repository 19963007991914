import React from 'react';

const NewsItemSkeleton = () => {
  return (
    <div className="flex mb-5 items-center w-full bg-white shadow-md animate-pulse">
      <div className="w-full md:w-1/2">
        <div className="h-55 md:max-h-[300px] bg-gray-300"></div>
      </div>
      <div className="w-full md:w-1/2 p-4">
        <div className="text-[22px] max-h-[60px] mb-5 bg-gray-300"></div>
        <div className="h-[100px] bg-gray-300"></div>
        <div className="bg-gray-300 h-6 w-24 mt-4"></div>
      </div>
    </div>
  );
};

export default NewsItemSkeleton;
