import React from "react";

const CartSkeleton = () => {
  return (
    <div className="bg-white flex items-start gap-2 rounded-md mb-2 p-4 animate-pulse">
      <div>
        <input type="checkbox" className="cursor-not-allowed ml-1" disabled />
      </div>

      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200 bg-gray-200"></div>

      <div className="flex w-full flex-col px-4">
        <span className="font-bold capitalize bg-gray-200 h-4 w-4/5 mb-2"></span>
        <span className="float-right">
          <span className="line-through mr-3 bg-gray-200 h-4 w-1/3"></span>
          <span className="text-green-500 bg-gray-200 h-4 w-1/4"></span>
        </span>

        <span className="text-green-500 font-bold pb-0 bg-gray-200 h-4 w-1/3"></span>
        <span className="text-yellow-400 pb-0 bg-gray-200 h-4 w-1/4"></span>

        <div className="flex flex-wrap md:flex-row xl:flex-row ">
          <div className="flex flex-col md:flex-row pb-2">
            <div className="h-8 items-stretch bg-gray-100 rounded-md px-2 py-1 flex justify-between">
              <div className="bg-white rounded-full px-4 font-bold w-8 h-8 animate-pulse"></div>
              <div className="bg-white w-10 items-center justify-center px-4 text-xs font-bold animate-pulse"></div>
              <div className="bg-white rounded-full px-4 font-bold w-8 h-8 animate-pulse"></div>
            </div>
            <div className="md:ml-2 bg-gray-100 rounded-md font-bold uppercase text-xs p-2 px-4 text-center md:inline mt-2 md:mt-0 w-full animate-pulse"></div>
          </div>
        </div>

        <div className="flex flex-row justify-between items-center pb-2">
          <div className="bg-gray-100 rounded-md font-bold uppercase text-xs p-1 px-4 text-center md:inline mt-1 md:mt-0 animate-pulse w-2/5"></div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row xl:flex-row md:space-x-4 xl:space-x-4">
        <div className="mb-2 md:mb-0 xl:mb-0 md:flex md:flex-col xl:flex-col animate-pulse">
          <p className="bg-black text-white px-4 capitalize font-bold rounded-lg h-8 w-24"></p>
          <p className="uppercase font-bold text-xs bg-gray-100 rounded-md p-1 px-4 text-center md:inline mt-1 md:mt-0"></p>
        </div>
        <div className="mb-2 md:mb-0 xl:mb-0 md:flex md:flex-col xl:flex-col animate-pulse">
          <p className="bg-black text-white px-4 inline-block font-bold rounded-lg h-8 w-16"></p>
          <p className="uppercase font-bold text-xs bg-gray-100 rounded-md p-1 px-4 text-center md:inline mt-1 md:mt-0"></p>
          <button className="md:ml-2 xl:ml-4">
            {/* Placeholder for share icon */}
          </button>
        </div>
      </div>
    </div>
  );
};

const CartCardSkeleton=()=>{
    return (
        <>
        <CartSkeleton/>
        <CartSkeleton/>
        <CartSkeleton/>
        <CartSkeleton/>

        </>
    )
}


export default CartCardSkeleton;
