import {
  CREATE_PLAYER_AGE_REQUEST,
  CREATE_PLAYER_AGE_SUCCESS,
  CREATE_PLAYER_AGE_FAIL,
  GET_PLAYER_AGE_REQUEST,
  GET_PLAYER_AGE_SUCCESS,
  GET_PLAYER_AGE_FAIL,
  LIST_PLAYER_AGE_REQUEST,
  LIST_PLAYER_AGE_SUCCESS,
  LIST_PLAYER_AGE_FAIL,
  UPDATE_PLAYER_AGE_REQUEST,
  UPDATE_PLAYER_AGE_SUCCESS,
  UPDATE_PLAYER_AGE_FAIL,
  DELETE_PLAYER_AGE_REQUEST,
  DELETE_PLAYER_AGE_SUCCESS,
  DELETE_PLAYER_AGE_FAIL,
  DELETE_PLAYER_AGE_RESET,
  CLEAR_PLAYER_AGE_ERRORS,
} from "../constants/LeagueInfoConstants";

  const initialState = {
    leagueInfo: [],
    cityCount: 0,
    resultPerPage: 0,
    city: {},
    loading: false,
    error: null,
    filteredCityCount: 0,
  };
  
  export const leagueInfoReducer = (state = initialState, action) => {
    switch (action.type) {
      case LIST_PLAYER_AGE_REQUEST:
      case GET_PLAYER_AGE_REQUEST:
        return {
          ...state,
          loading: true,
          

        };
      case LIST_PLAYER_AGE_SUCCESS:
        return {
          loading: false,
          leagueInfo: action.payload,
          // teamCount: action.payload.cityCount,
          // resultPerPage: action.payload.resultPerPage,
          // filteredCityCount: action.payload.filteredCityCount,
        };
  
        case GET_PLAYER_AGE_SUCCESS:
          return {
            loading: false,
            leagueInfo:action.payload

          };
      case GET_PLAYER_AGE_FAIL:
      case LIST_PLAYER_AGE_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_PLAYER_AGE_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  
  
  
  
  
  
  
  // export const newProductReducer = (state = { product: {} }, action) => {
  //   switch (action.type) {
  //     case CREATE_PRODUCT_REQUEST:
  //       return {
  //         ...state,
  //         loading: true,
  //       };
  //     case CREATE_PRODUCT_SUCCESS:
  //       return {
  //         loading: false,
  //         success: action.payload.success,
  //         product: action.payload.product,
  //       };
  //     case CREATE_PRODUCT_FAIL:
  //       return {
  //         ...state,
  //         loading: false,
  //         error: action.payload,
  //       };
  //     // case ADD_PRODUCT_RESET:
  //     //   return {
  //     //     ...state,
  //     //     success: false,
  //     //   };
  //     case CLEAR_PRODUCT_ERRORS:
  //       return {
  //         ...state,
  //         error: null,
  //       };
  //     default:
  //       return state;
  //   }
  // };
  
  
  
  // export const productDetailsReducer = (state = { product: {} }, action) => {
  //   switch (action.type) {
  //     case GET_PRODUCT_REQUEST:
  //       return {
  //         loading: true,
  //         ...state,
  //       };
  //     case GET_PRODUCT_SUCCESS:
  //       return {
  //         loading: false,
  //         product: action.payload,
  //       };
  //     case GET_PRODUCT_FAIL:
  //       return {
  //         loading: false,
  //         error: action.payload,
  //       };
  
  //     case CLEAR_PRODUCT_ERRORS:
  //       return {
  //         ...state,
  //         error: null,
  //       };
  //     default:
  //       return state;
  //   }
  // };
  
 

  

  
  
  // export const productReducer = (state = {}, action) => {
  //   switch (action.type) {
  //     case DELETE_PRODUCT_REQUEST:
  //     case UPDATE_PRODUCT_REQUEST:
  //       return {
  //         ...state,
  //         loading: true,
  //       };
  //     case DELETE_PRODUCT_SUCCESS:
  //       return {
  //         ...state,
  //         loading: false,
  //         isDeleted: action.payload,
  //       };
  
  //     case UPDATE_PRODUCT_SUCCESS:
  //       return {
  //         ...state,
  //         loading: false,
  //         isUpdated: action.payload,
  //       };
  //     case DELETE_PRODUCT_FAIL:
  //     case UPDATE_PRODUCT_FAIL:
  //       return {
  //         ...state,
  //         loading: false,
  //         error: action.payload,
  //       };
  //     case DELETE_PRODUCT_RESET:
  //       return {
  //         ...state,
  //         isDeleted: false,
  //       };
  //     // case UPDATE_PRODUCT_RESET:
  //     //   return {
  //     //     ...state,
  //     //     isUpdated: false,
  //     //   };
  //     case CLEAR_PRODUCT_ERRORS:
  //       return {
  //         ...state,
  //         error: null,
  //       };
  //     default:
  //       return state;
  //   }
  // };