import React, { useState, useEffect } from "react";
import OrderCard from "./OrderCard";

const OrderDetailsCard = ({ ordersList }) => {
  useEffect(() => {
    console.log("orderList", ordersList);
  }, [ordersList]);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-semibold mb-6">Orders</h1>
      <div className="grid grid-cols-1  gap-4">
        {ordersList?.map((order) => (
          <OrderCard order={order} key={order?.id}/>
        ))}
      </div>
    </div>
  );
};

export default OrderDetailsCard;
