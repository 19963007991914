import { API, Auth, graphqlOperation } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import * as mutations from "../graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import useAuthInfo from "./getUser";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useSignUpAsVolunteer = () => {
  const { isAuthenticated, userId } = useAuthInfo(); // Use your user hook to get the userId
  const [pdfLink, setPdfLink] = useState(""); // Correct syntax for destructuring
  const [loading, setLoading] = useState(false); // Correct syntax for destructuring
  const [success, setSuccess] = useState(null); // Correct syntax for destructuring
  const [error, setError] = useState(null); // Use your user hook to get the userId
  const navigate = useNavigate();

  const registerVolunteerFunc = async (signUpData) => {
    try {
      setLoading(true);
      if (isAuthenticated) {
        // const checkoutPayload = {
        //   body: {
        //     // success_url: "http://localhost:3000/order-success",
        //     // cancel_url: "http://localhost:3000/cart",
        //     success_url: "https://www.sfbayareabasketball.com/payment-success",
        //     cancel_url: "https://www.sfbayareabasketball.com/payment-failure",

        //     ...signUpData,

        //     // document: document,
        //     // profile: profileImage,
        //   },
        // };
        // console.log("check program2");

        // // Send a request to the Stripe API to create a checkout session
        // const response = await API.post(
        //   "signup",
        //   "/signup/volunteer",
        //   checkoutPayload
        // );

        // window.location.href = response?.url;

        const response = await API.graphql({
          query: mutations.createVolunteer,
          variables: {
            input: { userId, ...signUpData },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        })
          .then((response) => {
            // Add the arrow function here

            setLoading(false);
            setSuccess(true);
            if (response?.data?.createVolunteer) {
              setLoading(false);
              toast.success("Registered Successfully!");
              navigate("/");
              return true;
            } else {
              setLoading(false);
              toast.error("Something went wrong try after sometime");
              toast.error("Failed to create signup league.");
              return false;
            }
          })

          .catch((error) => {
            setLoading(false);
            console.log("Sign up volunteer response - ", error);

            setError(error);
            setSuccess(false);

            console.log("Sign up error - ", error);
            console.log("Sign up error code - ", error?.code);
            console.log("Sign up error msg - ", error?.message);
            return false;
          });
      }
    } catch (error) {
      console.log("not Logged in ");
      setLoading(false);
      setError(error);
      setSuccess(false);
    }
  };

  return { registerVolunteerFunc, error, loading, success };
};

export default useSignUpAsVolunteer;
