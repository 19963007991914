import React, { useEffect, useState } from "react";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameDay,
  parseISO,
  isBefore,
  startOfWeek,
  endOfWeek,
  addDays,
} from "date-fns";
import {
  getTutorDetails,
  getUserFromAppointmentId,
  useHandleMeet,
} from "./processAvailableSlots";

const AppointmentCalendar = ({ appointment, show = false }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const { loading: meetLoading, joinMeet } = useHandleMeet();
  const [selectedDate, setSelectedDate] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [tutorDetailsMap, setTutorDetailsMap] = useState({});
  const [userMeets, setUserMeets] = useState([]);
  const [loading, setLoading] = useState([]);

  const today = new Date();

  useEffect(() => {
    const fetchTutorDetails = async () => {
      setLoading(true);
      const detailsMap = {};
      for (const schedule of appointment.schedule.items) {
        const details = await getTutorDetails(schedule.tutorId);
        detailsMap[schedule.tutorId] = details;
      }
      setTutorDetailsMap(detailsMap);
      setLoading(false);
    };

    const fetchUserMeets = async () => {
      setLoading(true);

      const meets = await getUserFromAppointmentId(appointment.id);
      setUserMeets(meets);
      setLoading(false);
    };

    fetchTutorDetails();
    fetchUserMeets();
  }, [appointment.schedule.items, appointment.id]);

  const getDaysInMonth = (date) => {
    const start = startOfWeek(startOfMonth(date), { weekStartsOn: 1 });
    const end = endOfWeek(endOfMonth(date), { weekStartsOn: 1 });
    return eachDayOfInterval({ start, end }).filter(
      (day) => day.getMonth() === date.getMonth()
    );
  };

  const daysInMonth = getDaysInMonth(currentMonth);

  const getAvailableSlots = (date) => {
    let availableSlots = [];

    appointment.schedule.items.forEach((schedule) => {
      const slotsForSchedule = getAvailableSlotsForSchedule(date, schedule);
      if (slotsForSchedule.length > 0) {
        availableSlots = [...availableSlots, ...slotsForSchedule];
      }
    });

    return availableSlots;
  };

  const isDateAvailable = (date, schedule) => {
    if (schedule.daily === true) return true;

    const isIncluded = schedule.dates.some((d) => isSameDay(parseISO(d), date));

    const currentDay = getDayFromDate(date); // Get the current day of the week (e.g., "Monday")
    const isDayIncluded = schedule.days?.includes(currentDay);

    console.log("ISDaYINcluded", currentDay, isDayIncluded);
    return isIncluded || isDayIncluded;
  };
  const getAvailableSlotsForSchedule = (date, schedule) => {
    if (!isDateAvailable(date, schedule)) return [];

    let availableSlots = [...schedule.slots];

    const excludedSlotsForDate = schedule.excludeSlots?.find((es) =>
      isSameDay(parseISO(es.date), date)
    );
    if (excludedSlotsForDate) {
      availableSlots = availableSlots.filter(
        (slot) =>
          !excludedSlotsForDate.slots.some(
            (excludedSlot) =>
              slot.startTime === excludedSlot.startTime &&
              slot.endTime === excludedSlot.endTime
          )
      );
    }

    // Filter out slots that have reached maximum capacity
    availableSlots = availableSlots.filter((slot) => {
      const registeredUsers = userMeets.filter(
        (meet) =>
          isSameDay(parseISO(meet.date), date) &&
          meet.slot.startTime === slot.startTime &&
          meet.slot.endTime === slot.endTime &&
          meet.appointmentScheduleId === schedule.id
      );
      return registeredUsers.length < appointment.maxStudents;
    });

    return availableSlots.map((slot) => ({
      ...slot,
      tutorId: schedule.tutorId,
    }));
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    setDialogOpen(true);
  };

  const joinMeetFun = async (dataToSend) => {
    const data = {
      courseId: appointment.courseId,
      date: dataToSend.date,
      appointmentId: appointment.id,
      id: dataToSend.id,
      slot: dataToSend.slot,
    };
    console.log("JoinData", data, dataToSend);
    await joinMeet(data);
  };

  if (loading || meetLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-5 font-sans ">
      <div className="mb-5 border border-gray-300 p-3 rounded">
        <h2 className="text-xl font-bold mb-2">{appointment.name}</h2>
        <p>{appointment.description}</p>
        <p>Duration: {appointment.duration} minutes</p>
        <p>Max Students: {appointment.maxStudents}</p>
        <p>Price: ${appointment.price}</p>
      </div>

      <div className="flex justify-center items-center mb-5">
        <h2 className="text-2xl font-bold">
          {format(currentMonth, "MMMM yyyy")}
        </h2>
      </div>

      <div className="grid grid-cols-7 gap-2">
        {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
          <div key={day} className="text-center font-bold">
            {day}
          </div>
        ))}
        {daysInMonth.map((day) => {
          const availableSlots = getAvailableSlots(day);
          const isDisabled =
            isBefore(day, today) || availableSlots.length === 0;
          const isCurrentMonth = day.getMonth() === currentMonth.getMonth();
          return (
            <button
              key={day.toISOString()}
              onClick={() => handleDateClick(day)}
              disabled={isDisabled}
              className={`
                h-12 w-12 rounded-full border
                flex flex-col items-center justify-center
                ${
                  isDisabled
                    ? "opacity-50 cursor-not-allowed"
                    : "cursor-pointer"
                }
                ${isSameDay(day, today) ? "bg-blue-100" : ""}
                ${
                  availableSlots.length > 0 && !isDisabled ? "bg-green-100" : ""
                }
                ${!isCurrentMonth ? "text-gray-400" : ""}
                hover:bg-gray-200
              `}
            >
              <span className="text-sm">{format(day, "d")}</span>
              {!isDisabled && (
                <span className="text-xs">{availableSlots.length}</span>
              )}
            </button>
          );
        })}
      </div>
      {dialogOpen && (
        <ImprovedAppointmentModal
          selectedDate={selectedDate}
          appointment={appointment}
          tutorDetailsMap={tutorDetailsMap}
          userMeets={userMeets}
          onClose={() => setDialogOpen(false)}
          joinMeetFun={joinMeetFun}
          show={show}
          getAvailableSlotsForSchedule={getAvailableSlotsForSchedule}
        />
      )}
    </div>
  );
};

const ImprovedAppointmentModal = ({
  selectedDate,
  appointment,
  tutorDetailsMap,
  userMeets,
  onClose,
  joinMeetFun,
  show,
  getAvailableSlotsForSchedule,
}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-2xl font-semibold text-gray-800">
            Available Slots for {format(selectedDate, "MMMM d, yyyy")}
          </h2>
        </div>
        <div className="p-6 space-y-6">
          {appointment.schedule.items.map((schedule) => {
            const availableSlots = getAvailableSlotsForSchedule(
              selectedDate,
              schedule
            );
            if (availableSlots.length === 0) return null;

            const tutorDetails = tutorDetailsMap[schedule.tutorId];

            return (
              <div
                key={schedule.id}
                className="bg-gray-50 rounded-lg p-4 shadow"
              >
                <div className="flex items-center space-x-4 mb-4">
                  {tutorDetails && tutorDetails?.profile && (
                    <img
                      src={tutorDetails.profile}
                      alt={tutorDetails.name}
                      className="w-12 h-12 rounded-full object-cover border-2 border-blue-500"
                    />
                  )}
                  <h3 className="text-lg font-medium text-gray-900">
                    Tutor: {tutorDetails && tutorDetails?.name}
                  </h3>
                </div>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                  {availableSlots.map((slot, index) => {
                    const registeredUsers = userMeets.filter(
                      (meet) =>
                        isSameDay(parseISO(meet.date), selectedDate) &&
                        meet.slot.startTime === slot.startTime &&
                        meet.slot.endTime === slot.endTime &&
                        meet.appointmentScheduleId === schedule.id
                    );
                    return (
                      <div
                        key={index}
                        className="flex flex-col items-center bg-white p-3 rounded-md shadow"
                      >
                        <p className="text-sm font-medium text-gray-600 mb-2">
                          {format(parseISO(slot.startTime), "HH:mm")} -{" "}
                          {format(parseISO(slot.endTime), "HH:mm")}
                        </p>
                        {show && (
                          <button
                            className="w-full py-2 px-4 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50"
                            onClick={() => {
                              joinMeetFun({
                                date: selectedDate,
                                id: schedule.id,
                                slot: slot,
                              });
                            }}
                          >
                            Join
                          </button>
                        )}
                        {/* <p className="text-xs text-gray-500 mt-1">
                          {registeredUsers.length}/{appointment.maxStudents}{" "}
                          registered
                        </p> */}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <div className="px-6 py-4 border-t border-gray-200 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppointmentCalendar;
function getDayFromDate(dateString) {
  const date = new Date(dateString);
  const options = { weekday: "short" }; // Specify 'short' for abbreviated weekday name
  return date.toLocaleDateString("en-US", options);
}
