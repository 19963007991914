import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import getLink from "../utils/getLinks";

const useGetAllTournamentTeamsPlayer = () => {
  const [teamslist, setTeamsList] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchAllTeams = async () => {
    try {
      setLoading(true);

      const responseTeams = await API.graphql({
        query: queries.listTournamentTeams,
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const responsePlayers = await API.graphql({
        query: queries.listTournaments,
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const teams = responseTeams?.data?.listTournamentTeams?.items;
      const players = responsePlayers?.data?.listTournaments?.items;

      const updatedTeams = teams.map(team => {
        const teamPlayers = players.filter(player => player.teamName === team.name);
        return { ...team, players: teamPlayers };
      });

      setTeamsList(updatedTeams);
      setLoading(false);
      return updatedTeams;
    } catch (error) {
      setLoading(false);
      console.error("Error fetching teams:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchAllTeams();
  }, []);

  return { teamslist, loading };
};

export default useGetAllTournamentTeamsPlayer;
