import React, { useEffect, useState } from "react";
import { PiShoppingCartSimpleBold } from "react-icons/pi";
import LazyLoad from "react-lazyload";
import ReactStars from "react-rating-stars-component";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addToCart, getCart } from "../../redux/actions/cartAction";
import { BiStar } from "react-icons/bi";
import { BsFillStarFill } from "react-icons/bs";


const ProductCard = ({ item,setIsUpdated }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log("Item", item);

  const { isAuthenticated, user } = useSelector((state) => state?.user);
  const { cartItems, loading } = useSelector((state) => state?.cart);
  const AddToCart = async (item) => {
    if (isAuthenticated) {
      if(setIsUpdated){
        setIsUpdated(true);

      }

      try {
        const data = {
          title: item?.title,
          selling_price: item?.selling_price,
          original_price: item?.original_price,
          rating: item?.rating,
          itemId: item?.id,
          img_url: item?.img_url,
          tags: item?.tags,
          stock_qty: item?.stock_qty,
          // category: "Sport",
          quantity: 1,
          finalPrice: item?.selling_price,
        };

        console.log("Item", item);

        // await addItemToCart(item.id);
        dispatch(addToCart(user, data, cartItems));
        if(setIsUpdated){
          setIsUpdated(false);

        }
        // Check if this is an action creator that fetches news
        dispatch(getCart(user));
        // Check if this is an action creator that fetches new
        toast.success("Added-to-cart");
      } catch (error) {
        toast.error("Failed to add item to cart.");
      }
    } else {
      toast.error("Login to Add to cart.");
      navigate("/login");
    }
  };

  const [isHovered, setIsHovered] = React.useState(false);
  const createdAt = item?.createdAt;
  const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

  const isNew = () => {
    if (!createdAt) return false;

    const currentTime = new Date().getTime();
    const difference = currentTime - new Date(createdAt).getTime();

    return difference < ONE_DAY_IN_MS;
  };
  const formatNumber = (value) => {
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      return parsedValue.toFixed(2);
    }
    return value;
  };
  

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  return (
    <div
      className={`bg-white cursor-pointer min-h-[350px] max-h-[500px] w-[14rem]  rounded-lg overflow shadow-lg m-4 relative ${
        isHovered
          ? "transform -translate-y-3 ease-in-out transition-all duration-500"
          : "transform ease-in-out transition-all duration-500"
      }`}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      {isNew() && (
        <div className="absolute bg-red-500  text-white w-[75px] p-[10px] z-[1] top-[-10px] left-[-10px]">
          NEW
        </div>
      )}

      <div className="relative">
        {/* <LazyLoad height={200} offset={100}  > */}
          <img
            className="w-full h-48 object-contain"
            src={item?.render_img_url}
            alt={item?.title}
          />
        {/* </LazyLoad> */}
        <div
          onClick={() => AddToCart(item)}
          className="absolute top-2 right-3 bg-gray-300 rounded-full p-2 cursor-pointer"
        >
          <PiShoppingCartSimpleBold size={25} />
        </div>
      </div>
      <div className="p-4 flex flex-col gap-2 items">
        <span className="text-black font-bold text-1xl mb-2">
          {item.title.length > 20
            ? `${item.title.substring(0, 20)}...`
            : item.title}
        </span>
        {/* You can add more details or buttons here */}
      </div>
      <div className="flex flex-wrap absolute p-1 bottom-7 w-full justify-between gap-1 items-center ">
        <div className="flex gap-1 items-center mb-2 overflow-hidden">
        <span className="text-gray-400 opacity-500 line-through">
            $ {formatNumber(item.original_price)}
          </span>
          <span className="text-red-400">
            $ {formatNumber(item?.selling_price)}
          </span>
        </div>
        <div className="flex text-black items-center mb-2">
          ({item?.rating||0})
          <BsFillStarFill color="#ffd700" />
          {/* <ReactStars
            count={5}
            value={item.rating}
            size={20}
            edit={false}
            isHalf={true}
            activeColor="#ffd700"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
