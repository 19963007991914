import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import { useParams } from "react-router-dom";
import { allImage, getNonMatchingValues, replaceUrl, updateImageSrcInHtml, getKey } from '../utils/replaceUrl';
import getLink from "../utils/getLinks";

const useGetAllCamps = () => {
  const [camps, setCamps] = useState([]);
  const [campsDetails, setCampsDetails] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const getCamps = async () => {
    try {
      setLoading(true);
      const response = await API.graphql({
        query: queries.listCampCards,
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      if (!response || !response.data || !response.data.listCampCards || !response.data.listCampCards.items) {
        throw new Error("No data found");
      }

      const sortedCamps = response.data.listCampCards.items.sort((a, b) => a.order - b.order);

      const updatedCamps = await Promise.all(
        sortedCamps.map(async (camp) => {
          const descriptionWithUpdatedImageURL = await updateImageSrcInHtml(camp.description, getLink, getKey);
          camp.description = descriptionWithUpdatedImageURL;
          return camp;
        })
      );

      setCamps(updatedCamps);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching camps:', error);
    }
  };

  const getCampsDetail = async () => {
    try {
      setLoading(true);
      const response = await API.graphql(
        graphqlOperation(queries.getCampCard, { id })
      );
      if (!response || !response.data || !response.data.getCampCard) {
        throw new Error("No data found");
      }

      const campDetails = response.data.getCampCard;
      const descriptionWithUpdatedImageURL = await updateImageSrcInHtml(campDetails.description, getLink, getKey);
      campDetails.description = descriptionWithUpdatedImageURL;

      setCampsDetails(campDetails);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching camp details:', error);
    }
  };

  useEffect(() => {
    if (id) {
      getCampsDetail();
    }
    getCamps();
  }, [id]);

  return { camps, loading, campsDetails };
};

export default useGetAllCamps;
