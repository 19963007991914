export const REGISTER_CLINIC_REQUEST = 'REGISTER_CLINIC_REQUEST';
export const REGISTER_CLINIC_SUCCESS = 'REGISTER_CLINIC_SUCCESS';
export const REGISTER_CLINIC_FAIL = 'REGISTER_CLINIC_FAIL';

export const UPDATE_CLINIC_REQUEST = 'UPDATE_CLINIC_REQUEST';
export const UPDATE_CLINIC_SUCCESS = 'UPDATE_CLINIC_SUCCESS';
export const UPDATE_CLINIC_FAIL = 'UPDATE_CLINIC_FAIL';

export const GET_CLINIC_REQUEST = 'GET_CLINIC_REQUEST';
export const GET_CLINIC_SUCCESS = 'GET_CLINIC_SUCCESS';
export const GET_CLINIC_FAIL = 'GET_CLINIC_FAIL';

export const LOAD_CLINICS_REQUEST = 'LOAD_CLINICS_REQUEST';
export const LOAD_CLINICS_SUCCESS = 'LOAD_CLINICS_SUCCESS';
export const LOAD_CLINICS_FAIL = 'LOAD_CLINICS_FAIL';

export const DELETE_CLINIC_REQUEST = 'DELETE_CLINIC_REQUEST';
export const DELETE_CLINIC_SUCCESS = 'DELETE_CLINIC_SUCCESS';
export const DELETE_CLINIC_FAIL = 'DELETE_CLINIC_FAIL';
export const DELETE_CLINIC_RESET = 'DELETE_CLINIC_RESET';

export const CLEAR_CLINIC_ERRORS = 'CLEAR_CLINIC_ERRORS';
