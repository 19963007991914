import { IoHandRightOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";

const Workwith = ({ title, button, link }) => {
  return (
    <div className="text-center text-black bg-white flex flex-col items-center justify-center">
      <div className="font-bold text-sm uppercase mt-8 mb-4">{title}</div>
      <IoHandRightOutline className="text-6xl" />
      <div className="text-center my-10">
        <span className="py-4 whitespace-nowrap inline-block w-full">
          <NavLink to={link} className="w-full bg-yellow-300 px-20 py-3">
            {button}
          </NavLink>
        </span>
      </div>
    </div>
  );
};

export default Workwith;
