import {
  REGISTER_TRAININGPROGRAM_REQUEST,
  REGISTER_TRAININGPROGRAM_SUCCESS,
  REGISTER_TRAININGPROGRAM_FAIL,
  UPDATE_TRAININGPROGRAM_REQUEST,
  UPDATE_TRAININGPROGRAM_SUCCESS,
  UPDATE_TRAININGPROGRAM_FAIL,
  GET_TRAININGPROGRAM_REQUEST,
  GET_TRAININGPROGRAM_SUCCESS,
  GET_TRAININGPROGRAM_FAIL,
  LOAD_TRAININGPROGRAMS_REQUEST,
  LOAD_TRAININGPROGRAMS_SUCCESS,
  LOAD_TRAININGPROGRAMS_FAIL,
  DELETE_TRAININGPROGRAM_REQUEST,
  DELETE_TRAININGPROGRAM_SUCCESS,
  DELETE_TRAININGPROGRAM_FAIL,
  DELETE_TRAININGPROGRAM_RESET,
  CLEAR_TRAININGPROGRAM_ERRORS,
} from "../constants/TrainingProgramConstants";

const initialState = {
  trainingPrograms: [],
  trainingProgramDetails: {},
  created:false,
  score: {},
  team: {},
  loading: false,
  error: null,
};

export const trainingProgramReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRAININGPROGRAMS_REQUEST:
    case GET_TRAININGPROGRAM_REQUEST:
      case REGISTER_TRAININGPROGRAM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TRAININGPROGRAM_SUCCESS:
      return {
        ...state,
        loading: false,
        trainingProgramDetails: action.payload.trainingProgram,
        team: action.payload.team,
        score: action.payload.score,
      };

      case REGISTER_TRAININGPROGRAM_SUCCESS:
        return {
          ...state,
          loading: false,
          trainingProgramDetails: action.payload,
          created:true
        };
    case LOAD_TRAININGPROGRAMS_SUCCESS:
      return {
          ...state,
        loading: false,
        trainingPrograms: action.payload,
      };
    case GET_TRAININGPROGRAM_FAIL:
    case LOAD_TRAININGPROGRAMS_FAIL:
      case REGISTER_TRAININGPROGRAM_FAIL:

      return {
          ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_TRAININGPROGRAM_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
