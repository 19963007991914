import TypoBu from "../TypoBu";

import { Link, useNavigate } from "react-router-dom";
import LtNewsSkeleton from "../Skeleton/LtNewsSkeleton";
import LtNewsCard from "./LtNewsCard";
import useHandleNews from "../../hooks/handleNews";
import AdvertisementNews from "../Advertisement/NewsAdvertisement";
import { useState } from "react";
const LtNews = ({ type }) => {
  const { news, loading } = useHandleNews();
  const navigate = useNavigate();
  const [videos, setVideos] = useState([{}]);
  // const [news, setNews] = useState([]);

  //
  const viewMore = () => navigate("/news/all");

  // Inside your component:

  // useEffect(() => {
  //   console.log('news',news)
  //   // getVideos(curPage);
  // }, []);

  return (
    <div className="flex flex-col justify-between gap-10">
      <TypoBu
        item={{
          text: "latest",
          text_ssize: "text-3xl",
          text_bsize: "text-4xl",
          border: true,
          button: false,
        }}
      />
      {loading ? (
        <>
          <LtNewsSkeleton />
          <LtNewsSkeleton />
          <LtNewsSkeleton />
          <LtNewsSkeleton />
          <LtNewsSkeleton />
        </>
      ) : (
        <>
          {news?.slice(0, 12)?.map((item, index) => (
            <div key={item?.id}>
              <LtNewsCard item={item} />
              {(index + 1) % 3 === 0 && (
                <div className="mt-10 mb-10 w-full h-full">
                  <AdvertisementNews length={news?.length} one index={Math.floor((index + 1) / 3)} />
                </div>
              )}
            </div>
          ))}
        </>
      )}
      <div className="text-center mb-10" onClick={viewMore}>
        <button className=" border-2 border-black px-10">VIEW MORE</button>
      </div>
    </div>
  );
};

export default LtNews;
