import { API, Auth, Storage, graphqlOperation } from "aws-amplify";
import { useNavigate } from "react-router-dom";

import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";

import { toast } from "react-toastify";

import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import useAuthInfo from "./getUser";
import { useState } from "react";
import uploadFile from "../utils/uploadFile";

const useSignUpAsPartner = () => {
  const { isAuthenticated, userId } = useAuthInfo(); // Use your user hook to get the userId
  const [loading, setLoading] = useState(""); // Use your user hook to get the userId
  const navigate = useNavigate();
  console.log("userId", userId);
  const signUpPartnerFunc = async (signUpData) => {
    try {
      if (isAuthenticated) {
        setLoading(true);

        const data = {
          userId,
          // sessionId: signUpData.sessionsIds[0],
          name: signUpData.name,
          message: signUpData.message,
          email: signUpData.email,
          reason: signUpData.reason,
          organization: signUpData.organization,
          number: signUpData.number,
        };
        console.log("data sugnuppartner", data);

        const checkoutPayload = {
          body: {
            ...signUpData,

            // document: document,
            // profile: profileImage,
          },
        };
        console.log("check program2");

        // Send a request to the Stripe API to create a checkout session
        const response = await API.post(
          "handleWorkWithUsAndPartnerApiForSfbayarea",
          "/signup/partner",
          checkoutPayload
        );

        // const resume=await uploadFile(signUpData.resume);
        // const response = await API.graphql({
        //   query: mutations.createPartner,
        //   variables: {
        //     input: { userId, ...signUpData,resume },
        //   },
        //   authMode: GRAPHQL_AUTH_MODE.API_KEY,
        // })
        //   .then((response) => {
        //     setLoading(false);
        //     // Add the arrow function here
        toast.success("Registered successfully!");

        navigate("/");

        //     console.log("response", response?.data?.createPartner?.Items);
        //     navigate("/");
        //     // addLink(pdf);
        //     return true;
        //   })

        //   .catch((error) => {
        setLoading(false);
        //     console.log("Sign up error - ", error);
        //     console.log("Sign up error code - ", error?.code);
        //     console.log("Sign up error msg - ", error?.message);
        //     return false;
        //   });
      } else {
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      console.log("not Logged in ", error);
      return false;
    }
  };

  return { signUpPartnerFunc, loading };
};

export default useSignUpAsPartner;
