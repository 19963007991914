import React from 'react';

const VlogItemSkeleton = () => {
  return (
    <div className="bg-white shadow-lg animate-pulse">
      <div className="w-full h-40 bg-gray-400"></div>
      <div className="uppercase text-sm mt-4 px-4 font-bold bg-gray-400 h-6 animate-pulse"></div>
      <div className="text-sm px-4 mt-4 h-[100px] bg-gray-400 animate-pulse"></div>
    </div>
  );
};

export default VlogItemSkeleton;
