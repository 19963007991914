import { useEffect, useState } from "react";
import Loader from "../Loader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addToCart, getCart } from "../../redux/actions/cartAction";
import { clearProductErrors } from "../../redux/actions/ProductAction";
import ProductCard from "../Product/ProductCard";

const SqShop = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state?.user);
  const { products, loading, error } = useSelector((state) => state?.products);
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const itemsPerPage = 8;
  const startIndex = page * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(products?.length / itemsPerPage);
  const {
    cart,
    cartItems,
    loading: cartLoading,
    added,
  } = useSelector((state) => state?.cart);
  useEffect(() => {}, [added]);
  const [updatedProduct, setProduct] = useState([]);

  const [imageLoading, setImageLoading] = useState(false);

  const AddToCart = async (item) => {
    if (isAuthenticated) {
      try {
        const data = {
          title: item?.title,
          selling_price: item?.selling_price,
          original_price: item?.original_price,
          rating: item?.rating,
          itemId: item?.id,
          img_url: item?.img_url,
          tags: item?.tags,
          stock_qty: item?.stock_qty,
          // category: "Sport",
          quantity: 1,
          finalPrice: item?.selling_price,
        };

        console.log("Item", item);
        console.log("user", user);
        // await addItemToCart(item.id);
        dispatch(addToCart(user, data, cartItems));

        // Check if this is an action creator that fetches news
        dispatch(getCart(user));
        // Check if this is an action creator that fetches new
        toast.success("Added-to-cart");
      } catch (error) {
        toast.error("Failed to add item to cart.");
      }
    } else {
      toast.error("Login to Add to cart.");
      navigate("/login");
    }
  };

  const renderStarRating = (rating) => {
    const fullStars = Math.floor(rating);

    // Array to hold the JSX elements for the star ratings
    const stars = [];

    // Add full star emojis
    for (let i = 0; i < fullStars; i++) {
      stars.push(<span key={i}>⭐</span>);
    }

    return stars;
  };

  useEffect(() => {
    if (error) {
      dispatch(clearProductErrors());
    }

    const filteredData = products?.slice(startIndex, endIndex);
    setFilterData(filteredData);
  }, [page, products, dispatch, error, startIndex, endIndex]);

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage.selected);
  };

  if (loading || cartLoading) {
    return <loading />;
  }

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="bg-[#181B22] pt-10 pb-20 rounded-md text-center flex-col px-4">
      <span className=" text-white">SHOP</span>
      {/* <div className=" grid grid-cols-2 w-full lg:grid-cols-1  gap-2 mt-4"> */}

      <div className="flex flex-wrap justify-center">
        {products?.length > 0 &&
          products
            ?.slice(0, 4)
            ?.map((item, index) => (
              <ProductCard item={item} key={item?.id} AddToCart={AddToCart} />
            ))}
      </div>
    </div>
  );
};

export default SqShop;
