import { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import { createAddress as createAddressQuery } from "../graphql/mutations";
import * as queries from "../graphql/queries";

import { getAddress } from "../graphql/queries";

import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

const useCreateAddress = () => {
  const [loading, setLoading] = useState(false);

  const createAddress = async (id) => {
    try {
      setLoading(true);

      const isAlreadyCreated = await API.graphql({
        query: getAddress,
        variables: {
          id,
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      console.log("IsCrated", isAlreadyCreated);
      if (isAlreadyCreated?.data?.getAddress === null) {
        // If getAddress is null, create the address using the API
        const response = await API.graphql({
          query: mutations.createAddress,
          variables: {
            input: {
              id: id,
              userid: id,
              addresses: [],
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        // Address creation was successful
        setLoading(false);
        return true;
      } else {
        // Address already exists or an error occurred
        setLoading(false);
        return;
      }
    } catch (error) {
      console.error("Error creating Address:", error);
      setLoading(false);
      return false;
    }
  };

  return { createAddress, loading };
};

export default useCreateAddress;
