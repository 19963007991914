import { API, Auth, Storage, graphqlOperation } from "aws-amplify";
import { useNavigate } from "react-router-dom";

import * as mutations from "../graphql/mutations";
import { toast } from "react-toastify";

import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import useAuthInfo from "./getUser";
import { useState } from "react";
import uploadFile from "../utils/uploadFile";

const useSignUpAsWorkWithUs = () => {
  const { isAuthenticated, userId } = useAuthInfo(); // Use your user hook to get the userId
  const [loading, setLoading] = useState(""); // Use your user hook to get the userId
  const [pdfLink, setPdfLink] = useState(""); // Use your user hook to get the userId
  const [profileLink, setProfileLink] = useState(""); // Use your user hook to get the userId
const navigate = useNavigate();


  const signUpWorkWithUsFunc = async (signUpData) => {
    try {
      setLoading(true);
      if (isAuthenticated) {
      
        // const checkoutPayload = {
        //   body: {
        //     // success_url: "http://localhost:3000/order-success",
        //     // cancel_url: "http://localhost:3000/cart",
        //     success_url: "https://www.sfbayareabasketball.com/payment-success",
        //     cancel_url: "https://www.sfbayareabasketball.com/payment-failure",
  
        //     ...signUpData,
      
  
        //     // document: document,
        //     // profile: profileImage,
        //   },
        // };
        // console.log("check program2");
  
        // // Send a request to the Stripe API to create a checkout session
        // const response = await API.post(
        //   "signup",
        //   "/signup/workWithUs",
        //   checkoutPayload
        // );
  
        // window.location.href = response?.url;

        const resume=await uploadFile(signUpData.resume);
        signUpData.resume = resume;
        const response = await API.graphql({
          query: mutations.createWorkWithUs,
          variables: {
            input: {...signUpData },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        })
          .then((response) => {
            setLoading(false);
            if (response?.data?.createWorkWithUs) {
              setLoading(false);
                toast.success("Registered Successfully!");
                navigate("/");
                return true;
              
            } else {
              setLoading(false);
              toast.error("Something went wrong try after sometime");
              toast.error("Failed to create signup league.");
              return false;
            }
          })

          .catch((error) => {
            setLoading(false);
            console.log("Sign up error - ", error);
            console.log("Sign up error code - ", error?.code);
            console.log("Sign up error msg - ", error?.message);
            return false;
          });
          
      }
    } catch (error) {
      setLoading(false);
      console.log("not Logged in ");
      return false;
    }
  };
  
 

  return { signUpWorkWithUsFunc,loading };
};

export default useSignUpAsWorkWithUs;
