import React, { useState, useEffect } from "react";
import { BsFillShareFill } from "react-icons/bs";
import useUpdateCart from "../../hooks/updateCart";
import { useDispatch, useSelector } from "react-redux";

import { getCart, updateCartQuantity } from "../../redux/actions/cartAction";

const CartCard = ({
  item,
  index,
  selectedItems,
  setIsUpdated,
  toggleItemSelection,
  updateCartItemById
}) => {
  const dispatch = useDispatch();
  const [cartValue, setCartValue] = useState(item?.quantity); // State to store user ID
  const [imageLink, setLink] = useState("");


  

  const increaseCartValue = () => {
    // update(item?.id, cartValue - 1);
    // if (cartValue<item?.stock_qty) {

    setCartValue(cartValue + 1);
    const updatedQuantity = cartValue + 1;
    const data = {
      ...item,
      quantity: updatedQuantity,
      finalPrice: item?.finalPrice * cartValue.quantity + 1,
    };
    updateCartItemById(data?.id,data)
    // dispatch(updateCartQuantity(item?.id, data));
    // setCartValue(updatedQuantity);
    // dispatch(getCart(item?.userid));
    // setIsUpdated(true);

    // }
  };
  const decreaseCartValue = () => {
    if (cartValue > 1) {
      setIsUpdated(true);
      const updatedQuantity = cartValue - 1;
      const data = {
        ...item,
        quantity: updatedQuantity,
        finalPrice: item?.selling_price * cartValue.quantity - 1,
      };
      dispatch(updateCartQuantity(item?.id, data));
      setCartValue(updatedQuantity);

      dispatch(getCart(item?.userid));
      setIsUpdated(false);

      // update(item?.id, cartValue - 1);
    }
  };

  return (
    <div
      className="bg-white flex items-start gap-2 rounded-md mb-2"
      key={index}
    >
      <div className="">
        <input
          type="checkbox"
          className="cursor-pointer ml-1"
          checked={selectedItems.includes(item?.id)}
          onChange={() => toggleItemSelection(item?.id)}
        />
      </div>

      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
        <img
          src={item?.render_img_url}
          alt={item?.title}
          className="h-full w-full object-cover object-center"
        />
      </div>
      <div className="flex w-full flex-col px-4">
        <span className="font-bold capitalize">{item?.title}</span>
        <span className="float-right">
          <span className="line-through  mr-3">${item?.original_price}</span>
          <span className="text-green-500">
            {Math.round(
              ((item?.original_price - item?.selling_price) /
                item?.original_price) *
                100
            )}
            % off
          </span>
        </span>

        <span className="text-green-500 font-bold pb-0">
          ${item?.selling_price}
        </span>
        <span className="text-yellow-400 pb-0">{item?.rating}</span>

        <div className="flex flex-wrap   md:flex-row xl:flex-row ">
          <div className="flex flex-col md:flex-row pb-2">
            <div className="h-8 items-stretch  bg-gray-100 rounded-tl-md rounded-tr-md rounded-br-md rounded-bl-md px-2 py-1 flex justify-between">
              <button
                onClick={decreaseCartValue}
                className="flex bg-white items-center justify-center rounded-full px-4 font-bold transition"
              >
                -
              </button>
              <div className="flex w-half items-center justify-center  px-4 text-xs font-bold transition">
                {cartValue}
              </div>
              <button
                onClick={increaseCartValue}
                className="flex bg-white  items-center justify-center rounded-full px-4 font-bold transition"
              >
                +
              </button>
            </div>
            <div className="md:ml-2  bg-gray-100 rounded-tl-md rounded-tr-md rounded-br-md rounded-bl-md font-bold uppercase text-xs p-2 px-4 text-center md:inline mt-2 md:mt-0">
              swift delivery available
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-between items-center pb-2">
          <div className="bg-gray-100 rounded-tl-md rounded-tr-md rounded-br-md rounded-bl-md font-bold uppercase text-xs p-1 px-4 text-center md:inline mt-1 md:mt-0">
            more like this
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row xl:flex-row md:space-x-4 xl:space-x-4">
        <div className="mb-2 md:mb-0 xl:mb-0 md:flex md:flex-col xl:flex-col">
          {item?.shipping ? (
            <>
              <p className="bg-black text-white px-4 capitalize font-bold rounded-lg">
                {item?.shipping}
              </p>
              <p className="uppercase font-bold text-xs">shipping</p>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="mb-2 md:mb-0 xl:mb-0 md:flex md:flex-col xl:flex-col">
          {item?.days_return && (
            <>
              <p className="bg-black text-white px-4 inline-block font-bold rounded-lg">
                {item?.days_return}
              </p>
              <p className="uppercase font-bold text-xs">days return</p>
            </>
          )}
          <button className="md:ml-2 xl:ml-4">
            {" "}
            <BsFillShareFill />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartCard;
