import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";
import Loader from "../../Components/Loader";
import { NavLink } from "react-router-dom";
import useGetActivityByType from "../../hooks/useGetActivityByType";

const CampDescription = () => {
  const { activity, loading } = useGetActivityByType('CAMP');

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="px-4 md:px-10 mt-5">
    <div className="flex flex-col lg:flex-row items-center gap-[25px]">
      <CardContent className="w-full max-w-[1400px] mx-auto ">
        {activity && ( // Check if activity exists
          <>
            <Typography sx={{ fontSize: '3rem' }} component="div" textTransform={'uppercase'} gutterBottom>
              {activity?.title}
            </Typography>

            <Typography variant="body1" color="text.secondary">
              <div dangerouslySetInnerHTML={{ __html: activity?.description }} />
            </Typography>
          </>
        )}
      </CardContent>
    </div>
      <div className="flex pt-20 mb-5 ml-2 mr-2 justify-center">
        <NavLink
          to="/signupcamp"
          className="font-bold bg-[#fec749] lg:px-40 px-20 whitespace-nowrap py-1"
        >
          Sign Up
        </NavLink>
      </div>

      <AdvertisementOne index={0} />
    </div>
  );
};

export default CampDescription;
