import React from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import SocialMediaShareButtons from '../SocialMediaShareButtons';
import LazyLoad from 'react-lazyload';

const LgNewsCard = ({ item, formattedDate }) => {
  console.log('News',item)
  return (
    <Link
      to={`/news/${item.id}`}
      className="bg-white px-4 flex flex-col justify-between h-full py-4 shadow-lg rounded-md overflow-hidden transition duration-300 hover:shadow-xl"
      style={{ maxWidth: '400px' }} // Adjust the maximum width as needed
    >
      <div className="flex items-center mb-2">
        <span className="mr-2">{formattedDate}</span>
        <AiOutlineEye className="inline" />
        <span className="ml-1">{item?.view_count}</span>
      </div>
      <span className="text-xl mb-2 overflow-hidden">
        {item?.title.split(' ').slice(0, 20).join(' ')}
        {item?.title.split(' ').length > 20 ? '...' : ''}
      </span>

      {/* <LazyLoad height={150} className='h-[150px] w-full' offset={100}> */}
        <img
          className="w-full object-cover mt-2"
          src={item?.content_img_url ? item.content_img_url:item.img_url[0]}
          alt={item?.title}
          style={{ height: '150px' }} // Set maximum image height
        />
      {/* </LazyLoad> */}
      <SocialMediaShareButtons />
    </Link>
  );
};

export default LgNewsCard;
