import Articles from "../../Components/Articles";
import LtNews from "../../Components/LtNews";
import SqShop from "../../Components/SqShop";
import SignUpWK from "../../Components/SignUpWK";
import { NavLink } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { ImFacebook } from "react-icons/im";
import { IoLogoTwitter } from "react-icons/io";
import { AiOutlineGooglePlus } from "react-icons/ai";
import { SiInstagram } from "react-icons/si";
import SignUpList from "../../Components/SignUPList";
import Workwith from "../../Components/Workwith";
import Advertisement from "../../Components/Advertisement";
import Sponsors from "../../Components/Sponsors";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import Loader from "../../Components/Loader";
import RenderShop from "../../Components/Shop/RenderShop";
import { useDispatch, useSelector } from "react-redux";

import {
  clearNewsErrors,
  getNewsDetails,
  getUpdateNewsViews,
} from "../../redux/actions/NewsAction";
import getLinks from "../../utils/getLinks";
import useGetNewsById from "../../hooks/getNewsById";
import ArticleLoading from "../../Components/Skeleton/ArticleLoading";
import FlatNews from "../../Components/FlatNews";
import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";

const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = new Date(dateString).toLocaleDateString(
    "en-US",
    options
  );
  return formattedDate;
};

const SingleNews = () => {
  const { newsDetails, view_count, loading } = useGetNewsById();
  const dispatch = useDispatch();
  const {
    news,
    loading: newsLoading,
    error,
  } = useSelector((state) => state?.news);

  useEffect(() => {
    if (error) {
      dispatch(clearNewsErrors());
    }
  }, [dispatch, error]);

  if (loading || newsLoading) {
    return <Loader />;
  }
  return (
    <div className="pt-5">
      <div className="px-5 md:px-20 py-5">
        <AdvertisementOne index={0} />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-20 px-4 md:px-20">
        <div className="col-span-2">
          <div className="py-2 uppercase font-bold text-4xl border-b-2 mb-10 border-[#c4c4c4]">
            <span className=" border-l-4 border-[#D70D15] text-3xl px-2">
              News
            </span>
          </div>
          <>
            <div>
              <div className="px-10">
                <span className="mr-4">
                  {formatDate(newsDetails?.createdAt)}
                </span>
                <AiOutlineEye className="inline" />
                <span> {newsDetails?.view_count}</span>
                <span className="text-2xl font-semibold uppercase block my-4 ">
                  {newsDetails?.title}
                </span>
                {/* <img
                  alt="news_banner"
                  src={newsDetails?.img_url}
                  className="w-full max-h-[500px] mx-auto aspect-video object-cover"
                /> */}
                {/* <span className="text-xl leading-8 block space mb-3">
                  {newsDetails?.content_text
                    .split(".")
                    .map((paragraph, index) => (
                      <p key={index}>{paragraph.trim()}</p>
                    ))}
                </span>

                <span className="text-xl leading-8 block space mb-10">
                  {newsDetails?.brief.split(".").map((paragraph, index) => (
                    <p key={index}>{paragraph.trim()}.</p>
                  ))}
                </span> */}
                 <div
            className="text-xl leading-8 block space mb-10"
            dangerouslySetInnerHTML={{ __html: newsDetails?.brief }}
          />
              </div>
            </div>
          </>
        </div>
        <div>
          {loading ? <ArticleLoading /> : <Articles news={news} />}
          <Sponsors />
        </div>
      </div>
      <div className="px-4 md:px-20 mt-20 mb-20">
        <FlatNews loading={loading} newsList={news} />
      </div>
      <br />

      <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-cols-3 md:gap-10 bg-gray-300 pt-20 px-4 md:px-18">
        <div className="bg col-span-2 ">
          <LtNews />
        </div>

        <div className="flex  min-w-[100%] lg:w-full  flex-col  gap-10 mb-10">
          {/* <div className=" hidden lg:block"> */}
          <RenderShop />
          {/* </div> */}
          {/* <div className="w-full block lg:hidden"> */}
          {/* <Shop /> */}
          {/* </div> */}
          <Sponsors />
          {/* <SignUpWK /> */}
          <SignUpList />
          {/* <Workwith title="work with us" button="volunteer" link="/signupvolunteer" /> */}
        </div>
      </div>
    </div>
  );
};

export default SingleNews;
