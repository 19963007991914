export const ReplaceUrl = (htmlContent) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  console.log('DOC', doc);
  const img = doc.querySelectorAll('img');
  console.log('IMG', img);
  if (img.length > 0) {
    const src = img[0].getAttribute('src');
    return src;
  }

  return null;
};

export const allImage = async (htmlContent, getKey) => {
  const parser = new DOMParser();
  const serializer = new XMLSerializer();
  const doc = parser.parseFromString(htmlContent, 'text/html');

  const imgElements = Array.from(doc.querySelectorAll('img'));

  if (!imgElements || imgElements.length < 0) {
    return [];
  }
  const keyArray = [];

  await Promise.all(
    imgElements.map(async (img) => {
      const src = img.getAttribute('src');
      const key = getKey(src);

      if (key) {
        keyArray.push(key);
      }
    })
  );

  return keyArray;

  // Use Promise.all to handle asynchronous operations
};

export const updateImageSrcInHtml = async (htmlContent, getLink, getKey) => {
  const parser = new DOMParser();
  const serializer = new XMLSerializer();
  const doc = parser.parseFromString(htmlContent, 'text/html');

  const imgElements = Array.from(doc.querySelectorAll('img'));

  console.log("IMG ELEMENTS", imgElements)
  // Use Promise.all to handle asynchronous operations
  await Promise.all(
    imgElements.map(async (img) => {
      const src = img.getAttribute('src');
      console.log("IMG ELEMENTS SRC ", src)
      const key = getKey(src);

      if (key) {
        const newSrc = await getLink(key);
        img.setAttribute('src', newSrc);
      }
    })
  );

  return serializer.serializeToString(doc);
};

export const getNonMatchingValues = (oldArray, newArray) => {
  const oldSet = new Set(oldArray);
  const newSet = new Set(newArray);
  console.log("oldArray", oldArray);
  console.log("NewSet", newArray)
  const notInNew = oldArray.filter((item) => !newSet.has(item));
  const notInOld = newArray.filter((item) => !oldSet.has(item));
  console.log("notInNew", notInNew)
  console.log("notInOld", notInOld)

  return {
    notInNew,
    notInOld,
  };
};

export const getKey = (url) => {
  console.log("URL", url);
  try {
    const urlObject = new URL(url);
    const pathname = urlObject.pathname;
    const prefix = 'public/';
    const startIndex = pathname.indexOf(prefix);

    if (startIndex !== -1) {
      return decodeURIComponent(pathname.substring(startIndex + prefix.length));
    }

  } catch (error) {
    console.log("Error fetching key ", error)
    return url;
  }

};


