import { useEffect, useState } from "react";
import useGetAllTeams from "../../hooks/getTeamsLists";

const useTeamCityValidation = () => {
  const { teamslist, loading: teamLoading } = useGetAllTeams();
  const [isTeamValid, setIsTeamValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const validateTeam = async (teamName, cityName) => {
    const existingTeam = teamslist.find(
      (team) => team.city === cityName && team.name === teamName
    );

    if (existingTeam) {
      // Team with the same name already exists in the same city
      setIsTeamValid(false);
      setErrorMessage(
        `Team ${existingTeam.name} is already created in ${cityName}`
      );
    } else {
      // No team with the same name exists in the same city
      setIsTeamValid(true);
      setErrorMessage("");
    }
  };

  return { validateTeam, isTeamValid, errorMessage };
};

export default useTeamCityValidation;
