import { Table } from "flowbite-react";
import useListScores from "../../hooks/useListScores";
import Loader from "../Loader";
import { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import Spinner from "../Loader/Spinner";
import LazyLoad from "react-lazyload";

const LeagueGame = ({ teamDetails, loading }) => {
  const [imageLoading, setImageLoading] = useState(false);
  const [playerDetails, setPlayerDetails] = useState(teamDetails?.playerDetails | []);

  useEffect(() => {
    setImageLoading(true);
    console.log("teamDetails", teamDetails)
    const fetchData = async () => {
      try {
        const updatedPlayerDetails = await Promise.all(
          teamDetails?.playerDetails?.map(async (player) => {
            try {
              const signedUrl = await getSignedUrl(player?.playerImage);
              player.profileLink = signedUrl;
            } catch (error) {
              console.error("Error fetching image URL", error);
            }
            return player;
          })
        );
        const teamLink = await getSignedUrl(teamDetails?.eventImage);
        teamDetails.eventImageLink = teamLink;
        teamDetails.playerDetails = updatedPlayerDetails;
        setPlayerDetails([teamDetails]); // Store the updated team details as an array
      } catch (error) {
        setPlayerDetails(teamDetails?.playerDetails)
        console.error("Error fetching data", error);
      } finally {
        setImageLoading(false);
      }
    };

    fetchData();
  }, [teamDetails]);

  // Function to get signed URL
  const getSignedUrl = async (link) => {
    try {
      const signedUrl = await Storage.get(link, {
        level: "public",
        download: false,
      });
      return signedUrl;
    } catch (error) {
      console.error("Error getting signed URL", error);
      return null;
    }
  };

  if (loading || imageLoading) {
    return <Loader />;
  }
  // fsdfas
  // rwerwerr
  return (
    <div className="flex bg-white overflow-x-auto">
      {playerDetails?.length > 0 &&
        playerDetails?.map((teamDetail, index) => (
          <>
            <div className="flex flex-col items-center  text-center" key={index}>
              <div className="flex w-[250px] justify-center ">
                <LazyLoad
                  className="my-2 object-cover  lg:h-40 w-full "
                  // height={100}
                  offset={100}
                >
                  <img
                    className="object-cover rounded-md lg:h-40 w-full"
                    src={teamDetail?.eventImageLink}
                    alt={teamDetail?.title}
                  />
                </LazyLoad>
                {/* <img 
              src={teamDetail?.eventImage}
               alt="sanjose" /> */}
              </div>
              <div className="border w-4/5 flex justify-between  items-center px-2 text-sm border-[#C4C4C4]">
                {teamDetail?.teams?.map((team, index) => (
                  <>
                    {team?.teamName}
                    <div key={index} className="whitespace-nowrap">
                    </div>

                  </>

                ))}
              </div>
              <div className="text-center flex flex-col gap-2 w-4/5 mt-8 mx-2 mr-2">
                <div className="flex justify-between items-start">
                  <span className="font-bold text-xs">Record</span>
                  <span className="ml-auto text-xs">{teamDetail?.record}</span>
                </div>
                <div className="flex justify-between items-start">
                  <span className="font-bold text-xs">Ranking</span>
                  <span className="ml-auto text-xs">{teamDetail?.ranking}</span>
                </div>
                <div className="flex justify-between items-start">
                  <span className="font-bold text-xs">PPG</span>
                  <span className="ml-auto text-xs">{teamDetail?.ppg}</span>
                </div>
                <div className="flex justify-between items-start">
                  <span className="font-bold text-xs">RPE</span>
                  <span className="ml-auto text-xs">{teamDetail?.rpe}</span>
                </div>
                <div className="flex justify-between items-start">
                  <span className="font-bold text-xs">APG</span>
                  <span className="ml-auto text-xs">{teamDetail?.apg}</span>
                </div>
              </div>
            </div>

            <Table className="text-center">
              <Table.Head>
                <Table.HeadCell className="bg-white py-1 px-1 border">
                  Player
                </Table.HeadCell>
                <Table.HeadCell
                  className="bg-white py-1 px-1"
                  colSpan={Teams.stats.length}
                >
                  Stats
                </Table.HeadCell>
                <Table.HeadCell className="bg-white py-1 px-1 border">
                  FIBA Ranking Points
                </Table.HeadCell>
              </Table.Head>
              <Table.Head>
                <Table.HeadCell></Table.HeadCell>
                {Teams.stats.map((teamDetails, index) => (
                  <Table.HeadCell key={index} className="py-1 pl-1 pr-1">
                    {teamDetails}
                  </Table.HeadCell>
                ))}
              </Table.Head>

              <Table.Body className="divide-y">
                {playerDetails[0]?.playerDetails?.map((player, playerIndex) => (
                  <Table.Row
                    className="bg-white dark:border-gray-700 dark:bg-gray-800 px-1 py-1 border"
                    key={playerIndex}
                  >
                    <Table.Cell className="whitespace-nowrap flex flex-col items-center border font-medium text-gray-900 dark:text-white px-1 py-1">
                      <div className="flex justify-center w-[50px] h-[50px] rounded-full overflow-hidden">
                        {player?.profileLink ? (
                          <img
                            src={player?.profileLink}
                            className="w-full object-contain overflow-hidden"
                            alt={player.playerName}
                          />
                        ) : (
                          <Spinner /> // Sho a loading spinner here
                        )}
                      </div>
                      {player?.playerName}
                    </Table.Cell>
                    <>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.GP}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.MIN}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.PTS}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.FGM}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.FGA}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.FGPERCENT}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.THREEPM}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.THREEPA}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.THREEPPERCENT}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.FTM}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.FTA}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.FTPERCENT}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.OREB}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.DREB}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.DREB}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.REB}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.AST}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.TOV}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.STL}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.BLK}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.PF}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.FP}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.DDTWO}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.TDTHREE}
                      </Table.Cell>
                      <Table.Cell className="py-1 pl-1 pr-1">
                        {player?.PLUSMINUS}
                      </Table.Cell>
                    </>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </>
        ))}
    </div>
  );
};

export default LeagueGame;

const Teams = {
  stats: [
    "GP",
    "MIN",
    "PPG",
    "FGM",
    "FGA",
    "FG%",
    "3PM",
    "3PA",
    "3P%",
    "FTM",
    "FTA",
    "FT%",
    "OREB",
    "DREB",
    "REP",
    "APG",
    "TOV",
    "STL",
    "BLK",
    "PF",
    "FP",
    "DD2",
    "TD3",
    "+/-",
  ],
};
