import TypoBu from "../TypoBu";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";

const FollowUS = () => {
  return (
    <div className="bg-white px-4 pt-10">
      <TypoBu
        item={{
          text: "follow us",
          text_ssize: "text-xl",
          text_bsize: "text-2xl",
          border: true,
          button: false,
        }}
      />
      <div className="flex justify-between lg:px-8 md:px-4 py-10 text-white">
        <div className="w-10 h-10 rounded-full flex mr-2 bg-[rgb(63,85,171)] justify-center items-center">
          <FaFacebookF className="text-lg md:text-2xl" />
        </div>
        <div className="w-10 h-10 rounded-full flex mr-2 bg-[rgb(96,184,255)] justify-center items-center">
          <FaTwitter className="text-lg md:text-2xl" />
        </div>
        <div className="w-10 h-10 rounded-full flex mr-2 bg-[rgb(225,75,146)] justify-center items-center">
          <FaInstagram className="text-lg md:text-2xl" />
        </div>
        <div className="w-10 h-10 rounded-full flex bg-[rgb(254,46,60)] justify-center items-center">
          <FaYoutube className="text-lg md:text-2xl" />
        </div>
      </div>
    </div>
  );
};
export default FollowUS;
