import react from "react";

import { useDispatch, useSelector } from "react-redux";

// Function to validate coupons
const validateCoupon = (couponCode, totalValue, coupons) => {
  // Check if the coupon is available in the coupons array
  const validCoupon = coupons.find((coupon) => coupon.code === couponCode);
  const currentDate = new Date();
  if (
    !validCoupon ||
    validCoupon.validTo < currentDate ||
    !validCoupon.isActive
  ) {
    return {
      isValid: false,
      message: "Invalid Discount Code or Code is Expire ",
    };
  }
  console.log("validCoupon: ", validCoupon);
  const flatDiscount = parseInt(validCoupon.discountOnUpto);
  const couponValue = parseInt(validCoupon.couponValue);
  if (couponValue >= totalValue) {
    return {
      isValid: false,
      message: `This Coupon is only applicable on Order Upto $${couponValue}`,
      flatDiscount,
    };
  }
  const couponType = validCoupon.type;

  // Check if the coupon value is applicable (e.g., meets a certain threshold)
  // Apply the coupon discount if it is applicable
  if (couponType === "FLAT_DISCOUNT") {
    const discountedTotal = totalValue - couponValue;
    return {
      isValid: true,
      discountedTotal,
      couponValue,
      couponId: validCoupon.id,
    };
  } else if (couponType === "PERCENTAGE_DISCOUNT") {
    const discountInDollar = (totalValue * couponValue) / 100;
    const couponCodeReal=discountInDollar
    const totalAfterDiscount = totalValue - discountInDollar;
    console.log("discountInDollar",discountInDollar,totalAfterDiscount);
    return {
      isValid: true,
      discountedTotal:totalAfterDiscount,
      couponValuePerce:couponValue,
      couponValue:couponCodeReal,

      couponId: validCoupon.id,
    };
  }
};

export default validateCoupon;
