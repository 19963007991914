import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../../graphql/queries"; // Import your GraphQL queries
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import {
    CREATE_TEAM_REQUEST,
    CREATE_TEAM_SUCCESS,
    CREATE_TEAM_FAIL,
    UPDATE_TEAM_REQUEST,
    UPDATE_TEAM_SUCCESS,
    UPDATE_TEAM_FAIL,
    GET_TEAM_REQUEST,
    GET_TEAM_SUCCESS,
    GET_TEAM_FAIL,
    LOAD_TEAMS_REQUEST,
    LOAD_TEAMS_SUCCESS,
    LOAD_TEAMS_FAIL,
    LIST_TEAMS_REQUEST,
    LIST_TEAMS_SUCCESS,
    LIST_TEAMS_FAIL,
    DELETE_TEAM_REQUEST,
    DELETE_TEAM_SUCCESS,
    DELETE_TEAM_FAIL,
    DELETE_TEAM_RESET,    
    CLEAR_TEAM_ERRORS,
    
} from "../constants/TeamsConstants";
import getLinks from "../../utils/getLinks";

export const getAllTeams = () => async (dispatch) => {

  
  try {
    console.log("data" );

    dispatch({ type: LIST_TEAMS_REQUEST });

    const response = await API.graphql({
      query: queries.listTeams,
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const data = response?.data?.listTeams?.items || [];
    console.log("data",data );
    const updatedProducts = await Promise.all(

      data?.map(async (product) => {
        try {
          const signedUrl = await getLinks(product?.imageURL);
          return { ...product, img_url: signedUrl };
        } catch (error) {
          console.error("Error fetching Team image URL", error);
          return product;
        }
      })
    );
console.log("updatedTeams",updatedProducts,data)
    const updatedData={
      teams:updatedProducts,
      teamsCount:updatedProducts?.length,
      resultPerPage:10,
      filteredTeamsCount:null

    }

    dispatch({
      type: LIST_TEAMS_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    const errorMessage =
    error.response && error.response.data.message
      ? error.response.data.message
      : error.message;

    dispatch({
      type: LIST_TEAMS_FAIL,
      payload: errorMessage ,
    });
  }
};


export const getTeamDetails = (teamsId) => async (dispatch) => {
    try {
      dispatch({ type: GET_TEAM_REQUEST });
  
      const response = await API.graphql(
        graphqlOperation(queries.getTeam, { id: teamsId })
      );

      const data=response?.data.getItem;
      dispatch({
        type: GET_TEAM_SUCCESS,
        payload: data
      });
    } catch (error) {
          const errorMessage =
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message;
  
      dispatch({
        type: GET_TEAM_FAIL,
        payload: errorMessage,
      });
    }
  };
  
//add a new product
// export const addProduct = (productData,images) => async (dispatch) => {
//     try {
//       dispatch({ type: ADD_PRODUCT_REQUEST });

//       const config = {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       };
//       // const config = { headers: { "Content-Type": "multipart/form-data" } };

//       const { data } = await axios.post("/api/v1/admin/new/product", productData, config);

//       dispatch({
//         type: ADD_PRODUCT_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       const errorMessage =
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message;

//       dispatch({
//         type: ADD_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

// export const updateProduct = (eventId, productData) => async (dispatch) => {
//     try {
//       dispatch({ type: UPDATE_PRODUCT_REQUEST });

//       const config = {
//         headers: { "Content-Type": "application/json" },
//       };

//       const { data } = await axios.put(
//         `/api/v1/admin/product/${eventId}`,
//         productData,
//         config
//       );

//       dispatch({
//         type: UPDATE_PRODUCT_SUCCESS,
//         payload: data.success,
//       });
//     } catch (error) {
//           const errorMessage =
//             error.response && error.response.data.message
//               ? error.response.data.message
//               : error.message;

//       dispatch({
//         type: UPDATE_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

// export const deleteProduct = (eventId) => async (dispatch) => {
//     try {
//       dispatch({ type: DELETE_PRODUCT_REQUEST });

//       const { data } = await axios.delete(`/api/v1/admin/product/${eventId}`);

//       dispatch({
//         type: DELETE_PRODUCT_SUCCESS,
//         payload: data.success,
//       });
//     } catch (error) {
//           const errorMessage =
//             error.response && error.response.data.message
//               ? error.response.data.message
//               : error.message;

//       dispatch({
//         type: DELETE_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };


export const clearTeamErrors = () => async (dispatch) => {
  
    dispatch({ type: CLEAR_TEAM_ERRORS });
  };