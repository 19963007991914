import React, { useState, useEffect, lazy } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import Loader from "../../Components/Loader";
import TypoBu from "../TypoBu";
import Advertisement from "../Advertisement";
import NewsCard from "../AllNews/NewsCard";
import ReactPaginate from "react-paginate";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import LazyLoad from "react-lazyload";
import { useDispatch, useSelector } from "react-redux";
import { clearNewsErrors, getAllNews } from "../../redux/actions/NewsAction";
import { Link } from "react-router-dom";
import NewsItemSkeleton from "../Skeleton/NewsListSkeleton";
import useHandleNews from "../../hooks/handleNews";
import AdvertisementNews from "../Advertisement/NewsAdvertisement";

const NewsList = () => {
  const dispatch = useDispatch();
  const {
    news,
    nextNextToken,
    loading,
    fetchNextPage,
    fetchPreviousPage,
    previousTokens,
  } = useHandleNews();

  const handleNextPage = () => {
    fetchNextPage();
  };

  const handlePreviousPage = () => {
    fetchPreviousPage();
  };

    console.log("newsnews", news);
  return (
    <div className="bg-transparent ">
      <div className="flex flex-col justify-between">
        <AdvertisementNews one index={0} />
        <TypoBu
          item={{
            text: "News",
            text_ssize: "text-3xl",
            text_bsize: "text-4xl",
            border: true,
            button: false,
          }}
        />

        <br/>
        {loading ? (
          <>
            <NewsItemSkeleton />
            <NewsItemSkeleton />
          </>
        ) : (
          <>
          {news?.length === 0 ? (
            <div className="flex items-center justify-center h-full">
              <p className="text-2xl">No News.</p>
            </div>
          ) : (
            <>
              {news?.map((item, index) => (
                <div key={item?.id} className="flex flex-col mb-5 items-center w-full ">
                  <NewsCard item={item} />
                  <div className="w-full">
                    {(index+1) % 3 === 0 && (
                      <div className="mt-10 mb-10 w-full h-full">
                        <AdvertisementNews one length={news.length} index={Math.floor((index + 1) / 3)} />
                      </div>
                    )}
                  </div>
                </div>
              ))}
              <div className="pagination-buttons flex gap-4 justify-center items-center mt-4">
                {fetchPreviousPage && previousTokens?.length > 0 && (
                  <button
                    onClick={handlePreviousPage}
                    className="w-[100px] text-center p-3 border rounded-md bg-blue-500 text-white hover:bg-blue-600 transition duration-300"
                  >
                    Previous
                  </button>
                )}
                {nextNextToken !== null && (
                  <button
                    onClick={handleNextPage}
                    className="w-[100px] text-center p-3 border rounded-md bg-blue-500 text-white hover:bg-blue-600 transition duration-300"
                  >
                    Next
                  </button>
                )}
              </div>
            </>
          )}
        </>
      )}
      </div>


    </div>
  );
};

export default NewsList;
