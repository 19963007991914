
import React from 'react'
import { useParams } from 'react-router-dom'
import useScoreById from './useGetScoreById';
import LeagueGame from '../../Components/LeagueGame';
import Loader from '../../Components/Loader';

const Score = () => {
  const { id } = useParams();

  const { score, loading, error } = useScoreById();

  if (loading) {
    return <Loader />
  }

  return (
    <div className='w-fit mx-auto py-10'>
      <h1 className='text-wider  font-normal'>
        Score Details
      </h1>
      <LeagueGame teamDetails={score} loading={loading} />

    </div>
  )
}

export default Score
