import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import { useParams } from "react-router-dom";
import { allImage, getNonMatchingValues, replaceUrl, updateImageSrcInHtml, getKey } from '../utils/replaceUrl';
import getLink from "../utils/getLinks";

const useGetAllClinics = () => {
  const [clinics, setClinics] = useState([]);
  const [clinicsDetails, setClinicsDetails] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const getClinics = async () => {
    try {
      setLoading(true);
      const response = await API.graphql({
        query: queries.listClinicCards,
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      if (!response || !response.data || !response.data.listClinicCards || !response.data.listClinicCards.items) {
        throw new Error("No data found");
      }

      const sortedClinics = response.data.listClinicCards.items.sort((a, b) => a.order - b.order);

      const updatedClinics = await Promise.all(
        sortedClinics.map(async (clinic) => {
          const descriptionWithUpdatedImageURL = await updateImageSrcInHtml(clinic.description, getLink, getKey);
          clinic.description = descriptionWithUpdatedImageURL;
          return clinic;
        })
      );

      setClinics(updatedClinics);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching clinics:', error);
    }
  };

  const getClinicsDetail = async () => {
    try {
      setLoading(true);
      const response = await API.graphql(
        graphqlOperation(queries.getClinicCard, { id })
      );
      if (!response || !response.data || !response.data.getClinicCard) {
        throw new Error("No data found");
      }

      const clinicDetails = response.data.getClinicCard;
      const descriptionWithUpdatedImageURL = await updateImageSrcInHtml(clinicDetails.description, getLink, getKey);
      clinicDetails.description = descriptionWithUpdatedImageURL;

      setClinicsDetails(clinicDetails);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching clinic details:', error);
    }
  };

  useEffect(() => {
    if (id) {
      getClinicsDetail();
    }
    getClinics();
  }, [id]);

  return { clinics, loading, clinicsDetails };
};

export default useGetAllClinics;
