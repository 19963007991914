import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import getLinks from "../utils/getLinks";

const useAllLeaguePlayerList = () => {
  const [allLeaguesUsersList, setGetLeaguesUserList] = useState([]);
  const [verifiedLeaguesUsersList, setVerifiedGetLeaguesUserList] = useState([]);
  const [notVerifiedLeaguesUsersList, setNonVerifiedGetLeaguesUserList] = useState([]);
  const [playerDetails, setPlayerDetails] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchLeaguesUser = async () => {
      try {
        setLoading(true);
        const response = await API.graphql({
          query: queries.listPlayers,
          authMode: GRAPHQL_AUTH_MODE.API_KEY,

        });

        const newList = response?.data?.listPlayers?.items?.map((item) => ({
          ...item,
          zone: "Leagues Player",
        }));

        // Sort the array alphabetically by participantName
        newList.sort((a, b) => a.participantName.localeCompare(b.participantName));

        // Group same teams together
        const groupedByTeam = {};
        newList.forEach((player) => {
          const teamName = player.teamName;
          if (!groupedByTeam[teamName]) {
            groupedByTeam[teamName] = [];
          }
          groupedByTeam[teamName].push(player);
        });

        // Sort teams array 
        const sortedTeams = Object.keys(groupedByTeam).sort();

        // Map teams back to array in sorted order
        const sortedGroupedData = sortedTeams.map(team => groupedByTeam[team]);

        // Flatten into single array  
        const flattenedSortedData = sortedGroupedData.flat();

        const promises = flattenedSortedData.map(async (player) => {
          // const imageLink = await getLink(player?.profile);
          // return { ...player, imageLink };
          const url = await getLinks(player?.profile);
          return { ...player, playerUrl: url };
        });

        const playersWithImages = await Promise.all(promises);

        const notVerifiedUsers = flattenedSortedData?.filter((item) => item?.isVerified === null);
        const verifiedUsers = flattenedSortedData?.filter((item) => item?.isVerified === true);

        setLoading(false);
        setGetLeaguesUserList(playersWithImages);
        setNonVerifiedGetLeaguesUserList(notVerifiedUsers);
        setVerifiedGetLeaguesUserList(verifiedUsers);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching leagues:", error);
        throw error;
      }
    };


    fetchLeaguesUser();
  }, []);

  const fetchLeaguesUserByName = async (id) => {
    try {
      setLoading(true);
      API.graphql({
        query: queries.listPlayers,
        filter: { id: { eq: id }, isVerified: { eq: true } },
        variables: { id },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      }).then((response) => {
        const newList = response?.data?.listPlayers?.items?.map((item) => ({
          ...item,

        }));
        // const notVerifie
        // Users = newList.filter((item) => item.isVerified === null);
        const user = newList?.filter((item) => item.isVerified === true);

        setPlayerDetails(user)
      });
    } catch (error) {
      setLoading(false);
      console.error("Error fetching leagues:", error);
      throw error;
    }
  };
  return {
    allLeaguesUsersList,
    verifiedLeaguesUsersList,
    notVerifiedLeaguesUsersList,
    loading,
    playerDetails,
    fetchLeaguesUserByName

  };
};

export default useAllLeaguePlayerList;
