import { useEffect, useState } from "react";
import { Auth, API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import getLink from "../utils/getLinks";
import { useLocation } from "react-router-dom";

const useFetchAdvertisement = (filterType) => {
  const [advertisement, setAdvertisement] = useState([]);
  const [newsAdvertisement, setNewsAdvertisement] = useState([]);
  const [blogVertisement, setBlogAdvertisement] = useState([]);
  const [vlogAdvertisement, setVlogAdvertisement] = useState([]);
  const [eventsAdvertisement, setEventsAdvertisement] = useState([]);
  // const [Advertisement, setVlogAdvertisement] = useState([]);

  const [sponsors, setSponsor] = useState([]);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthAndFetchData = async () => {
      try {
        setLoading(true);

        // Check if the user is authenticated

        // Fetch items if authenticated
        const response = await API.graphql({
          query: queries.listAdvertisements,
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        const updatedData = await Promise.all(
          (response?.data?.listAdvertisements?.items || []).map(async (ads) => {
            try {
              const lgImageUrl = await getLink(ads?.lg_img_url);
              const mdImageUrl = await getLink(ads?.md_img_url);
              const smImageUrl = await getLink(ads?.sm_img_url);
              const xsImageUrl = await getLink(ads?.xs_img_url);

              return { ...ads, lgImageUrl, mdImageUrl, smImageUrl, xsImageUrl };
            } catch (error) {
              console.error("Error fetching image URL", error);
              return ads;
            }
          })
        );

        const currentPage = location.pathname.slice(1).toUpperCase();
        console.log("AdvertisementOne", response, currentPage);
const ads=updatedData?.filter((ad)=>ad.type=="ADVERTISEMENT")
        let filteredAds = [];
        let filteredSponsor = [];

        if (currentPage === "" || currentPage === "/") {
          // Home page, show relevant ads
          filteredAds = ads.filter((ad) => ad.page === "HOME");
        } else if (currentPage === "SIGNUPLEAGUE") {
          filteredAds = ads.filter((ad) => ad.page === "PLAYER");
        } else if (currentPage.includes("NEWS")) {
          filteredAds = ads.filter((ad) => ad.page === "NEWS");
          setNewsAdvertisement(filteredAds);
        } else if (currentPage.includes("BLOG")) {
          filteredAds = ads.filter((ad) => ad.page === "BLOG");
          setBlogAdvertisement(filteredAds);
        } else if (currentPage.includes("VLOG")) {
          filteredAds = ads.filter((ad) => ad.page === "VLOG");
          setVlogAdvertisement(filteredAds);
        } else {
          const adTypesKeywords = {
            NEWS: ["NEWS"],
            EVENTS: ["EVENT"],
            BLOG: ["BLOG"],
            VLOG: ["VLOG"],
            STORE: ["STORE"],
            CONTACT: ["CONTACT"],
            ABOUT: ["ABOUT"],
            RULES: ["RULES"],
            CLINICS: ["CLINICS"],
            TOURNAMENT: ["TOURNAMENT"],
            TRAININGPROGRAM: ["TRAININGPROGRAM"],
            PARTNER: ["PARTNER"],
            ICON: ["ICON"],
            CAMP: ["CAMP"],
            LEAGUETEAM: ["TEAM"],
            REGISTER: ["REGISTER"],
            LOGIN: ["LOGIN"],
            LEAGUEPLAYER: ["LEAGUEPLAYER"],
            LEAGUECITY: ["LEAGUECITY"],
            LEAGUEINFO: ["INFO"],
          };

          // Identify the ad type based on keywords in the current page
          const adType = Object.keys(adTypesKeywords).find((type) =>
            adTypesKeywords[type].some((keyword) =>
              currentPage.includes(keyword)
            )
          );
          // Filter ads based on the identified ad type
          filteredAds = ads.filter((ad) => ad.page === adType);

          console.log("filteredAds: ", currentPage, filteredAds);
        }

        filteredSponsor = updatedData.filter((ad) => ad.type === "SPONSOR");

        setAdvertisement(filteredAds);
        setSponsor(filteredSponsor);
        // setSponsor(filteredAds);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching advertisement:", error);
        setLoading(false);
        throw new Error(error);
        // Redirect to the login page if not authenticated
      }
    };

    checkAuthAndFetchData();
  }, []);

  return { advertisement,newsAdvertisement,eventsAdvertisement,vlogAdvertisement,sponsors, loading };
};

export default useFetchAdvertisement;
