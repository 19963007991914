
import TypoBu from "../TypoBu";
import FlatNewsSkeleton from "../Skeleton/FlatNewsSkeleton";
import MoreNewsCard from "./MoreNewsCard";

const FlatNews = ({ newsList, loading }) => {
  const limitedNewsList = newsList?.slice(0, 8); // Limiting newsList to maximum 6 items

  return (
    <>
      <TypoBu
        item={{
          text: "More",
          text_ssize: "text-3xl",
          text_bsize: "text-4xl",
          border: true,
          button: false,
        }}
      />
      <br />
      <div className="grid h-auto  grid-cols-1 bg-white md:grid-cols-2 gap-2 lg:grid-cols-3 xl:grid-cols-4   ">
        {loading  ? (
          <>
          <FlatNewsSkeleton />
          <FlatNewsSkeleton />
          <FlatNewsSkeleton />
          <FlatNewsSkeleton />
        
          
          </>
        ) : (
          <>
            {limitedNewsList?.map((item, index) => (
             <MoreNewsCard key={item?.id} item={item}/>
            ))}
          </>
        )}
      </div>
    </>
  );
};
export default FlatNews;
