import { useState, useEffect } from "react";

import TypoBu from "../TypoBu";

import VlogItemSkeleton from "../Skeleton/VlogSkeleton";
import FlatVideoCard from "./FlatVideoCard";
import useHandleNews from "../../hooks/handleNews";

const FlatVideo = () => {
  const {
    vlog,
    loading,

  } = useHandleNews();
  const [page, setPage] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const itemsPerPage = 4;
  const totalPages = Math.ceil(vlog?.length / itemsPerPage);

  useEffect(() => {
    const startIndex = page * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const filteredData = vlog.slice(startIndex, endIndex);
    setFilterData(filteredData);
  }, [page, vlog]);

  return (
    <div className=" px-4 pt-10">
      <TypoBu
        item={{
          text: "media gallery",
          text_ssize: "text-3xl",
          text_bsize: "text-4xl",
          border: true,
          button: true,
        }}
        disabledLeft={page === 0}
        onClickLeft={() => setPage(page - 1)}
        disabledRight={page === totalPages - 1}
        onClickRight={() => setPage(page + 1)}
      />
      <div className="grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 mt-10">
        {loading ? (
          <>
            <VlogItemSkeleton />
            <VlogItemSkeleton />
            <VlogItemSkeleton />
            <VlogItemSkeleton />
          </>
        ) : (
          <>
            {filterData?.length > 0 &&
              filterData.map((item, index) => <FlatVideoCard key={item?.id} item={item} />)}
          </>
        )}
      </div>
    </div>
  );
};
export default FlatVideo;
