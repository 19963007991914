// /* eslint-disable consistent-return */
// /* eslint-disable camelcase */
// /* eslint-disable arrow-body-style */
// /* eslint-disable no-unused-vars */
// /* eslint-disable prefer-template */

// import { useState, useEffect } from "react";
// import { API, graphqlOperation } from "aws-amplify";

// import { useSelector } from "react-redux";

// import * as queries from "../graphql/queries";

// const useSessionCharges = () => {
//   const [sessionDetails, setSessionDetails] = useState([]);

//   const [loading, setLoading] = useState(false);
//   const currentUrl = location.pathname;
//   const fetchSessions = async (teamName) => {
//     try {
//       setLoading(true);

//       const resp = await API.graphql({
//         query: queries.listSeasonCharges,
//       });

//       const sessions = resp?.data?.listSeasonCharges?.items;
//       setLoading(false);
//       setSessionDetails(sessions);
//       return sessions;
//     } catch (error) {
//       setLoading(false);

//       throw new Error(error);
//     }
//   };

//   useEffect(() => {
//     fetchSessions();
//   }, []);
//   return {
//     sessionDetails,
//     fetchSessions,
//     loading
//   };
// };

// export default useSessionCharges;

import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useLocation } from "react-router-dom";

import * as queries from "../graphql/queries";

const useSessionCharges = () => {
  const [sessionDetails, setSessionDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const fetchSessions = async () => {
    try {
      setLoading(true);
      let query = queries.listSeasonCharges;

      // Extract the last segment of the URL path
      const segments = location.pathname.split("/");
      const lastSegment = segments[segments.length - 1];

      // Construct the filter condition based on the last segment
      let filter = null;
      switch (lastSegment) {
        case "tournament":
          filter = { type: { eq: "TOURNAMENT" } };
          break;
        case "trainingprogram":
          filter = { type: { eq: "TRAINING_PROGRAM" } };
          break;
        case "player":
          filter = { type: { eq: "PLAYER" } };
          break;
        default:
          break;
      }

      const resp = await API.graphql({
        query,
        // variables: { filter }, // Pass the filter condition to the GraphQL query
      });

      const sessions = resp?.data?.listSeasonCharges?.items;
      setLoading(false);
      setSessionDetails(sessions);
      return sessions;
    } catch (error) {
      setLoading(false);
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchSessions();
  }, [location.pathname]);

  return {
    sessionDetails,
    fetchSessions,
    loading,
  };
};

export default useSessionCharges;
