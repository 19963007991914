import { useState, useEffect } from "react";
import { API,graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useParams } from "react-router-dom";

const useEndedEvents = () => {
  const [listScores, setListScores] = useState(null);
  const [multipleListScores,setMultipleListScores] = useState([])
  const [loading, setLoading] = useState(false);
  const [totalAverages, setTotalAverages] = useState({});
  const [total, setTotal] = useState(0);

  const [error, setError] = useState(null);
  const [playerDetails, setPlayerDetails] = useState(null);
  // const [playerId, setPlayerId] = useState(null);
  const { playerId } = useParams();

  const fetchEndedEvents = async (playerName) => {
    try {
      setLoading(true);
      const playerResponse= await API.graphql(graphqlOperation(queries.getPlayer, { id: playerId }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const playersDetails=playerResponse?.data?.getPlayer?.userId
      const response = await API.graphql({
        query: queries.listScores,
        variables: {
          filter: {
            eventStatus: {
              eq: "EVENT_ENDED",
            },
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
   
      const scores = response?.data?.listScores?.items || [];

      
      const filteredScores = scores.map((score) => {
        const playerDetails = score.playerDetails.find(
          (playerDetail) => playerDetail.playerId === playerId
        );
        return {
          ...score,
          playerDetails: [playerDetails],
        };
      });
      console.log("scores", scores);

      console.log("filteredScores", filteredScores);

      const filteredScoresWithMatchingPlayer = filteredScores.filter(
        (score) => score.playerDetails[0] !== undefined
      );

      if (filteredScoresWithMatchingPlayer.length < 1) {
        setLoading(false);
        setListScores(null);
      } else {
        setListScores(filteredScoresWithMatchingPlayer);
        setLoading(false);
        // setPlayerId(
        //   filteredScoresWithMatchingPlayer[0].playerDetails[0].playerId
        // );
      }
    } catch (error) {
      setLoading(false);
      setError(error);
      console.error("Error fetching scores:", error);
    }
  };

  

  const calculateTotalAverages = (scores) => {
    if (!scores || scores.length === 0) {
      return {};
    }

    const propertiesToAverage = ["AST", "BLK", "PTS", "REB", "STL"];
    const averages = {};

    propertiesToAverage.forEach((property) => {
      averages[property] = 0;
    });

    scores?.forEach((game) => {
      game?.playerDetails?.forEach((player) => {
        propertiesToAverage.forEach((property) => {
          averages[property] += Number(player[property] || 0);
        });
      });
    });

    const totalAverages = {};
    propertiesToAverage.forEach((property) => {
      totalAverages[property] = (
        averages[property] / scores.length
      ).toFixed(2);
    });

    return totalAverages;
  };

  const calculateTotal = (listScores) => {
    const propertiesForAverage = [
      "AST",
      "BLK",
      "DDTWO",
      "DREB",
      "FGA",
      "FGM",
      "FGPERCENT",
      "FP",
      "FTA",
      "FTM",
      "FTPERCENT",
      "GP",
      "MIN",
      "OREB",
      "PF",
      "PTS",
      "PlusMinus",
      "REB",
      "STL",
      "TDTHREE",
      "THREEPA",
      "THREEPM",
      "THREEPPERCENT",
      "TOV",
    ];
    console.log("Listscore length: ", listScores);
    if (!listScores || listScores.length === 0) {
      return 0;
    }
    let sumOfAverages = 0;

    listScores?.forEach((game) => {
      game?.playerDetails?.forEach((player) => {
        let playerTotal = 0;

        propertiesForAverage?.forEach((property) => {
          playerTotal += Number(player[property] || 0);
          // fsfsdffadfdf
        });

        // Calculate the average for the row
        sumOfAverages += playerTotal / propertiesForAverage.length;
      });
    });

    // Calculate the final average based on the number of rows in listScores
    const finalAverage = sumOfAverages / listScores.length;
    console.log("Total", finalAverage.toFixed(2));
    setTotal(finalAverage.toFixed(2));
    return finalAverage.toFixed(2);
  };
  useEffect(() => {
    if (playerId) {
      async function fetchData() {
        try {
          const response = await API.graphql({
            query: queries.getPlayer,
            variables: {
              id: playerId,
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });

          const playerDetail = response?.data?.getPlayer;
          setPlayerDetails(playerDetail);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          setError(error);
          console.error("Error fetching player details:", error);
        }
      }

      fetchData();
    }

    if (playerId) {
      fetchEndedEvents(playerId);
    }
  }, [playerId]);

  useEffect(() => {
    // Calculate total averages whenever listScores changes
    if (listScores) {
      const averages = calculateTotalAverages(listScores);
      calculateTotal(listScores)
      setTotalAverages(averages);
    }
  }, [listScores]);

  return {
    listScores,
    totalAverages,
    fetchEndedEvents,
    error,
    loading,
    total,
    playerDetails,
  };
};

export default useEndedEvents;
