import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import { useParams } from "react-router-dom";

const useGetAllClinicCourses = () => {
  const [clinicCourses, setClinicCourses] = useState([]);
  const [clinicCoursesDetails, setClinicCoursesDetails] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const getClinicCourses = async () => {
    try {
      setLoading(true);
      const response = await API.graphql({
        query: queries.listClinicCourses,
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      if (!response) {
        return new Error("Something went wrong");
      }

      setClinicCourses(response?.data?.listClinicCourses?.items);

      setLoading(false);
    } catch (error) {
      setLoading(false);

      throw new Error(error);
    }
  };

  const getClinicCoursesDetail = async () => {
    try {
      setLoading(true);

      const response = await API.graphql(
        graphqlOperation(queries.getClinicCourse, { id })
      );
      if (!response) {
        return new Error("Something went wrong");
      }
      setClinicCoursesDetails(response?.data?.getClinicCourse);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      throw new Error(error);
    }
  };

  useEffect(() => {
    if (id) {
      getClinicCoursesDetail();
    }
    getClinicCourses();
  }, [id]);
  return { clinicCourses, loading, clinicCoursesDetails };
};

export default useGetAllClinicCourses;
