import React from 'react';

const SmallLoader = () => (
<div className="flex justify-center items-center ">
  <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 w-[20px] h-[20px]"></div>
</div>

);

export default SmallLoader;
