import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

const useAuthInfo = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState('');
  const [customAttributes, setCustomAttributes] = useState({});
  const [userName, setUserName] = useState('');
  const [loading, setLoading] = useState(false); // Initialize loading as true

  useEffect(() => {

    setLoading(false);
    const fetchAuthInfo = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        });

        setIsAuthenticated(true);
        setUserId(user.attributes.sub);
        setCustomAttributes(user.attributes);

        const printCustomAttributes = () => {
          for (const attribute in customAttributes) {
            if (attribute.startsWith('custom:')) {
              if (attribute === 'custom:name') {
                setUserName(customAttributes[attribute]);
              }
            }
          }
        };

        printCustomAttributes();

        setLoading(false); // Set loading to false when data is successfully retrieved
      } catch (err) {
        setIsAuthenticated(false);
        setUserId('');
        setCustomAttributes({});
        console.error('Error fetching user information:', err);
        setLoading(false); // Ensure loading is set to false even in case of an error
      }
    };

    fetchAuthInfo();
  }, []);

  return { isAuthenticated, loading, userId, userName };
};

export default useAuthInfo;
