import { useEffect, useState } from "react";
import SessionOrderBox from "../../Components/SessionOrderBox";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import usePaymentApi from "../../hooks/paymentApi";

import SessionCard from "./SessionCard";

import { useDispatch, useSelector } from "react-redux";
import useSessionCharges from "../../hooks/useSessionCharges";
import Loader from "../../Components/Loader";

const Session = () => {
  const { loading, sessionDetails } = useSessionCharges();

  const [isUpdated, setIsUpdated] = useState(false);

  const { isAuthenticated, user } = useSelector((state) => state.user);

  const [selectedItems, setSelectedItems] = useState([]);
  const [cart, setCart] = useState([]);

  const addToCart = async (item) => {
    if (!cart.find((cartItem) => cartItem.id === item.id)) {
      toast.success("Item added");
      setCart([...cart, item]);
    } else {
      toast.success("Already added");
    }
  };

  const removeFromCart = async (item) => {
    const filtered = cart?.filter((car) => car.id !== item.id);
    setCart(filtered);
    toast.success("Item removed from cart");
  };

  console.log("session Details", sessionDetails);

  const toggleItemSelection = (itemId) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="">
      <ToastContainer />

      <div className="relative text-xl p-7 ml-2 uppercase font-bold mb-4">
        Session Selection
        <p className="text-sm uppercase mt-2">
          select one or more sessions below. you can register multile people at
          the same time.
        </p>
      </div>

      <div className="md:pr-4 sm:pr-2 md:ml-1 md:pl-8 sm:ml-4 sm:pl-4 md:flex">
        <div className=" md:w-3/5 p-4 ">
          <div className="border-black mt-4 rounded-md overflow-y-auto  max-h-[800px] flex-col pt-4">
            <div className="mt-4">
              {sessionDetails?.length > 0 && (
                <span className="text-[25px] mt-2 mb-2 font-semibold">
                  Session List
                </span>
              )}
              {sessionDetails?.length === 0 ? (
                <div className="text-center">No On Going Session.</div>
              ) : (
                sessionDetails?.map((item, index) => (
                  <SessionCard
                    item={item}
                    isUpdated={isUpdated}
                    setIsUpdated={setIsUpdated}
                    key={item.id}
                    index={index}
                    addToCart={addToCart}
                    // updateCartItemById={updateCartItemById}
                    toggleItemSelection={toggleItemSelection}
                    selectedItems={selectedItems}
                  />
                ))
              )}
            </div>
          </div>
        </div>

        <div className=" w-full md:w-2/5 md:ml-4">
          <SessionOrderBox
            items={cart}
            sessions={sessionDetails}
            removeFromCart={removeFromCart}
          />
        </div>
      </div>
    </div>
  );
};

export default Session;
