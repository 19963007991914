import HeaderLg from "../../Components/HeaderLg";
import UpComingEvents from "../../Components/UpComingEvents";
import Registration from "../../Components/Registration";
import FollowUS from "../../Components/FollowUS";
import SqShop from "../../Components/SqShop";
import SignUpWK from "../../Components/SignUpWK";
import SignUpList from "../../Components/SignUPList";
import Workwith from "../../Components/Workwith";
import LgNews from "../../Components/LgNews";
import FlatVideo from "../../Components/FlatVideo";
import Advertisement from "../../Components/Advertisement";
import Sponsors from "../../Components/Sponsors";
import TypoBu from "../../Components/TypoBu";
import ImageGrid from "../../Components/ImageGrid";
import { NavLink } from "react-router-dom";
import { HiOutlineHandRaised } from "react-icons/hi2";
import { RiSuitcaseLine } from "react-icons/ri";
import useLeagueList from "../../hooks/getLeagues";
import RenderShop from "../../Components/Shop/RenderShop";
import Loader from "../../Components/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";

import Links from "../../Components/Links/Links";
import { useEffect } from "react";
import { getAllEvents } from "../../redux/actions/EventsAction";
import UpComingSkeleton from "../../Components/Skeleton/UpComingEventsSkeleton";
import MainUpComingEvents from "../../Components/UpComingEvents/MainUpComingEvents";
import useHandleEvents from "../../hooks/handleEvents";
import AdvertisementEvent from "../../Components/Advertisement/EVENTADVERTISMENT";

const LeagueHome = () => {
  const {
    events,
    nextNextToken,
    loading,
    fetchNextPage,
    fetchPreviousPage,
    previousTokens,
  } = useHandleEvents();
  // const { loading, events } = useSelector((state) => state?.events);

  return (
    <div className=" bg-[#E7E7E7] pb-10 scroll  ">
      <ToastContainer />
      <HeaderLg />
      <ImageGrid />
      <div className="px-4 md:px-10 mt-10 lg:mt-20">
        <div className="grid grid-cols-1 lg:grid-cols-7 gap-4">
          <div className="lg:col-span-5 flex flex-col gap-10">
            <TypoBu
              item={{
                text: "upcoming events",
                text_ssize: "text-3xl",
                text_bsize: "text-4xl",
                border: true,
                button: true,
                onClickRight: fetchNextPage,
                onClickLeft: fetchPreviousPage,
                disabledRight: nextNextToken === null,

                disabledLeft: !fetchPreviousPage && !previousTokens?.length > 0,
              }}
            />
            {loading ? (
              <UpComingSkeleton />
            ) : (
              <>
                {events?.length < 1 && (
                  <span className="text-center text-[25px]">
                    Currently No Events Going on
                  </span>
                )}
                {events?.length > 0 &&
                  events?.map((eventItem, index) => {
                    return (
                      <div key={index} className="flex flex-col gap-[25px]">
                        <MainUpComingEvents item={eventItem} />
                        <AdvertisementEvent one length={events.length}  index={index} />
                      </div>
                    );
                  })}
              </>
            )}
            <LgNews />
            <AdvertisementEvent  length={events.length}  />
            <FlatVideo />
          </div>
          <div className="lg:col-span-2">
            <Registration />
            <div className="mt-10">
              <FollowUS />
            </div>
            <Sponsors />
            <div className="my-5 md:my-10">
              <RenderShop />
            </div>
            <Sponsors />
            <div className="my-10">
              <SignUpList />
              {/* <SignUpWK /> */}
            </div>
            <div></div>
            {/* <Links/> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default LeagueHome;
