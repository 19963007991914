import { FaCampground } from "react-icons/fa";
import { BiBasketball } from "react-icons/bi";
import { HiOutlineHandRaised } from "react-icons/hi2";
import { RiSuitcaseLine } from "react-icons/ri";
import Teams from "../../Components/Teams";
import Shop from "../../Components/Shop";
import { useState, useEffect } from "react";
import Advertisement from "../../Components/Advertisement";
import useGetAllCity from "../../hooks/getCityList";
import useGetAllTeams from "../../hooks/getTeamsLists";
import getLeaguesList from "../../hooks/getLeagues";
import { ToastContainer, toast } from "react-toastify";
import { Link, NavLink, useLocation } from "react-router-dom";
import TypoBu from "../../Components/TypoBu";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader";
import { registerAsPlayer } from "../../redux/actions/PlayerAction";
import { clearUserErrors, loadUser } from "../../redux/actions/UserAction";
import useHandlePagintationPlayers from "../../hooks/handlePaginationPlayer";
import uploadFile from "../../utils/uploadFile";
import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";
import useSignUpAsPartner from "../../hooks/signUpPartner";


const SignUpPartner = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signUpPartnerFunc, loading: creatingPartner } = useSignUpAsPartner();

  const [isLoading, setIsLoading] = useState(false);

  const reasons = [
    "AFFILIATE",
    "ADVERTISEMENT",
    "SPONSORSHIP",
    "PARTNERSHIP"
  ];
  const {
    user,
    userDetails,
    loading: userLoading,
    error: userError,
  } = useSelector((state) => state.user);

  
  const [formData, setFormData] = useState({
    name: userDetails['name'] || '',
    organization: '',
    reason: '',
    number: userDetails['phone_number'] || '',
    email: userDetails['email'] || '',
    message: '',
  });
  const [formErrors, setFormErrors] = useState({
    name: '',
    organization: '',
    reason: '',
    number: '',
    email: '',
    message: '',
  });



  const handleRegister = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.reason || !formData.number || !formData.organization || !formData.email || !formData.message) {
      setFormErrors({
        name: formData.name ? "" : "Name is required",
        email: formData.email ? "" : "Email is required",
        message: formData.message ? "" : "Message is required",
        organization: formData.organization ? "" : "Organization is required",
        reason: formData.reason ? "" : "Reason is required",
        number: formData.number ? "" : "Phone Number is required",
      });
      return;
    }


    localStorage.setItem("formData", JSON.stringify(formData));

    await signUpPartnerFunc(formData);
    setFormData({
      name: "",
      email: "",
      message: "",
      number: "",
      organization: "",
      reason: ""
    });

  };
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    setFormErrors({
      ...formErrors,
      [e.target.id]: "",
    });
  };
  useEffect(() => {
    if (userError) {
      dispatch(clearUserErrors());
    }

  }, []);

  if (userLoading || creatingPartner) {
    return <Loader />;
  }
  return (
    <div className="">
      <ToastContainer />
      <form
        onSubmit={handleRegister}
        className="md:pr-2 w-full mt-12 sm:pr-2 md:ml-1 md:pl-2 sm:ml-2 sm:pl-2 md:flex "
      >
        <div className="md:w-2/5 ml-2 mr-2">
          <div className="text-3xl font-bold">SIGNUP FOR PARTNER</div>

          <div className="mt-4">
            <h5>Name</h5>
            <input
              className={`border-[#e7e7e7] border-2 rounded-md p-2 lg:w-96 w-full ${formErrors.name && "border-red-500" // Apply red border for error
                }`}
              type="text"
              id="name"
              value={formData.name}
              onChange={handleInputChange}
            ></input>
            <p className="text-red-500">{formErrors.name}</p>
          </div>
          <div className="mt-4">
            <h5>Organization</h5>
            <input
              className={`border-[#e7e7e7] border-2 rounded-md p-2 lg:w-96 w-full ${formErrors.name && "border-red-500" // Apply red border for error
                }`}
              type="text"
              id="organization"
              value={formData.organization}
              onChange={handleInputChange}
            ></input>
            <p className="text-red-500">{formErrors.organization}</p>
          </div>
          <div className="mt-4">
            <h5>Phone Number</h5>
            <input
              className={`border-[#e7e7e7] border-2 rounded-md p-2 lg:w-96 w-full ${formErrors.name && "border-red-500" // Apply red border for error
                }`}
              type="text"
              id="number"
              value={formData.number}
              onChange={handleInputChange}
            ></input>
            <p className="text-red-500">{formErrors.number}</p>
          </div>
          <div className="mt-4">
            <h5>Email</h5>
            <input
              className={`border-[#e7e7e7] border-2 rounded-md p-2 lg:w-96 w-full ${formErrors.email && "border-red-500" // Apply red border for error
                }`}
              type="email"
              id="email"
              value={formData.email}
              onChange={handleInputChange}
            ></input>
            <p className="text-red-500">{formErrors.email}</p>
          </div>
          

          <div className="mt-4">
            <h5 className="">Reason</h5>
            <select
              className={`block p-2.5 text-sm text-black rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 lg:w-96 w-full ${formErrors.message && "border-red-500" // Apply red border for error
                }`}
              name="reason"
              id="reason"
              value={formData.reason}
              onChange={handleInputChange}
            >
              <option value="" >
                Select Reason
              </option>
              {reasons?.map((item, index) => {
                return (
                  <option value={item} key={index}>
                    {item}
                  </option>
                );
              })}
            </select>
            <p className="text-red-500">{formErrors.reason}</p>
          </div>

          <div className="mt-4">
            <h5>Comment Box</h5>
            <textarea
              value={formData.message}
              onChange={handleInputChange}

              id="message"
              rows="4"
              className={`block p-2.5 text-sm text-black rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 lg:w-96 w-full ${formErrors.message && "border-red-500" // Apply red border for error
                }`}
            ></textarea>
            {/* Display error in red color */}
            <p className="text-red-500">{formErrors.message}</p>
          </div>
          <div className="flex pt-20 mb-5 ml-2 mr-2">
            <button
              type="submit"
              className="font-bold  bg-[#fec749] lg:px-40 px-20 whitespace-nowrap py-1"
            >
              Sign Up
            </button>
          </div>
        </div>
        <div className="md:w-3/5 p-4">
          <div className="text-center uppercase whitespace-nowrap text-xl font-bold">
            Sign Up For
          </div>
          <div className="text-center bg-white px-10 py-16">
            <div className="flex flex-wrap justify-center md:gap-[9rem]  md:flex-row flex-col ">
              <NavLink to="/signuptournament">
                <BiBasketball className="inline text-4xl mb-4" />
                <div className="font-bold mb-4 text-sm ">Tournament</div>
              </NavLink>
              <NavLink to="/signupcamp" className="mb-4">
                <FaCampground className="inline text-4xl mb-4" />
                <div className="font-bold text-sm mb-4">CAMPS</div>
              </NavLink>
              <NavLink to="/signupevent" className="mb-4">
                <img
                  src="/images/Ticket.svg"
                  alt="Ticket"
                  className="inline h-12 mb-2 w-16"
                />
                <div className="font-bold text-sm mb-4">EVENTS</div>
              </NavLink>
              <NavLink to="/signupvolunteer" className="mb-2">
                <HiOutlineHandRaised className="inline text-4xl mb-4" />
                <div className="font-bold text-sm mb-4">Volunteer</div>
              </NavLink>
              <NavLink to="/signupworkwithus" className="mb-2">
                <RiSuitcaseLine className="inline text-4xl mb-4" />
                <div className="font-bold text-sm mb-4">Work with us</div>
              </NavLink>
            </div>
          </div>
        </div>
      </form>
      <div>
        <div className="md:p-20">
          <AdvertisementOne index={0} />
        </div>
        <Teams />
        <div className="bg-[#181B22] md:p-20">
          <AdvertisementOne index={1} />
        </div>
        <div className="md:p-20 p-4 bg-[#181B22] text-white flex flex-col gap-20 py-20">
          <TypoBu
            item={{
              text: "shop",
              text_ssize: "text-3xl",
              text_bsize: "text-4xl",
              border: true,
              button: false,
            }}
          />
          <Shop />
          <div className="text-center">
            <Link
              to="/store"
              className="px-20 py-2 whitespace-nowrap border-white border rounded-sm"
            >
              VIEW ALL
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignUpPartner;
