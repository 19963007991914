import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";
import useGetAllTrainingPrograms from "../../hooks/getAllTrainingPrograms";
import Loader from "../../Components/Loader";
import TrainingProgramCard from "./TrainingProgramCard";
import useGetActivityByType from "../../hooks/useGetActivityByType";

const TrainingProgramDescription = () => {
  const { trainingPrograms, loading } = useGetAllTrainingPrograms();

  const { activity, loading:activityLoading } = useGetActivityByType('TRAINING_PROGRAM');

  if (loading || activityLoading) {
    return <Loader />;
  }
  return (
    <div className="px-4 md:px-10 mt-5">
      <div className="flex flex-col lg:flex-row items-center gap-[25px]">
        <CardContent className="w-full max-w-[1400px] mx-auto ">
          {activity && ( // Check if activity exists
            <>
              <Typography sx={{ fontSize: '3rem' }} component="div" textTransform={'uppercase'} gutterBottom>
                {activity?.title}
              </Typography>

              <Typography variant="body1" color="text.secondary">
                <div dangerouslySetInnerHTML={{ __html: activity?.description }} />
              </Typography>
            </>
          )}
        </CardContent>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 my-8">
        {trainingPrograms?.map((trainingProgram) => (
          <div key={trainingProgram.id}>
            <TrainingProgramCard item={trainingProgram} />
          </div>
        ))}
      </div>
      <AdvertisementOne index={0} />
    </div>
  );
};

export default TrainingProgramDescription;
