import React from 'react';
import './spinner.css'

const Spinner = () => {
  return (
    <div className="spinner">
      {/* You can add spinning animation here */}
    </div>
  );
};

export default Spinner;
