import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import getLink from "../utils/getLinks";

const useGetAllTeams = () => {
  const [teamslist, setTeamsList] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchAllTeams = async () => {
    setLoading(true);

    let nextToken = null;
    let allTeams = [];

    try {
      do {
        const response = await API.graphql({
          query: queries.listTeams,
          variables: { nextToken },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        const teams = response?.data?.listTeams?.items;
        nextToken = response?.data?.listTeams?.nextToken;

        const updatedData = await Promise.all(
          teams.map(async (team) => {
            try {
              const playerCount = team.players.items.length;

              if (playerCount >= 4) {
                return null;
              }

              const signedUrl = await getLink(team?.imageURL);

              return {
                ...team,
                imageURL: signedUrl,
                playerCount: playerCount,
              };
            } catch (error) {
              console.error("Error fetching player count for team:", error);
              return null;
            }
          })
        );

        allTeams = allTeams.concat(updatedData.filter((team) => team !== null));
      } while (nextToken);

      setTeamsList(allTeams);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching teams:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchAllTeams();
  }, []);

  return { teamslist, loading };
};

export default useGetAllTeams;
