import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import getLink from "../utils/getLinks";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import { useParams, useLocation } from "react-router-dom";

const useHandleEvents = () => {
  const [initialNextNextToken, setInitialNextNextToken] = useState(undefined);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState(undefined);
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState({});

  const [loading, setLoading] = useState(false);
  const [previousTokens, setPreviousTokens] = useState([]);
  const location = useLocation();

  const isHomeRoute = location.pathname === "/"; // Check if the current route is the home page

  const fetchImageFromCacheWithExpiration = async (url) => {
    const cachedData = localStorage.getItem(url);
    if (cachedData) {
      const { timestamp, imageUrl } = JSON.parse(cachedData);
      const currentTime = Date.now();

      // Check if the stored image is older than 300 seconds (5 minutes)
      if (currentTime - timestamp < 300000) {
        return imageUrl; // Use the cached image URL
      }
    }
    // If the image is older than 300 seconds or not found, fetch a new one
    const signedUrl = await loadImageAndCache(url);
    if (signedUrl) {
      // Store the new image URL with the current timestamp
      const dataToCache = JSON.stringify({
        timestamp: Date.now(),
        imageUrl: signedUrl,
      });
      localStorage.setItem(url, dataToCache);
      return signedUrl;
    }
    return null;
  };
  const loadImageAndCache = async (url) => {
    try {
      const signedUrl = await getLink(url);
      localStorage.setItem(url, signedUrl); // Store image data in cache
      return signedUrl;
    } catch (error) {
      console.error("Error fetching or caching image:", error);
      return null;
    }
  };

  const fetchEvents = async () => {
    setLoading(true);

    let variables;
    try {
      // variables = {
      //   limit: isHomeRoute ? 1 : 2, // Set limit based on route (1 for home, 2 for others)
      // };

      // if (nextToken !== null && nextToken !== undefined && nextToken !== "") {
        variables = {
     
          nextToken,
          filter: {
            eventStatus: {
              eq: "EVENT_NOT_STARTED",
            },
          },
        };
      

      const result = await API.graphql({
        query: queries.listLeagues,
        variables,
        authMode: GRAPHQL_AUTH_MODE.API_KEY, // Adjust this as needed
      });

      const fetchedEvents = result?.data?.listLeagues?.items || [];
      const updatedEvents = await Promise.all(
        fetchedEvents.map(async (product) => {
          try {
            const cachedImgUrl = await fetchImageFromCacheWithExpiration(
              product?.img_url
            );

            const dataWithImageUrl = {
              img_link: cachedImgUrl,
              ...product,
            };
            return { ...dataWithImageUrl };
          } catch (error) {
            console.error("Error fetching image URL", error);
            return product;
          }
        })
      );

      // if (!initialNextNextToken) {
      //   setInitialNextNextToken(result?.data?.listLeagues?.nextToken);
      // }

      // setNextNextToken(result?.data?.listLeagues?.nextToken);
      // if (!nextToken) {
      setEvents(updatedEvents); // If nextToken is undefined, set the Events directly
      // } else {
      //   setEvents((prevEvents) => [...prevEvents, ...updatedEvents]); // Append to existing Events
      // }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching Events:", error);
    }
  };

  const fetchEventById = async (eventId) => {
    setLoading(true);

    try {

      const result = await API.graphql(
        graphqlOperation(queries.getLeague, { id:eventId })
      );

      if (result?.data?.getLeague === null||result?.data?.getLeague===undefined) {
        return {};
      }

      const event = result.data?.getLeague;

      const cachedImgUrl = await fetchImageFromCacheWithExpiration(
        event?.img_url
      );

      const dataWithImageUrl = {
        img_link: cachedImgUrl,
        ...event,
      };

      setEvent(dataWithImageUrl)
      setLoading(false);
      // If nextToken is undefined, set the Events directly
      // } else {
      //   setEvents((prevEvents) => [...prevEvents, ...updatedEvents]); // Append to existing Events
      // }
console.log('datawithinage',dataWithImageUrl)

      // return dataWithImageUrl;
    } catch (error) {
      setLoading(false);
      console.error("Error fetching Events:", error);
      return {}
    }
  };

  const fetchNextPage = () => {
    if (nextNextToken) {
      setPreviousTokens([...previousTokens, nextToken]);
      setNextToken(nextNextToken);
    }
  };

  const fetchPreviousPage = () => {
    if (previousTokens.length > 0) {
      const prevTokens = [...previousTokens];
      const lastToken = prevTokens.pop();

      setPreviousTokens(prevTokens);
      setNextNextToken(nextToken || initialNextNextToken);
      setNextToken(lastToken);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [nextToken]);

  return {
    events,
    nextNextToken,
    loading,
    fetchNextPage,
    event,
    fetchPreviousPage,
    previousTokens,
    fetchEventById
  };
};

export default useHandleEvents;
