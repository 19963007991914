import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import Shop from "../../Components/Shop";
import { NavLink, useNavigate } from "react-router-dom"; // Import useNavigate
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import RenderShop from "../../Components/Shop/RenderShop";

const PaymentFailure = () => {
  const [countdown, setCountdown] = useState(10);
  const navigate = useNavigate(); // Use useNavigate for navigation

  useEffect(() => {
 

    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    if (countdown === 0) {
      clearInterval(timer);
      navigate("/"); // Use navigate instead of history.push
    }

    return () => {
      clearInterval(timer);
    };
  }, [countdown, navigate]);

  return (
    <>
      <div className="flex items-center justify-center px-4 h-screen">
        <div className="flex flex-col items-center">
          <img
            src="https://miro.medium.com/v2/resize:fit:810/1*OkeqV425CNZUQT2HSkTnJA.png"
            className="h-48 md:w-80 w-full lg:w-80"
            alt="Payment Failure"
          />
          <h1 className="uppercase font-bold underline text-red-500">Failed</h1>
          <p className="uppercase font-bold sm:text-left">
            Unfortunately payment was rejected!
          </p>
          <p>
            Redirecting to the home page in {countdown} sec or else click button
            below
          </p>
          <NavLink to="/" className="bg-[#fec749] mt-2 mb-2 px-4 py-2 rounded-md">
            Done
          </NavLink>
        </div>
      </div>

      <div className="p-20 bg-[#181B22] text-white">
        <div className="py-2 uppercase font-bold text-4xl border-b-2 border-[#c4c4c4]">
          <span className="border-l-4 border-[#D70D15] text-3xl px-2">shop</span>

          <div className="float-right text-3xl">
            <button className="text-[#D70D15] font-bold mr-8">
              <AiOutlineLeft className="text-lg font-bold" />
            </button>
            <button className="text-[#D70D15] font-bold">
              <AiOutlineRight className="text-lg font-bold" />
            </button>
          </div>
        </div>
        <RenderShop/>
        <div className="text-center">
          <button className="border-2 p-2 border-white rounded-md w-60 mr-20">VIEW ALL</button>
        </div>
      </div>
    </>
  );
};

export default PaymentFailure;
