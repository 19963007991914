import React from "react";

const OrderCard = ({ order }) => {
  const formattedSellingPrice = `$${Number(order?.finalPrice).toFixed(1)}`;

  return (
    <div className="border h-[150px] p-[8px] items-center flex rounded-lg overflow-hidden shadow-md">
      <img
        src={order?.img_url}
        alt={order?.title}
        className="h-[75px] w-[75px] object-cover"
      />
      <div className="p-4 flex gap-2 flex-wrap sm:flex-nowrap justify-between w-full">
        <p className="text-[1xl] font-bold  spacing-3  md:text-xl mb-2 w-full md:w-2/5 ">
          {order?.title.split(" ").slice(0, 5).join(" ")}
          {order?.title.split(" ").length > 5 ? "..." : ""}
        </p>
        <p className="hidden md:block">{order.quantity}</p>
        <p className="hidden md:block">{formattedSellingPrice}</p>
        <p className="hidden md:block">Payment Status: {order?.transactionStatus}</p>
        <p  className="w-fit text-sm">{order?.orderStatus}</p>
      </div>
    </div>
  );
};

export default OrderCard;
