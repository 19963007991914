import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import useGetAllLeagueTeamsPlayer from '../../hooks/getLeagueTeamsWithPlayer';
import OrderCardSkeleton from "../../Components/Skeleton/OrderSkeleton";
import RenderShop from "../../Components/Shop/RenderShop";
import { Link } from "react-router-dom";
import Sponsors from "../../Components/Sponsors";
import Registration from "../../Components/Registration";
import FollowUS from "../../Components/FollowUS";
import SignUpList from "../../Components/SignUPList";
import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";
import Select from 'react-select';
import useGetAllCity from "../../hooks/getCityList";

const LeagueTeamByAgeCity = () => {

  const { teamslist, loading } = useGetAllLeagueTeamsPlayer();
  console.log('teamslistysydes', teamslist);
  const ages = ["5-7", "8-9", "10-12", "13-14", "15-17", "18-40", "40+"];
  const { citylist } = useGetAllCity();

  const formattedAgeList = ages.map(age => ({ value: age, label: age }));
  const formattedCityList = citylist.map(city => ({ value: city.id, label: city.name }));

  const [ageFilter, setAgeFilter] = useState('');
  const [cityFilter, setCityFilter] = useState('');
  const nullOptionForCity = { value: '', label: 'Select City' };
  const nullOptionForAge = { value: '', label: 'Select Age Group' };

  const [page, setPage] = useState(0);

  const itemsPerPage = 10;
  const filteredPlayers = teamslist.filter((p) => {
    const cityMatch = !cityFilter || p.location.trim().toLowerCase() === cityFilter.trim().toLowerCase();
    const ageMatch = !ageFilter || p.agegroup.trim().toLowerCase() === ageFilter.trim().toLowerCase();
    // console.log('cityMatch', cityMatch, 'for', p.location, 'filter:', cityFilter);
    // console.log('ageMatch', ageMatch, 'for', p.agegroup, 'filter:', ageFilter);
    return cityMatch && ageMatch;
  });
 
  const totalPages = Math.ceil(filteredPlayers.length / itemsPerPage);
  const startIndex = page * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const slicedData = filteredPlayers.slice(startIndex, endIndex);
  console.log('slicedData', slicedData)
  const handlePageChange = (selectedPage) => {
    setPage(selectedPage.selected);
  };

  const clearFilters = () => {
    setCityFilter(null);
    setAgeFilter(null);
  };

  const [selectedTeam, setSelectedTeam] = useState(null);
  const [openAccordion, setOpenAccordion] = useState(null);



  const toggleModal = (teamId) => {
    setSelectedTeam(teamId);
  };

  const toggleAccordion = (teamId) => {
    setOpenAccordion(openAccordion === teamId ? null : teamId);
  };

  const closeModal = () => {
    setSelectedTeam(null);
    setOpenAccordion(null);
  };

  return (
    <div className=" p-8">
      <AdvertisementOne />
      <div className="px-4 md:px-10 mt-10 lg:mt-20">
        <div className="grid grid-cols-1 lg:grid-cols-7 gap-4">
          <div className="lg:col-span-5 flex flex-col gap-10">
            <h1 className="text-3xl font-semibold mb-6">League Teams</h1>

            {loading ? (
              <>
                <OrderCardSkeleton />
                <OrderCardSkeleton />
                <OrderCardSkeleton />
              </>
            ) : (
              <>
                <div className="mb-4 flex flex-wrap justify-center md:justify-start">
                <div className="mb-2 md:mb-0 mr-4">
                      <Select
                        className="w-full md:w-48 rounded-md shadow-sm border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                        placeholder="Select City"
                        options={formattedCityList}
                        value={cityFilter ? formattedCityList.find((option) => option.label === cityFilter) : nullOptionForCity}
                        onChange={(selectedOption) => setCityFilter(selectedOption.label)}
                        components={{
                          IndicatorSeparator: () => null, // Remove the dropdown indicator separator
                        }}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderRadius: '0.375rem', // Rounded corners
                            padding: '0.5rem', // Add padding
                          }),
                          menu: (provided) => ({
                            ...provided,
                            borderRadius: '0.375rem', // Rounded corners
                            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', // Add box shadow
                          }),
                        }}
                      />
                    </div>
                  <div className="w-full md:w-auto mb-2 md:mb-0 md:mr-4">
                    <Select
                      className="w-full md:w-48  rounded-md shadow-sm border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Select Age Group"
                      options={formattedAgeList}
                      value={
                        ageFilter
                          ? formattedAgeList.find((option) => option.label === ageFilter)
                          : nullOptionForAge
                      }
                      onChange={(selectedOption) => setAgeFilter(selectedOption.label)}
                      components={{
                        IndicatorSeparator: () => null, // Remove the dropdown indicator separator
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderRadius: '0.375rem', // Tailwind's rounded-md class
                          padding: '0.5rem', // Tailwind's p-2 class
                        }),
                        menu: (provided) => ({
                          ...provided,
                          borderRadius: '0.375rem', // Tailwind's rounded-md class
                          boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', // Tailwind's shadow-sm class
                        }),
                      }}
                    />
                  </div>
                  <button
                    className="w-full md:w-auto px-4 py-2 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700 transition-colors duration-200"
                    onClick={clearFilters}
                  >
                    Clear Filters
                  </button>
                </div>
                <div className="w-full overflow-x-auto bg-white rounded-lg shadow-md">
                  <table className="w-full border-collapse table-auto">
                    <thead>
                      <tr className="bg-gradient-to-r from-indigo-500 to-purple-600 text-white">
                        <th className="px-4 py-2 font-semibold text-left">SR. NO.</th>
                        <th className="px-4 py-2 font-semibold text-left">Team</th>
                        <th className="px-4 py-2 font-semibold text-left">City</th>
                        <th className="px-4 py-2 font-semibold text-left">Age Group</th>
                      </tr>
                    </thead>
                    <tbody>
                      {slicedData?.map((p, index) => (
                        <tr
                          key={index}
                          className="border-b border-gray-200 hover:bg-gray-100 transition-colors duration-200"
                        >
                          <td className="px-4 py-3">{index + 1}</td>
                          <td className="px-4 py-3">
                            <button
                              className="text-indigo-600 hover:text-indigo-800 font-semibold"
                              onClick={() => toggleModal(p.id)}
                            >
                              {p.name}
                            </button>
                          </td>
                          <td className="px-4 py-3">{p?.location}</td>
                          <td className="px-4 py-3">{p.agegroup}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {selectedTeam && (
                  <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="relative bg-white rounded-lg shadow-lg w-full max-w-3xl mx-4 sm:mx-0">
                      <button
                        className="absolute top-2 right-2 m-2 p-2 bg-white rounded-full shadow-lg text-gray-600 hover:text-gray-800"
                        onClick={closeModal}
                      >
                        <svg
                          className="h-6 w-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          ></path>
                        </svg>
                      </button>
                      <div className="p-4 mt-12">
                        <Accordion
                          team={teamslist.find((team) => team.id === selectedTeam)}
                          openAccordion={openAccordion}
                          toggleAccordion={toggleAccordion}
                        />
                      </div>
                    </div>
                  </div>
                )}


                <ReactPaginate
                  previousLabel={<BsChevronLeft className="text-orange-500" />}
                  nextLabel={<BsChevronRight className="text-orange-500" />}
                  breakLabel={'...'}
                  pageCount={totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName={'pagination flex justify-center items-center mt-4'}
                  activeClassName={'active text-orange-500'}
                  previousClassName={'border rounded-full p-2 mr-2'}
                  nextClassName={'border rounded-full p-2 ml-2'}
                  pageClassName={'m-2 cursor-pointer'}
                  breakClassName={'text-orange-500'}
                  pageLinkClassName={'underline'}
                />
              </>
            )}
          </div>
          <div className="lg:col-span-2">
            <Registration />
            <div className="mt-10">
              <FollowUS />
            </div>
            <Sponsors />
            <div className="my-5 md:my-10">
              <RenderShop />
            </div>
            <Sponsors />
            <div className="my-10">
              <SignUpList />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Accordion = ({ team, openAccordion, toggleAccordion }) => {
  return (
    <div className="border-b border-gray-300">
      <div
        className="flex justify-between items-center bg-gradient-to-r from-indigo-500 to-purple-600 text-white px-4 py-2 cursor-pointer"
        onClick={() => toggleAccordion(team.id)}
      >
        <div className="flex items-center">
          <span className="text-lg font-semibold mr-2">{team.name}</span>
        </div>
        <span>{openAccordion === team.id ? '-' : '+'}</span>
      </div>
      {openAccordion === team.id && (
        <div className="bg-gray-100 p-4">
          <ul className="  text-gray-800">
            {team.players.map((detail, idx) => (
              <li key={idx} className="mt-2 border-b border-gray-300 pb-2">
                <div className="flex justify-between items-center">
                  <span>{detail.participantName}</span>

                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};



export default LeagueTeamByAgeCity;
