import { API, graphqlOperation } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations"; // Import your GraphQL queries
// Import your GraphQL queries
import {toast } from "react-toastify";

import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import {
  REGISTER_PLAYER_REQUEST,
  REGISTER_PLAYER_SUCCESS,
  REGISTER_PLAYER_FAIL,
  UPDATE_PLAYER_REQUEST,
  UPDATE_PLAYER_SUCCESS,
  UPDATE_PLAYER_FAIL,
  GET_PLAYER_REQUEST,
  GET_PLAYER_SUCCESS,
  GET_PLAYER_FAIL,
  LOAD_PLAYERS_REQUEST,
  LOAD_PLAYERS_SUCCESS,
  LOAD_PLAYERS_FAIL,
  DELETE_PLAYER_REQUEST,
  DELETE_PLAYER_SUCCESS,
  DELETE_PLAYER_FAIL,
  DELETE_PLAYER_RESET,
  CLEAR_PLAYER_ERRORS,
} from "../constants/PlayerConstants";
import getLinks from "../../utils/getLinks";
import uploadFile from "../../utils/uploadFile";

export const getAllPlayer = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_PLAYERS_REQUEST });

    const response = await API.graphql({
      query: queries.listPlayers,
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const players = response?.data.listPlayers.items;
    const modifiedPlayers = await Promise.all(
      players?.map(async (player) => {
        const url = await getLinks(player?.profile);
        return { ...player, playerUrl: url };
      })
    );

    const verifiedPlayers = modifiedPlayers?.filter(
      (player) => player.isVerified === true
    );
    const nonVerifiedPlayers = modifiedPlayers?.filter(
      (player) => player.isVerified !== true
    );

    dispatch({
      type: LOAD_PLAYERS_SUCCESS,
      payload: {
        players: modifiedPlayers,
        verifiedPlayers,
        nonVerifiedPlayers,
      },
    });
  } catch (error) {
    dispatch({
      type: LOAD_PLAYERS_FAIL,
      payload: error.message || "Failed to fetch player data",
    });
  }
};

export const getPlayerDetails = (playerId) => async (dispatch) => {
  try {
    dispatch({ type: GET_PLAYER_REQUEST });

    const playerResponse = await API.graphql({
      query: queries.getPlayer,
      variables: {
        id: playerId,
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const playerData = playerResponse?.data?.getPlayer;

    const signedUrl = await getLinks(playerData?.profile);
    // const document = await getLinks(playerData?.document);

    const updatedData = {
      ...playerData,
      img_url: signedUrl,
      profile: signedUrl,
    };

    const data = {
      playerDetails: updatedData,
    };

    dispatch({
      type: GET_PLAYER_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    const errorMessage =
      error.response?.data?.message ||
      error.message ||
      "Failed to fetch player details";

    dispatch({
      type: GET_PLAYER_FAIL,
      payload: errorMessage,
    });
  }
};

export const getPlayerDetailsByName = (playerName) => async (dispatch) => {
  try {
    dispatch({ type: GET_PLAYER_REQUEST });

    const playerResponse = await API.graphql({
      query: queries.listPlayers,
      variables: {
        filter: {
          participantName: {
            eq: playerName,
          },
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const playerData =
      playerResponse?.data?.listPlayers?.items.length > 0
        ? playerResponse?.data?.listPlayers?.items[0]
        : [];

    const signedUrl = await getLinks(playerData?.profile);

    const updatedData = {
      ...playerData,

      profile: signedUrl,
    };

    dispatch({
      type: GET_PLAYER_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    const errorMessage =
      error.response?.data?.message ||
      error.message ||
      "Failed to fetch player details";

    dispatch({
      type: GET_PLAYER_FAIL,
      payload: errorMessage,
    });
  }
};

// export const getPlayerDetails = (playerId) => async (dispatch) => {
//   try {
//     dispatch({ type: GET_PLAYER_REQUEST });

//     const playerResponse = await API.graphql({
//       query: queries.getPlayer,
//       variables: {
//         id: playerId,
//       },
//       authMode: GRAPHQL_AUTH_MODE.API_KEY,
//     });

//     const playerData = playerResponse?.data?.getPlayer;

//     const signedUrl = await getLinks(playerData?.profile);
//     const document = await getLinks(playerData?.document);

//     const updatedData = {
//       ...playerData,
//       img_url: signedUrl,
//       profile: signedUrl,
//       document: document,
//     };

//     const data = {
//       playerDetails: updatedData,
//     };

//     dispatch({
//       type: GET_PLAYER_SUCCESS,
//       payload: data,
//     });
//   } catch (error) {
//     const errorMessage =
//       error.response?.data?.message ||
//       error.message ||
//       "Failed to fetch player details";

//     dispatch({
//       type: GET_PLAYER_FAIL,
//       payload: errorMessage,
//     });
//   }
// };

//add a new product
export const registerAsPlayer = (data, userId) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_PLAYER_REQUEST });

    const alreadyRegisters = await API.graphql({
      query: queries.listPlayers,

      variables: {
        input: {
          filter: {
            userId: {
              eq: userId,
            },
            league: {
              eq: data?.league,
            },
          },
        },
      },

      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    console.log("check program", data, userId);

    if (alreadyRegisters) {
      return;
      toast.error("Already registered")
    }
    const profileImage = await uploadFile(data.profile);
    const document = await uploadFile(data.document);

    const checkoutPayload = {
      body: {
        // success_url: "http://localhost:3000/order-success",
        // cancel_url: "http://localhost:3000/cart",
        success_url: "https://www.sfbayareabasketball.com/order-success",
        cancel_url: "https://www.sfbayareabasketball.com/payment-failure",

        ...data,
        userId,
      

        document: document,
        profile: profileImage,
      },
    };
    console.log("check program2");

    // Send a request to the Stripe API to create a checkout session
    const response = await API.post(
      "signup",
      "/signup/player",
      checkoutPayload
    );

    window.location.href = response?.url;

    console.log("check program3");

    // const isAlreadyRegisterd=alreadyRegisters?.data?.listPlayers?.items.length>0;

    // if(isAlreadyRegisterd){

    //   toast.error("Already Registerd for this Event")
    //   dispatch({
    //     type: REGISTER_PLAYER_FAIL,
    //     payload: "Already Registered for this event",
    //   });
    // }

    // const config = { headers: { "Content-Type": "multipart/form-data" } };

    // const response = await API.graphql({
    //   query: mutations.createPlayer,

    //   variables: {
    //     input: {
    //       userId,
    //       ...data,
    //       profile: profileImage,
    //       document: document,
    //     },
    //   },

    //   authMode: GRAPHQL_AUTH_MODE.API_KEY,
    // });
    // const config = { headers: { "Content-Type": "multipart/form-data" } };

    // dispatch({
    //   type: REGISTER_PLAYER_SUCCESS,
    //   payload: response?.data?.createPlayer,
    // });
    toast.success("Registered Successfully!");
    // window.location ='/';
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: REGISTER_PLAYER_FAIL,
      payload: errorMessage,
    });
  }
};

// export const updateProduct = (productId, productData) => async (dispatch) => {
//     try {
//       dispatch({ type: UPDATE_PRODUCT_REQUEST });

//       const config = {
//         headers: { "Content-Type": "application/json" },
//       };

//       const { data } = await axios.put(
//         `/api/v1/admin/product/${productId}`,
//         productData,
//         config
//       );

//       dispatch({
//         type: UPDATE_PRODUCT_SUCCESS,
//         payload: data.success,
//       });
//     } catch (error) {
//           const errorMessage =
//             error.response && error.response.data.message
//               ? error.response.data.message
//               : error.message;

//       dispatch({
//         type: UPDATE_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

// export const deleteProduct = (productId) => async (dispatch) => {
//     try {
//       dispatch({ type: DELETE_PRODUCT_REQUEST });

//       const { data } = await axios.delete(`/api/v1/admin/product/${productId}`);

//       dispatch({
//         type: DELETE_PRODUCT_SUCCESS,
//         payload: data.success,
//       });
//     } catch (error) {
//           const errorMessage =
//             error.response && error.response.data.message
//               ? error.response.data.message
//               : error.message;

//       dispatch({
//         type: DELETE_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

export const clearPlayerErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_PLAYER_ERRORS });
};
