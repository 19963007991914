import { FaCampground } from "react-icons/fa";
import { BiBasketball } from "react-icons/bi";
import { HiOutlineHandRaised } from "react-icons/hi2";
import { RiSuitcaseLine } from "react-icons/ri";
import Teams from "../../Components/Teams";
import Shop from "../../Components/Shop";
import SignUpDetail from "../../Components/SignUpDetail";
import { useState, useEffect } from "react";
import useGetAllCity from "../../hooks/getCityList";
import useGetAllTeams from "../../hooks/getTeamsLists";
import getLeaguesList from "../../hooks/getLeagues";
import { ToastContainer, toast } from "react-toastify";
import TypoBu from "../../Components/TypoBu";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader";
// import { registerAsClinic } from "../../redux/actions/ClinicAction";
import { clearUserErrors, loadUser } from "../../redux/actions/UserAction";
import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";
import useGetAllClinics from "../../hooks/getAllClinics";
import useHandleClinicMeetUser from "../../hooks/handleClinicMeetUser";
import useGetAllClinicCourses from "../../hooks/getAllClinicCourses";
import useTeamCityValidation from "../SignUpLeague/useTeamCityValidation";
import uploadFile from "../../utils/uploadFile";
import useHandleRegisterClinic from "../../hooks/useHandleRegisterClinic";
import Calendar from "../../Components/Calendar/Calendar";
import SmallLoader from "../../Components/Loader/SmallLoader";

const ages = ["5-7", "8-9", "10-12", "13-14", "15-17", "18-40", "40+"];

const SignUpClinic = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading: meetLoadng } = useHandleClinicMeetUser();
  const { registerAsClinic, loading: regClinicLoading } =
    useHandleRegisterClinic();
  const {
    user,
    userDetails,
    loading: userLoading,
    error: userError,
  } = useSelector((state) => state.user);
  const {
    clinic,
    loading: clinicLoading,
    created,
    error: clinicError,
  } = useSelector((state) => state.clinic);
  const { clinics, loading } = useGetAllClinics();
  const { clinicCourses, loading: courseLoading } = useGetAllClinicCourses();
  const { citylist } = useGetAllCity();
  const { teamslist } = useGetAllTeams();
  const { leagues } = getLeaguesList();
  const [itemlist, setItemList] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [agegroup, setAgegroup] = useState(userDetails["age"] || "");
  const [typeClinic, setClinicsType] = useState("");
  const [typeClinicError, setClinicsTypeError] = useState("");
  const [course, setCourse] = useState("");
  const [courseError, setCourseError] = useState("");

  const [ageGroupError, setAgeGroupError] = useState("");
  const [email, setEmail] = useState(userDetails["email"] || "");
  const [cellPhone, setCellPhone] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");
  const [ext, setExt] = useState("");
  const [homePhone, setHomePhone] = useState("");
  const [country, setCountry] = useState("");
  const [participant, setParticipant] = useState(
    userDetails["name"] || ""
  );
  const [cityOfRegistration, setCityOfRegistration] = useState("");
  const [nameOfRegisteredTeam, setNameOfRegisteredTeam] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [isParentGuardian, setIsParentGuardian] = useState(false);
  const [grade, setGrade] = useState("");
  // const [emailAddress, setEmailAddress] = useState("");
  const [jerseySize, setJerseySize] = useState("");
  const [isMemberOfLeague, setIsMemberOfLeague] = useState(false);
  const [schoolName, setSchoolName] = useState("");
  const [isMemberOfTeamOrClub, setIsMemberOfTeamOrClub] = useState(false);
  const [clubName, setClubName] = useState("");

  const [secondaryEmergencyContact, setSecondaryEmergencyContact] =
    useState("");
  const [primaryEmergencyContact, setPrimaryEmergencyContact] = useState("");
  const [event, setEvent] = useState("");
  const [primaryEmergencyContactEmail, setPrimaryEmergencyContactEmail] =
    useState("");
  const [primaryCallPhoneNumber, setPrimaryCallPhoneNumber] = useState("");
  const [secondaryEmergencyContactEmail, setSecondaryEmergencyContactEmail] =
    useState("");
  const [secondaryCallPhoneNumber, setSecondaryCallPhoneNumber] = useState("");
  const [howDidYouHear, setHowDidYouHear] = useState("");
  const [healthConditions, setHealthConditions] = useState(false);
  const [agreeRelease, setAgreeRelease] = useState(false);
  const [agreeActive, setAgreeActive] = useState(false);
  const [signatureType, setSignatureType] = useState(""); // Default value
  const [electronicSignature, setElectronicSignature] = useState("");
  const [profile, setProfile] = useState(""); // You can use null or an initial value
  // const { signUpLeagues, loading } = useCreateSignupLeagues();

  // const { alreadyUserLeagues } = useAlreadySignup();
  const [participantError, setParticipantError] = useState("");
  const [cityOfRegistrationError, setCityOfRegistrationError] = useState("");
  const [eventError, setEventError] = useState(""); // Add validation error state
  const [nameOfRegisteredTeamError, setNameOfRegisteredTeamError] =
    useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [cellPhoneError, setCellPhoneError] = useState("");
  const [businessPhoneError, setBusinessPhoneError] = useState("");
  const [extError, setExtError] = useState("");
  const [homePhoneError, setHomePhoneError] = useState("");
  const [profileError, setProfileError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState("");
  const [isParentGuardianError, setIsParentGuardianError] = useState("");
  const [parentEmailAddress, setParentEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(
    userDetails["phone_number"] || ""
  );

  const [gradeError, setGradeError] = useState("");
  // const [emailAddressError, setEmailAddressError] = useState("");
  const [schoolNameError, setSchoolNameError] = useState("");
  const [isMemberOfLeagueError, setIsMemberOfLeagueError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const [jerseySizeError, setJerseySizeError] = useState("");
  const [isMemberOfTeamOrClubError, setIsMemberOfTeamOrClubError] =
    useState("");
  const [clubNameError, setClubNameError] = useState("");
  const [secondaryEmergencyContactError, setSecondaryEmergencyContactError] =
    useState("");
  const [
    secondaryEmergencyContactEmailError,
    setSecondaryEmergencyContactEmailError,
  ] = useState("");
  const [secondaryCallPhoneNumberError, setSecondaryCallPhoneNumberError] =
    useState("");
  const [primaryEmergencyContactError, setPrimaryEmergencyContactError] =
    useState("");
  const [
    primaryEmergencyContactEmailError,
    setPrimaryEmergencyContactEmailError,
  ] = useState("");
  const [primaryCallPhoneNumberError, setPrimaryCallPhoneNumberError] =
    useState("");
  const [howDidYouHearError, setHowDidYouHearError] = useState("");
  const [healthConditionsError, setHealthConditionsError] = useState("");
  const [agreeReleaseError, setAgreeReleaseError] = useState("");
  const [agreeActiveError, setAgreeActiveError] = useState("");
  const [signatureTypeError, setSignatureTypeError] = useState("");
  const [electronicSignatureError, setElectronicSignatureError] = useState("");
  // State variables
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [yearsHS, setYearsHS] = useState("");
  const [yearsNBA, setYearsNBA] = useState("");
  const [yearsCollege, setYearsCollege] = useState("");
  const [updatedTeams, setUpdatedTeams] = useState(teamslist || []);
  const [loadingRegister, setLoadingRegister] = useState(false);

  // Error variables
  const [heightError, setHeightError] = useState("");
  const [weightError, setWeightError] = useState("");
  const [yearsHSError, setYearsHSError] = useState("");
  const [yearsNBAError, setYearsNBAError] = useState("");
  const [yearsCollegeError, setYearsCollegeError] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [parentEmailAddressError, setParentEmailAddressError] = useState("");
  const [newTeamImage, setNewTeamIamge] = useState(""); // You can use null or an initial value
  const [newTeam, setNewTeam] = useState("");
  const [newTeamError, setNewTeamError] = useState();
  const [newTeamImageError, setNewTeamImageError] = useState("");
  const { validateTeam, isTeamValid, errorMessage } = useTeamCityValidation();

  const handleParticipantChange = (e) => {
    setParticipant(e.target.value);
    setParticipantError(""); // Reset error
  };
  const handleDateOfBirthChange = (e) => {
    setDateOfBirth(e.target.value);
    setDateOfBirthError("");
  };
  const createNewTeam = async (e) => {
    setNewTeam(e.target.value);
    validateTeam(cityOfRegistration, e.target.value);
    setNameOfRegisteredTeamError(""); // Reset error
  };
  const handleTeamImage = async (e) => {
    const file = e.target.files[0];
    // Handle file upload here if needed
    if (file) {
      setNewTeamIamge(file);
    } else {
      setNewTeamIamge(null);
    }
    setProfileError("");
  };
  const handleCityOfRegistrationChange = (e) => {
    setCityOfRegistration(e.target.value);
    const teams = e.target.value;
    console.log("TeamList", teamslist);
    const matchingTeams = teamslist.filter(
      (team) => team.location === teams && team.agegroup === agegroup
    );

    console.log("matchingTeams", teams, matchingTeams);
    // If there are matching teams, add them to updatedTeams
    if (matchingTeams.length > 0) {
      setUpdatedTeams([...matchingTeams]);
    } else {
      setUpdatedTeams([]);
    }

    setCityOfRegistrationError(""); // Reset error
  };
  const handleAgeGroup = (e) => {
    const selectedAgeGroup = e.target.value;
    setAgegroup(selectedAgeGroup);
    const matchingTeams = teamslist.filter(
      (team) =>
        team.location === cityOfRegistration &&
        team.agegroup === selectedAgeGroup
    );

    console.log("ageGroup", teamslist, selectedAgeGroup, matchingTeams);
    // If there are matching teams, add them to updatedTeams
    if (matchingTeams.length > 0) {
      setUpdatedTeams([...matchingTeams]);
    } else {
      setUpdatedTeams([]);
    }

    setAgeGroupError("");
  };
  const handleClinicsChange = (e) => {
    setClinicsType(e.target.value);
    setClinicsTypeError("");
    console.log("Clinic Type", typeClinic);
  };
  const handleEventChange = (e) => {
    setEvent(e.target.value);
    setEventError(""); // Reset error
  };
  const handleCourseChange = (e) => {
    console.log("CourseChange", e.target.value);
    setCourse(e.target.value);
    setCourseError("");
  };
  const handleNameOfRegisteredTeamChange = (e) => {
    setNameOfRegisteredTeam(e.target.value);
    setNameOfRegisteredTeamError(""); // Reset error
  };
  const handleProfile = async (e) => {
    const file = e.target.files[0];
    // Handle file upload here if needed
    if (file) {
      setProfile(file);
    } else {
      setProfile(null);
    }
    setProfileError("");
  };
  // onChange functions
  const handleHeightChange = (e) => {
    setHeight(e.target.value);
    setHeightError("");
  };

  const handleWeightChange = (e) => {
    setWeight(e.target.value);
    setWeightError("");
  };

  const handleYearsHSChange = (e) => {
    setYearsHS(e.target.value);
    setYearsHSError("");
  };

  const handleYearsNBAChange = (e) => {
    setYearsNBA(e.target.value);
    setYearsNBAError("");
  };

  const handleYearsCollegeChange = (e) => {
    setYearsCollege(e.target.value);
    setYearsCollegeError("");
  };

  // console.log('dateOfBirth',dateOfBirth)
  const handleRegister = async (e) => {
    e.preventDefault();
    let hasErrors = false; // Validation functions

    if (!height) {
      setHeightError("Height is required.");
      hasErrors = true;
    }
  
    if (!weight) {
      setWeightError("Weight is required.");
      hasErrors = true;
    }
    if (!yearsHS) {
      setYearsHSError("No. of Years Played in HS is required.");
      hasErrors = true;
    }
    if (yearsHS && !/^\d+$/.test(yearsHS)) {
      setYearsHSError("Only Numeric values for Years Played in HS");
      hasErrors = true;
    }
    if (!yearsNBA) {
      setYearsNBAError("No. of Years played as pro is required.");
      hasErrors = true;
    }

    if (yearsNBA && !/^\d+$/.test(yearsNBA)) {
      setYearsNBAError("only Numeric values for Years played as pro");
      hasErrors = true;
    }
    if (!yearsCollege) {
      setYearsCollegeError("No. of Years Played in College is required.");
      hasErrors = true;
    }


    if (yearsCollege && !/^\d+$/.test(yearsCollege)) {
      setYearsCollegeError(
        "Only Numeric values for Years Played in College"
      );
      hasErrors = true;
    }

    if (!firstName) {
      setFirstNameError("First Name is required.");
      hasErrors = true;
    }
    if (!agegroup) {
      setAgeGroupError("Age Group is required.");
      hasErrors = true;
    }
    if (!phoneNumber) {
      setPhoneNumberError("Phone Number is required.");
      hasErrors = true;
    }
    if (!email) {
      setEmailError("Email address is required.");
      hasErrors = true;
    }

    if (!lastName) {
      setLastNameError("Last Name is required.");
      hasErrors = true;
    }

    if (!cellPhone) {
      setCellPhoneError("cell Phone is required.");
      hasErrors = true;
    }

    if (!businessPhone) {
      setBusinessPhoneError("Business Phone is required.");
      hasErrors = true;
    }
    if (!ext) {
      setExtError("Ext is required.");
      hasErrors = true;
    }
    if (!homePhone) {
      setHomePhoneError("Home Phone is required.");
      hasErrors = true;
    }
    if (!participant) {
      setParticipantError("Participant is required.");
      hasErrors = true;
    }

    if (!cityOfRegistration) {
      setCityOfRegistrationError("City of Registration is required.");
      hasErrors = true;
    }

    // if (!event) {
    //   setEventError("Event is required.");
    //   hasErrors = true;
    // }

    if (!course) {
      setCourseError("Course is required.");
      hasErrors = true;
    }

  if (!typeClinic) {
      setClinicsTypeError("Clinic is required.");
      hasErrors = true;
    }

    if (!nameOfRegisteredTeam) {
      setNameOfRegisteredTeamError("Name of Registered Team is required.");
      hasErrors = true;
    }

    if (nameOfRegisteredTeam === "Other Teams") {
      if (!newTeam) {
        setNewTeamError("Team Name is required.");
        hasErrors = true;
      }
      if (!newTeamImage) {
        setNewTeamImageError("Image is required.");
        hasErrors = true;
      }
    }

    if (!dateOfBirth) {
      setDateOfBirthError("Date of Birth is required.");
      hasErrors = true;
    }
   
    if (!jerseySize) {
      setJerseySizeError("Jersey Size is required.");
      hasErrors = true;
    }
    if (!schoolName) {
      setSchoolNameError("School Name is required.");
      hasErrors = true;
    }
    if (!clubName) {
      setClubNameError("Club Name is required.");
      hasErrors = true;
    }

    if (!profile) {
      setProfileError("Profile is required.");
      hasErrors = true;
    }
    if (!country) {
      setCountryError("Country is required.");
      hasErrors = true;
    }

    if (!isParentGuardian) {
      setIsParentGuardianError("Is Parent Guardian is required.");
      hasErrors = true;
    }

    if (!grade) {
      setGradeError("Grade is required.");
      hasErrors = true;
    }
    if (!parentEmailAddress) {
      setParentEmailAddressError("Email Address is required.");
      hasErrors = true;
    }
 
    if (!isMemberOfLeague) {
      setIsMemberOfLeagueError("Is Member Of League is required.");
      hasErrors = true;
    }

    if (!isMemberOfTeamOrClub) {
      setIsMemberOfTeamOrClubError("Is Member Of Team Or Club is required.");
      hasErrors = true;
    }
 
    if (!secondaryEmergencyContact) {
      setSecondaryEmergencyContactError(
        "Secondary Emergency Contact is required."
      );
      hasErrors = true;
    }
    if (!primaryEmergencyContact) {
      setPrimaryEmergencyContactError("Primary Emergency Contact is required.");
      hasErrors = true;
    }
    if (!secondaryEmergencyContactEmail) {
      setSecondaryEmergencyContactEmailError("Email is required.");
      hasErrors = true;
    }
    if (!primaryEmergencyContactEmail) {
      setPrimaryEmergencyContactEmailError("Email is required.");
      hasErrors = true;
    }
    if (!secondaryCallPhoneNumber) {
      setSecondaryCallPhoneNumberError("Cell Phone Number is required.");
      hasErrors = true;
    }
    if (!primaryCallPhoneNumber) {
      setPrimaryCallPhoneNumberError("Cell Phone Number is required.");
      hasErrors = true;
    }
    if (!howDidYouHear) {
      setHowDidYouHearError("How Did You Hear is required.");
      hasErrors = true;
    }

    if (!healthConditions) {
      setHealthConditionsError("Health Condition is required.");
      hasErrors = true;
    }

    if (!agreeRelease) {
      setAgreeReleaseError("Agree Release is required.");
      hasErrors = true;
    }

    if (!agreeActive) {
      setAgreeActiveError("Agree Active is required.");
      hasErrors = true;
    }

    // if (!signatureType) {
    //   setSignatureTypeError("Signature Type is required.");
    //     hasErrors = true;
    // }

    if (!electronicSignature) {
      setElectronicSignatureError("Electronic Signature is required.");
      hasErrors = true;
    }

    if (hasErrors) {
      // Display validation errors and prevent form submission
      return;
    }
  
    const checkRequiredFields = () => {
      const requiredFields = [
        participant,
        cityOfRegistration,
        nameOfRegisteredTeam,
        dateOfBirth,
        isParentGuardian,
        ext,
        typeClinic,
        course,
        jerseySize,
        email,
        agegroup,
        profile,
        grade,
        schoolName,
        isMemberOfTeamOrClub,
        clubName,
        primaryEmergencyContact,
        secondaryEmergencyContact,
        primaryCallPhoneNumber,
        phoneNumber,
        secondaryCallPhoneNumber,
        primaryEmergencyContactEmail,
        secondaryEmergencyContactEmail,
        howDidYouHear,
        healthConditions,
        firstName,
        lastName,
        parentEmailAddress,
        cellPhone,
        businessPhone,
        homePhone,
        country,
        electronicSignature,
        height,
        weight,
        yearsHS,
        yearsNBA,
        yearsCollege,
      ];
      // Check if nameOfRegisteredTeam is "Other Teams" and add newTeam and newTeamImage to required fields
      if (nameOfRegisteredTeam === "Other Teams") {
        requiredFields.push(newTeam, newTeamImage);
      }
      // Check if any required field is empty
      const isEmpty = requiredFields.some((field) => field === "");

      return !isEmpty; // Return true if all required fields are filled
    };
    const allRequiredFilled = checkRequiredFields();
   
    if (allRequiredFilled) {
    setLoadingRegister(true);
     
       
    const data = {
      participantName: participant,
      cityOfRegistration: cityOfRegistration,
      teamName: newTeam || nameOfRegisteredTeam,
      dateOfBirth: dateOfBirth,
      isAdult: isParentGuardian,
      message,
      ext,
      transactionStatus: "PENDING",
      agegroup,
      jerseySize,
      teamImage: newTeamImage,
      email,
      profile,
      grade,
      schoolName,
      isMemberOfOtherTeam: isMemberOfTeamOrClub,
      clubName: clubName,
      primaryPersonName: primaryEmergencyContact,
      secondaryPersonName: secondaryEmergencyContact,
      primaryCallPhoneNumber,
      phoneNumber,
      secondaryCallPhoneNumber,
      primaryEmergencyContactEmail,
      secondaryEmergencyContactEmail,
      heardAboutLeague: howDidYouHear,
      healthConditions: healthConditions,
      parentFirstName: firstName,
      parentLastName: lastName,
      parentEmailAddress: email,
      parentPhoneNumber: cellPhone,
      parentBusinessPhoneNumber: businessPhone,
      parentHomePhoneNumber: homePhone,
      parentCountry: country,
      signature: electronicSignature,
      HEIGHT: height,
      WEIGHT: weight,
      H_S: yearsHS,
      NBA: yearsNBA,
      COLLEGE: yearsCollege,
      type: typeClinic,
      courseID: [course],
    };
      localStorage.setItem("formData", JSON.stringify(data));
      dispatch(registerAsClinic(data));
     
    }
    setLoadingRegister(false);

    setParticipantError("");
    setAgeGroupError("");
    setCityOfRegistrationError("");
    // setEventError(""); // Reset error
    setNameOfRegisteredTeamError("");
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setCellPhoneError("");
    setBusinessPhoneError("");
    setExtError("");
    setHomePhoneError("");
    setProfileError("");
    setCountryError("");
    setDateOfBirthError("");
    setIsParentGuardianError("");
    setGradeError("");
    // setEmailAddressError("");
    setParentEmailAddressError("");
    setSchoolNameError("");
    setIsMemberOfLeagueError("");
    setJerseySizeError("");
    setIsMemberOfTeamOrClubError("");
    setClubNameError("");
    setSecondaryEmergencyContactError("");
    setSecondaryEmergencyContactEmailError("");
    setSecondaryCallPhoneNumberError("");
    setPrimaryEmergencyContactError("");
    setPrimaryEmergencyContactEmailError("");
    setPrimaryCallPhoneNumberError("");
    setPhoneNumberError("");
    setHowDidYouHearError("");
    setHealthConditionsError("");
    setAgreeReleaseError("");
    setAgreeActiveError("");
    setSignatureTypeError("");
    setElectronicSignatureError("");
    setHeightError("");
    setYearsCollegeError("");
    setWeightError("");
    setYearsNBAError("");
    setYearsHSError("");
    setCourseError("");
    setNewTeamImageError("");
    setNewTeamError("");
  
  };
  const stateSetters = {
    message,
    setMessage,
    showMessage,
    setShowMessage,
    setFirstName,
    firstName,
    setLastName,
    lastName,
    setEmail,
    email,
    setParentEmailAddress,
    parentEmailAddress,
    setCellPhone,
    cellPhone,
    setBusinessPhone,
    businessPhone,
    setExt,
    ext,
    setHomePhone,
    homePhone,
    setCountry,
    country,
    setDateOfBirth,
    dateOfBirth,
    setIsParentGuardian,
    isParentGuardian,
    setGrade,
    grade,
    // setEmailAddress,
    // emailAddress,
    setSchoolName,
    schoolName,
    setIsMemberOfLeague,
    isMemberOfLeague,
    setJerseySize,
    jerseySize,
    setIsMemberOfTeamOrClub,
    isMemberOfTeamOrClub,
    setClubName,
    clubName,
    setSecondaryEmergencyContact,
    secondaryEmergencyContact,
    setPrimaryEmergencyContact,
    primaryEmergencyContact,
    setPrimaryEmergencyContactEmail,
    primaryEmergencyContactEmail,
    setPrimaryCallPhoneNumber,
    primaryCallPhoneNumber,
    setPhoneNumber,
    phoneNumber,
    setSecondaryEmergencyContactEmail,
    secondaryEmergencyContactEmail,
    setSecondaryCallPhoneNumber,
    secondaryCallPhoneNumber,
    setHowDidYouHear,
    howDidYouHear,
    setHealthConditions,
    healthConditions,
    setAgreeRelease,
    agreeRelease,
    setAgreeActive,
    agreeActive,
    setSignatureType,
    signatureType,
    setElectronicSignature,
    electronicSignature,
    setFirstNameError,
    firstNameError,
    setLastNameError,
    lastNameError,
    setEmailError,
    emailError,
    setCellPhoneError,
    cellPhoneError,
    setBusinessPhoneError,
    businessPhoneError,
    setExtError,
    extError,
    setHomePhoneError,
    homePhoneError,
    setCountryError,
    countryError,
    setDateOfBirthError,
    dateOfBirthError,
    setIsParentGuardianError,
    isParentGuardianError,
    setGradeError,
    gradeError,
    // setEmailAddressError,
    // emailAddressError,
    setSchoolNameError,
    schoolNameError,
    setIsMemberOfLeagueError,
    isMemberOfLeagueError,
    setJerseySizeError,
    jerseySizeError,
    setIsMemberOfTeamOrClubError,
    isMemberOfTeamOrClubError,
    setClubNameError,
    clubNameError,
    setSecondaryEmergencyContactError,
    secondaryEmergencyContactError,
    setPrimaryEmergencyContactError,
    primaryEmergencyContactError,
    setPrimaryEmergencyContactEmailError,
    primaryEmergencyContactEmailError,
    setPrimaryCallPhoneNumberError,
    primaryCallPhoneNumberError,
    setPhoneNumberError,
    phoneNumberError,
    setSecondaryEmergencyContactEmailError,
    secondaryEmergencyContactEmailError,
    setSecondaryCallPhoneNumberError,
    secondaryCallPhoneNumberError,
    setHowDidYouHearError,
    howDidYouHearError,
    setHealthConditionsError,
    healthConditionsError,
    setAgreeReleaseError,
    agreeReleaseError,
    setAgreeActiveError,
    agreeActiveError,
    setSignatureTypeError,
    signatureTypeError,
    setElectronicSignatureError,
    electronicSignatureError,
    setParentEmailAddressError,
    parentEmailAddressError

  };

  useEffect(() => {
    if (userError) {
      dispatch(clearUserErrors());
    }

    if (userError) {
      dispatch(clearUserErrors());
    }
  }, [created]);

  if (
    userLoading ||
    meetLoadng ||
    clinicLoading ||
    loading ||
    regClinicLoading
  ) {
    return <Loader />;
  }
  return (
    <div className="">
      <ToastContainer />
      <form  onSubmit={handleRegister} className="md:pr-2 mt-12 sm:pr-2 md:ml-1 md:pl-2 sm:ml-2 sm:pl-2 md:flex ">
        <div className="md:w-2/5 ml-2 mr-2">
          <div className="text-3xl font-bold">SIGNUP FOR CLINIC</div>
          <div className="mt-4">
            <h5>Participant</h5>
            <input
              name="participant"
              type="text"
            
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              value={participant}
              onChange={handleParticipantChange}
            />
            <span className="text-red-500">{participantError}</span>
          </div>

          <div className="mt-4">
            <h5 className="">Clinics</h5>
            <select
              name="Clinics"
              id="age"
         
              value={typeClinic}
              onChange={handleClinicsChange}
              className="  border-[#e7e7e7] uppercase border-2 rounded-md p-2 w-full "
            >
              <option value="" className="uppercase">
                Select Clinics
              </option>
              {clinics?.map((item, index) => (
                <option value={item?.title} className="uppercase" key={index}>
                  {item?.title}
                </option>
              ))}
            </select>
            <span className="text-red-500">{typeClinicError}</span>
          </div>
          <div className="mt-4">
            <h5 className="">Course</h5>
            <select
              name="TrainingProgramCourse"
              id="program"
              value={course}
              onChange={handleCourseChange}
              className="  border-[#e7e7e7] uppercase border-2 rounded-md p-2 w-full "
            >
              <option value="" className="uppercase">
                Select Course
              </option>
              {clinicCourses?.map((item, index) => {
                return (
                  <option
                    value={item?.id}
                    className="uppercase"
                    key={index?.id}
                  >
                    {item?.title} - ${item?.price}
                  </option>
                );
              })}
            </select>
            <span className="text-red-500">{courseError}</span>
          </div>

          <div className="mt-2 scheduleMeet">
            {/* <MeetCalendarCopy id={course} clinic={true} /> */}
            {course && <Calendar appointmentId={course} />}
          </div>
          <div className="mt-4">
            <h5>Date of birth</h5>
            <input
              type="date"
              value={dateOfBirth}
              className="border border-2"
              onChange={handleDateOfBirthChange}
            />
          </div>
          <span className="text-red-500 pb-2">{dateOfBirthError}</span>
          <div className="mt-4">
            <h5 className="">Age</h5>
            <select
              name="age"
              id="age"
              value={agegroup}
              onChange={handleAgeGroup}
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
            >
              <option value="">Age</option>
              {ages?.map((item, index) => {
                return (
                  <option value={item} key={index}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          <span className="text-red-500 pb-2">{ageGroupError}</span>
          <div className="mt-4">
            <h5>City of Registration</h5>
            <select
              name="city_of_registration"
              id="city_of_registration"
       
              value={cityOfRegistration}
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              onChange={handleCityOfRegistrationChange}
            >
              <option value="" defaultValue="">
                Choose option
              </option>

              {citylist?.map((value, index) => (
                <option value={value.name} key={index}>
                  {value.name}
                </option>
              ))}
            </select>
            <span className="text-red-500">{cityOfRegistrationError}</span>
          </div>
          {/* <div className="mt-4">
            <h5>Event Signing up for</h5>
            <select
              name="event"
              id="event"
           
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              value={event}
              onChange={handleEventChange}
            >
              <option value="" defaultValue="">
                Choose option
              </option>

              {leagues?.map((value, index) => (
                <option value={value?.title} key={index}>
                  {value?.title}
                </option>
              ))}
            </select>
            <span className="text-red-500">{eventError}</span>
          </div> */}
          <div className="mt-4">
            <h5>Name of registered team</h5>
            <select
              name="name_of_registered_team"
              id="name_of_registered_team"
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              value={nameOfRegisteredTeam}
              onChange={handleNameOfRegisteredTeamChange}
            >
              <option value="" defaultValue="">
                Choose Teams
              </option>

              {updatedTeams?.map((value, index) => (
                <option value={value.name} key={index}>
                  {value.name}
                </option>
              ))}
              <option value="Other Teams">Other Teams</option>
            </select>
            {nameOfRegisteredTeam === "Other Teams" && (
              <div className="flex flex-col gap-1">
                <input
                  name="team"
                  type="text"
                  className="border-[#e7e7e7] mt-2 border-2 rounded-md p-2 w-full"
                  value={newTeam}
                  placeholder="Team Name"
                  onChange={createNewTeam}
                />
                <span className="text-red-500">{newTeamError}</span>

                {errorMessage && (
                  <p className="text-red-500 py-2">errorMessage</p>
                )}
                <label
                  htmlFor="teamImageInput"
                  className="cursor-pointer bg-gray-800 max-w-fit hover:bg-gray-600 text-white py-2 px-4 rounded-lg"
                >
                  Upload Team Image
                </label>
                <input
                  type="file"
                  id="teamImageInput"
                  className="hidden"
                  onChange={handleTeamImage}
                />
                {newTeamImage && (
                  <p className="mt-2">Selected File: {newTeamImage.name}</p>
                )}
                <span className="text-red-500">{newTeamImageError}</span>

              </div>
            )}
            <span className="text-red-500">{nameOfRegisteredTeamError}</span>
          </div>
          <div className="mt-4 mb-2">
            <h5 className="mb-2">Upload Profile Picture</h5>
            <label
              htmlFor="profileInput"
              className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg"
            >
              Upload Profile Picture
            </label>
            <input
              type="file"
              id="profileInput"
              className="hidden"
              onChange={handleProfile}
            />
            {profile && <p className="mt-2">Selected File: {profile.name}</p>}
          </div>
          <span className="text-red-500 pb-2">{profileError}</span>

          <div className="mt-4">
            <h5>Height</h5>
            <input
              name="height"
              type="text"
          
              className="border-[#e7e7e7] border-2 rounded-md p-2 w-full"
              value={height}
              onChange={handleHeightChange}
            />
            <span className="text-red-500 pb-2">{heightError}</span>
          </div>
          <div className="mt-4">
            <h5>Weight</h5>
            <input
              name="weight"
              type="text"
         
              className="border-[#e7e7e7] border-2 rounded-md p-2 w-full"
              value={weight}
              onChange={handleWeightChange}
            />
            <span className="text-red-500 pb-2">{weightError}</span>
          </div>
          <div className="mt-4">
            <h5>Years Played in HS</h5>
            <input
              name="years_high_school"
              type="text"
          
              pattern="\d*"
              className="border-[#e7e7e7] border-2 rounded-md p-2 w-full"
              value={yearsHS}
              onChange={handleYearsHSChange}
            />
            <span className="text-red-500 pb-2">{yearsHSError}</span>
          </div>
          <div className="mt-4">
            <h5>Years played as pro</h5>
            <input
              name="years_nba"
              type="text"
        
              pattern="\d*"
              className="border-[#e7e7e7] border-2 rounded-md p-2 w-full"
              value={yearsNBA}
              onChange={handleYearsNBAChange}
            />
            <span className="text-red-500 pb-2">{yearsNBAError}</span>
          </div>
          <div className="mt-4">
            <h5>Years Played in·College</h5>
            <input
              name="years_college"
              type="text"
         
              pattern="\d*"
              className="border-[#e7e7e7] border-2 rounded-md p-2 w-full"
              value={yearsCollege}
              onChange={handleYearsCollegeChange}
            />
            <span className="text-red-500 pb-2">{yearsCollegeError}</span>
          </div>
          <SignUpDetail stateSetters={stateSetters} />
          <div className="flex pt-20 mb-5 ml-2 mr-2">
          <button
              type="submit"
              disabled={regClinicLoading || loadingRegister}
              className="font-bold  bg-[#fec749] lg:px-40 px-20 whitespace-nowrap py-1"
            >
              {regClinicLoading ? <SmallLoader /> : <span>Sign Up</span>}
            </button>
          </div>
        </div>
        <div className="md:w-3/5 p-4">
          <div className="text-center uppercase whitespace-nowrap text-xl font-bold">
            Sign Up For
          </div>
          <div className="text-center bg-white px-10 py-16">
            <div className="flex flex-wrap justify-center md:gap-[9rem]  md:flex-row flex-col ">
              <NavLink to="/signuptournament">
                <BiBasketball className="inline text-4xl mb-4" />
                <div className="font-bold mb-4 text-sm ">Tournament</div>
              </NavLink>
              <NavLink to="/signupcamp" className="mb-4">
                <FaCampground className="inline text-4xl mb-4" />
                <div className="font-bold text-sm mb-4">CAMPS</div>
              </NavLink>
              <NavLink to="/signupevent" className="mb-4">
                <img
                  src="/images/Ticket.svg"
                  alt="Ticket"
                  className="inline h-12 mb-2 w-16"
                />
                <div className="font-bold text-sm mb-4">EVENTS</div>
              </NavLink>
              <NavLink to="/signupvolunteer" className="mb-2">
                <HiOutlineHandRaised className="inline text-4xl mb-4" />
                <div className="font-bold text-sm mb-4">Volunteer</div>
              </NavLink>
              <NavLink to="/signupworkwithus" className="mb-2">
                <RiSuitcaseLine className="inline text-4xl mb-4" />
                <div className="font-bold text-sm mb-4">Work with us</div>
              </NavLink>
            </div>
          </div>
        </div>
      </form>
      <div className="bg-[#181B22] text-white">
        <div className="md:p-20">
          <AdvertisementOne index={0} />
        </div>
        <Teams />
        <div className="md:p-20 b-[#181B22]">
          <AdvertisementOne index={1} />
        </div>
        <div className="md:p-20 p-4 b-[#181B22] flex flex-col gap-20 py-20">
          <TypoBu
            item={{
              text: "shop",
              text_ssize: "text-3xl",
              text_bsize: "text-4xl",
              border: true,
              button: false,
            }}
          />
          <Shop />
          <div className="text-center">
            <Link
              to="/store"
              className="px-20 py-2 whitespace-nowrap border-white border rounded-sm"
            >
              VIEW ALL
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignUpClinic;
