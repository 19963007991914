import React from "react";
import SocialMediaShareButtons from "../SocialMediaShareButtons";
import { NavLink } from "react-router-dom";

const FlatVideoCard = ({ item }) => {
  console.log("ItemsVlog", item);
  return (
    <div className="max-w-md mx-auto">
      <div className="bg-white transition-all ease-in-out duration-300 border hover:shadow-xl max-w-[300px] w-auto h-full rounded-md overflow-hidden">
        <NavLink to={`/vlog/${item.id}`} className="flex flex-col h-full">
          <div className="w-full min-h-64 h-auto object-cover">
            <iframe
              width="351"
              height="200"
              className="w-full h-full"
              src={item?.youtube_url ? item?.youtube_url : item?.video_url}
              title={item?.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <div className="uppercase text-sm mt-4 px-4 font-bold overflow-hidden flex-grow">
            {item?.title.split(" ").slice(0, 10).join(" ")}
            {item?.title.split(" ").length > 10 ? "..." : ""}
          </div>
          <div className="flex justify-end items-center p-4">
            {item?.video_url && item?.title && (
              <SocialMediaShareButtons
                url={item?.video_url}
                title={item?.title}
              />
            )}
          </div>
        </NavLink>
      </div>
    </div>
  );
};

export default FlatVideoCard;
