import React, { useEffect, useState } from "react";
import Spinner from "../../Components/Loader/Spinner";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Components/Loader";
import { Storage } from "aws-amplify";
import { Link } from "react-router-dom";
import { getAllPlayer } from "../../redux/actions/PlayerAction";
import TypoBu from "../../Components/TypoBu";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const LeaguePlayerShow = () => {
  const { players, loading } = useSelector((state) => state.player);
  const dispatch = useDispatch();
  const [startIndex, setStartIndex] = useState(0);

  const handleLeftClick = () => {
    console.log("left click");
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  const handleRightClick = () => {
    if (startIndex < players.length - 3) {
      setStartIndex(startIndex + 1);
    }
  };
  useEffect(() => {
    dispatch(getAllPlayer());
    console.log('players',players)
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <div
        className={`py-2 uppercase font-bold  border-b-2  text-[32px]  border-[#c4c4c4] " : ""
        }`}
      >
        <span className="text-[#D70D15] font-bold">|</span>
        <span className="">Players</span>
        <div className="float-right text-3xl">
          <button
            onClick={handleLeftClick}
            className="text-[#D70D15] font-bold mr-8"
          >
            <AiOutlineLeft className="text-lg font-bold" />
          </button>
          <button
            onClick={handleRightClick}
            className="text-[#D70D15] font-bold"
          >
            <AiOutlineRight className="text-lg font-bold" />
          </button>
        </div>
      </div>

      {players?.slice(startIndex, startIndex + 3)?.map((player, index) => (
        <Link
          to={`/leagueteam/${player?.id}`}
          key={index}
          className="bg-white  cursor-pointer flex my-10 p-2"
        >
          {player?.playerUrl ? (
            <img
              src={player?.playerUrl}
              className="w-[150px] h-[150px]  object-contain overflow-hidden"
              alt={player?.participantName}
            />
          ) : (
            <Spinner /> // Show a loading spinner here
          )}
          <div className="px-8 py-4 m-auto">
            <img
              alt="sanjose"
              src="/images/sanjose.png"
              className="h-10 pr-4 inline"
            />
            <span className="inline-block">{player?.registeredTeamName}</span>
            <span className="font-bold text-lg block">
              {player?.participantName}
            </span>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default LeaguePlayerShow;
