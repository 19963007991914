
import { Storage } from 'aws-amplify';

  const deleteFile = async (key) => {
   
    if (!key) {
      console.error('Provide Key');
     
      return false;
    }
    console.log('Deleting ...', key);

    try {
      await Storage.remove(key); // Delete the video from S3
     
      console.log('deleted successfully');
      return true;
    } catch (error) {
      console.error('Error deleting :', error);
      
      return false;
    }
  };


export default deleteFile;
