import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { getAddress } from "../graphql/queries";
import * as query from "../graphql/queries";

import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import useAuthInfo from "./getUser";
import useCreateAddress from "./createAddress";
import { getAddressQuery } from "../graphql/customQueries";

const useGetAddressList = () => {
  const [addressesList, setAddressesList] = useState([]);
  const { userId, isAuthenticated } = useAuthInfo();
  const { createAddress } = useCreateAddress();
  const [loading, setLoading] = useState(false);

  const fetchAddressesList = async () => {
    try {
      if (isAuthenticated && userId) {
        setLoading(true);

        const response = await API.graphql({
          query: query.getAddress,
          variables: { id: userId },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        const addresses = response?.data?.getAddress?.addresses;
        if (!addresses) {
          const addressResponses = await createAddress(userId);
        }
        const addressesWithoutTypename = addresses?.map((address) => {
          const { __typename, ...rest } = address;
          return rest;
        });
        // if (!addresses || addresses.length === 0) {
        //   // Create a new address if the addresses array is null or empty

        //   const createAddressResponse = await API.graphql(
        //     graphqlOperation(mutations.createAddress, {
        //       input: {
        //         id:userId,
        //         userid: userId,
        //         addresses:[]
        //       },
        //     })
        //   );

        //   const createdAddress = createAddressResponse?.data?.createAddress;

        //   if (createdAddress) {
        //     // Update the addresses list with the newly created address
        //     setAddressesList(createdAddress);
        //   }
        // } else {
        //   // If addresses exist, set them in the state
        //   setAddressesList(addressesWithoutTypename);
        // }
        console.log("addressres", response);
        setAddressesList(addressesWithoutTypename);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // Log any other unexpected errors
      console.error("Error fetching AddressesList:", error);
      throw error;
    }
  };
  useEffect(() => {
    fetchAddressesList();
  }, [isAuthenticated, userId]);

  return { addressesList, loading, fetchAddressesList };
};

export default useGetAddressList;
