// ImageGrid.js

import React from 'react';

function ImageGrid() {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 m-10">
      {images.map((image, index) => (
        <img key={index} src={image.src} alt={image.alt} />
      ))}
    </div>
  );
}

export default ImageGrid;
// Define the images array outside the component
const images = [
  { src: '/images/lg_sonics.png', alt: 'LG_SONICS' },
  { src: '/images/lg_sizzle.png', alt: 'lg_sizzle' },
  { src: '/images/lg_hoopitup.png', alt: 'lg_hoopitup' },
];