/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";
import { toast } from "react-toastify";
import getLink from "../utils/getLinks";
import { useDispatch, useSelector } from "react-redux";
import deleteFile from "../utils/deleteFile";
import * as queries from "../graphql/queries";
import uploadFile from "../utils/uploadFile";
import * as mutations from "../graphql/mutations";
import useGetAllTeams from "./getTeamsLists";

const useHandleRegisterClinic = () => {
  const { teamslist } = useGetAllTeams();

  const { user, isAuthenticated } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);

  const fetchImageFromCacheWithExpiration = async (url) => {
    const cachedData = localStorage.getItem(url);
    if (cachedData) {
      const { timestamp, imageUrl } = JSON.parse(cachedData);
      const currentTime = Date.now();

      // Check if the stored image is older than 300 seconds (5 minutes)
      if (currentTime - timestamp < 300000) {
        return imageUrl; // Use the cached image URL
      }
    }
    // If the image is older than 300 seconds or not found, fetch a new one
    const signedUrl = await loadImageAndCache(url);
    if (signedUrl) {
      // Store the new image URL with the current timestamp
      const dataToCache = JSON.stringify({
        timestamp: Date.now(),
        imageUrl: signedUrl,
      });
      localStorage.setItem(url, dataToCache);
      return signedUrl;
    }
    return null;
  };
  const loadImageAndCache = async (url) => {
    try {
      const signedUrl = await getLink(url);
      localStorage.setItem(url, signedUrl); // Store image data in cache
      return signedUrl;
    } catch (error) {
      console.error("Error fetching or caching image:", error);
      return null;
    }
  };

  const registerAsClinic = async (data) => {
    try {
      setLoading(true);
    
      const teamName = data.teamName;
      const isTeam = teamslist?.filter((team) => team.name === teamName);
      let teamID = isTeam[0]?.id;
      data.teamID = teamID;
      console.log("!isTeam", isTeam, teamslist);

      if (isTeam.length === 0) {
        let teamImageLink = "";

        if (data.teamImage) {
          teamImageLink = await uploadFile(data.teamImage);
        }

        const resp = await API.graphql({
          query: mutations.createTeam,
          variables: {
            input: {
              name: teamName,
              agegroup: data.agegroup,
              location: data.cityOfRegistration,
              // players: [],
              scoreID: [],
              imageURL: teamImageLink,

            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        const newTeam = resp?.data?.createTeam;
        teamID = newTeam?.id;
        data.teamID = teamID;
        // Further logic after team creation (if needed)
      }
      const profileImage = await uploadFile(data.profile);
      data.profile = profileImage;
      delete data.teamImage;
     
      console.log("check program", data, user);

      const checkoutPayload = {
        body: {
          // success_url: "http://localhost:3000/order-success",
          // cancel_url: "http://localhost:3000/cart",
          success_url: `https://www.sfbayareabasketball.com/scheduleMeet/clinic/${data.courseID[0]}`,
          // success_url: `http:/localhost:3000//scheduleMeet/clinic/${data.courseID[0]}`,

          // success_url: `https://www.sfbayareabasketball.com/scheduleMeet/${data.courseID[0]}`,
          cancel_url: "https://www.sfbayareabasketball.com/payment-failure",

          ...data,
          userId: user,
         
        },
      };
      console.log("check program2");

      // Send a request to the Stripe API to create a checkout session
      const response = await API.post(
        "handleClinicAndTrainingProgram",
        "/signup/clinic",
        checkoutPayload
      );

      window.location.href = response?.url;

      console.log("check program3");

      // toast.success("Registered Successfully!");
      setLoading(false);
      // window.location ='/';
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      setLoading(false);

      throw new Error(errorMessage);
    }
  };

  return {
    loading,
    registerAsClinic,
  };
};

export default useHandleRegisterClinic;
