import { useEffect, useState } from "react";
import useAddOrder from "../../hooks/addOrder";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { getAllCoupons } from "../../redux/actions/CouponAction";
import validateCoupon from "../../utils/validateCoupons";
import { ToastContainer, toast } from "react-toastify";
import OrderListSkeleton from "../../Components/Skeleton/OrderSkeleton";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader";
import SmallLoader from "../Loader/SmallLoader";

const OrderBox = ({ cartItems, league, address, loading }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [couponApplied, setCouponApplied] = useState(null);
  const [couponValue, setCouponValue] = useState("");
  const [couponValuePercent, setCouponValuePercent] = useState("");

  const [couponID, setCouponId] = useState("");

  const [couponError, setCouponError] = useState(null);

  const { coupons } = useSelector((state) => state.coupons);
  const { user } = useSelector((state) => state.user);

  const [calculateTotal, setCalculateTotal] = useState(0);
  const { addOrder, buyEvent } = useAddOrder();
  console.log("itemsRRRRRR", league);

  // const handleBuy = async (item, sessionId) => {
  //   // console.log("items",item)

  //   item.cartItemId = item.id;
  //   delete item.__typename;
  //   delete item.updatedAt;
  //   delete item.createdAt;
  //   delete item.category;
  //   delete item.render_img_url;
  //   item.userId = user;

  //   // delete item.cartId

  //   const currentDate = new Date();
  //   const isoDateString = currentDate.toISOString();

  //   const data = {
  //     placed_date: isoDateString,
  //     cartItemId: item.id,
  //     cartId: item.id,
  //     amount: item.finalPrice,

  //     userid: user,
  //     transactionStatus: "PENDING",
  //     orderStatus: "PLACED",
  //     // Other fields...
  //   };
  //   delete item.id;

  //   const order = {
  //     ...item,
  //     sessionId,
  //     ...data,
  //   };

  //   const response = await addOrder(order);

  //   // Handle the response or any further actions if needed
  // };

  const finalBuy = async () => {
    setLoading(true);
    const carts = cartItems?.map((item) => item?.id);
    const leagueItems = league?.map((item) => item?.id);
    let items = [...carts, ...leagueItems];
    console.log("itemssss", cartItems);

    console.log("Iems", items);

    const checkoutPayload = {
      body: {
        // success_url: "http://localhost:3000/order-success",
        // cancel_url: "http://localhost:3000/cart",
        success_url: "https://www.sfbayareabasketball.com/order-success",
        cancel_url: "https://www.sfbayareabasketball.com/payment-failure",
        items: items,
        couponID,

        cartItems: cartItems,
        leagueItems: league,
        totalValue: calculateTotal,
      },
    };

    try {
      // Send a request to the Stripe API to create a checkout session
      const response = await API.post(
        "orderApi",
        "/order/create-checkout-session",
        checkoutPayload
      );

      // Log the Stripe payment API response
      console.log("Payment api response - ", response);

      window.location.href = response?.url;

      setLoading(false);
      // Redirect to the Stripe payment URL

      // window.location = response?.url;
    } catch (err) {
      setLoading(false);

      toast.error("Something Went Wrong");
      console.error(err);
      throw new Error(err); // Handle errors, e.g., log the error
    }
  };
  const buy = async () => {
    console.log("Address", address);

    if (!address || address.length < 1) {
      toast.error("Add address to your order");
      return false;
    } else if (!cartItems?.length && !league?.length) {
      toast.error("Add items to your order");
      return false;
    } else {
      await finalBuy();
    }
  };

  const onChangeCoupon = (e) => {
    setCoupon(e.target.value);
    setCouponApplied(null);
  };
  // const applyCoupon = () => {
  //   if (!couponApplied || couponApplied === null) {
  //     const { isValid, discountedTotal, message, couponValue } = validateCoupon(
  //       coupon,
  //       calculateTotal,
  //       coupons
  //     );
  //     if (isValid) {
  //       setCalculateTotal(discountedTotal);
  //       setCouponApplied(true); // Set couponApplied to true after applying the coupon
  //       setCouponValue(couponValue);
  //     } else {
  //       setCouponError(message);
  //     }
  //   } else {
  //     // Coupon already applied, remove the coupon
  //     setCalculateTotal(calculateTotal + couponValue);
  //     setCouponApplied(false);
  //     setCouponValue(0);
  //     setCouponError(null);
  //   }
  // };

  const applyCoupon = () => {
    if (!couponApplied || couponApplied === null) {
      const {
        isValid,
        discountedTotal,
        message,
        couponValue,
        couponId,
        couponValuePerce,
      } = validateCoupon(coupon, calculateTotal, coupons);
      if (isValid) {
        console.log("Dsocutnte", discountedTotal);
        setCalculateTotal(discountedTotal);
        setCouponValuePercent(couponValuePerce);
        setCouponApplied(true); // Set couponApplied to true after applying the coupon
        setCouponValue(couponValue);
        setCouponId(couponId);
      } else {
        setCouponError(message);
      }
    }
  };

  useEffect(() => {
    // Calculate the finalPrice for each cart item
    const updatedCartItems = cartItems?.map((item) => ({
      ...item,
      finalPrice: item.selling_price * item.quantity,
    }));

    // Calculate the total finalPrice of the entire cart
    const total = updatedCartItems?.reduce(
      (total, item) => total + item.finalPrice,
      0
    );

    let totalCost = 0;
    if (league.length > 0) {
      // Calculate the total finalPrice of league items
      totalCost = league?.reduce(
        (acc, item) => acc + Number(item?.finalPrice),
        0
      );
    }

    // Calculate total after applying coupon if it is applied
    const discountedTotal = couponApplied
      ? total + totalCost - couponValue
      : total + totalCost;
    //
    setCalculateTotal(discountedTotal);
    dispatch(getAllCoupons());
  }, [
    cartItems,
    league,
    dispatch,
    couponApplied,
    couponValue,
    calculateTotal,
    couponValuePercent,
  ]);

  return (
    <>
      <div className="p-2 mt-4 rounded-md">
        <ToastContainer />
        <div className="flex  mb-4">
          <input
            type="text"
            className="px-2 py-1 border rounded-md flex-grow "
            placeholder="Discount code or Gift Card"
            value={coupon}
            onChange={onChangeCoupon}
          />

          <button
            onClick={applyCoupon}
            className="ml-2 sm:ml-1 py-1 px-1 lg:px-6 md:px-6 rounded-sm transform transition duration-300 ease-in-out hover:scale-90 active:scale-90 bg-[#fec749] hover:bg-[#fec749] focus:outline-none"
          >
            Apply
          </button>
        </div>
        <p className="text-sm uppercase font-bold">order summary</p>
        {loading ? (
          <>
            <OrderListSkeleton />
            <OrderListSkeleton />
          </>
        ) : (
          <ul className="space-y-4">
            {league?.length > 0 &&
              league?.map((item) => (
                <li
                  key={item?.leagueId}
                  className="flex justify-between items-center border-b border-gray-300 pb-2"
                >
                  <span className="text-sm">{item?.title}</span>

                  <span className="font-bold text-sm">
                    ${item?.selling_price}
                  </span>
                </li>
              ))}
            {cartItems?.length > 0 &&
              cartItems?.map((item) => (
                <li
                  key={item?.itemId}
                  className="flex justify-between items-center border-b border-gray-300 pb-2"
                >
                  <span className="text-sm">{item?.title}</span>
                  <span className="text-sm">{item?.quantity}</span>

                  <span className="font-bold text-sm">
                    ${item?.selling_price?.toFixed(2) * item?.quantity}
                  </span>
                </li>
              ))}
            <br />

            {couponApplied ? (
              couponValuePercent ? (
                <span className="text-green-600">
                  Coupon Applied - {couponValuePercent}%Off
                </span>
              ) : (
                <span className="text-green-600">
                  Coupon Applied - {couponValue} Off
                </span>
              )
            ) : (
              <span className="text-red-600 font-bold text-sm text-center">
                {couponError}
              </span>
            )}
            <br />
            <br />

            <li className="flex justify-between items-center border-b border-gray-300 pb-2">
              <span>Order Total:</span>
              <span className="font-bold">${calculateTotal.toFixed(2)}</span>
            </li>
          </ul>
        )}
        {loading || isLoading ? (
          <div className="mt-2">
            <SmallLoader />
          </div>
        ) : (
          <div className="mt-4">
            <button
              onClick={buy}
              disabled={loading || isLoading}
              className="bg-[#fec749] w-full px-4 py-2 rounded-md "
            >
              PROCEED TO PAY
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default OrderBox;
