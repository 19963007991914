import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const TypoBu = (props) => {
  return (
    <div
      className={`py-2 uppercase font-bold ${props.item.text_bsize} ${props.item.border ? "border-b-2   border-[#c4c4c4]" : ""
        }`}
    >
      <span className="text-[#D70D15] font-bold">|</span>
      <span className={props.item.text_ssize}> {props.item.text}</span>
      {props.item.button && ( // Check if props.item.button is true
        <div className="float-right text-3xl">
          <button onClick={props.onClickLeft} disabled={props.disabledLeft} className="text-[#D70D15] font-bold mr-8">
            <AiOutlineLeft className="text-lg font-bold" />
          </button>
          <button onClick={props.onClickRight} disabled={props.disabledRight} className="text-[#D70D15] font-bold">
            <AiOutlineRight className="text-lg font-bold" />
          </button>
        </div>
      )}
    </div>
  );
};
export default TypoBu;
