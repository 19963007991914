import HeaderLg from "../../Components/HeaderLg";
import Registration from "../../Components/Registration";
import FollowUS from "../../Components/FollowUS";
import SqShop from "../../Components/SqShop";
import Workwith from "../../Components/Workwith";
import LgNews from "../../Components/LgNews";
import FlatVideo from "../../Components/FlatVideo";
import LeagueGame from "../../Components/LeagueGame";
import Advertisement from "../../Components/Advertisement";
import Sponsors from "../../Components/Sponsors";
import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { Storage } from "aws-amplify";

import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { NavLink } from "react-router-dom";
import ImageGrid from "../../Components/ImageGrid";
import useListScores from "../../hooks/useListScores";
import Loader from "../../Components/Loader";
import useGetAllItems from "../../hooks/getItems";

const LeaguePlayer = () => {
  const { itemlist, loading, error } = useGetAllItems();
  const { listScores, loading: listScoresLoading } = useListScores();

  const leagueScores = listScores?.filter(
    (score) => score?.tournament === false || score?.tournament === null || score?.tournament === undefined
  );


  console.log("listscores", listScores, leagueScores)
  if (loading || listScoresLoading) {
    return <Loader />;
  }

  return (
    <div className="mt-[25px] bg-[#E7E7E7] pb-10  ">
      <HeaderLg />
      <ImageGrid />

      <div className="px-4 md:px-10 mt-10 lg:mt-20">
        <div className="grid grid-cols-1 lg:grid-cols-7 gap-4">
          <div className="lg:col-span-5 flex flex-col gap-10 ">
            {leagueScores?.length > 0 &&
              leagueScores?.map((score) => (
                <LeagueGame teamDetails={score} key={score?.id} loading={loading} />
              ))}
            {leagueScores?.length < 1 && (
              <h1 className="text-[28px]">No team information available</h1>
            )}

            <Advertisement />
            <LgNews />
            <Advertisement />
            <FlatVideo />
          </div>
          <div className="lg:col-span-2">
            <Registration />
            <div className="mt-10">
              <FollowUS />
            </div>
            <div className="py-5 md:py-10">
              <Workwith
                title="join the team as a volunteer"
                button="volunteer"
              />
            </div>
            <Sponsors />
            <div className="my-10">
              <SqShop item={itemlist} />
            </div>
            <Sponsors />
          </div>
        </div>
      </div>
    </div>
  );
};
export default LeaguePlayer;
