import { NavLink } from "react-router-dom";
import { Carousel } from "flowbite-react";
import Shop from "../../Components/Shop";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import ShopFl from "../../Components/ShopFl";
import Advertisement from "../../Components/Advertisement";
import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import useGetAllItems from "../../hooks/getItems";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProductCardSkeleton from "../../Components/Skeleton/ProductCardSkeleton";
import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";

const Store = () => {
  return (
    <div>
      <ToastContainer />
      <div className="bg-[#D70D15] hidden py-3 md:flex gap-2  text-white px-[25px] justify-between uppercase">
        {subMenuItems.map((item, index) => (
          <NavLink to={item.url} key={index}>
            {item.name}
          </NavLink>
        ))}
      </div>
      <div className="bg-[#FEC749] hidden lg:flex py-4 px-6 md:px-12 lg:px-24 text-white flex-col md:flex-row justify-between items-center">
        <button className="bg-white text-black font-bold px-6 md:px-12 py-1 text-base md:text-xl leading-3 h-8 md:h-11">
          SALE ALERT
        </button>
        <span className="text-2xl md:text-5xl text-center md:text-left">
          Up to 70% off
        </span>
        <span className="text-2xl md:text-5xl font-bold">
          <AiOutlineRight />
        </span>
      </div>

      <div className="h-[9rem] overflow-hidden  md:h-[19rem] lg:h-[34rem] relative">
        <Carousel>
          <img src="/images/carousel-banner1.png" alt="..." />
          <img src="/images/carousel-banner1.png" alt="..." />
          <img src="/images/carousel-banner1.png" alt="..." />
          <img src="/images/carousel-banner1.png" alt="..." />
          <img src="/images/carousel-banner1.png" alt="..." />
        </Carousel>
        <div className="absolute bottom-10 right-10 font-bold">
          <div className="mb-2 md:mb-10">
            <span className="text-sm md:text-4xl text-black">
              NEW
              <br /> ARRIVALS
            </span>
          </div>
          <button
            href="#"
            className="text-sm border-black border-2 px-4 md:px-24 md:py-2"
          >
            SHOP NOW
          </button>
        </div>
      </div>
      <div className="bg-[#EDEFF4] flex flex-col gap-20">
        <div className="px-4 md:px-20 text-center">
          <Shop />
        </div>
        <AdvertisementOne index={0} />
        <div className=" mx-10 pb-20 ">
          <div className="py-2 uppercase font-bold text-4xl border-b-2   border-[#c4c4c4]">
            <span className=" border-l-4 border-[#D70D15] text-3xl px-2">
              upcoming products
            </span>

            <div className="float-right text-3xl">
              <button className="text-[#D70D15] font-bold mr-8">
                <AiOutlineLeft className="text-lg font-bold" />
              </button>
              <button className="text-[#D70D15] font-bold">
                <AiOutlineRight className="text-lg font-bold" />
              </button>
            </div>
          </div>
          <div className="py-10">
            {/* <ShopFl item={{ itemlist }} /> */}
            <ProductCardSkeleton quantity={4} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Store;

const subMenuItems = [
  {
    name: "brends",
    url: "/store",
  },
  {
    name: "men",
    url: "/men",
  },
  {
    name: "women",
    url: "/women",
  },
  {
    name: "kids",
    url: "/kids",
  },
  {
    name: "hats",
    url: "/hats",
  },
  {
    name: "footwears",
    url: "/footwears",
  },
  {
    name: "lorem",
    url: "/lorem",
  },
  {
    name: "ipsom",
    url: "/ipsom",
  },
  {
    name: "ipsom",
    url: "/ipsom",
  },
  {
    name: "ipsom",
    url: "/ipsom",
  },
];
