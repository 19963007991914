import React, { useState, useEffect } from "react";

import axios from "axios"; // Import axios
import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import useAuthInfo from "./getUser";
import useItemById from "./getItemById";
import useLeagueList from "./getLeagues";
import getLinks from "../utils/getLinks";
import getLink from "../utils/getLinks";

const useGetOrderList = () => {
  const [ordersList, setOrdersList] = useState([]);
  const [evenList, setEventsList] = useState([]);

  const [itemDetails, setItemDetails] = useState([]);
  const { userId, isAuthenticated } = useAuthInfo();
  const [leagueOrder, setLeagueOrder] = useState([]);
  const { fetchItems } = useItemById();
  const [loading, setLoading] = useState(false);
  const { leagues } = useLeagueList();
  const fetchImageFromCacheWithExpiration = async (url) => {
    const cachedData = localStorage.getItem(url);
    if (cachedData) {
      const { timestamp, imageUrl } = JSON.parse(cachedData);
      const currentTime = Date.now();

      // Check if the stored image is older than 300 seconds (5 minutes)
      if (currentTime - timestamp < 300000) {
        return imageUrl; // Use the cached image URL
      }
    }
    // If the image is older than 300 seconds or not found, fetch a new one
    const signedUrl = await loadImageAndCache(url);
    if (signedUrl) {
      // Store the new image URL with the current timestamp
      const dataToCache = JSON.stringify({
        timestamp: Date.now(),
        imageUrl: signedUrl,
      });
      localStorage.setItem(url, dataToCache);
      return signedUrl;
    }
    return null;
  };
  const loadImageAndCache = async (url) => {
    try {
      const signedUrl = await getLink(url);
      localStorage.setItem(url, signedUrl); // Store image data in cache
      return signedUrl;
    } catch (error) {
      console.error("Error fetching or caching image:", error);
      return null;
    }
  };
  useEffect(() => {
    const fetchOrdersList = async () => {
      try {
        setLoading(true);
        if (isAuthenticated && userId) {
          const checkoutPayload = {
            body: {
              userId: userId,
            },
          };
          const response = await API.post(
            "orderApi",

            `/order/getOrder`,
            checkoutPayload
          );
          setLoading(true);

          const orderItems = response?.allOrders;

          const updatedOrdersList = await Promise.all(
            orderItems?.map(async (order) => {
              console.log("response", response);

              const cachedImgUrl = await fetchImageFromCacheWithExpiration(
                order?.img_url
              );

              const updated = {
                ...order,
                img_url: cachedImgUrl,
              };
              return updated;
            })
          );

          // Concatenate updated orders and events lists
          const list = [...updatedOrdersList];

          // Set the states
          setOrdersList(list);

          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching OrdersList:", error);
        throw error;
      }
    };

    fetchOrdersList();
  }, [isAuthenticated, userId, leagues]);
  const fetchLeaguesById = async (id) => {
    try {
      setLoading(true);

      let league = leagues?.find((value) => (value.id = id));
      console.log("League", league);
      return league;
      // const response = await API.graphql(graphqlOperation(queries.listLeagues));
      // setLeagues(leaguesData);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching leagues By Id:", error);
    }
  };

  return { ordersList, evenList, loading, itemDetails };
};

export default useGetOrderList;
// type Order
//   @model
//   @auth(
//     rules: [
//       { allow: private, operations: [create, read,update] }
//       { allow: groups, groups: ["Admin"] }
//     ]
//   ) {
//   id: ID!
//   placed_date: AWSDateTime
//   shipped_date: AWSDateTime
//   delivered_date: AWSDateTime
//   cancelled_date: AWSDateTime
//  userid: String!
//   itemId: ID
//   title: String
//   selling_price: Float
//   original_price: Float
//   rating: Int
//   img_url: String
//   tags: [String]
//   stock_qty: Int
//   category: [String]
//   cartId: ID
//   quantity: Int
//   cartItemId:String
//   finalPrice: Float
//   code: String
//   couponValue: String
//   amount: String
//   sessionId:String
//   transactionStatus: transactionStatusType
//   orderStatus: orderStatusType
// }

// type BookedEvent
//   @model
//   @auth(
//     rules: [
//       { allow: private, operations: [create, read, update] }
//       { allow: groups, groups: ["Admin"] }
//     ]
//   ) {
//   id: ID!
//   userid: String!
//   placed_date: AWSDateTime
//   eventDate: AWSDateTime
//   eventEnded: Boolean
//   leagueId: String
//   title: String!
//   date: AWSDateTime!
//   location: String
//   img_url: String
//   selling_price: Float
//   teamsId: [ID!]
//   teamsName: [String!]
//   leagueCartId: ID
//   quantity: Int
//   finalPrice: Float
//   sessionId:String
//   code: String
//   couponValue: String
//   transactionStatus: transactionStatusType
//   orderStatus: orderStatusType
// }
