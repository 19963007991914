import { API, graphqlOperation } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations"; // Import your GraphQL queries
// Import your GraphQL queries
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import {
  REGISTER_CAMP_REQUEST,
  REGISTER_CAMP_SUCCESS,
  REGISTER_CAMP_FAIL,
  UPDATE_CAMP_REQUEST,
  UPDATE_CAMP_SUCCESS,
  UPDATE_CAMP_FAIL,
  GET_CAMP_REQUEST,
  GET_CAMP_SUCCESS,
  GET_CAMP_FAIL,
  LOAD_CAMPS_REQUEST,
  LOAD_CAMPS_SUCCESS,
  LOAD_CAMPS_FAIL,
  DELETE_CAMP_REQUEST,
  DELETE_CAMP_SUCCESS,
  DELETE_CAMP_FAIL,
  DELETE_CAMP_RESET,
  CLEAR_CAMP_ERRORS,
} from "../constants/CampConstants";
import getLinks from "../../utils/getLinks";
import uploadFile from "../../utils/uploadFile";

export const getAllCamp = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_CAMPS_REQUEST });

    const response = await API.graphql({
      query: queries.listCamps,
      variables: {
        filter: {
          isVerified: {
            eq: true,
          },
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    dispatch({
      type: LOAD_CAMPS_SUCCESS,
      payload: response?.data?.listCamps.items,
    });
  } catch (error) {
    dispatch({
      type: LOAD_CAMPS_FAIL,
      payload: error.message || "Failed to fetch Camp data",
    });
  }
};

export const getCampDetails = (campId) => async (dispatch) => {
  try {
    dispatch({ type: GET_CAMP_REQUEST });

    const campResponse = await API.graphql({
      query: queries.getCamp,
      variables: {
        id: campId,
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const campData = campResponse?.data?.getCamp;

    const signedUrl = await getLinks(campData?.profile);

    const updatedData = {
      ...campData,
      img_url: signedUrl,
      profile: signedUrl,
    };

    const data = {
      campDetails: updatedData,
    };

    dispatch({
      type: GET_CAMP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage =
      error.response?.data?.message ||
      error.message ||
      "Failed to fetch CAMP details";

    dispatch({
      type: GET_CAMP_FAIL,
      payload: errorMessage,
    });
  }
};

//add a new product
export const registerAsCamp = (data, userId) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_CAMP_REQUEST });
    const profileImage = await uploadFile(data.profile);
    console.log("Profile_Image", profileImage);
    const checkoutPayload = {
      body: {
        // success_url: `https://www.sfbayareabasketball.com/scheduleMeet/camp/${data.courseID[0]}`,
        success_url: `https://www.sfbayareabasketball.com/scheduleMeet/camp/${data.courseID[0]}`,

        // success_url: `https://www.sfbayareabasketball.com/scheduleMeet/${data.courseID[0]}`,
        cancel_url: "https://www.sfbayareabasketball.com/payment-failure",
        ...data,
        userId,
        profile: profileImage,
      },
    };

    console.log("Data Payload", checkoutPayload);

    // Send a request to the Stripe API to create a checkout session
    const response = await API.post(
      "signupother",
      "/signup/camp",
      checkoutPayload
    );

    window.location.href = response?.url;

    dispatch({
      type: REGISTER_CAMP_SUCCESS,
      payload: response?.data?.createCamp,
    });
    toast.success("Registered Successfully!");
    // window.location ='/';
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    console.log("Error", errorMessage);
    dispatch({
      type: REGISTER_CAMP_FAIL,
      payload: errorMessage,
    });
    throw new Error(errorMessage);
  }
};

export const clearCampErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_CAMP_ERRORS });
};
