import { BiClinic } from "react-icons/bi";
import { FaCampground } from "react-icons/fa";
import { BiBasketball } from "react-icons/bi";
import { HiOutlineHandRaised } from "react-icons/hi2";
import Teams from "../Teams";
import Shop from "../Shop";
import Advertisement from "../Advertisement";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import AdvertisementOne from "../Advertisement/HOMEADVERTISEMENT";

const SignTour = () => {
  const [itemlist, setItemList] = useState([]);

  useEffect(() => {
    API.graphql({
      query: queries.listItems,
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    }).then((response) => {
      setItemList(response?.data?.listItems?.items);
    });
  }, []);
  return (
    <div>
      <div className="m-0 md:flex bg-[#181B22] mt-20">
        <div className="md:w-3/5 flex md:flex-row flex-col">
          <img src="/images/john-arano-big.png" alt="john-arano" className="w-full" />
          <div className="w-full md:w-2/5">
            <div className="uppercase text-6xl text-white font-bold px-10 pt-20  mb-10">
              Sign Up For A <p className="text-5xl">Weekend Tournament</p>
            </div>
            <div className="pl-12 mb-4">
              <button href="#" className="bg-[#fec749] px-20 whitespace-nowrap py-1">
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center bg-white px-10 py-32">
        <div>
          <span className="uppercase font-bold text-2xl">
            sign up for
            <br /> clinics, camps and training programs.
          </span>
        </div>
        <div className="flex mt-20 justify-between md:flex-row flex-col ">
          <div className="mb-4">
            <BiClinic className="inline text-4xl mb-2" />
            <div className="font-bold mb-4">
              CLINICS
            </div>
            <NavLink to="/signupclinic" className="font-bold  bg-[#fec749] px-20 whitespace-nowrap py-1">Sign Up </NavLink>
          </div>
          <div className="mb-4">
            <FaCampground className="inline text-4xl mb-2" />
            <div className="font-bold mb-4">
              CAMPS
            </div>
            <NavLink to="/signupcamp" className="font-bold  bg-[#fec749] px-20 whitespace-nowrap py-1">Sign Up</NavLink>
          </div>
          <div>
            <BiBasketball className="inline text-4xl mb-2" />
            <div className="font-bold mb-4 whitespace-nowrap">
              TRAINING PROGRAMS
            </div>
            <NavLink to="/signuptrainingprogram" className="font-bold  bg-[#fec749] px-20 whitespace-nowrap py-1">Sign Up </NavLink>
          </div>
        </div>
      </div>
      <div className="bg-work-with ty-10 uppercase text-white text-4xl text-center pt-32">
        <div>
          <div className="">work with us</div>
          <div className=" mt-20">
            <HiOutlineHandRaised className="inline" />
          </div>
          <div className="pt-20 pb-20">
            <NavLink
              to="/signupvolunteer"
              className="px-20 py-1 bg-[#fec749]  text-black"
            >
              VOLUNTEER
            </NavLink>
          </div>
        </div>
      </div>
      <div className="md:p-20">
        <AdvertisementOne index={0} />
      </div>
      <Teams />
      <div className="bg-[#181B22] md:p-20">
        <AdvertisementOne index={1} />
      </div>
      <div className="bg-[#181B22] md:p-20 p-4 text-white">
        <div className="py-2 uppercase font-bold text-4xl border-b-2 mb-10 text-white border-[#c4c4c4]">
          <span className=" border-l-4 border-[#D70D15] text-3xl px-2">
            SHOP
          </span>
{/* 
          <div className="float-right text-3xl">
            <button className="text-[#D70D15] font-bold mr-8">
              <AiOutlineLeft className="text-lg font-bold" />
            </button>
            <button className="text-[#D70D15] font-bold">
              <AiOutlineRight className="text-lg font-bold" />
            </button>
          </div> */}
        </div>
        <Shop item={{ itemlist }} />
        <div className="text-center ">
          <button className="border-2 p-2 border-white rounded-md w-60 mr-20">
            VIEW ALL
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignTour;

// const itemlist = [
//   {
//     text: "PRODUCT TITLE",
//     price: "$250",
//     src: "/images/shop_ball.png",
//     rating: "⭐⭐⭐⭐⭐",
//     badge: "new",
//   },
//   {
//     text: "PRODUCT TITLE",
//     price: "$250",
//     src: "/images/shop_band.png",
//     rating: "⭐⭐⭐⭐⭐",
//   },
//   {
//     text: "PRODUCT TITLE",
//     price: "$250",
//     src: "/images/shop_net.png",
//     rating: "⭐⭐⭐⭐⭐",
//   },
//   {
//     text: "PRODUCT TITLE",
//     price: "$250",
//     src: "/images/shop_ball.png",
//     rating: "⭐⭐⭐⭐⭐",
//   },
// ];
