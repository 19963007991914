import React from 'react';

const FlatNewsSkeleton = () => {
  return (
    <div>
      <div className="animate-pulse w-full h-[150px] bg-gray-200"></div>
      <span className="animate-pulse block h-[10px] bg-gray-200 mt-4"></span>
      <span className="animate-pulse text-sm mt-4 h-[5px] block bg-gray-200"></span>
      <span className="animate-pulse text-sm mt-4 h-[5px] block bg-gray-200"></span>
      <span className="animate-pulse text-sm mt-4 h-[5px] block bg-gray-200"></span>
      <span className="animate-pulse text-sm mt-4 h-[5px] block bg-gray-200"></span>
    
    </div>
  );
};

export default FlatNewsSkeleton;
