import { NavLink } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { ImFacebook } from "react-icons/im";
import { IoLogoTwitter } from "react-icons/io";
import { AiOutlineGooglePlus } from "react-icons/ai";
import { SiInstagram } from "react-icons/si";
import truncateText from '../../utils/truncateText'
import OrNewsSkeleton from "../Skeleton/OrNewsSkeleton";

function formatDate(createdAt) {
  const date = new Date(createdAt);
  const options = { month: "short", day: "numeric", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
}
const OrNews = ({ news, loading, event = false }) => {
  return (
    <div className="flex flex-col gap-10 h-full">
      {loading ? (
        <>
          <OrNewsSkeleton />
        </>
      ) : (
        <div className="border-[#E7E7E7]   border-2 py-4">
          {event
            ? news && (
                <div className="w-full">
                  <div className="px-10">
                    <span className="mr-4">
                      {news?.createdAt && formatDate(news?.createdAt)}
                    </span>
                    <AiOutlineEye className="inline" />
                    <span> {news?.view_count}</span>
                    <span className="text-2xl font-semibold uppercase block my-4 ">
                      {news?.title}
                    </span>
                    <span className="text-sm block mb-10">
                      {news?.content_text
                        ? news?.content_text
                        : news?.description}
                    </span>
                  </div>
                        <img
                          alt="news_banner"
                          src={news?.img_link}
                          className="w-full max-h-[500px] mx-auto aspect-video object-cover"
                        />
                </div>
              )
            : news && (
                <NavLink to={`/news/${news?.id}`}>
                  <div className="px-10">
                    <span className="mr-4">{formatDate(news?.createdAt)}</span>
                    <AiOutlineEye className="inline" />
                    <span> {news?.view_count}</span>
                    <span className="text-2xl font-semibold uppercase block my-4 ">
                      {news?.title}
                    </span>
                    <span className="text-sm block mb-10">
                      {news?.content_text
                        ? news?.content_text
                        : news?.description}
                    </span>
                  </div>
                  <img
                    alt="news_banner"
                    src={news?.content_img_url ? news?.content_img_url : news?.img_url[0]}
                    className="w-full max-h-[500px] mx-auto px-10 aspect-video object-cover"
                  />
                </NavLink>
              )}

          <div className="float-right text-gray-400 pt-10">
            <NavLink>SHARE</NavLink>
            <NavLink>
              <ImFacebook className="mx-4 inline" />
            </NavLink>
            <NavLink>
              <IoLogoTwitter className="mx-4 inline" />
            </NavLink>
            <NavLink>
              <AiOutlineGooglePlus className="mx-4 inline" />
            </NavLink>
            <NavLink>
              <SiInstagram className="mx-4 inline" />
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrNews;
