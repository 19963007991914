import { API, graphqlOperation } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations"; // Import your GraphQL queries
// Import your GraphQL queries
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import {
  REGISTER_TRAININGPROGRAM_REQUEST,
  REGISTER_TRAININGPROGRAM_SUCCESS,
  REGISTER_TRAININGPROGRAM_FAIL,
  UPDATE_TRAININGPROGRAM_REQUEST,
  UPDATE_TRAININGPROGRAM_SUCCESS,
  UPDATE_TRAININGPROGRAM_FAIL,
  GET_TRAININGPROGRAM_REQUEST,
  GET_TRAININGPROGRAM_SUCCESS,
  GET_TRAININGPROGRAM_FAIL,
  LOAD_TRAININGPROGRAMS_REQUEST,
  LOAD_TRAININGPROGRAMS_SUCCESS,
  LOAD_TRAININGPROGRAMS_FAIL,
  DELETE_TRAININGPROGRAM_REQUEST,
  DELETE_TRAININGPROGRAM_SUCCESS,
  DELETE_TRAININGPROGRAM_FAIL,
  DELETE_TRAININGPROGRAM_RESET,
  CLEAR_TRAININGPROGRAM_ERRORS,
} from "../constants/TrainingProgramConstants";
import getLinks from "../../utils/getLinks";
import uploadFile from "../../utils/uploadFile";

export const getAllTrainingProgram = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_TRAININGPROGRAMS_REQUEST });

    const response = await API.graphql({
      query: queries.listTrainingPrograms,
      variables: {
        filter: {
          isVerified: {
            eq: true,
          },
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    dispatch({
      type: LOAD_TRAININGPROGRAMS_SUCCESS,
      payload: response?.data?.listTrainingPrograms.items,
    });
  } catch (error) {
    dispatch({
      type: LOAD_TRAININGPROGRAMS_FAIL,
      payload: error.message || "Failed to fetch TrainingProgram data",
    });
  }
};

export const getTrainingProgramDetails =
  (trainingProgramId) => async (dispatch) => {
    try {
      dispatch({ type: GET_TRAININGPROGRAM_REQUEST });

      const trainingProgramResponse = await API.graphql({
        query: queries.getTrainingProgram,
        variables: {
          id: trainingProgramId,
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const trainingProgramData =
        trainingProgramResponse?.data?.getTrainingProgram;

      const signedUrl = await getLinks(trainingProgramData?.profile);

      const updatedData = {
        ...trainingProgramData,
        img_url: signedUrl,
        profile: signedUrl,
      };

      const data = {
        trainingProgramDetails: updatedData,
      };

      dispatch({
        type: GET_TRAININGPROGRAM_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Failed to fetch TRAININGPROGRAM details";

      dispatch({
        type: GET_TRAININGPROGRAM_FAIL,
        payload: errorMessage,
      });
    }
  };

//add a new product
export const registerAsTrainingProgram = (data, userId) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_TRAININGPROGRAM_REQUEST });
    const profileImage = await uploadFile(data.profile);

    const checkoutPayload = {
      body: {
        // success_url: "http://localhost:3000/order-success",
        // cancel_url: "http://localhost:3000/cart",
        success_url: `https://www.sfbayareabasketball.com/scheduleMeet/training/${data.courseID[0]}`,
        cancel_url: "https://www.sfbayareabasketball.com/payment-failure",
        ...data,
        userId,

        // document: document,
        profile: profileImage,
      },
    };
    console.log("check program2", checkoutPayload);

    // Send a request to the Stripe API to create a checkout session
    const response = await API.post(
      "handleClinicAndTrainingProgram",
      "/signup/trainingprogram",
      checkoutPayload
    );

    window.location.href = response?.url;
    // const response = await API.graphql({
    //   query: mutations.createTrainingProgram,

    //   variables: {
    //     input: {
    //       userId,
    //       ...data,
    //       profile: profileImage
    //     },
    //   },

    //   authMode: GRAPHQL_AUTH_MODE.API_KEY,
    // });

    // dispatch({
    //   type: REGISTER_TRAININGPROGRAM_SUCCESS,
    //   payload: response?.data?.createTrainingProgram,
    // });
    toast.success("Registered Successfully!");
    localStorage.removeItem("formData")
    // window.location ='/';
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: REGISTER_TRAININGPROGRAM_FAIL,
      payload: errorMessage,
    });
  }
};

export const clearTrainingProgramErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_TRAININGPROGRAM_ERRORS });
};
