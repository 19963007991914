
import {
  LOAD_EVENTS_REQUEST,
  LOAD_EVENTS_SUCCESS,
  LOAD_EVENTS_FAIL,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAIL,
  BUY_EVENT_TICKET_REQUEST,
  BUY_EVENT_TICKET_SUCCESS,
  BUY_EVENT_TICKET_FAIL,
  CLEAR_EVENT_ERRORS,
  ALL_EVENT_REQUEST,
  ALL_EVENT_SUCCESS,
  ALL_EVENT_FAIL,
} from "../constants/EventsConstants";

  const initialState = {
    events: [],
    eventCount: 0,
    resultPerPage: 0,
    event: {},
    loading: false,
    error: null,
    filteredEventsCount: 0,
  };
  
  export const eventsReducer = (state = initialState, action) => {
    switch (action.type) {
      case ALL_EVENT_REQUEST:
      case GET_EVENT_REQUEST:
        return {
          loading: true,
          events: [],
          event:{}

        };
      case ALL_EVENT_SUCCESS:
        return {
          loading: false,
          events: action.payload.events,
          eventCount: action.payload.eventsCount,
          resultPerPage: action.payload.resultPerPage,
          filteredEventsCount: action.payload.filteredEventsCount,
        };
  
        case GET_EVENT_SUCCESS:
          return {
            loading: false,
            event:action.payload

          };
      case GET_EVENT_FAIL:
      case ALL_EVENT_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_EVENT_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  
  
  
  
  
  
  
  // export const newProductReducer = (state = { product: {} }, action) => {
  //   switch (action.type) {
  //     case CREATE_PRODUCT_REQUEST:
  //       return {
  //         ...state,
  //         loading: true,
  //       };
  //     case CREATE_PRODUCT_SUCCESS:
  //       return {
  //         loading: false,
  //         success: action.payload.success,
  //         product: action.payload.product,
  //       };
  //     case CREATE_PRODUCT_FAIL:
  //       return {
  //         ...state,
  //         loading: false,
  //         error: action.payload,
  //       };
  //     // case ADD_PRODUCT_RESET:
  //     //   return {
  //     //     ...state,
  //     //     success: false,
  //     //   };
  //     case CLEAR_PRODUCT_ERRORS:
  //       return {
  //         ...state,
  //         error: null,
  //       };
  //     default:
  //       return state;
  //   }
  // };
  
  
  
  // export const productDetailsReducer = (state = { product: {} }, action) => {
  //   switch (action.type) {
  //     case GET_PRODUCT_REQUEST:
  //       return {
  //         loading: true,
  //         ...state,
  //       };
  //     case GET_PRODUCT_SUCCESS:
  //       return {
  //         loading: false,
  //         product: action.payload,
  //       };
  //     case GET_PRODUCT_FAIL:
  //       return {
  //         loading: false,
  //         error: action.payload,
  //       };
  
  //     case CLEAR_PRODUCT_ERRORS:
  //       return {
  //         ...state,
  //         error: null,
  //       };
  //     default:
  //       return state;
  //   }
  // };
  
 

  

  
  
  // export const productReducer = (state = {}, action) => {
  //   switch (action.type) {
  //     case DELETE_PRODUCT_REQUEST:
  //     case UPDATE_PRODUCT_REQUEST:
  //       return {
  //         ...state,
  //         loading: true,
  //       };
  //     case DELETE_PRODUCT_SUCCESS:
  //       return {
  //         ...state,
  //         loading: false,
  //         isDeleted: action.payload,
  //       };
  
  //     case UPDATE_PRODUCT_SUCCESS:
  //       return {
  //         ...state,
  //         loading: false,
  //         isUpdated: action.payload,
  //       };
  //     case DELETE_PRODUCT_FAIL:
  //     case UPDATE_PRODUCT_FAIL:
  //       return {
  //         ...state,
  //         loading: false,
  //         error: action.payload,
  //       };
  //     case DELETE_PRODUCT_RESET:
  //       return {
  //         ...state,
  //         isDeleted: false,
  //       };
  //     // case UPDATE_PRODUCT_RESET:
  //     //   return {
  //     //     ...state,
  //     //     isUpdated: false,
  //     //   };
  //     case CLEAR_PRODUCT_ERRORS:
  //       return {
  //         ...state,
  //         error: null,
  //       };
  //     default:
  //       return state;
  //   }
  // };