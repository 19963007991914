import useFetchAdvertisement from "../../hooks/getAdvertisementByType";
import Loader from "../Loader";
import LazyLoad from "react-lazyload";
import placeholderImage from "./advertisement.png";

const Sponsors = () => {
  const { sponsors, loading } = useFetchAdvertisement("SPONSOR");

 
  return (
    <div className="text-center my-5 lg:col-span-2">
      {" "}
      {/* Update this line */}
      {/* Center image for small screens */}
      <div className="relative mx-auto min-h-[350px] max-w-xs md:max-w-none">
        {loading ? (
          // Placeholder for image while loading
          <div className="animate-pulse flex items-center justify-center w-full h-full bg-gray-200">
            <div className="h-[450px] w-full bg-gray-400 "></div>
          </div>
        ) : (
          // Actual image when not loading
          <>
            {sponsors?.length > 0 ? (
              <Spons ad={sponsors[0]} />
            ) : (
              <div>
                <img
                  className="w-full h-[450px] object-cover"
                  src={placeholderImage}
                  alt="Advertisements"
                />
              </div>
            )}{" "}
          </>

          // <>{sponsors?.length > 0 && sponsors?.map((ad) => <Spons ad={ad} />)}</>
        )}
      </div>
    </div>
  );
};

const Spons = (ad) => {
  let imageSize;
  const windowWidth = window.innerWidth;
  console.log("Sponsors", ad);

  if (ad) {
    const { lgImageUrl, smImageUrl, mdImageUrl, xsImageUrl } = ad.ad;
    if (windowWidth > 1500) {
      imageSize = lgImageUrl;
    } else if (windowWidth > 1000) {
      imageSize = mdImageUrl;
    } else if (windowWidth > 640) {
      imageSize = smImageUrl;
    } else {
      imageSize = xsImageUrl;
    }
  }

  return (
    <div>
      <img
        className="w-full h-[450px] object-cover"
        src={imageSize}
        alt="Advertisements"
      />
    </div>
  );
};

export default Sponsors;
