import { API, Auth, graphqlOperation } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import * as mutations from "../graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import useAuthInfo from "./getUser";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useSignUpAsEvent = () => {
  const { isAuthenticated, userId } = useAuthInfo(); // Use your user hook to get the userId
  const [loading, setLoading] = useState(false); // Correct syntax for destructuring
  const [success, setSuccess] = useState(null); // Correct syntax for destructuring
  const [error, setError] = useState(null); // Use your user hook to get the userId
  const navigate = useNavigate();

  const registerEventFunc = async (signUpData) => {
    try {
      setLoading(true);
      if (isAuthenticated) {
        const response = await API.graphql({
          query: mutations.createSignUpEvent,
          variables: {
            input: { userId, ...signUpData },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        })
          .then((response) => {
            // Add the arrow function here

            setLoading(false);
            setSuccess(true);
            if (response?.data?.createSignUpEvent) {
              setLoading(false);
              toast.success("Registered Successfully!");
              navigate("/");
              return true;
            } else {
              setLoading(false);
              toast.error("Something went wrong try after sometime");
              toast.error("Failed to create signup league.");
              return false;
            }
          })

          .catch((error) => {
            setLoading(false);
            console.log("Sign up Event response - ", error);

            setError(error);
            setSuccess(false);

            console.log("Sign up error - ", error);
            console.log("Sign up error code - ", error?.code);
            console.log("Sign up error msg - ", error?.message);
            return false;
          });
      }
    } catch (error) {
      console.log("not Logged in ");
      setLoading(false);
      setError(error);
      setSuccess(false);
    }
  };

  return { registerEventFunc, error, loading, success };
};

export default useSignUpAsEvent;
