import { memo } from "react";
import { AiOutlineClockCircle, AiOutlineCalendar } from "react-icons/ai";
import { VscLocation } from "react-icons/vsc";
import { FaShareAltSquare } from "react-icons/fa";
import TypoBu from "../../Components/TypoBu";
import getLeaguesList from "../../hooks/getLeagues";
import useTeam from "../../hooks/getTeams";
import { useEffect, useState } from "react";
import useUpdateCart from "../../hooks/addToCart";
import { ToastContainer } from "react-toastify";

import useUserCart from "../../hooks/getUserCart";
import useAddleagueToCart from "../../hooks/addLeagueToCart";
import Loader from "../Loader";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaDollarSign } from "react-icons/fa";
import { getTeamDetails } from "../../redux/actions/TeamAction";
import { addLeaguesToCart } from "../../redux/actions/cartAction";
import { getAllEvents } from "../../redux/actions/EventsAction";
import { Link } from "react-router-dom";

const MainUpComingEvents = (props) => {
  const { fetchTeams } = useTeam();
  const dispatch = useDispatch();
  const { added } = useSelector((state) => state?.cart);

  const { team, loading: loadingTeams } = useSelector((state) => state?.teams);
  const { user, loading } = useSelector((state) => state?.user);
  const item = props.item;
  const [teamDetails, setTeamDetails] = useState([]);
  const [success, setSuccess] = useState(false);

  const AddToCart = async () => {
    const id = props?.item?.id;
    const data = {
      title: item?.title,
      selling_price: item?.selling_price,
      location: item?.location,
      date: item?.date,
      img_url: item?.img_url,
      rating: item?.rating,
      leagueId: item?.id,
      teamsId: item?.teamsId,
      teamsName: item?.teamsName,
      cartId: user,
      quantity: 1,
      finalPrice: item?.selling_price,
    };
    dispatch(addLeaguesToCart(user, data));
    toast.success("Added League To Cart");
  };

  useEffect(() => {
    if (props?.item.teamsId && props?.item?.teamsId.length > 0) {
      const teamIds = props?.item?.teamsId;

      // Dispatch an action to fetch team details
      fetchTeams(teamIds)
        .then((teamsData) => {
          setTeamDetails(teamsData);

          console.log("Team  details fetched Events:", teamsData);
        })
        .catch((error) => {
          console.error("Error fetching team details:", error);
        });
    }

    console.log("Team details fetched Events", props?.item);
  }, [props, success, added]);

  if (loadingTeams) {
    return <Loader />;
  }
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const renderDescription = () => {
    if (item && item.description && item.description.length > 200) {
      const shortenedDescription = item.description.slice(0, 200) + "...";
      return (
        <>
          <p className="text-[14px] font-normal">{shortenedDescription}</p>
          <Link
            to={`/event/${props.item.id}`}
            className="text-blue-500 hover:opacity-80"
          >
            Read More
          </Link>
        </>
      );
    } else {
      return (
        <p className="text-[14px] font-normal">{item && item.description}</p>
      );
    }
  };

  return (
    <div>
      <img
        src={props?.item?.img_link}
        alt="LG BALL"
        className="mt-0 w-full object-cover lg:h-[400px]"
      />

      <div className="bg-white p-10 text-2xl font-bold">
        <div className="text-[20px] uppercase">
          {props?.item?.title?.split(" ").slice(0, 15).join(" ")}
          {props?.item?.title?.split(" ").length > 15 ? "..." : ""}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-5">
          <div className="lg:col-span-2 my-[20px]">
            <div className="uppercase  text-red-600 py-1">
              {new Date(props?.item?.date).getDate()}
              <span className="text-xl font-semibold ml-2">
                {monthNames[new Date(props.item.date).getMonth()]}
              </span>
            </div>
            <div className="uppercase  text-red-600 text-xl">
              <AiOutlineClockCircle className="inline mr-2" />
              <span className="text-base text-black">
                {new Date(props.item.date).toLocaleTimeString("en-US")}
              </span>
            </div>
            <div className=" text-red-600 text-xl">
              <FaDollarSign className="inline mr-1" />
              <span className="text-base text-black">
                {props.item.selling_price}
              </span>
            </div>
            <div className="uppercase text-red-600 text-xl">
              <VscLocation className="inline mr-2" />
              <span className="text-base text-black">
                {props.item.location}
              </span>
            </div>
            <div className="uppercase  text-red-600 text-xl">
              <AiOutlineCalendar className="inline mr-2" />
              <span className="text-base text-black">
                {new Date(props.item.date).toLocaleString("en-us", {
                  weekday: "long",
                })}
              </span>
            </div>
          </div>
          <div className="lg:col-span-3 ">
            <div className="lg:px-10 flex-1 flex-col bg-white text-black lg:py-10 border-white">
              {/* {props?.item?.teamsId.length > 0 && (
              <div className="flex justify-between items-center py-8">
                  <>
                    <img
                      alt={teamDetails[0]?.name}
                      src={teamDetails[0]?.imageURL} // Correct the image URL key
                      className="w-2/5 h-[250px] object-cover"
                    />
                    <span className="italic">VS</span>
                    <img
                      alt={teamDetails[1]?.name}
                      src={teamDetails[1]?.imageURL} // Correct the image URL key
                      className="w-2/5 h-[250px] object-cover"
                    />
                  </>
              </div>
                )} */}
              <p className="text-[14px] font-normal">{renderDescription()}</p>
              <div className="flex float-right">
                <FaShareAltSquare className="inline text-5xl mr-4" />
                {parseInt(props?.item?.selling_price) > 0 ? (
                  <button
                    onClick={AddToCart}
                    className="bg-yellow-300 rounded-md text-xl whitespace-nowrap p-2 min-w-[75px] w-fit"
                  >
                    Buy
                  </button>
                ) : (
                  <button
                    onClick={AddToCart}
                    className="bg-yellow-300 rounded-md text-xl whitespace-nowrap p-2 min-w-[75px] w-fit"
                  >
                    Register For Free
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(MainUpComingEvents);
