import React from "react";
import {
  Typography,
} from "@mui/material";
import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";
import useGetActivityByType from "../../hooks/useGetActivityByType";
import Loader from "../../Components/Loader";

const ComptetiveDetails = () => {
  const { activity, loading: activityLoading } = useGetActivityByType('COMPTETIVE');

  if (activityLoading) {
    return <Loader />;
  }

  return (
    <div className="px-4 md:px-10 my-10">
      <div className="flex flex-col gap-4">
        {activity && ( 
          <>
            <Typography sx={{ fontSize: '3rem' }} component="div" textTransform={'uppercase'} gutterBottom>
              {activity?.title}
            </Typography>

            <Typography variant="body1" color="text.secondary">
              <div dangerouslySetInnerHTML={{ __html: activity?.description }} />
            </Typography>
          </>
        )}
      </div>
    
      <AdvertisementOne index={0} />
    </div>
  );
};

export default ComptetiveDetails;
