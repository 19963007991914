/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import z from 'zod';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations'; // Make sure to import your GraphQL mutations

const ComptetiveTeamSchema = z.object({
    name: z.string(),
    agegroup: z.string(),
    imageURL: z.string(),
    location: z.string(),
    city: z.string()
});



const useHandleComptetiveTeams = () => {
    const [comptetiveTeams, setComptetiveTeams] = useState([]);

    const [loading, setLoading] = useState(false);





    const getTeamById = async (teamId) => {
        setLoading(true);

        try {
            const response = await API.graphql(
                graphqlOperation(queries.getComptetiveTeam, { id: teamId }),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );
            return response.data.getComptetiveTeam;
        } catch (error) {
            toast.error("Failed to fetch team.");
            throw error;
        } finally {
            setLoading(false)
        }
    };


    const getAllTeams = async () => {
        setLoading(true);

        try {
            const response = await API.graphql(
                graphqlOperation(queries.listComptetiveTeams),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );

            // const withImageUrl = await Promise.all(

            //     response.data.listComptetiveTeams.items?.map(async (data) => {
            //         const url = await getLink(data.imageURL);
            //         return {
            //             ...data,
            //             img_url: url
            //         }

            //     })

            // );
            // console.log("withImageUrl", withImageUrl)
            // setComptetiveTeams(withImageUrl);
            return response.data.listComptetiveTeams.items|| [];
        } catch (error) {
            toast.error("Failed to fetch teams.");
            throw error;
        } finally {
            setLoading(false)
        }
    };

    return {
        getTeamById,
        getAllTeams,
        loading,
        comptetiveTeams
        
    };
};

export { ComptetiveTeamSchema, useHandleComptetiveTeams };
