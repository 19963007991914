import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

import { useEffect, useState } from "react";
import { getAllTeams } from "../../redux/actions/TeamAction";
import getLink from "../../utils/getLinks";

const Teams = () => {
  const { teams, loading } = useSelector((state) => state.teams);
  const dispatch = useDispatch();
  const [startIndex, setStartIndex] = useState(0);

  const handleImage = async (key) => {
    const link = await getLink(key);
    return link;
  };
  useEffect(() => {
    dispatch(getAllTeams());
  }, []);
  console.log("teams,", teams);

  const handleLeftClick = () => {
    console.log("left click");
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  const handleRightClick = () => {
    if (startIndex < teams.length - 3) {
      setStartIndex(startIndex + 1);
    }
  };

  return (
    <div className="p-20 bg-[#181B22] text-white">
      <div
        className={`py-2 uppercase font-bold  border-b-2  text-[32px]  border-[#c4c4c4] " : ""
        }`}
      >
        <span className="text-[#D70D15] font-bold">|</span>
        <span className="">Teams</span>
        <div className="float-right text-3xl">
          <button
            onClick={handleLeftClick}
            className="text-[#D70D15] font-bold mr-8"
          >
            <AiOutlineLeft className="text-lg font-bold" />
          </button>
          <button
            onClick={handleRightClick}
            className="text-[#D70D15] font-bold"
          >
            <AiOutlineRight className="text-lg font-bold" />
          </button>
        </div>
      </div>
      <div className="md:grid grid-cols-3 flex flex-col gap-20 my-20">
        {loading ? (
          <>
           <div className="h-40 bg-gray-400 animate-pulse"></div>
           <div className="h-40 bg-gray-400 animate-pulse"></div>
           <div className="h-40 bg-gray-400 animate-pulse"></div>
          
          </>
        ) : (
          <>
            {teams?.slice(startIndex, startIndex + 3).map((team, index) => (
              <img
                key={index}
                src={team?.img_url}
                className="w-full h-[250px] object-cover"
                alt={team?.name}
              />
            ))}
          </>
        )}
      </div>
      <div className="flex flex-col md:flex-row justify-center items-center gap-20">
        <NavLink
          to="/signupleague"
          className="uppercase text-center  border-2 p-2 text-[#fec749] border-[#fec749] rounded-md w-60 whitespace-nowrap"
        >
          join our team
        </NavLink>
        <NavLink
          to="/register"
          className="uppercase text-center  border-2 p-2 bg-[#fec749] text-black border-[#fec749] rounded-md w-60 whitespace-nowrap"
        >
          register
        </NavLink>
      </div>
    </div>
  );
};

export default Teams;
