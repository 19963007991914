import { useState } from "react";
import { API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import useAuthInfo from "./getUser";
import useGetAddressList from "./getAddressList";

const useDeleteAddress = () => {
  const [loading, setLoading] = useState(false);
  const { userId, isAuthenticated } = useAuthInfo();
  const { addressesList } = useGetAddressList();

  const deleteAddress = async (index, deleteAddress) => {
    console.log("deleteAddress", deleteAddress);
    console.log("addressList", addressesList);

    try {
      if (isAuthenticated && userId) {
        setLoading(true);

        const updatedAddresses = addressesList.filter((_, i) => i !== index);

        // Update the address using the API
        const response = await API.graphql({
          query: mutations.updateAddress,
          variables: {
            input: {
              id: userId,
              userid: userId,
              addresses: updatedAddresses,
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        console.log("Delete Address Response:", response);

        // Address deletion was successful
        setLoading(false);
        return true;
      }
    } catch (error) {
      console.error("Error deleting Address:", error);
      setLoading(false);
      return false;
    }
  };

  return { deleteAddress, loading };
};

export default useDeleteAddress;
