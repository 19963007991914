import React from "react";

const LgNewsSkeleton = () => {
  return (
    <div className="bg-white px-4 flex flex-col justify-between h-full py-4 shadow-lg rounded-md overflow-hidden transition duration-300 hover:shadow-xl">
      <div className="animate-pulse flex items-center mb-2">
        <span className="mr-2 bg-gray-200 rounded-md h-4 w-12 animate-pulse"></span>
        <span className="inline bg-gray-200 rounded-md h-4 w-4 animate-pulse"></span>
        <span className="ml-1 bg-gray-200 rounded-md h-4 w-6 animate-pulse"></span>
      </div>
      <span className="text-xl mb-2 bg-gray-200 rounded-md h-8 w-full animate-pulse"></span>
      <div className="h-[150px] w-full bg-gray-200 animate-pulse"></div>
      <div className="h-6 w-full bg-gray-200 animate-pulse"></div>
    </div>
  );
};

export default LgNewsSkeleton;
