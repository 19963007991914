import Teams from "../../Components/Teams";
import axios from "axios";
import Shop from "../../Components/Shop";
import TypoBu from "../../Components/TypoBu";
import { Link, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import * as mutations from "../../graphql/mutations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Advertisement from "../../Components/Advertisement";
import RenderShop from "../../Components/Shop/RenderShop";
import Loader from "../../Components/Loader";
import SmallLoader from "../../Components/Loader/SmallLoader";
import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";

const Contact = () => {
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    message: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.message) {
      setFormErrors({
        name: formData.name ? "" : "Name is required",
        email: formData.email ? "" : "Email is required",
        message: formData.message ? "" : "Message is required",
      });
      return;
    }
    try {
      setLoading(true);
      const checkoutPayload = {
        body: {
          ...formData,
        },
      };

      // Send a request to the Stripe API to create a checkout session
      const response = await API.post(
        "contactApi",
        "/contact/contactus",
        checkoutPayload
      );

      console.log("Response:", response.data);
      setLoading(false);
      // setFormData({
      //   name: "",
      //   email: "",
      //   message: "",
      // })

      toast.success("We will get back to you soon!");
      // Handle success, display a success message, or redirect the user
      setFormData({
        name: "",
        email: "",
        message: "",
      });
    } catch (error) {
      setLoading(false);
      toast.error("Something Went Wrong!");
      console.error("Error:", error);

      throw new Error(error);
      // Handle error, display an error message
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    setFormErrors({
      ...formErrors,
      [e.target.id]: "",
    });
  };

  return (
    <div className="bg-white text-white">
      <ToastContainer />
      <div className="sm:ml-4 md:flex mb-20">
        <div className="md:w-3/5 px-4 md:mt-[6rem] mt-4 relative mb-20">
          <div className="text-4xl text-[#F1F1F1] font-bold relative z-10 whitespace-nowrap md:text-[6rem] md:flex md:flex-col">
            <span className="uppercase whitespace-nowrap md:whitespace-normal pr-2 mb-[4rem]">
              contact
            </span>
            <span className="uppercase whitespace-nowrap md:whitespace-normal">
              us
            </span>
          </div>
          <img
            className="absolute top-2 mt-auto w-96 left-2 sm:left-[4rem] z-20"
            src="/images/Bigball.png"
            alt="Bigball"
          />
        </div>
        <form
          onSubmit={handleSubmit}
          className="w-full md:w-2/5 text-black md:mr-8 lg:mt-[5rem] md:mt-20 mt-[15rem] px-2"
        >
          <TypoBu
            item={{
              text: "contact us",
              text_ssize: "text-3xl",
              text_bsize: "text-4xl",
              border: true,
              button: false,
            }}
          />
          <div className="mt-4">
            <h5>Name</h5>
            <input
              className={`border-[#e7e7e7] border-2 rounded-md p-2 lg:w-96 w-full ${
                formErrors.name && "border-red-500" // Apply red border for error
              }`}
              type="text"
              id="name"
              value={formData.name}
              onChange={handleInputChange}
            ></input>
            {/* Display error in red color */}
            <p className="text-red-500">{formErrors.name}</p>
          </div>
          <div className="mt-4">
            <h5>Email</h5>
            <input
              className={`border-[#e7e7e7] border-2 rounded-md p-2 lg:w-96 w-full ${
                formErrors.email && "border-red-500" // Apply red border for error
              }`}
              type="email"
              id="email"
              value={formData.email}
              onChange={handleInputChange}
            ></input>
            {/* Display error in red color */}
            <p className="text-red-500">{formErrors.email}</p>
          </div>
          <div className="mt-4">
            <h5>Message</h5>
            <textarea
              value={formData.message}
              onChange={handleInputChange}
              id="message"
              rows="4"
              className={`block p-2.5 text-sm text-black rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 lg:w-96 w-full ${
                formErrors.message && "border-red-500" // Apply red border for error
              }`}
            ></textarea>
            {/* Display error in red color */}
            <p className="text-red-500">{formErrors.message}</p>
          </div>
          <div className="flex pt-20 mb-5 ml-2 mr-2 ">
            <button
              type="submit"
              className="font-bold bg-[#fec749] lg:px-40 px-20 whitespace-nowrap py-2"
            >
              {loading ? <SmallLoader /> : <>submit</>}
            </button>
          </div>
        </form>
      </div>
      <Teams />
      <div className="bg-[#181B22]">
        <AdvertisementOne />
      </div>
      <div className="md:p-20 p-4 b-[#181B22] bg-black flex flex-col gap-20 py-20">
        {/* <Advertisement /> */}
        <TypoBu
          item={{
            text: "shop",
            text_ssize: "text-3xl",
            text_bsize: "text-4xl",
            border: true,
            button: false,
          }}
        />
        <Shop />
        {/* <RenderShop /> */}
        <div className="text-center">
          <Link
            to="/store"
            className="px-20 py-2 whitespace-nowrap border-white border rounded-sm"
          >
            VIEW ALL
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Contact;
