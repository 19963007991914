import { NavLink } from "react-router-dom";

const SignUpWK = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 bg-[#181B22] text-white">
      <img
        alt="johan-arano"
        src="/images/john-arano.png"
        className="md:col-span-3 h-full w-full"
      ></img>
      <div className="col-span-2 flex flex-col justify-center mt-5 gap-5 gap-10">
        <span className="text-lg  font-bold pl-2 pr-2">
          SIGN UP <br /> FOR A <br /> WEEKEND <span className="text-xs pr-2 font-bold ">TOURNAMENT</span>
        </span>
       
        <div className="text-center px-4 mb-10">
          <NavLink
            to="/register"
            className="bg-[#fec749] whitespace-nowrap rounded-md text-black block p-1"
          >
            Sign Up
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default SignUpWK;
