import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { composeWithDevTools } from "redux-devtools-extension";
import { userReducer } from "./reducers/UserReducer";
import {
  productsReducer,
} from "./reducers/ProductReducer";
import { eventsReducer } from "./reducers/EventReducer";
import { teamsReducer } from "./reducers/TeamReducer";
import { cityReducer } from "./reducers/CityReducer";
import { newsReducer } from "./reducers/NewsReducer";
import { cartReducer } from "./reducers/CartItem";
import { couponsReducer } from "./reducers/CouponReducer";
import { playerReducer } from "./reducers/PlayerReducer";
import { clinicReducer } from "./reducers/ClinicReducer";
import { campReducer } from "./reducers/CampReducer";
import { tournamentReducer } from "./reducers/TournamentReducer";
import { trainingProgramReducer } from "./reducers/TrainingProgramReducer";
import { leagueInfoReducer } from "./reducers/LeagueInfoReducer";
import { tournamentTeamsReducer } from "./reducers/TournamentTeamReducer";
import { tournamentEventsReducer } from "./reducers/TournamentEventReducer";


const reducer = combineReducers({
  products: productsReducer,
  user:userReducer,
  events:eventsReducer,
  teams:teamsReducer,
  city:cityReducer,
  news:newsReducer,
  cart:cartReducer,
  coupons:couponsReducer,
  player:playerReducer,
  clinic:clinicReducer,
  camp:campReducer,
  tournament:tournamentReducer,
  trainingProgram:trainingProgramReducer,
  leagueInfo:leagueInfoReducer,
  tournamentTeams:tournamentTeamsReducer,
  tournamentEvents:tournamentEventsReducer




//   forgotPassword:forgotPasswordReducer,
//   cart:cartReducer,
//   newOrder:newOrderReducer,
//   myOrders:myOrdersReducer,
//   newReview:newReviewReducer,
//   review:productReviewsReducer,
//   orderDetails:orderDetailsReducer,
//   allOrders: allOrdersReducer,
//   allUsers:allUsersReducer,
//   order: orderReducer,
//   profile:profileReducer,
//   userDetails:userDetailsReducer
});



const middleware = [thunk];

// let initialState = {
//   cart: {
//     cartItems: localStorage.getItem("cartItems")
//       ? JSON.parse(localStorage.getItem("cartItems"))
//       : [],
//     shippingInfo: localStorage.getItem("shippingInfo")
//       ? JSON.parse(localStorage.getItem("shippingInfo"))
//       : {},
//   },
// };


const store = createStore(
  reducer,
  // initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
export default store;