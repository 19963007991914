import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../../graphql/queries"; // Import your GraphQL queries
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import {
    CREATE_CITY_REQUEST,
    CREATE_CITY_SUCCESS,
    CREATE_CITY_FAIL,
    GET_CITY_REQUEST,
    GET_CITY_SUCCESS,
    GET_CITY_FAIL,
    LIST_CITIES_REQUEST,
    LIST_CITIES_SUCCESS,
    LIST_CITIES_FAIL,
    UPDATE_CITY_REQUEST,
    UPDATE_CITY_SUCCESS,
    UPDATE_CITY_FAIL,
    DELETE_CITY_REQUEST,
    DELETE_CITY_SUCCESS,
    DELETE_CITY_FAIL,
    DELETE_CITY_RESET,
    CLEAR_CITY_ERRORS,
    
    
  } from "../constants/CityConstants";
import getLinks from "../../utils/getLinks";

export const getAllCity = () => async (dispatch) => {

  console.log('cities:',1)
  
  try {
    dispatch({ type: LIST_CITIES_REQUEST });
    const response = await API.graphql(
      graphqlOperation(queries.listCities),
    {authMode: GRAPHQL_AUTH_MODE.API_KEY},
    );
    console.log('cities:',response)

    const data = response?.data?.listCities?.items || [];
 
  

    dispatch({
      type: LIST_CITIES_SUCCESS,
      payload:data,
    });
  } catch (error) {
    const errorMessage =
    error.response && error.response.data.message
      ? error.response.data.message
      : error.message;

    dispatch({
      type: LIST_CITIES_SUCCESS,
      payload: errorMessage,
    });
    console.log("errormessage: " + errorMessage)
  }
};


export const getCityDetails = (cityId) => async (dispatch) => {
    try {
      dispatch({ type: GET_CITY_REQUEST });
  
      const response = await API.graphql(
        graphqlOperation(queries.getCity, { id: cityId })
      );

      const data=response?.data?.getItem;
      dispatch({
        type: GET_CITY_REQUEST,
        payload: data
      });
    } catch (error) {
          const errorMessage =
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message;
  
      dispatch({
        type: GET_CITY_FAIL,
        payload: errorMessage,
      });
    }
  };
  
//add a new product
// export const addProduct = (productData,images) => async (dispatch) => {
//     try {
//       dispatch({ type: ADD_PRODUCT_REQUEST });

//       const config = {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       };
//       // const config = { headers: { "Content-Type": "multipart/form-data" } };

//       const { data } = await axios.post("/api/v1/admin/new/product", productData, config);

//       dispatch({
//         type: ADD_PRODUCT_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       const errorMessage =
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message;

//       dispatch({
//         type: ADD_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

// export const updateProduct = (eventId, productData) => async (dispatch) => {
//     try {
//       dispatch({ type: UPDATE_PRODUCT_REQUEST });

//       const config = {
//         headers: { "Content-Type": "application/json" },
//       };

//       const { data } = await axios.put(
//         `/api/v1/admin/product/${eventId}`,
//         productData,
//         config
//       );

//       dispatch({
//         type: UPDATE_PRODUCT_SUCCESS,
//         payload: data.success,
//       });
//     } catch (error) {
//           const errorMessage =
//             error.response && error.response.data.message
//               ? error.response.data.message
//               : error.message;

//       dispatch({
//         type: UPDATE_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

// export const deleteProduct = (eventId) => async (dispatch) => {
//     try {
//       dispatch({ type: DELETE_PRODUCT_REQUEST });

//       const { data } = await axios.delete(`/api/v1/admin/product/${eventId}`);

//       dispatch({
//         type: DELETE_PRODUCT_SUCCESS,
//         payload: data.success,
//       });
//     } catch (error) {
//           const errorMessage =
//             error.response && error.response.data.message
//               ? error.response.data.message
//               : error.message;

//       dispatch({
//         type: DELETE_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };


export const clearCityErrors = () => async (dispatch) => {
  
    dispatch({ type: CLEAR_CITY_ERRORS });
  };