import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import store from "./redux/store";

Amplify.configure(awsExports);


// Define your custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#FEC749', // Yellow color
    },
  },
});




const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        {/* Your component tree */}


        <App />
      </ThemeProvider>

    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
