import React, { useState } from "react";
import { BsFillShareFill } from "react-icons/bs";

const LeagueCart = ({ item, index, selectedItems, toggleItemSelection }) => {
  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);

    return `${day}/${month}/${year}`;
  }

  return (
    <div
      className="bg-white flex items-start gap-2 rounded-md mb-2"
      key={index}
    >
      <div className="">
        <input
          type="checkbox"
          className="cursor-pointer ml-1"
          checked={selectedItems.includes(item?.id)}
          onChange={() => toggleItemSelection(item?.id)}
        />
      </div>

      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
        <img
          src={item?.render_img_url}
          alt={item?.title}
          className="h-full w-full object-cover object-center"
        />
      </div>
      <div className="flex w-full flex-col px-4">
        <span className="font-bold capitalize">{item?.title}</span>
        <span className="float-right">
          <span className="mr-3">{formatDate(item?.date)}</span>
        </span>
        <span className="text-green-500">{item?.location}</span>

        <span className="mr-3 font-semibold">${item?.selling_price}</span>
      </div>

      <div className="flex flex-col md:flex-row xl:flex-row md:space-x-4 xl:space-x-4">
        <div className="mb-2 md:mb-0 xl:mb-0 md:flex md:flex-col xl:flex-col">
          {item?.shipping ? (
            <>
              <p className="bg-black text-white px-4 capitalize font-bold rounded-lg">
                {item?.shipping}
              </p>
              <p className="uppercase font-bold text-xs">shipping</p>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="mb-2 md:mb-0 xl:mb-0 md:flex md:flex-col xl:flex-col">
          {item?.days_return && (
            <>
              <p className="bg-black text-white px-4 inline-block font-bold rounded-lg">
                {item?.days_return}
              </p>
              <p className="uppercase font-bold text-xs">days return</p>
            </>
          )}
          <button className="md:ml-2 xl:ml-4">
            {" "}
            <BsFillShareFill />
          </button>
        </div>
      </div>
    </div>
  );
};

export default LeagueCart;
