import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../../graphql/queries"; // Import your GraphQL queries
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import {
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,
  LIST_PRODUCTS_REQUEST,
  LIST_PRODUCTS_SUCCESS,
  LIST_PRODUCTS_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT_RESET,
  BUY_PRODUCT_REQUEST,
  BUY_PRODUCT_SUCCESS,
  BUY_PRODUCT_FAIL,
  CLEAR_PRODUCT_ERRORS,
} from "../constants/ProductsConstants";
import getLinks from "../../utils/getLinks";

export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
      __typename
    }
  }
`;

export const getAllProducts =
  (page = 1, itemsPerPage = 10, productName = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: LIST_PRODUCTS_REQUEST });

    
    
      let response;

      // Fetch data using the previous token

      response = await API.graphql({
        query: queries.listItems,

        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const data = response.data.listItems.items || [];
      const { nextToken: prevToken } = response.data.listItems;

     
      // const previosresult.data.listItems.nextToken

      const updatedProducts = await Promise.all(
        data?.map(async (product) => {
          try {
            const signedUrl = await getLinks(product?.img_url);
            const dataWithImageUrl = {
              render_img_url: signedUrl,
              ...product,
            };
            return { ...dataWithImageUrl };
          } catch (error) {
            console.error("Error fetching image URL", error);
            return product;
          }
        })
      );

      const updatedData = {
        products: updatedProducts,
        productsCount: response?.data.listItems.items.length,
        currentPage: page,
        resultPerPage: itemsPerPage,
        previousTokens: prevToken,
        filteredProductsCount: response.data.listItems.items.length,
      };

      dispatch({
        type: LIST_PRODUCTS_SUCCESS,
        payload: updatedData,
      });
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        "Error fetching products";

      dispatch({
        type: LIST_PRODUCTS_FAIL,
        payload: errorMessage,
      });
    }
  };

export const getProductDetails = (productId) => async (dispatch) => {
  try {
    dispatch({ type: GET_PRODUCT_REQUEST });

    const response = await API.graphql(
      graphqlOperation(queries.getItem, { id: productId })
    );

    const data = response?.data.getItem;
    dispatch({
      type: GET_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: GET_PRODUCT_FAIL,
      payload: errorMessage,
    });
  }
};

//add a new product
// export const addProduct = (productData,images) => async (dispatch) => {
//     try {
//       dispatch({ type: ADD_PRODUCT_REQUEST });

//       const config = {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       };
//       // const config = { headers: { "Content-Type": "multipart/form-data" } };

//       const { data } = await axios.post("/api/v1/admin/new/product", productData, config);

//       dispatch({
//         type: ADD_PRODUCT_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       const errorMessage =
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message;

//       dispatch({
//         type: ADD_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

// export const updateProduct = (eventId, productData) => async (dispatch) => {
//     try {
//       dispatch({ type: UPDATE_PRODUCT_REQUEST });

//       const config = {
//         headers: { "Content-Type": "application/json" },
//       };

//       const { data } = await axios.put(
//         `/api/v1/admin/product/${eventId}`,
//         productData,
//         config
//       );

//       dispatch({
//         type: UPDATE_PRODUCT_SUCCESS,
//         payload: data.success,
//       });
//     } catch (error) {
//           const errorMessage =
//             error.response && error.response.data.message
//               ? error.response.data.message
//               : error.message;

//       dispatch({
//         type: UPDATE_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

// export const deleteProduct = (eventId) => async (dispatch) => {
//     try {
//       dispatch({ type: DELETE_PRODUCT_REQUEST });

//       const { data } = await axios.delete(`/api/v1/admin/product/${eventId}`);

//       dispatch({
//         type: DELETE_PRODUCT_SUCCESS,
//         payload: data.success,
//       });
//     } catch (error) {
//           const errorMessage =
//             error.response && error.response.data.message
//               ? error.response.data.message
//               : error.message;

//       dispatch({
//         type: DELETE_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

export const clearProductErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_PRODUCT_ERRORS });
};
