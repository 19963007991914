import { Storage } from 'aws-amplify';

const uploadFile = async (file) => {


  try {
    console.log(`Uploading`, file);

    if (file === null || '') {
      return;
    }
    // Check if the file type is an image (e.g., jpg, png, gif)
    //   if (file.type.includes("image/")) {

    const currentTime = new Date().toISOString().replace(/[:.]/g, '');
    const randomData = Math.random().toString(36).substring(2, 8);
    const uniqueFileName = `${currentTime}_${randomData}_${file.name}`;

    const link = await Storage.put(uniqueFileName, file, {
      level: 'public',
      contentType: file.type,
    });
    console.log('Uploading file uploaded successfully.', link);

    console.log('File URL - ', link);

    // eslint-disable-next-line consistent-return
    return link.key;
    // Assuming setImageLink is a state setter for the image link
  } catch (error) {
    console.error('Error uploading file: ', error);
    throw new Error(error);
  }
};

export default uploadFile;
