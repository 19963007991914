import axios from "axios";
import { useEffect, useState } from "react";
import LtNews from "../../Components/LtNews";
import TypoBu from "../../Components/TypoBu";
import SignUpWK from "../../Components/SignUpWK";
import { Link, NavLink, useParams } from "react-router-dom";
import SocialMediaShareButtons from "../../Components/SocialMediaShareButtons";
import Advertisement from "../../Components/Advertisement";
import Sponsors from "../../Components/Sponsors";
import Loader from "../../Components/Loader";
import RenderShop from "../../Components/Shop/RenderShop";
import { useDispatch, useSelector } from "react-redux";

import {
  clearNewsErrors,
  getNewsDetails,
} from "../../redux/actions/NewsAction";

const Vlog = () => {
  let { id } = useParams();
  const [singleVideos, setSingleVideos] = useState({});
  const dispatch = useDispatch();
  const { vlog, newsDetails, newsViews, loading, error } = useSelector(
    (state) => state?.news
  );
  useEffect(() => {
    if (error) {
      dispatch(clearNewsErrors());
    }

    if (id) {
      dispatch(getNewsDetails(id));
    }
  }, [id, dispatch, error]);
console.log('vlog', vlog);
  useEffect(() => {
    if (Object.keys(newsDetails).length !== 0) {
      setSingleVideos(newsDetails);
    } else if (vlog.length > 0) {
      setSingleVideos(vlog[0]);
    }
  }, [newsDetails]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="bg-[#E7E7E7]">
      {/* <video className="w-full" controls>
        <source type="video/youtube" src={videos[0]?.video_url} />
      </video> */}

      {vlog.length > 0 &&
          <iframe
            width="351"
            height="624"
            className="w-full"
            src={singleVideos.youtube_url ? singleVideos?.youtube_url : singleVideos?.video_url}
            title={singleVideos?.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        }

      {!vlog?.length > 0 && (
        <p className="py-[25px] text-center">No videos available</p>
      )}
      {/* ):( ) }
      {Object.keys(singleVideos)?.length > 0 ? (
        <iframe
          width="351"
          height="624"
          className="w-full"
          src={singleVideos.video_url}
          title={singleVideos.title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      ) : videos && videos.length > 0 ? (
        <iframe
          width="351"
          height="624"
          className="w-full"
          src={videos[0].video_url}
          title={videos[0].title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      ) : (
        <p>No videos available</p>
      )} */}
      {vlog?.length > 1 && (
        <div className="py-20 px-5 md:px-20  bg-white">
          <TypoBu
            item={{
              text: "videos",
              text_ssize: "text-3xl",
              text_bsize: "text-4xl",
              border: true,
              button: false,
            }}
          />
          <div className="mt-10 grid  grid-cols-1 sm:grid-cols-3 gap-10">
            {vlog?.map((item, index) => (
              <div key={index} className="bg-white min-h-[450px] rounded-sm h-auto transition-all ease-in-out duration-500    hover:shadow-lg border">
                <Link to={`/vlog/${item?.id}`} className="flex gap-1 h-full flex-col justify-between"> 
                  {/* <div className="bg-white relative"> */}
                    {/* <video className="w-full" controls>
                    <source type="video/youtube" src={item.video_url} />
                  </video> */}
                    {item?.youtube_url ? (
                      <iframe
                        width="351"
                        height="324"
                        className="w-full"
                        src={item?.youtube_url}
                        title={item?.title}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      ></iframe>
                    ) : (
                      <iframe
                      width="351"
                      height="324"
                      className="w-full"
                      src={item?.video_url}
                      title={item?.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                    )}

                    <div className="px-4 my-1">
                      <span className="text-1xl uppercase font-bold">
                        {item?.title.split(" ").slice(0, 20).join(" ")}
                        {item?.title.split(" ").length > 20 && "..."}
                      </span>
                    </div>

                    <div className="px-[15px] relative bottom-0">
                      <SocialMediaShareButtons />
                    </div>
                  {/* </div> */}
                </Link>
              </div>
            ))}
          </div>
          <div className="mt-20">
            <Advertisement />
          </div>
        </div>
      )}
      <div className="flex justify-center py-20 bg-gray-300">
        <div className="w-[600px] text-center">
          <span className="text-2xl font-bold block mb-4 uppercase">
            You will love OUR Vlog
          </span>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-3 px-4 md:px-18 gap-2 md:gap-20 pt-10 bg-white">
        <div className="col-span-2">
          <LtNews />
          <div className="mt-20">
            <Advertisement />
          </div>
        </div>

        <div className="flex flex-col gap-10">
          <SignUpWK />
          <Sponsors />
          <div className="my-5 md:my-10">
            <RenderShop />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vlog;
