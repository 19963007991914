import axios from "axios";
import { useEffect, useState } from "react";
import Shop from "../../Components/Shop";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { BsPaypal } from "react-icons/bs";
import OrderBox from "../../Components/OrderBox";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Payment = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [cartItems, setCartItems] = useState([
        { id: 1, name: 'Item sub total', price: 399 },
        { id: 2, name: 'Delivery', price: 32 },
        { id: 3, name: 'Discount', price: 32 },
        // Add more items as needed
    ]);
    const getStyles = () => {
        if (window.innerWidth < 300) {
            const size = "20px";
            return {
                width: size,
                minHeight: size,
                marginTop: "1px"
            };
        }
        return {};
    };
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <>
            <div className="text-xl p-7 ml-2 uppercase font-bold mb-4">payment method</div>
            <ToastContainer />
            <div className="md:pr-4 sm:pr-2 md:ml-1 md:pl-8 sm:ml-4 sm:pl-4 md:flex">
                <div className="md:w-3/5 p-4">
                    <div className="mt-2 border ">
                        <p className="text-sm px-2 uppercase font-bold pb-0">saved cards</p>
                        <span className="text-sm px-2 mt-4 flex flex-wrap ">A default payment method is already selected. choose another to change it for the current transaction only.</span>

                        <div className="flex flex-nowrap">
                            <div className="flex items-center p-2  w-2/3 mr-2 flex-shrink-0 mt-2">

                                <div className="flex flex-col space-y-1 ">
                                    <div className="uppercase font-bold">debit cards</div>
                                    <label className="flex items-center space-x-2">
                                        <input type="radio" name="size" />
                                        <span className="uppercase text-xs pb-2">Bank Debit Card Ending with 4356</span>
                                    </label>
                                    <label className="flex items-center space-x-2">
                                        <input type="radio" name="size" />
                                        <span className="uppercase text-xs pb-2">Bank Debit Card Ending with 4356</span>
                                    </label>
                                    <label className="flex items-center space-x-2">
                                        <input type="radio" name="size" />
                                        <span className="uppercase text-xs pb-2">Bank Debit Card Ending with 4356</span>
                                    </label>
                                    <label className="flex items-center space-x-2">
                                        <input type="radio" name="size" />
                                        <span className="uppercase text-xs">Bank Debit Card Ending with 4356</span>
                                    </label>
                                </div>
                            </div>
                            <div
                                class="w-1/5 mt-2 mt-4 sm:pl-4 ml-auto mr-2"
                                style={getStyles()}
                            >
                                <div className="border bg-gray-50 rounded-lg md:h-16 flex justify-center items-center" >
                                    <button className="text-gray-500 text-2xl font-bold">+</button>
                                </div>
                            </div>
                        </div>




                        <div className="flex flex-nowrap">
                            <div className="flex p-2 w-2/3 mr-2 flex-shrink-0 mt-2">
                                <div className="flex flex-col space-y-1">
                                    <div className="uppercase font-bold">credit cards</div>
                                    <div className="flex flex-col md:flex-row justify-between items-center pb-2 space-x-2 flex-wrap">
                                        <label className="flex items-center space-x-2">
                                            <input type="radio" name="credit_card" checked />
                                            <span className="uppercase text-xs whitespace-nowrap">Bank Debit Card Ending with 4356</span>
                                        </label>
                                        <span class="text-white bg-gray-400 rounded-tl-md rounded-tr-md rounded-br-md rounded-bl-md font-bold uppercase text-xs p-1 px-4 text-center md:inline mt-2 md:mt-0">emi options available</span>
                                    </div>



                                    <div className="flex flex-col px-8">
                                        <label className="flex flex-wrap items-center mb-1 space-x-2 pb-2">
                                            <input type="radio" name="size" checked />
                                            <span className="uppercase text-xs whitespace-nowrap">full payment</span>
                                            <div className="flex items-center space-x-2 mt-2 md:mt-0">
                                                <input
                                                    type="text"
                                                    className="h-8 px-1 w-20 sm:w-40 border rounded-md"
                                                    placeholder="Enter CVV"
                                                />
                                                <button className="bg-[#fec749] px-2 py-1 rounded-sm hover:bg-[#fec749]">
                                                    Apply
                                                </button>
                                            </div>
                                        </label>


                                        <div className="flex flex-wrap  mb-1 space-x-2 pb-2">
                                            <label className="flex  space-x-2">
                                                <input type="radio" name="size" />
                                                <span className="uppercase text-xs whitespace-nowrap">$23 X 3 Months</span>
                                            </label>
                                            <span className="text-white text-center bg-gray-400  rounded-tl-md rounded-tr-md rounded-br-md rounded-bl-md font-bold uppercase text-xs p-1 px-4 md:inline mt-2 md:mt-0">
                                                Total with interest:$69
                                            </span>
                                        </div>

                                        <div className="flex flex-wrap  mb-1 space-x-2 pb-2">
                                            <label className="flex  space-x-2">
                                                <input type="radio" name="size" />
                                                <span className="uppercase text-xs whitespace-nowrap">$23 X 6 Months</span>
                                            </label>
                                            <span className="text-white text-center bg-gray-400  rounded-tl-md rounded-tr-md rounded-br-md rounded-bl-md font-bold uppercase text-xs p-1 px-4 md:inline mt-2 md:mt-0">
                                                Total with interest:$69
                                            </span>
                                        </div>
                                        <div className="flex flex-wrap  mb-1 space-x-2 pb-2">
                                            <label className="flex  space-x-2">
                                                <input type="radio" name="size" />
                                                <span className="uppercase text-xs whitespace-nowrap">$23 X 9 Months</span>
                                            </label>
                                            <span className="text-white text-center bg-gray-400  rounded-tl-md rounded-tr-md rounded-br-md rounded-bl-md font-bold uppercase text-xs p-1 px-4 md:inline mt-2 md:mt-0">
                                                Total with interest:$69
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col md:flex-row justify-between items-center pb-2 space-x-2 flex-wrap">
                                        <label className="flex items-center space-x-2">
                                            <input type="radio" name="credit_card" />
                                            <span className="uppercase text-xs whitespace-nowrap">Bank Debit Card Ending with 4356</span>
                                        </label>
                                        <span class="text-white bg-gray-400 rounded-tl-md rounded-tr-md rounded-br-md rounded-bl-md font-bold uppercase text-xs p-1 px-4 text-center md:inline mt-2 md:mt-0">emi options available</span>
                                    </div>
                                    <div className="flex flex-col md:flex-row justify-between items-center pb-2 space-x-2 flex-wrap">
                                        <label className="flex items-center space-x-2">
                                            <input type="radio" name="credit_card" />
                                            <span className="uppercase text-xs whitespace-nowrap">Bank Debit Card Ending with 4356</span>
                                        </label>
                                        <span class="text-white bg-gray-400 rounded-tl-md rounded-tr-md rounded-br-md rounded-bl-md font-bold uppercase text-xs p-1 px-4 text-center md:inline mt-2 md:mt-0">emi options available</span>
                                    </div>
                                </div>
                            </div>
                            <div className="w-1/5 mt-4 sm:pl-4 ml-auto mr-2" style={getStyles()}>
                                <div className="border bg-gray-50 rounded-lg md:h-16 flex justify-center items-center">
                                    <button className="text-gray-500 text-2xl font-bold">+</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="flex flex-col mt-4">
                        <div className="uppercase font-bold  mb-2">net banking</div>
                        <div className="flex items-center space-x-2">
                            <input type="radio" name="size" />
                            <select className="w-46 px-2 py-1 border rounded-md text-sm text-gray-900 bg-white">
                                <option value="">Select Your Bank</option>
                                <option value="bank1">Bank 1</option>
                                <option value="bank2">Bank 2</option>
                                {/* Add more bank options here */}
                            </select>
                        </div>
                        <div className="mt-4">
                            <div className="uppercase font-bold  mb-2">paypal</div>
                            <div className="flex items-center space-x-2">
                                <input type="radio" name="size" />
                                <button className="border border-dark "><BsPaypal /></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=" w-full md:w-2/5 md:ml-4 md:mr-2">
                    <OrderBox cartItems={cartItems} />
                </div>
            </div>





            <div className="p-20 bg-[#181B22] text-white">
                <div className="py-2 uppercase font-bold text-4xl border-b-2   border-[#c4c4c4]">
                    <span className="border-l-4 border-[#D70D15] text-3xl px-2">
                        shop
                    </span>

                    <div className="float-right text-3xl">
                        <button className="text-[#D70D15] font-bold mr-8">
                            <AiOutlineLeft className="text-lg font-bold" />
                        </button>
                        <button className="text-[#D70D15] font-bold">
                            <AiOutlineRight className="text-lg font-bold" />
                        </button>
                    </div>
                </div>
                <Shop item={{ itemlist: itemlist }} />
                <div className="text-center">
                    <button className="border-2 p-2 border-white rounded-md w-60 mr-20">
                        VIEW ALL
                    </button>
                </div>
            </div>

        </>
    );
};

export default Payment;

const itemlist = [
    {
        text: "PRODUCT TITLE",
        price: "$250",
        src: "/images/shop_ball.png",
        rating: "⭐⭐⭐⭐⭐",
        badge: "new",
    },
    {
        text: "PRODUCT TITLE",
        price: "$250",
        src: "/images/shop_band.png",
        rating: "⭐⭐⭐⭐⭐",
    },
    {
        text: "PRODUCT TITLE",
        price: "$250",
        src: "/images/shop_net.png",
        rating: "⭐⭐⭐⭐⭐",
    },
    {
        text: "PRODUCT TITLE",
        price: "$250",
        src: "/images/shop_ball.png",
        rating: "⭐⭐⭐⭐⭐",
    },
];
