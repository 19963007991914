import React from "react";
import useListScores from "../../hooks/useListScores";
import Loader from "../../Components/Loader";
import TournamentTable from "../latestTournaments/TournamentsTable";

const PastTournaments = () => {
  const scoreMapped = new Map();
  const { listScores, loading } = useListScores();

  if (loading) {
    return <Loader />;
  }

  // Filter scores where tournament is true
  const tournamentScores = listScores?.filter(
    (score) => score?.tournament === true
  );

  listScores?.map((score) => {
    if (score.eventStatus != "EVENT_ENDED") return;
    const ifHas = scoreMapped.has(score.eventId);
    if (ifHas) {
      scoreMapped.get(score.eventId).push(score);
    } else {
      scoreMapped.set(score.eventId, [score]); // Initialize as array with the first score
    }
  });
  console.log("List scorse", tournamentScores);
  return (
    <div className="max-w-[1400px] p-0 bg-gray-50 mx-auto">
      {/* <h1 className="track-wider font-normal">Matches</h1> */}
      {Array.from(scoreMapped.entries()).map(([eventId, scores]) => (
        <TournamentTable tournament={scores} />
      ))}
      {/* {tournamentScores?.map((score) => (
        <Match
          key={score?.matchNumber}
          date={score?.createdAt}
          matchNumber={score?.matchNumber}
          team1Name={score?.firstTeam?.teamName} // Pass individual team properties
          team1Logo={score?.firstTeam?.teamLogo}
          team2Name={score?.secondTeam?.teamName}
          team2Logo={score?.secondTeam?.teamLogo}
          id={score.id}
        />
      ))} */}
    </div>
  );
};
export default PastTournaments;