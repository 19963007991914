import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries"; // Make sure to import your GraphQL queries
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

const useItemById = () => {
  const [items, setItems] = useState([]); // Use an array to store multiple items
  const [loading, setLoading] = useState(false);

  const fetchItems = async (itemId) => {
    try {
      setLoading(true);
      console.log("itemId", itemId);
      // Iterate through the item IDs in the user's cart and fetch their details

      // const response = await API.graphql(
      //   graphqlOperation(queries.getItem, { id: itemId })
      // );

      const response = await API.graphql({
        query: queries.getItem,
        variables: { input: { id: itemId } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      // Wait for all promises to resolve
      setLoading(false);

      console.log("response", response?.data?.getItem);
      return response?.data?.getItem;
    } catch (error) {
      setLoading(false);
      console.error("Error fetching items:", error);
    }
  };

  return { fetchItems };
};

export default useItemById;
