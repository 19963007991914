import React, { useState } from "react";
import Loader from "../../Components/Loader";

const ProfileComponent = ({ playerData, updatePlayers }) => {
  delete playerData.createdAt;
  delete playerData.updatedAt;
  delete playerData.__typename;
  delete playerData.userId;
  delete playerData.isVerified;
  delete playerData.scoreID;
  delete playerData.teamID;
  delete playerData.sessionId;
  delete playerData.transactionStatus;


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toDateString(); // Convert the date to a string format
  };

  playerData.memberExpire = formatDate(playerData.memberExpire);

  const [userDetails, setUserDetails] = useState({...playerData,newProfile:""});
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setUserDetails(playerData);
    setIsEditing(false);
  };

  const handleSaveClick = async () => {
  const updated=  await updatePlayers(userDetails);
    
  // Perform logic to save updated user details to backend/database
    setIsEditing(false);
    // Additional logic for updating user details
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };
  const changeProfile = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      setUserDetails({ ...userDetails, newProfile: selectedImage });
    }
    console.log("userDetails changed", userDetails?.newProfile);
  };
  const renderImage = () => {
    return Object.keys(userDetails).map((key) => {
      if (key === "profile" && userDetails[key]) {
        // If the key is 'profile' and it exists in userDetails
        return (
          <div key={key}>
            <img
              className="h-[80px] w-[80px] rounded-full mt-3"
              src={userDetails.profileLink}
              alt="Profile"
            />
            {isEditing && (
              <input
                className="border rounded px-4 py-2 w-full mt-3"
                type="file"
                id={key}
                name={key}
                onChange={changeProfile} // Use the changeProfile function to handle file selection
                disabled={!isEditing}
              />
            )}
          </div>
        );
      }
      return null;
    });
  };

  return (
    <div className="container mx-auto ">
      <div className="mx-auto mt-8 ">
        <div className="flex justify-between  mb-4 items-center">
          <h2 className="text-2xl font-semibold">Profile</h2>
          {isEditing ? (
            <div className="flex space-x-4">
              <button
                type="button"
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
                onClick={handleSaveClick}
              >
                Save
              </button>
              <button
                type="button"
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              type="button"
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
              onClick={handleEditClick}
            >
              Edit
            </button>
          )}
        </div>
        <form>
          <div className="flex flex-col gap-4">{renderImage()}</div>
          <div className="flex flex-col gap-4">
            {Object?.keys(userDetails)?.map((key) => {
              if (key === "document" && userDetails[key]) {
                // If the key is 'document' and it exists in userDetails
                return (
                  <div key={key} className="flex flex-col gap-2">
                    <span>Document</span>
                    <a
                      className="p-[10px] w-[150px] text-center text-white bg-blue-500 rounded-lg"
                      href={userDetails?.documentLink}
                      target="_blank"
                    >
                      View Document
                    </a>
                  </div>
                );
              } else if (
                key !== "id" &&
                key !== "profile" &&
                key !== "document" &&
                key !== "profileLink" &&
                key !== "documentLink"&&
                key !== "newProfile"
              ) {
                return (
                  <div key={key}>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-1"
                      htmlFor={key}
                    >
                      {key}
                    </label>
                    <input
                      className="border rounded px-4 py- w-full"
                      type="text"
                      id={key}
                      name={key}
                      value={userDetails[key]}
                      onChange={handleChange}
                      disabled={!isEditing||key==='cityOfRegistration'}
                    />
                  </div>
                );
              }
            })}
          </div>
          {/* Add other input fields for user details */}
          {/* ... */}

          {isEditing ? (
            <div className="flex space-x-4">
              <button
                type="button"
                className="bg-gray-500 mt-4 hover:bg-gray-600 text-white px-4 py-2 rounded"
                onClick={handleSaveClick}
              >
                Save
              </button>
              <button
                type="button"
                className="bg-gray-500 mt-4 hover:bg-gray-600 text-white px-4 py-2 rounded"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              type="button"
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded mt-4"
              onClick={handleEditClick}
            >
              Edit
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default ProfileComponent;
