import { BiClinic } from "react-icons/bi";
import { FaCampground } from "react-icons/fa";
import { BiBasketball } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { HiOutlineHandRaised } from "react-icons/hi2";
import { RiSuitcaseLine } from "react-icons/ri";

const SignUpList = () => {
  return (
    <div className='flex flex-col gap-2'>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 bg-[#181B22] text-white">
        <img
          alt="johan-arano"
          src="/images/john-arano.png"
          className="md:col-span-3 h-full w-full"
        ></img>
        <div className="col-span-2 flex flex-col justify-center mt-5 gap-5 gap-10">
          <span className="text-lg  font-bold pl-2 pr-2">
            SIGN UP <br /> FOR A <br /> WEEKEND{" "}
            <span className="text-xs pr-2 font-bold ">TOURNAMENT</span>
          </span>

          <div className="text-center px-4 mb-10">
            <NavLink
              to="/signupevent"
              className="bg-[#fec749] whitespace-nowrap rounded-md text-black block p-1"
            >
              Sign Up
            </NavLink>
          </div>
        </div>
      </div>
      <div className="bg-white text-center pt-10 py-10">
        <div className="uppercase font-bold">
          <div>sign up for</div>
          clinics, camps and training programs.
        </div>
        <div className="mb-4 mt-4">
          <BiClinic className="inline text-4xl mb-2" />
          <div className="font-bold mb-4">CLINICS</div>
          <div className="text-center my-10">
            <span className="py-4 whitespace-nowrap inline-block w-full">
              <div className="flex items-center justify-center">
                <NavLink
                  to="/signupclinic"
                  className="w-full uppercase bg-yellow-300 p-2 ml-2 mr-2"
                >
                  sign up
                </NavLink>
              </div>
            </span>
          </div>
        </div>
        <div className="mb-4">
          <FaCampground className="inline text-4xl mb-2" />
          <div className="font-bold mb-4">CAMPS</div>
          <div className="text-center my-10">
            <span className="py-4 whitespace-nowrap inline-block w-full">
              <div className="flex items-center justify-center">
                <NavLink
                  to="/signupcamp"
                  className="w-full uppercase bg-yellow-300 p-2 ml-2 mr-2"
                >
                  sign up
                </NavLink>
              </div>
            </span>
          </div>
        </div>
        <div className="mb-4">
          <BiBasketball className="inline text-4xl mb-2" />
          <div className="font-bold mb-4 whitespace-nowrap">
            TRAINING PROGRAMS
          </div>
          <div className="text-center my-10">
            <span className="py-4 whitespace-nowrap inline-block w-full">
              <div className="flex items-center justify-center">
                <NavLink
                  to="/signuptrainingprogram"
                  className="w-full uppercase bg-yellow-300 p-2 ml-2 mr-2"
                >
                  sign up
                </NavLink>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className="bg-white text-center pt-10 py-10 mt-4">
        <div className="uppercase font-bold ">
          <div className="mb-4">
            <HiOutlineHandRaised className="inline text-4xl mb-2" />
            <div className="text-center my-10">
              <span className="py-4 whitespace-nowrap inline-block w-full">
                <div className="flex items-center justify-center">
                  <NavLink
                    to="/signupvolunteer"
                    className="w-full uppercase bg-yellow-300 p-2 ml-2 mr-2"
                  >
                    volunteer
                  </NavLink>
                </div>
              </span>
            </div>
          </div>
          <div className="mb-4">
            <RiSuitcaseLine className="inline text-4xl mb-2" />
            <div className="text-center my-10">
              <span className="py-4 whitespace-nowrap inline-block w-full">
                <div className="flex items-center justify-center">
                  <NavLink
                    to="/signupworkwithus"
                    className="w-full uppercase bg-yellow-300 p-2 ml-2 mr-2"
                  >
                    work with us
                  </NavLink>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignUpList;
