import TypoBu from "../../Components/TypoBu";
import SocialMediaShareButtons from "../SocialMediaShareButtons";
import { AiOutlineEye } from "react-icons/ai";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import LgNewsSkeleton from "../Skeleton/LgNewsSkeleton";
import { clearNewsErrors, getAllNews } from "../../redux/actions/NewsAction";
import LgNewsCard from "./LgNewsCard";

const LgNews = () => {
  const dispatch = useDispatch();
  const { news, loading, error } = useSelector((state) => state?.news);
  const [page, setPage] = useState(0);
  const itemsPerPage = 4;
  const startIndex = page * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const filteredData = news.slice(startIndex, endIndex);
  const totalPages = Math.ceil(news?.length / itemsPerPage);

  useEffect(() => {
    if (error) {
      dispatch(clearNewsErrors());
    }
    dispatch(getAllNews());
  }, [page, dispatch]);

  const handlePagination = (increment) => {
    const newPage = page + increment;
    if (newPage >= 0 && newPage < totalPages) {
      setPage(newPage);
    }
  };

  return (
    <div>
      <TypoBu
        item={{
          text: "media gallery",
          text_ssize: "text-3xl",
          text_bsize: "text-4xl",
          border: true,
          button: true,
        }}
        disabledLeft={page === 0}
        onClickLeft={() => handlePagination(-1)}
        disabledRight={page === totalPages - 1}
        onClickRight={() => handlePagination(1)}
      />
      <div className="mt-10 grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 w-full  items-center  gap-[20px]">
        {loading ? (
          <>
          <LgNewsSkeleton />
          <LgNewsSkeleton />
          <LgNewsSkeleton />
          <LgNewsSkeleton />
          
          </>

        ) : (
          <>
            {filteredData.map((item, index) => {
              // Convert the 'item.date' into a Date object
              const newsDate = new Date(item?.date);

              // Format the date to display day, month, and year
              const formattedDate = `${newsDate.toLocaleString("en-US", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}`;

              return <div className="mx-auto h-full sm:mx-0"> 
                <LgNewsCard item={item} key={item?.id} formattedDate={formattedDate} />
                </div>;
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default LgNews;
