import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";
import Loader from "../../Components/Loader";
import { NavLink } from "react-router-dom";
import useGetActivityByType from "../../hooks/useGetActivityByType";

const LeagueDescription = () => {
  const { activity, loading } = useGetActivityByType('PLAYER');

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="px-4 md:px-10 mt-5">
      <div className="flex flex-col lg:flex-row items-center gap-[25px]">
        <CardContent className="w-full max-w-[1400px] mx-auto ">
          {activity && ( // Conditionally render if activity exists
            <>
              <Typography sx={{ fontSize: '3rem' }} component="div" textTransform={'uppercase'} gutterBottom>
                {activity.title}
              </Typography>

              <Typography variant="body1" color="text.secondary">
                <div dangerouslySetInnerHTML={{ __html: activity.description }} />
              </Typography>

              <div className="flex pt-20 mb-5 ml-2 mr-2">
                <NavLink
                  to="/signupleague"
                  className="font-bold  bg-[#fec749] lg:px-40 px-20 whitespace-nowrap py-1"
                >
                  Sign Up
                </NavLink>
              </div>
            </>
          )}
        </CardContent>
      </div>

      <div className="mt-8">
        {/* Additional details or images can be added here */}
      </div>
      <AdvertisementOne index={0} />
      <br />
    </div>
  );
};

export default LeagueDescription;
