import React from "react";
import { Link } from "react-router-dom";

const TournamentCard = ({ item }) => {
  return (
    <Link
      className="min-w-[80px]  hover:opacity-80 transition-all ease-in-out  duration-300 w-full h-[80px] flex items-center justify-center bg-[#fec749]"
      to={`/${item.link}`}
    >
      {item?.title}
    </Link>
  );
};

export default TournamentCard;
