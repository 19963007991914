/* eslint-disable arrow-body-style */

import { Storage } from 'aws-amplify';



const getLink = async(key) => {
    try {
      if(key===''||null){
        return '';
      }
      const signedUrl = await getSignedUrl(key);

      return signedUrl;
    } catch (error) {
      console.error('Error fetching image URL', error);
      return ''; // Return an empty string in case of an error
    }
  };




const getSignedUrl = (link) => {
    return Storage.get(link, {
      level: 'public',
      download: false,
    });
  };


  export default getLink;