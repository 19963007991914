import React from "react";
import ClinicsCard from "./ClinicsCard";
import {
  Typography,
} from "@mui/material";
import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";
import useGetAllClinics from "../../hooks/getAllClinics";
import useGetActivityByType from "../../hooks/useGetActivityByType";
import Loader from "../../Components/Loader";

const Clinics = () => {
  const { clinics, loading } = useGetAllClinics();
  const { activity, loading: activityLoading } = useGetActivityByType('CLINIC');

  if (loading || activityLoading) {
    return <Loader />;
  }

  return (
    <div className="px-4 md:px-10 my-10">
      <div className="flex flex-col gap-4">
        {activity && ( 
          <>
            <Typography sx={{ fontSize: '3rem' }} component="div" textTransform={'uppercase'} gutterBottom>
              {activity?.title}
            </Typography>

            <Typography variant="body1" color="text.secondary">
              <div dangerouslySetInnerHTML={{ __html: activity?.description }} />
            </Typography>
          </>
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 my-8">
        {clinics?.map((clinic) => (
          <div key={clinic.id}>
            <ClinicsCard item={clinic} />
          </div>
        ))}
      </div>
      <AdvertisementOne index={0} />
    </div>
  );
};

export default Clinics;
