import {
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_FAIL,
  UPDATE_TEAM_REQUEST,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAIL,
  GET_TEAM_REQUEST,
  GET_TEAM_SUCCESS,
  GET_TEAM_FAIL,
  LOAD_TEAMS_REQUEST,
  LOAD_TEAMS_SUCCESS,
  LOAD_TEAMS_FAIL,
  LIST_TEAMS_REQUEST,
  LIST_TEAMS_SUCCESS,
  LIST_TEAMS_FAIL,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAIL,
  DELETE_TEAM_RESET,    
  CLEAR_TEAM_ERRORS,
  
} from "../constants/TournamentTeamsConstants";

  const initialState = {
    tournamentTeams: [],
    teamsCount: 0,
    resultPerPage: 0,
    tournamentTeam: {},
    loading: false,
    error: null,
    filteredTeamsCount: 0,
  };
  
  export const tournamentTeamsReducer = (state = initialState, action) => {
    switch (action.type) {
      case LIST_TEAMS_REQUEST:
      case GET_TEAM_REQUEST:
        case CREATE_TEAM_REQUEST:
          case DELETE_TEAM_REQUEST:
        return {
          ...state,
          loading: true,
          tournamentTeams: [],
          tournamentTeam:{}

        };
      case LIST_TEAMS_SUCCESS:
        return {
          ...state,
          loading: false,
          tournamentTeams: action.payload.tournamentTeams,
          teamCount: action.payload.teamsCount,
          resultPerPage: action.payload.resultPerPage,
          filteredteamsCount: action.payload.filteredteamsCount,
        };
        case CREATE_TEAM_SUCCESS:
          return {
            ...state,
            added: true,
            loading: false,
          };
        case GET_TEAM_SUCCESS:
          return {
            ...state,
            loading: false,
            tournamentTeam:action.payload

          };

      case GET_TEAM_FAIL:
      case LIST_TEAMS_FAIL:
      case CREATE_TEAM_FAIL:
        case DELETE_TEAM_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
        case DELETE_TEAM_SUCCESS:
          return {
            ...state,
            deleted: true,
            loading: false,
          };
      case CLEAR_TEAM_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };