import {
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  GET_CART_FAIL,
  UPDATE_CARTITEMS_REQUEST,
  UPDATE_CARTITEMS_SUCCESS,
  UPDATE_CARTITEMS_FAIL,
  UPDATE_LEAGUE_REQUEST,
  UPDATE_LEAGUE_SUCCESS,
  UPDATE_LEAGUE_FAIL,
  DELETE_CART_RESET,
  CLEAR_CART_ERRORS,
  ADD_ITEMS_TO_CART_REQUEST,
  ADD_ITEMS_TO_CART_SUCCESS,
  ADD_ITEMS_TO_CART_FAIL,
  ADD_ITEMS_TO_CART_RESET,
  REMOVE_ITEMS_FROM_CART_REQUEST,
  REMOVE_ITEMS_FROM_CART_SUCCESS,
  REMOVE_ITEMS_FROM_CART_FAIL,
  REMOVE_LEAGUE_FROM_CART_REQUEST,
  REMOVE_LEAGUE_FROM_CART_SUCCESS,
  REMOVE_LEAGUE_FROM_CART_FAIL,
  REMOVE_LEAGUE_FROM_CART_RESET,
  ADD_LEAGUE_TO_CART_REQUEST,
  ADD_LEAGUE_TO_CART_SUCCESS,
  ADD_LEAGUE_TO_CART_FAIL,
  ADD_LEAGUE_TO_CART_RESET,
  CREATE_CART_REQUEST,
  CREATE_CART_SUCCESS,
  CREATE_CART_FAIL,
  GET_MAIN_CART_REQUEST,
  GET_MAIN_CART_SUCCESS,
  GET__MAIN_CART_FAIL,
} from "../constants/CartConstants";

const initialState = {
  cart: {},
  cartItems: [],
  leagueItems: [],
  createCartItems: {},
  createLeagueCartItems: {},
  deleted: false,
  updated: false,
  added: false,
  cartQuantity: 0,
  leagueQuantity: 0,
  totalPrice: 0,
  loading: false,
  error: null,
  resultPerPage: 0,
  filteredCartCount: 0,
};

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MAIN_CART_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_CARTITEMS_REQUEST:
    case ADD_ITEMS_TO_CART_REQUEST:
    case ADD_LEAGUE_TO_CART_REQUEST:
    case CREATE_CART_REQUEST:
    case REMOVE_ITEMS_FROM_CART_REQUEST:
    case UPDATE_LEAGUE_REQUEST:
    case GET_CART_REQUEST:
      return {
        ...state,
        // loading: true,
      };
    // eslint-disable-next-line no-duplicate-case
    case GET_CART_SUCCESS:
    case GET_MAIN_CART_SUCCESS:
      return {
        ...state,
        cart: action.payload.cart,
        leagueItems: action.payload.leagueItems,
        cartItems: action.payload.cartItems,
        cartQuantity: action.payload.cartQuantity,
        totalPrice: action.payload.totalPrice,
        filteredCartCount: action.payload.filteredCartCount,
        loading: false,
      };
    case ADD_ITEMS_TO_CART_SUCCESS:
      return {
        ...state,
        cartItems: action.payload,
        added: true,
        loading: false,

        // leagueCartItems: action.payload.leagueCartItems,
      };
    case ADD_LEAGUE_TO_CART_SUCCESS:
      return {
        ...state,
        added: true,
        leagueItems: action.payload,
        loading: false,
      };
    case UPDATE_CARTITEMS_SUCCESS:
      return {
        ...state,
        cartItems: action.payload,
        updated: true,
        loading: false,

        // leagueCartItems: action.payload.leagueCartItems,
      };
    case UPDATE_LEAGUE_SUCCESS:
      return {
        ...state,
        leagueItems: action.payload,
        updated: true,
        loading: false,
      };
    case REMOVE_ITEMS_FROM_CART_SUCCESS:
      return {
        ...state,
        cartItems: action.payload,
        deleted: true,
        loading: false,

        // leagueCartItems: action.payload.leagueCartItems,
      };
    case REMOVE_LEAGUE_FROM_CART_SUCCESS:
      return {
        ...state,
        leagueItems: action.payload,
        deleted: true,
        loading: false,

        // leagueCartItems: action.payload.leagueCartItems,
      };
    case GET_CART_FAIL:
    case CREATE_CART_FAIL:
    case ADD_ITEMS_TO_CART_FAIL:
    case REMOVE_ITEMS_FROM_CART_FAIL:
    case REMOVE_LEAGUE_FROM_CART_FAIL:
    case UPDATE_CARTITEMS_FAIL:
    case UPDATE_LEAGUE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CLEAR_CART_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const cartLocalReducer = (
  state = { cartItems: [], leagueItems: [], shippingInfo: {} },
  action
) => {
  switch (action.type) {
    case ADD_LEAGUE_TO_CART_REQUEST:
      const league = action.payload;

      const isLeagueExist = state.cartItems.find(
        (i) => i.leagueId === item.leagueId
      );

      if (isLeagueExist) {
        return {
          ...state,
          leagueItems: state.leagueItems.map((i) =>
            i.product === isItemExist.product ? item : i
          ),
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, league],
        };
      }

    case REMOVE_LEAGUE_FROM_CART_REQUEST:
      return {
        ...state,
        leagueItems: state.leagueItems.filter(
          (i) => i.product !== action.payload
        ),
      };
    case ADD_ITEMS_TO_CART_REQUEST:
      const item = action.payload;

      const isItemExist = state.cartItems.find((i) => i.itemId === item.itemId);

      if (isItemExist) {
        return {
          ...state,
          cartItems: state.cartItems.map((i) =>
            i.product === isItemExist.product ? item : i
          ),
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item],
        };
      }

    case REMOVE_ITEMS_FROM_CART_REQUEST:
      return {
        ...state,
        cartItems: state.cartItems.filter((i) => i.product !== action.payload),
      };

    // case SAVE_SHIPPING_INFO:
    //   return {
    //     ...state,
    //     shippingInfo: action.payload,
    //   };

    default:
      return state;
  }
};
