import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries"; // Import your GraphQL queries
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

const useGetAllItems = () => {
  const [itemlist, setItemList] = useState([]);
  const [loading, setLoading] = useState(false); // Use an array to store multiple listScores
  const [error, setError] = useState(null); // Use an array to store multiple listScores

  useEffect(() => {
    const fetchItems=async()=>{

      try {
        setLoading(true)
        API.graphql({
          query: queries.listItems,
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        }).then((response) => {
          setLoading(false)
          setItemList(response?.data?.listItems?.items);
        });
      } catch (error) {
        setLoading(false);
        setError(error);
        
      }
    }
    fetchItems();
  }, []);

  return { itemlist,loading,error };
};

export default useGetAllItems;
