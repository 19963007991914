import { NavLink } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import { CiPhone } from "react-icons/ci";
import { VscLocation } from "react-icons/vsc";
import { ImFacebook } from "react-icons/im";
import { IoLogoTwitter } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineGooglePlus } from "react-icons/ai";
import { SiInstagram } from "react-icons/si";

const Footer = () => {
  const { cities, error: cityError } = useSelector((state) => {
    return state.city;
  });
  const pageLinks = [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "About",
      link: "about",
    },
    {
      text: "Rules",
      link: "rules",
    },
    // {
    //   text: "LeagueInfo",
    //   link: "leagueinfo",
    // },
    {
      text: "Score",
      link: "leagueplayer",
    },
    {
      text: "TournamentScore",
      link: "tournamentScore",
    },
    {
      text: "City",
      drop: cities?.map((city, index) => ({
        text: city?.name,
        city: city?.name,
        link: "leagueinfo", // Replace this link with the appropriate URL for each city
      })),
    },
    {
      text: "Events",
      link: "events",
    },
    {
      text: "News",
      link: "news",
    },
    {
      text: "Store",
      drop: [
        {
          text: "Sonics",
          img: "images/team-logo-sonics.png",
          link: "store",
        },
        {
          text: "BayArea",
          img: "images/team-logo-hoopitup.png",
          link: "store",
        },
        {
          text: "Sizzle",
          img: "images/team-logo-sizzle.png",
          link: "store",
        },
        {
          text: "ForMen",
          link: "store",
        },
        {
          text: "ForWomen",
          link: "store",
        },
        {
          text: "ForKids",
          link: "store",
        },
      ],
    },
    {
      text: "Blog",
      link: "blog",
    },
    {
      text: "Vlog",
      link: "vlog",
    },
    {
      text: "ContactUs",
      link: "contact",
    },
  ];
  return (
    <div>
      <div className="footer-logo inline">
        <img src="/images/logo_sponsor.png" alt="logo" />
        <img src="/images/logo_sponsor.png" alt="logo" />
      </div>
      <div className="bg-black text-white">
        <div className="px-4 md:px-10 lg:px-20 py-20 flex lg:grid grid-cols-3 flex-col sm:flex-col">
          <div className="mx-auto flex flex-col items-center md:items-start w-full">
            <div className="home-logo mb-10 w-full" />

            <div className="mt-16 ">
              <NavLink
                to="/signupleague"
                className="uppercase whitespace-nowrap  border-2 px-[25px]  py-2 mr-2 text-[#fec749] border-[#fec749] rounded-md w-full my-10"
              >
                join our team
              </NavLink>
            </div>
            <div className="mt-10 mb-5 ">
              <NavLink
                to="/register"
                className="uppercase whitespace-nowrap  border-2 px-[46px] py-2 mr-2 bg-[#fec749] text-black border-[#fec749] rounded-md w-full"
              >
                register
              </NavLink>
            </div>
          </div>
          <div className="grid grid-cols-3 text-sm leading-10">
            <div className="">
              <NavLink to={"/"} className="  px-4 block">
                Home
              </NavLink>
              <NavLink to="/about" className="px-4 block">
                About
              </NavLink>
              <NavLink to="/rules" className="px-4 block">
                Rules
              </NavLink>
              <NavLink to="/leagueplayer" className="px-4 block">
                Score
              </NavLink>
              <NavLink to="/events" className="px-4 block">
                Events
              </NavLink>
              <NavLink to="/league-details" className="px-4 block">
                LeagueInfo
              </NavLink>
              <NavLink to="/leagueteam" className="px-4 block">
                LeagueTeam
              </NavLink>
             
              <NavLink to="/tournamentsScore" className="px-4 block">
                TournamentScore
              </NavLink>
            </div>
            <div>
               
            <NavLink to="/tournament-details" className="px-4 block">
                Tournament
              </NavLink>
              <NavLink to="/news" className="px-4 block">
                News
              </NavLink>
              <NavLink to="/store" className="px-4 block">
                Store
              </NavLink>
              <NavLink to="/vlog" className="px-4 block">
                Vlog
              </NavLink>
           
              
              <NavLink to="/contact" className="px-4 block">
                Contact
              </NavLink>
            </div>
            <div>
            <NavLink to="/blog" className="px-4 block">
                Blog
              </NavLink>
              <NavLink to="/signupicon" className="px-4 block">
                Icon
              </NavLink>

              <NavLink to="/signupleague" className="px-4 block">
                Player
              </NavLink>
             
              <NavLink to="/signupclinic" className="px-4 block">
                Clinic
              </NavLink>
              <NavLink to="/signupcamp" className="px-4 block">
                Camp
              </NavLink>

              <NavLink to="/signupvolunteer" className="px-4 block">
                Volunteer
              </NavLink>
              <NavLink to="/signuppartner" className="px-4 block">
                Partner
              </NavLink>
            </div>
          </div>

          <div className="mr-0">
            <span className="border-b-2 border-white text-xl py-2 block mb-10">
              <span className="text-[#D70D15] text-2xl font-bold">|</span>{" "}
              CONTACT US
            </span>
            <div className="my-4">
              <AiOutlineMail className="text-yellow-400 inline mx-4" />
              <span>ray@3×3-league.com</span>
            </div>
            <div className="my-4">
              <CiPhone className="text-yellow-400 inline mx-4" />
              <span>+1 -609-932-7884</span>
            </div>
            <div className="mt-4 mb-10">
              <VscLocation className="text-yellow-400 inline mx-4" />
              <span>Location</span>
            </div>
            <div className="border-t-2 border-white text-white   px-4 py-4"></div>
            <NavLink to="https://www.facebook.com/sfbayarea3x3league">
              <ImFacebook className="mx-4 inline" />
            </NavLink>
          </div>
        </div>
        <div className="text-white text-center py-10">
          <span className="underline mr-10">Term & Conditions</span>
          <span className="underline">Privacy Policy</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
