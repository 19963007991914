import React, { useState, useEffect } from "react";
import { BsFillShareFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { VscLocation } from "react-icons/vsc";
import {
  FaUser,
  FaAppStore,
  FaClock,
  FaCartPlus,
  FaTimes,
} from "react-icons/fa"; // Import user and clock icons

import { getCart, updateCartQuantity } from "../../redux/actions/cartAction";

const SessionCard = ({
  item,
  index,
  selectedItems,
  setIsUpdated,
  toggleItemSelection,
  updateCartItemById,
  addToCart,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const toggleDetails = (item) => {
    setSelectedItem(item);
    setShowDetails(!showDetails);
  };

  const closeModal = () => {
    setShowDetails(false);
    setSelectedItem(null);
  };

  return (
    <div
      className="bg-white flex items-start gap-2 rounded-md mb-2"
      key={index}
    >
      <div key={item?.id} className="flex w-full flex-col px-4">
        <span className="font-bold capitalize">{item?.title}</span>
        <span className="text-blue-500 font-bold pt-2 pb-4">
          <button onClick={() => toggleDetails(item)}>View more details</button>
        </span>
        <div className="flex items-center pb-2">
          <FaClock className="inline text-sm" />
          <span className="ml-1 text-black text-sm">
            {new Date(item.startingDate).toLocaleDateString()} -{" "}
            {new Date(item.endingDate).toLocaleDateString()}
          </span>
        </div>
        <div className="flex items-center pb-2">
          <VscLocation className="inline text-[#D70D15] text-xl" />
          <span className=" text-black text-sm">{item.location}</span>
        </div>
        <div className="flex items-center pb-2">
          <FaUser className="inline text-sm" />
          <span className="ml-1 text-black text-sm">{item.agegroup}</span>
        </div>
        <div className="bg-gray-300 border-gray-300 rounded-md px-4 py-2 my-2">
          <div className="flex justify-between items-center">
            <span>Price</span>
            <span className="font-bold"> ${item?.price}</span>
          </div>
        </div>
        <div className="flex justify-end  border-gray-300 pb-2">
          <button
            onClick={() => addToCart(item)}
            className="flex items-center whitespace-nowrap bg-orange-500 text-white px-4 py-2 rounded-md"
          >
            <FaCartPlus className="mr-2" />
            Add To Cart
          </button>
        </div>
      </div>

      {showDetails && selectedItem && (
        <div className="fixed inset-0 z-[1000] px-[4rem] flex items-center justify-center  bg-black bg-opacity-50">
          <div className="absolute w-full h-full bg-gray-900 opacity-50"></div>
          <div
            className="z-50 bg-white rounded-md relative p-6 sm:w-full md:w-3/4 lg:w-1/2 xl:w-1/3"
            style={{ maxHeight: "100%", overflowY: "auto" }}
          >
            <div className="flex justify-between border-b mb-2 uppercase items-center mb-4">
              <h2 className="text-2xl font-bold text-center flex-1">
                Session Information
              </h2>
              <button onClick={closeModal}>
                <FaTimes />
              </button>
            </div>
            <div>
              <div className="pb-2">
                <span className="font-bold text-xl">{selectedItem.title}</span>
              </div>
              <div className="flex items-center pb-2">
                <FaClock className="inline text-sm" />
                <span className="ml-1 text-black text-sm">
                  {new Date(selectedItem?.startingDate).toLocaleDateString()} -{" "}
                  {new Date(selectedItem?.endingDate).toLocaleDateString()}
                </span>
              </div>
              <div className="flex items-center pb-2">
                <VscLocation className="inline text-[#D70D15] text-xl" />
                <span className=" text-black text-sm">
                  {selectedItem?.location}
                </span>
              </div>
              <div className="flex items-center pb-2">
                <FaUser className="inline text-sm" />
                <span className="ml-1 text-black text-sm">
                  {selectedItem?.agegroup}
                </span>
              </div>
              <div className="flex items-center pb-2">
                <FaAppStore className="inline text-sm" />
                <span className="ml-1 text-black text-sm">Advanced Skills</span>
              </div>
              <div className="flex pb-2">{selectedItem?.description}</div>
              <div className="bg-gray-300 border-gray-300 rounded-md px-4 py-2 my-2 mb-4">
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    <span>Price</span>
                  </div>
                  <div className="flex items-center">
                    <div className="ml-auto">
                      <span className="font-bold mr-2">
                        ${selectedItem?.price}
                      </span>
                    </div>
                    <button
                      onClick={(e) => addToCart(selectedItem)}
                      className="flex items-center whitespace-nowrap bg-orange-500 text-white px-4 py-2 rounded-md"
                    >
                      <FaCartPlus className="mr-2" />
                      Add To Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SessionCard;
