import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import useAllLeaguePlayerList from '../../hooks/getAllLeaguesPlayer';
import OrderCardSkeleton from "../../Components/Skeleton/OrderSkeleton";
import RenderShop from "../../Components/Shop/RenderShop";
import { Link } from "react-router-dom";
import Sponsors from "../../Components/Sponsors";
import Registration from "../../Components/Registration";
import FollowUS from "../../Components/FollowUS";
import SignUpList from "../../Components/SignUPList";
import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";
import useGetAllCity from "../../hooks/getCityList";
import useGetAllTeams from "../../hooks/getTeamsLists";
import Select from 'react-select';

const Players = () => {
  const { allLeaguesUsersList, loading } = useAllLeaguePlayerList();
  const { citylist } = useGetAllCity();
  const { teamslist } = useGetAllTeams();
  const formattedCityList = citylist.map(city => ({ value: city.id, label: city.name }));
  const formattedTeamsList = teamslist.map(team => ({ value: team.id, label: team.name }));

  console.log('teamslist', teamslist)
  const [cityFilter, setCityFilter] = useState('');
  const [teamFilter, setTeamFilter] = useState('');
  const nullOptionForCity = { value: '', label: 'Select City' };
  const nullOptionForTeam = { value: '', label: 'Select Team' };

  const [page, setPage] = useState(0);

  const itemsPerPage = 10;
  const filteredPlayers = allLeaguesUsersList.filter((p) => {
    const cityMatch = !cityFilter || p.cityOfRegistration.trim().toLowerCase() === cityFilter.trim().toLowerCase();
    const teamMatch = !teamFilter || p.teamName.trim().toLowerCase() === teamFilter.trim().toLowerCase();
    return cityMatch && teamMatch;
  });

  const totalPages = Math.ceil(filteredPlayers.length / itemsPerPage);
  const startIndex = page * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const slicedData = filteredPlayers.slice(startIndex, endIndex);
  console.log('slicedData', slicedData)
  const handlePageChange = (selectedPage) => {
    setPage(selectedPage.selected);
  };

  const clearFilters = () => {
    setCityFilter(null);
    setTeamFilter(null);
  };


  return (
    <div className=" p-8">
      <AdvertisementOne />
      <div className="px-4 md:px-10 mt-10 lg:mt-20">
        <div className="grid grid-cols-1 lg:grid-cols-7 gap-4">
          <div className="lg:col-span-5 flex flex-col gap-10">
            <h1 className="text-3xl font-semibold mb-6">Players</h1>

            {loading ? (
              <>
                <OrderCardSkeleton />
                <OrderCardSkeleton />
                <OrderCardSkeleton />
              </>
            ) : (
              <>
                <div className="mb-4 flex flex-wrap justify-center md:justify-start">
                  <div className="flex flex-wrap items-center w-full md:w-auto mb-2 md:mb-0 mr-4">
                    <div className="mb-2 md:mb-0 mr-4">
                      <Select
                        className="w-full md:w-48 rounded-md shadow-sm border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                        placeholder="Select City"
                        options={formattedCityList}
                        value={cityFilter ? formattedCityList.find((option) => option.label === cityFilter) : nullOptionForCity}
                        onChange={(selectedOption) => setCityFilter(selectedOption.label)}
                        components={{
                          IndicatorSeparator: () => null, // Remove the dropdown indicator separator
                        }}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderRadius: '0.375rem', // Rounded corners
                            padding: '0.5rem', // Add padding
                          }),
                          menu: (provided) => ({
                            ...provided,
                            borderRadius: '0.375rem', // Rounded corners
                            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', // Add box shadow
                          }),
                        }}
                      />
                    </div>
                    <div className="mb-2 md:mb-0 mr-4">
                      <Select
                        className="w-full md:w-48 rounded-md shadow-sm border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                        placeholder="Select Team"
                        options={formattedTeamsList}
                        value={teamFilter ? formattedTeamsList.find((option) => option.label === teamFilter) : nullOptionForTeam}
                        onChange={(selectedOption) => setTeamFilter(selectedOption.label)}
                        components={{
                          IndicatorSeparator: () => null, // Remove the dropdown indicator separator
                        }}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderRadius: '0.375rem', // Rounded corners
                            padding: '0.5rem', // Add padding
                          }),
                          menu: (provided) => ({
                            ...provided,
                            borderRadius: '0.375rem', // Rounded corners
                            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', // Add box shadow
                          }),
                        }}
                      />
                    </div>
                    <button
                      className="w-full md:w-auto px-4 py-2 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700 transition-colors duration-200"
                      onClick={clearFilters}
                    >
                      Clear Filters
                    </button>
                  </div>
                </div>
                <div className="w-full overflow-x-auto bg-white rounded-lg shadow-md">
                  <table className="w-full border-collapse table-auto">
                    <thead>
                      <tr className="bg-gradient-to-r from-indigo-500 to-purple-600 text-white">
                        <th className="px-4 py-2 font-semibold text-left">SR. NO.</th>
                        <th className="px-4 py-2 font-semibold text-left">Player</th>
                        <th className="px-4 py-2 font-semibold text-left">City</th>
                        <th className="px-4 py-2 font-semibold text-left">Team</th>
                      </tr>
                    </thead>
                    <tbody>
                      {slicedData?.map((p, index) => (
                        <tr
                          key={index}
                          className="border-b border-gray-200 hover:bg-gray-100 transition-colors duration-200"
                        >
                          <td className="px-4 py-3">{index + 1}</td>
                          <td className="px-4 py-3">
                            <Link
                              className="cursor-pointer flex my-2 p-2 font-bold text-blue-500 items-center"
                              to={`/leagueteam/${p?.id}`}
                            >
                              <div className="mr-2">
                                <img
                                  className="w-10 h-10 rounded-full"
                                  src={p?.playerUrl}
                                  alt={p?.participantName}
                                />
                              </div>
                              <div>{p?.participantName}</div>
                            </Link>
                          </td>
                          <td className="px-4 py-3">{p?.cityOfRegistration}</td>
                          <td className="px-4 py-3">{p?.teamName}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <ReactPaginate
                  previousLabel={<BsChevronLeft className="text-orange-500" />}
                  nextLabel={<BsChevronRight className="text-orange-500" />}
                  breakLabel={'...'}
                  pageCount={totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName={'pagination flex justify-center items-center mt-4'}
                  activeClassName={'active text-orange-500'}
                  previousClassName={'border rounded-full p-2 mr-2'}
                  nextClassName={'border rounded-full p-2 ml-2'}
                  pageClassName={'m-2 cursor-pointer'}
                  breakClassName={'text-orange-500'}
                  pageLinkClassName={'underline'}
                />
              </>
            )}
          </div>
          <div className="lg:col-span-2">
            <Registration />
            <div className="mt-10">
              <FollowUS />
            </div>
            <Sponsors />
            <div className="my-5 md:my-10">
              <RenderShop />
            </div>
            <Sponsors />
            <div className="my-10">
              <SignUpList />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Players;
