import {
  REGISTER_CAMP_REQUEST,
  REGISTER_CAMP_SUCCESS,
  REGISTER_CAMP_FAIL,
  UPDATE_CAMP_REQUEST,
  UPDATE_CAMP_SUCCESS,
  UPDATE_CAMP_FAIL,
  GET_CAMP_REQUEST,
  GET_CAMP_SUCCESS,
  GET_CAMP_FAIL,
  LOAD_CAMPS_REQUEST,
  LOAD_CAMPS_SUCCESS,
  LOAD_CAMPS_FAIL,
  DELETE_CAMP_REQUEST,
  DELETE_CAMP_SUCCESS,
  DELETE_CAMP_FAIL,
  DELETE_CAMP_RESET,
  CLEAR_CAMP_ERRORS,
} from "../constants/CampConstants";

const initialState = {
  camps: [],
  campDetails: {},
  created:false,
  score: {},
  team: {},
  loading: false,
  error: null,
};

export const campReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CAMPS_REQUEST:
    case GET_CAMP_REQUEST:
      case REGISTER_CAMP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CAMP_SUCCESS:
      return {
        ...state,
        loading: false,
        campDetails: action.payload.camp,
        team: action.payload.team,
        score: action.payload.score,
      };

      case REGISTER_CAMP_SUCCESS:
        return {
          ...state,
          loading: false,
          campDetails: action.payload,
          created:true
        };
    case LOAD_CAMPS_SUCCESS:
      return {
          ...state,
        loading: false,
       camps: action.payload,
      };
    case GET_CAMP_FAIL:
    case LOAD_CAMPS_FAIL:
      case REGISTER_CAMP_FAIL:

      return {
          ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_CAMP_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
