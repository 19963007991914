import HeaderLg from "../../Components/HeaderLg";
import Registration from "../../Components/Registration";
import FollowUS from "../../Components/FollowUS";
import SqShop from "../../Components/SqShop";
import Workwith from "../../Components/Workwith";
import LgNews from "../../Components/LgNews";
import FlatVideo from "../../Components/FlatVideo";
import { Table } from "flowbite-react";
import LastGames from "../../Components/LastGames";
import TypoBu from "../../Components/TypoBu";
import ImageGrid from "../../Components/ImageGrid";
import { useSelector, useDispatch } from "react-redux";

import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { NavLink, useParams } from "react-router-dom";
import { ImFacebook } from "react-icons/im";
import { IoLogoTwitter } from "react-icons/io";
import { AiOutlineGooglePlus } from "react-icons/ai";
import { SiInstagram } from "react-icons/si";
import useEndedEvents from "../../hooks/useListScoreEnded";
import useAllLeaguePlayerList from "../../hooks/getAllLeaguesPlayer";
import Advertisement from "../../Components/Advertisement";
import Sponsors from "../../Components/Sponsors";

import Loader from "../../Components/Loader";
import RenderShop from "../../Components/Shop/RenderShop";
import Spinner from "../../Components/Loader/Spinner";
import LeaguePlayerShow from "./LeaguePlayerShow";
import getLink from "../../utils/getLinks";
import { getPlayerDetailsByName } from "../../redux/actions/PlayerAction";
import usePlayerDetails from "../../hooks/getPlayerByName";

const LeagueTeam = () => {
  const { listScores, totalAverages, total, fetchEndedEvents, loading } =
    useEndedEvents();
  const [load, setLoaded] = useState(false);
  const {
    playerDetails,
    loading: playerLoading,
    fetchPlayerByName,
  } = usePlayerDetails();

  const dispatch = useDispatch();

  const { playerId } = useParams();

  console.log("listScores", listScores);
  // const [totalAverages, setTotalAverages] = useState({});
  const [playerImage, setPlayerImage] = useState("");

  function calculateAge(dateOfBirth) {
    const birthDate = new Date(dateOfBirth);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDifference = currentDate.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  useEffect(() => {
    const fetchData = async () => {
      await fetchEndedEvents(playerId);
    };
    if (playerId) {
      fetchPlayerByName(playerId);
    }
    if (load === false) {
      setLoaded(true);
    }
    console.log("playerDetails", playerDetails);
    console.log("listScores", listScores);

    // fsdf
    fetchData();
  }, [playerId, load]);

  // useEffect(() => {
  //   const updateImage = async () => {
  //     const key = listScores[0]?.playerDetails[0]?.playerImage;
  //     const link = await getLink(key);
  //     setPlayerImage(link);
  //   };
  //   calculateTotal();
  //   if (listScores) {
  //     updateImage();
  //   }
  //   console.log("updateImage", playerImage);
  // }, [listScores, playerImage]);

  // useEffect(() => {
  //   const fetch = async () => {
  //     await fetchEndedEvents(playerId);
  //   };
  //   setTotalAverages(calculateTotalAverages());
  //   calculateTotal();
  //   fetch();

  //   console.log('PlayerImage',playerImage)
  // }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (playerId) {
  //       await fetchEndedEvents(playerId);

  //       // setTotalAverages(calculateTotalAverages(listScores));
  //     }
  //     if (playerId && !playerLoading) {
  //       fetchPlayerByName(playerId);
  //     }
  //   };

  //   fetchData();
  // }, [

  // ]);

  const isDataAvailable = listScores && listScores.length > 0 && total !== "0";

  if (loading || playerLoading) {
    return <Loader />;
  }

  return (
    <div className=" bg-[#E7E7E7] pb-10 scroll  ">
      <HeaderLg />
      <ImageGrid />
      <div className="px-4 md:px-10 mt-10 lg:mt-20">
        <div className="grid grid-cols-1 lg:grid-cols-7 gap-2">
          <div className="lg:col-span-5 flex flex-col gap-10">
            {!playerDetails &&
                <h1 className="text-[25px]">Players Details Not Found</h1>
              }
            {playerDetails && (
              <div className="bg-white p-4 sm:p-10  grid sm:grid-cols-2 gap-2 items-center">
                <img
                  alt={playerDetails?.participantName}
                  src={playerDetails?.profile}
                  className="h-96  max-w-[96]"
                />
                <div className="mx-4 sm:mx-0">
                  <img
                    alt="thumb"
                    src="/images/sanjose.png"
                    className="h-10 pr-4 inline"
                  />
                  <span className="inline-block">
                    {playerDetails?.registeredTeam}
                  </span>
                  <div>
                    <span className="uppercase text-3xl  font-bold  my-4">
                      {playerDetails?.participantName}
                    </span>
                    {isDataAvailable && (
                      <span className="uppercase text-sm text-gray-900 ml-4 my-4">
                        (Total {total})
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col sm:flex-row justify-between mb-8">
                    <div className="overflow-x-auto">
                      <table className="border-2 border-collapse">
                        <tbody className="divide-y text-center">
                          <tr className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <td className="font-medium text-gray-900 border font-bold dark:text-white w-32 py-4">
                              <div className="text-xs font-bold uppercase">
                                PPG
                              </div>
                              <div className=" font-bold">
                                {totalAverages?.PTS}
                              </div>
                            </td>
                            <td className="font-medium text-gray-900 border font-bold dark:text-white w-32 py-4">
                              <div className="text-xs font-bold uppercase">
                                REP
                              </div>
                              <div className=" font-bold">
                                {totalAverages?.REB}
                              </div>
                            </td>
                            <td className="font-medium text-gray-900 border font-bold dark:text-white w-32 py-4">
                              <div className="text-xs font-bold uppercase">
                                APG
                              </div>
                              <div className=" font-bold">
                                {totalAverages?.AST}
                              </div>
                            </td>
                            <td className="font-medium text-gray-900 border font-bold dark:text-white w-32 py-4">
                              <div className="text-xs font-bold uppercase">
                                STL
                              </div>
                              <div className="font-bold">
                                {totalAverages?.STL}
                              </div>
                            </td>
                            <td className="font-medium text-gray-900 border font-bold dark:text-white w-32 py-4">
                              <div className="text-xs font-bold uppercase">
                                BLK
                              </div>
                              <div className="font-bold">
                                {totalAverages?.BLK}
                              </div>
                            </td>
                          </tr>
                          <tr className="bg-white dark:border-gray-700 dark:bg-gray-800 ">
                            <td className="font-medium text-gray-900 border font-bold dark:text-white w-32 py-4">
                              <div className="text-xs font-bold uppercase">
                                AGE
                              </div>
                              <div className="font-bold">
                                {playerDetails?.dateOfBirth &&
                                  calculateAge(playerDetails.dateOfBirth)}{" "}
                                years
                              </div>
                            </td>
                            <td
                              className="whitespace-nowrap font-medium text-gray-900 dark:text-white border font-bold py-4"
                              colSpan="2"
                            >
                              <div className="text-xs font-bold uppercase">
                                HEIGHT
                              </div>
                              <span className="block font-bold">
                                {playerDetails?.HEIGHT}
                              </span>
                            </td>
                            <td
                              className="whitespace-nowrap font-medium text-gray-900 dark:text-white border font-bold py-4"
                              colSpan="2"
                            >
                              <div className="text-xs font-bold uppercase">
                                WEIGHT
                              </div>
                              <span className="block font-bold">
                                {playerDetails?.WEIGHT}
                              </span>
                            </td>
                          </tr>
                          <tr className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <td
                              className="whitespace-nowrap font-medium text-gray-900 dark:text-white font-bold py-4"
                              colSpan="4"
                            >
                              <div className="text-xs font-bold uppercase">
                                experience
                              </div>

                              <div className="flex justify-between font-bold px-2 pr-2">
                                <span className=" text-sm">
                                  {playerDetails?.H_S} years H.S.
                                </span>

                                <span className="text-sm">
                                  {playerDetails?.COLLEGE} years College
                                </span>
                              </div>
                              <div className="font-bold flex justify-start pl-2 text-sm">
                                {playerDetails?.NBA} years NBA
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="text-black flex sm:flex-col justify-center flex-row  h-full">
                      <NavLink>
                        <ImFacebook className="mx-2 inline" />
                      </NavLink>
                      <NavLink>
                        <IoLogoTwitter className="mx-2 inline" />
                      </NavLink>
                      <NavLink>
                        <AiOutlineGooglePlus className="mx-2 inline" />
                      </NavLink>
                      <NavLink>
                        <SiInstagram className="mx-2 inline" />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {listScores?.length > 0 && (
              <>
                <TypoBu
                  item={{
                    text: "Last 3 games",
                    text_ssize: "text-xl",
                    text_bsize: "text-2xl",
                    border: true,
                    button: false,
                  }}
                />

                <LastGames teamDetails={listScores} loading={loading} />
              </>
            )}
            <Advertisement />
            <LgNews />
            <Advertisement />
            <FlatVideo />
          </div>
          <div className="lg:col-span-2">
            <div>
              <LeaguePlayerShow />
            </div>
            <Registration />
            <div className="mt-10">
              <FollowUS />
            </div>
            <div className="py-5 md:py-10">
              <Workwith
                title="work with us"
                button="volunteer"
                link="/signupvolunteer"
              />
            </div>
            <Sponsors />

            <div className="my-10">
              <RenderShop />
            </div>
            <Sponsors />
          </div>
        </div>
      </div>
    </div>
  );
};
export default LeagueTeam;

const players = [
  {
    src: "/images/LgPl1.png",
    name: "NAME LASTNAME",
  },
  {
    src: "/images/LgPl2.png",
    name: "NAME LASTNAME",
  },
  {
    src: "/images/LgPl3.png",
    name: "NAME LASTNAME",
  },
];
