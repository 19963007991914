import React from "react";

const ArticleLoading = () => {
  return (
    <div className="border-b-2 border-[#E7E7E7] py-4">
      <div className="border-b-2 border-[#E7E7E7] py-4"></div>
      <div className="border-b-2 border-[#E7E7E7] py-4"></div>
      <div className="border-b-2 border-[#E7E7E7] py-4"></div>
      <div className="border-b-2 border-[#E7E7E7] py-4"></div>
      <div className="border-b-2 border-[#E7E7E7] py-4"></div>
    </div>
  );
};

export default ArticleLoading;
