/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { API, Storage } from "aws-amplify";
import * as queries from "../graphql/queries"; // Make sure to import your GraphQL queries
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useLocation } from "react-router-dom"; // Import useLocation

const useListScores = () => {
  const [listScores, setListScores] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation(); // Use useLocation to get the current URL

  useEffect(() => {
    const fetchScores = async () => {
      try {
        setLoading(true);
        const isPastTournaments = location.pathname === "/past-tournaments"; // Determine if the current path is /past-tournaments
        const isLiveTournaments = location.pathname === "/tournamentsScore"; // Determine if the current path is /past-tournaments
        const isPastOrLiveTournaments = [
          "/past-tournaments",
          "/tournamentsScore",
        ].includes(location.pathname);

        const isPastLeagues = location.pathname === "/past-leagues";
        const isLiveLeagues = location.pathname === "/leagues-score";
        console.log("isPastTournaments", isPastTournaments);
        console.log("isPastLeagues", isPastLeagues);
        console.log("isLiveTournaments", isLiveTournaments);
        console.log("isLiveLeagues", isLiveLeagues);

        const response = await API.graphql({
          query: queries.listScores,
          variables: {
            filter: {
              tournament: {
                eq: isPastOrLiveTournaments,
              },
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        let scores = response?.data?.listScores?.items || [];

        if (
          isPastTournaments ||
          isPastLeagues ||
          isLiveTournaments ||
          isLiveLeagues
        ) {
          scores = await Promise.all(
            scores.map(async (score) => {
              let eventRecord;

              if (isPastTournaments || isLiveTournaments) {
                const eventStatusResponse = await API.graphql({
                  query: queries.getTournamentLeague,
                  variables: {
                    id: score.eventId,
                  },
                  authMode: GRAPHQL_AUTH_MODE.API_KEY,
                });
                eventRecord = eventStatusResponse?.data?.getTournamentLeague;
              } else if (isPastLeagues || isLiveLeagues) {
                const leagueEventStatusResponse = await API.graphql({
                  query: queries.getLeague,
                  variables: {
                    id: score.eventId,
                  },
                  authMode: GRAPHQL_AUTH_MODE.API_KEY,
                });
                eventRecord = leagueEventStatusResponse?.data?.getLeague;
              }

              let checkStatus;

              if (isLiveTournaments || isLiveLeagues) {
                checkStatus = "EVENT_STARTED";
              } else if (isPastLeagues || isPastTournaments) {
                checkStatus = "EVENT_ENDED";
              }

              if (eventRecord?.eventStatus === checkStatus) {
                return score;
              }
              return null;
            })
          );
        }

        // Filter out null scores
        scores = scores.filter((score) => score !== null);

        // Fetch and set images and player details for each score
        const updatedScores = await Promise.all(
          scores.map(async (score) => {
            const { firstTeamDetails, secondTeamDetails } = score;
            const playerDetails = [...firstTeamDetails, ...secondTeamDetails];

            // Fetch images for player details
            await Promise.all(
              playerDetails.map(async (player) => {
                try {
                  const signedUrl = await getSignedUrl(player.playerImage);
                  player.profileLink = signedUrl;
                } catch (error) {
                  console.error("Error fetching player image:", error);
                }
              })
            );

            const signedUrl = await getSignedUrl(score.eventImage);
            score.eventImage = signedUrl;
            const firstTeamsUrl = await getSignedUrl(score.firstTeam.teamLogo);
            score.firstTeam.teamLogo = firstTeamsUrl;
            const secondTeamsUrl = await getSignedUrl(
              score.secondTeam.teamLogo
            );
            score.secondTeam.teamLogo = secondTeamsUrl;

            return {
              ...score,
              playerDetails,
              firstTeamDetails,
              secondTeamDetails,
            };
          })
        );

        setListScores(updatedScores);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error);
        console.error("Error fetching scores:", error);
      }
    };

    fetchScores();
  }, [location.pathname]); // Add location.pathname as a dependency

  // Function to get signed URL for images
  const getSignedUrl = async (link) => {
    try {
      const signedUrl = await Storage.get(link, {
        level: "public",
        download: false,
      });
      return signedUrl;
    } catch (error) {
      console.error("Error getting signed URL:", error);
      throw error;
    }
  };

  return { listScores, error, loading };
};

export default useListScores;
