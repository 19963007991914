import React from 'react'

const OrderDetails = () => {
  return (
    <div className='flex'>

    </div>
  )
}

export default OrderDetails