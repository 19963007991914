import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { API } from "aws-amplify";
import useAuthInfo from "./getUser";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useCampEvents = () => {
  const { isAuthenticated, userId } = useAuthInfo();
  const [campsEventsList, setCampsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const getCampsEventsList = async () => {
    setLoading(true);
    try {
      //   if (isAuthenticated && userId) {
      setLoading(true);
      const response = await API.graphql({
        query: queries.listCampEvents,
      });

      const campsData = response?.data?.listCampEvents;
      setCampsList(campsData?.items || []);
      // Set the usercampsItem correctly
      const campsItems = campsData?.items || [];
      //   }
    } catch (error) {
      console.error("Error", error);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // if (isAuthenticated && userId) {
    getCampsEventsList();
    // }
  }, []);

  return { getCampsEventsList, loading, campsEventsList };
};
