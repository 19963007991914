import React from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";

const getTimeAgo = (date) => {
  const currentDate = new Date();
  const timestamp = new Date(date);
  const timeDifference = currentDate - timestamp;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 1) {
    return `${days} days ago`;
  } else if (days === 1) {
    return "1 day ago";
  } else if (hours > 1) {
    return `${hours} hours ago`;
  } else if (hours === 1) {
    return "1 hour ago";
  } else if (minutes > 1) {
    return `${minutes} minutes ago`;
  } else if (minutes === 1) {
    return "1 minute ago";
  } else {
    return "just now";
  }
};

const NewsCard = ({ item }) => {
  return (
    <div className="flex items-center py-2 px-4 bg-white w-full h-[150px] rounded-lg border border-gray-200">
      <Link
        to={`/news/${item?.id}`}
        target="_blank"
        rel="noreferrer"
        className="w-full"
      >
        <div className="flex w-full  rounded-lg overflow-hidden">
          <div >

          {/* <LazyLoad className="h-[100px] w-[100px]  "> */}
            <img
              className="min-h-[100px] min-w-[100px] max-h-[100px] max-w-[100px] object-cover"
              src={item?.content_img_url?item.content_img_url:item.img_url[0]}
              alt={item?.title}
              />
          {/* </LazyLoad> */}
              </div>

          <div className="flex flex-col w-full justify-between flex-1 p-4">
            <h2 className="text-lg font-semibold text-gray-800 mb-3 truncate">
              {item?.title}
            </h2>

            <div className="text-sm text-gray-500">
              {getTimeAgo(item.date)}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default NewsCard;
