export const CREATE_CITY_REQUEST = 'CREATE_CITY_REQUEST';
export const CREATE_CITY_SUCCESS = 'CREATE_CITY_SUCCESS';
export const CREATE_CITY_FAIL = 'CREATE_CITY_FAIL';

export const GET_CITY_REQUEST = 'GET_CITY_REQUEST';
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS';
export const GET_CITY_FAIL = 'GET_CITY_FAIL';

export const LIST_CITIES_REQUEST = 'LIST_CITIES_REQUEST';
export const LIST_CITIES_SUCCESS = 'LIST_CITIES_SUCCESS';
export const LIST_CITIES_FAIL = 'LIST_CITIES_FAIL';

export const UPDATE_CITY_REQUEST = 'UPDATE_CITY_REQUEST';
export const UPDATE_CITY_SUCCESS = 'UPDATE_CITY_SUCCESS';
export const UPDATE_CITY_FAIL = 'UPDATE_CITY_FAIL';

export const DELETE_CITY_REQUEST = 'DELETE_CITY_REQUEST';
export const DELETE_CITY_SUCCESS = 'DELETE_CITY_SUCCESS';
export const DELETE_CITY_FAIL = 'DELETE_CITY_FAIL';
export const DELETE_CITY_RESET = 'DELETE_CITY_RESET';

export const CLEAR_CITY_ERRORS = 'CLEAR_CITY_ERRORS';
