import React from "react";
import { NavLink } from "react-router-dom";
import LazyLoad from "react-lazyload";

const MoreNewsCard = ({ item }) => {
  console.log('Image url: ' ,item)
  return (
    <div className="bg-white rounded-lg overflow-hidden shadow-md">
      <NavLink to={`/news/${item?.id}`}>
        {/* <LazyLoad className="w-full h-40 md:h-56"> */}
          <img
            className="w-full h-40 md:h-56  object-cover"
            src={item?.content_img_url? item.content_img_url :item.img_url[0]}
            alt={item?.title}
          />
        {/* </LazyLoad> */}
        <div className="p-4">
          <h2 className="text-lg md:text-xl font-bold uppercase mb-2 line-clamp-2">
          {item?.title.split(" ").slice(0, 10).join(" ")}
            {item?.title.split(" ").length > 10 ? "..." : ""}
          </h2>
          <p className="text-sm ">
          {item?.content_text?.split(" ").slice(0, 20).join(" ")}
            {item?.content_text?.split(" ").length > 20 ? "..." : ""}
          </p>
        </div>
      </NavLink>
    </div>
  );
};

export default MoreNewsCard;
