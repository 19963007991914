export const REGISTER_TRAININGPROGRAM_REQUEST = 'REGISTER_TRAININGPROGRAM_REQUEST';
export const REGISTER_TRAININGPROGRAM_SUCCESS = 'REGISTER_TRAININGPROGRAM_SUCCESS';
export const REGISTER_TRAININGPROGRAM_FAIL = 'REGISTER_TRAININGPROGRAM_FAIL';

export const UPDATE_TRAININGPROGRAM_REQUEST = 'UPDATE_TRAININGPROGRAM_REQUEST';
export const UPDATE_TRAININGPROGRAM_SUCCESS = 'UPDATE_TRAININGPROGRAM_SUCCESS';
export const UPDATE_TRAININGPROGRAM_FAIL = 'UPDATE_TRAININGPROGRAM_FAIL';

export const GET_TRAININGPROGRAM_REQUEST = 'GET_TRAININGPROGRAM_REQUEST';
export const GET_TRAININGPROGRAM_SUCCESS = 'GET_TRAININGPROGRAM_SUCCESS';
export const GET_TRAININGPROGRAM_FAIL = 'GET_TRAININGPROGRAM_FAIL';

export const LOAD_TRAININGPROGRAMS_REQUEST = 'LOAD_TRAININGPROGRAMS_REQUEST';
export const LOAD_TRAININGPROGRAMS_SUCCESS = 'LOAD_TRAININGPROGRAMS_SUCCESS';
export const LOAD_TRAININGPROGRAMS_FAIL = 'LOAD_TRAININGPROGRAMS_FAIL';

export const DELETE_TRAININGPROGRAM_REQUEST = 'DELETE_TRAININGPROGRAM_REQUEST';
export const DELETE_TRAININGPROGRAM_SUCCESS = 'DELETE_TRAININGPROGRAM_SUCCESS';
export const DELETE_TRAININGPROGRAM_FAIL = 'DELETE_TRAININGPROGRAM_FAIL';
export const DELETE_TRAININGPROGRAM_RESET = 'DELETE_TRAININGPROGRAM_RESET';

export const CLEAR_TRAININGPROGRAM_ERRORS = 'CLEAR_TRAININGPROGRAM_ERRORS';
